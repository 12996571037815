import styled from "styled-components";

import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";
import { Link, Button } from "components/elements";
import BgDonate from "assets/images/bg-dontate.png";

const { baseSpacing: bs } = defaultShevy;

const LandingContainer = styled.div`
  transition: background-color 0.5s ease-in-out;

  &.save-date {
    background-color: white;
  }
  &.u-hou {
    background-color: #b6e4f3;
  }
  &.our-scope {
    background-color: #fff;
  }

  &.explore-topics {
    background-color: #ecd1d4;
  }

  &.community-voices {
    background-color: #aac9ef;
  }

  &.highlight-events {
    background-color: #beefcf;
  }

  .section {
    .title {
      position: relative;
    }

    &.save-date {
      display: none;
      background-color: #112e51;
      color: white;
      margin: ${bs(1.85)} ${bs()} 0;
      padding: 50px 30px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        border-width: 0 24px 24px 0;
        border-style: solid;
        border-color: #25456b #fff;
      }

      @media ${device.laptop} {
        margin: 0;
        padding: 27px 0;

        &:before {
          content: none;
        }
      }

      .content {
        display: flex;
        flex-direction: column;

        @media ${device.laptop} {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }

      div {
        &.first-column {
          p.text-uhou-event {
            color: #7ac896;
            font-size: 1.13em;
            font-weight: 900;
          }

          p.text-save-date {
            line-height: 1;
            margin-bottom: 8px;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 0.2px;
          }

          @media ${device.laptop} {
            p.text-uhou-event {
              margin-bottom: ${defaultShevy.baseSpacing(0.5)};
            }
          }
        }
        &.second-column {
          color: #fff;
          p {
            font-size: 0.72em;
            line-height: 1.75;
          }

          @media ${device.laptop} {
            margin-bottom: 0;
          }
        }

        &.third-column {
          > div {
            display: inline-block;
            text-align: center;
          }

          span {
            margin-top: 13px;
            display: block;
            font-size: 15px;
            line-height: 21px;
            color: #fff;

            ${Link} {
              color: white;
              font-weight: bold;
            }
          }

          ${Button} {
            height: ${bs(1.9)};
            white-space: nowrap;
          }

          @media ${device.laptop} {
            text-align: center;

            > div {
              display: block;
            }
          }
        }
      }
    }

    &.donate {
      background-image: url(${BgDonate});
      background-size: cover;
      background-position: center;
      margin: 0 -${bs()};
      height: ${bs(25)};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;

      @media ${device.tablet} {
        height: ${bs(24)};
      }

      h1 {
        font-size: 2.2em;
        margin-bottom: ${bs(0.5)};

        @media ${device.tablet} {
          margin-bottom: ${bs(0.75)};
        }
      }

      p {
        line-height: 1.54em;
        margin-bottom: ${bs(1.5)};
        text-align: center;
        max-width: 11em;

        @media ${device.tablet} {
          text-align: left;
          max-width: initial;
          margin-bottom: ${bs(1.15)};
        }
      }

      button {
        padding: 0 1.54em;
        height: ${bs(2.4)};
      }
    }
  }
`;

export default LandingContainer;
