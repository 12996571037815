import React, { createRef, useEffect } from "react";
import { cleanHTML } from "helpers/content";
import { useHistory } from "react-router-dom";

import WPContainer from "./index.style";

const WPContent = React.forwardRef(
  ({ children, className = "", cleanContent, ...props }, ref) => {
    const contentRef = ref || createRef();
    const history = useHistory();
    const goToContent = (clickEvent) => {
      if (clickEvent.target.dataset.linkMode !== "router") {
        return;
      }

      if (clickEvent.target.hash) {
        // TODO, this should be using history as well, but for some reason
        // has weird link jumping currently when using frontend routing.
        // Does this for full page default load as well, but less apparent jumping
        // since new page loads faster.
        return;
      }

      clickEvent.preventDefault();

      history.push(
        `${clickEvent.target.pathname}${clickEvent.target.hash ||
          ""}${clickEvent.target.search || ""}`
      );
    };

    useEffect(() => {
      contentRef.current.addEventListener("click", goToContent);
    }, []);

    const childrenHTML =
      (cleanContent && cleanHTML(children || "", "/")) || children;

    return (
      <WPContainer
        ref={contentRef}
        className={`wp-content ${className}`}
        dangerouslySetInnerHTML={{
          __html: childrenHTML,
        }}
        {...props}
      />
    );
  }
);

export default WPContent;
