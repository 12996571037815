import styled from "styled-components"

import Input from "components/input"
import { defaultShevy } from "styles/global.style"
import { device } from "styles/breakpoints"
import IconDown from "assets/images/sub-topic/arrow-down.svg"

import PageContainer from "../page.style"
import VoteContainer from "./vote.style"

const { baseSpacing: bs } = defaultShevy

const SubTopicContainer = styled(PageContainer)`
  &.subtopic-vulnerability-impacts .banner {
    background-position: center center;
  }
  &.subtopic-response-recovery .banner {
    background-position: bottom center;
  }
  &.subtopic-covid-19 .banner {
    background-position: bottom center;
  }
  .banner {
    background-color: lightgrey;
    background-size: cover;
    background-position: top center;
    height: ${bs(20)};
    margin: 0 -${bs()};

    @media ${device.tablet} {
      height: ${bs(30)};
    }

    @media ${device.laptopL} {
      height: ${bs(42)};
    }
  }

  sup {
    font-size: 0.6em;
  }

  ${VoteContainer} {
    margin-right: 0;
    margin-bottom: 50px;
    @media ${device.laptopL} {
      display: none;
    }
  }

  .mobile-hidden {
    display: none;

    @media ${device.laptopL} {
      display: block;
    }
  }

  section {
    &.content {
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin: 0;

      > div {
        width: 100%;

        .sticky-container {
          height: 100%;
          background: white;

          @media ${device.laptopL} {
            position: absolute;
            z-index: 90;
            right: auto;
            max-width: 340px;
            width: 340px;
            left: 0;
            right: calc(100% - 340px);
          }

          @media ${device.desktop} {
            position: absolute;
            z-index: 90;
            right: auto;
            max-width: 340px;
            width: 340px;
            left: 0;
            right: calc(100% - 340px);
          }
        }
      }

      @media ${device.laptop} {
        flex-direction: row;
        margin-top: -${bs(15)};
        width: 92%;
        margin-right: 8%;
        margin-left: 0;
      }

      @media ${device.laptopL} {
        width: auto;
        max-width: 92%;
        margin-left: 320px;
        margin-right: auto;
        border-left: 1px solid #ededed;
      }

      .vote-container {
        padding: 10px 0 5px;
        width: 30%;
        ${"" /* display: flex;
        flex-direction: row;
        @media ${device.laptopL} {
          display: block;
        } */}

        @media ${device.laptopL} {
          background: #E8F0F3;
          padding: 30px;
          width: 100%;
        }

        h4 {
          font-size: 1.27em;
          margin-bottom: 8.5px;
          font-weight: 700;
          font-size: 0.9em;
          font-weight: 700;
          line-height: 1.4em;
        }

        p.sm {
          border-bottom: 1px solid #d6d7d8;
          padding-bottom: 18px;
          font-size: 0.65em;
          line-height: 1.5;
        }

        &.sticky {
          transform: translateZ(0px);
          position: fixed;
          bottom: 0px;
          left: 0px;
          width: 340px;
        }

        .vote-intro {
          @media ${device.laptopL} {
          }
        }

        .vote-box {
          flex-direction: column;
          margin: 0;
          display: flex;
          padding: 0;
          justify-content: center;
          vertical-align: middle;
          position: relative;
          align-items: normal;

          .vote-count {
            background: #fff;
            align-items: center;
            display: inline-block;
            width: 46px;
            height: 46px;
            position: absolute;
            left: -10px;
            top: -10px;
            border-radius: 50%;
            color: #112E51;
            overflow: hidden;
            transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
            transition-delay: 0.2s;
            box-shadow: 0 1px 2.2px rgba(0, 0, 0, 0.008),0 2.3px 5.3px rgba(0, 0, 0, 0.012),0 4.4px 10px rgba(0, 0, 0, 0.015), 0 7.8px 17.9px rgba(0, 0, 0, 0.018),0 14.6px 33.4px rgba(0, 0, 0, 0.022),0 35px 80px rgba(0, 0, 0, 0.03);
            text-align: center;

            p {
              margin: 0;
              padding: 0;
              font-weight: 700;
              width: 100%;
              font-size: 0.6em;

              @media ${device.laptopL} {
                width: auto;
                padding: 30px;
                font-size: 0.7em;
                line-height: 46px;
              }
            }
          }
          .vote-actions {
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            width: 100%;
            text-align: center;

            @media ${device.laptopL} {
              padding: 0;
              display: block;
              flex-direction: row;
              align-items: baseline;
            }

            button {
              width: 100%;
              margin: auto;
              background: #112E51;
              color: #fff;
              font-size: 0.65em;

              @media ${device.laptopL} {
                width: 100%;
                font-size: 0.7em;
              }
            }

            p.sm {
              font-size: 0.6em;
              margin: 5px auto 0;
              border-radius: 3px;
              border-bottom: 0;
              padding: 3px;
              background-color: rgba(255,255,255,0.9);
              box-shadow: 0 1px 2.2px rgba(0, 0, 0, 0.008),0 2.3px 5.3px rgba(0, 0, 0, 0.012),0 4.4px 10px rgba(0, 0, 0, 0.015), 0 7.8px 17.9px rgba(0, 0, 0, 0.018),0 14.6px 33.4px rgba(0, 0, 0, 0.022),0 35px 80px rgba(0, 0, 0, 0.03);
              text-align: center;
              width: 100%;

              @media ${device.laptopL} {
                order: 2;
                background: none;
                width: auto;
                box-shadow: none;
                border-radius: 0;
                margin: 10px 0 0;
                padding: 0;
                font-size: 0.6em;
              }
            }
          }
        }
      }

      .sidebar-wrapper {
        z-index: 90;
        margin-top: 0;

        .progressbar-container {
          opacity: 0;
          display: none;
        }

        .vote-container {
          display: none;

          @media ${device.laptopL} {
            display: block;
          }
        }
      
        &.sticky {
          @media only screen and (max-width: 1439px) {
            left: 0 !important;
            width: 100% !important;
            top: auto !important;
            bottom: 0 !important;
          }

          .sidebar {
            @media ${device.laptopL} {
              
            }
          }

          .vote-container {
            display: block;
          }

          .progressbar-container {
            background-color: ${(props) => props.theme.other.ug100};
            position: fixed;
            opacity: 1;
            display: block;
            transition: opacity 0.6s;
            bottom: 100vh;
            margin-bottom: -9px;
            width: 100%;

            @media ${device.laptopL} {
              bottom: auto;
              margin: 0;
              width: 100%;
              top: 0;
            }

            .progressbar-progress {
              background-color: ${(props) => props.theme.other.ug} !important;
              height: 9px !important;
            }
          }
        }

        .sidebar {
          width: 100%;
          z-index: 90;

          @media ${device.laptopL} {
            background-color: white;
          }
          }

          .search-container {
            padding: 0 ${bs()};
            
            @media ${device.laptopL} {
            }

            ${Input} {
              background-color: #f8f8f8;
              font-size: 18px;
            }

            @media ${device.mobileL} {
              ${Input} {
                width: 100%;
              }
            }
          }

          .section-list {
            padding: ${bs()} 0 ${bs()} ${bs(2)};
            display: none;

            @media ${device.laptopL} {
              display: block;
            }

            h5 {
              font-size: 0.9em;
              margin-bottom: 10px;
              cursor: pointer;
              font-weight: normal;

              a.active,
              &.active {
                font-weight: bold;
              }
            }

            ul {
              list-style: none;
              padding: 0;
              position: relative;
              margin-top: 0;

              &:before {
                position: absolute;
                content: "";
                left: 10px;
                top: 12px;
                bottom: 12px;
                border: 0.5px dashed #322e6c;
              }

              li {
                position: relative;
                margin: 0;
                font-size: 0.8em;
                width: 100%;
                padding: 0;
                display: block;

                a {
                  margin: 0;
                  cursor: pointer;
                  padding: 7px 30px;
                  display: block;
                }
                &.active,
                a.active {
                  font-weight: bold;
                }

                &:before {
                  position: absolute;
                  background: white;
                  content: "";
                  width: 7px;
                  height: 7px;
                  border-radius: 50%;
                  border: 1px solid #159dbf;
                  top: 50%;
                  left: 7px;
                  transform: translateY(-50%);
                }

                &.parent {
                  padding-left: 0;
                  background: white;

                  a {
                    padding-left: 0;
                  }

                  &:before {
                    content: none;
                  }
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }

          ${VoteContainer} {
            display: none;

            @media ${device.laptopL} {
              display: block;
              margin-right: 4em;
              margin-bottom: 0;
            }
          }
        }
      }

      .main-content {
        width: 100%;
        position: relative;
        padding-left: 0;
        max-width: 100%;
        width: 92%;
        margin-right: 8%;
        margin-left: auto;
        padding-bottom: 200px;

        @media ${device.mobileL} {
          width: 100%;
        }

        @media ${device.tablet} {
          width: 92%;
        }

        @media ${device.laptop} {
          width: calc(80% + 60px);
        }

        .page-thumbs {
          padding: 5px 0 5px 20px;
          position: absolute;
          top: 0;
          left: -1px;
          width: 100%;
          transform: translateY(-100%);

          @media ${device.tablet} {
            width: calc(100% - 40px);
            top: 0;
            left: -1px;
          }

          @media ${device.laptop} {
            left: -10px;
            padding: 0 0 0 20px;
            margin-left: -25px;
            width: calc(100% - 40px);
            top: 0px;
          }

          @media ${device.laptopL} {
            left: 0;
            padding: 0 0 0 20px;
            margin-left: -25px;
            width: calc(100% - 40px);
            top: 1px;
          }
        }

        .page-content {
          position: relative;
          padding-top: 30px;

          .wrapper {
            margin: 0 auto;
            width: 90%;
            padding: ${bs()} 0;

            @media ${device.laptop} {
              width: 100%;
              margin: 0 auto;
            }

            @media only screen and (min-width: 1200px) and (max-width: 1400px) {
              max-width: 100%;
            }

            @media only screen and (min-width: 1590px) {
              max-width: 100%;
            }

            a {
              border-bottom: 2px solid ${(props) => props.theme.accent.pear};
              text-decoration: none;
              cursor: pointer;

              &:hover {
                background: ${(props) => props.theme.primary.spaceCadet};
                border-color: ${(props) => props.theme.primary.spaceCadet};
                color: ${(props) => props.theme.accent.pear};
              }
            }

            p {
              line-height: 1.8;
              font-size: 0.9em;
            }

            li {
              line-height: 1.8;
              font-size: 0.9em;
            }

            h3 {
              font-size: 1.27em;

              &.bigger-h3 {
                font-size: 2.27em;
              }
            }

            .alert-message {
              padding-left: 31px;
              border-left: 6px solid ${(props) => props.theme.accent.seaGreen};
              margin: 60px auto 60px 45px;
              max-width: 630px;

              h3, h4 {
                font-size: 1.2em !important;
                margin: 0 0 10px !important;
                line-height: 1.4 !important;
              }

              p {
                margin-bottom: 0 !important;
                font-size: 0.8em !important;
              }
            }

            .end-note {
              margin: 60px 0 30px;

              p {
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 1rem;
                line-height: 1.6rem;
              }

              ol {
                list-style-type: decimal;
                padding-left: 1em;
                margin: 0;
                font-size: 1rem;
                line-height: 1.6rem;

                li {
                  margin-bottom: 15px;
                  font-size: 1rem;
                  line-height: 1.6rem;
                }
              }
            }

            .text-2-column {
              display: flex;
              flex-direction: column;
              margin-bottom: 80px;

              h3 {
                font-weight: normal;
              }

              @media ${device.tablet} {
                flex-direction: row;

                h3 {
                  margin-right: 38px;
                  width: 290px;
                  flex-shrink: 0;
                  margin-bottom: 0;
                }
              }
            }

            .over-wrapper {
              @media ${device.laptopL} {
                width: 108%;
                margin: 0 0 0 -3%;
              }

              .statuses {
                display: flex;
                flex-direction: column;

                @media ${device.tablet} {
                  flex-direction: row;
                  margin: 60px -10px 100px;
                }

                .status {
                  background-color: white;
                  margin-bottom: ${bs()};
                  border-radius: 10px;
                  overflow: hidden;
                  flex: 1;
                  display: flex;
                  flex-direction: column;

                  &:hover {
                    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                  }

                  @media ${device.tablet} {
                    margin: 0 10px;
                  }

                  img {
                    width: 100%;
                  }

                  .text {
                    padding: 30px;
                    p {
                      font-size: 0.8em;
                      line-height: 1.5;
                    }

                    a {
                      font-size: 0.9em;
                      font-weight: 700;

                      &:after {
                        content: url(${IconDown});
                        margin-left: 5px;
                        top: -4px;
                        position: relative;
                      }

                      &:hover {
                        &:after {
                          filter: brightness(0) invert(1);
                        }
                      }
                    }
                  }
                }
              }
            }

            .resources-slider {
              padding-top: 80px;

              #resources_slider_original {
                @media ${device.tablet} {
                  margin-left: -20px;
                }
              }

              .slick-list {
                width: 100%;
                margin: 20px 0 40px;
                box-shadow: 0 14px 30px 0 rgba(0, 0, 0, 0.14);

                @media ${device.tablet} {
                  width: 150%;
                  margin: 0;
                  padding: 20px 0 40px 20px;
                  box-shadow: none;
                }
              }

              .slick-arrow {
                bottom: -80px;

                &.slick-prev {
                  left: auto;
                  right: 50px;
                  top: auto;
                }

                &.slick-next {
                  right: 0;
                  top: auto;
                }

                @media ${device.mobileL} {
                  bottom: auto;

                  &.slick-prev {
                    top: -58.5px;
                  }

                  &.slick-next {
                    top: -58.5px;
                  }
                }
              }

              .slick-slide {
                outline: none;

                @media ${device.tablet} {
                  margin-right: 20px;
                }
              }

              .slide-content {
                background: white;
                min-height: 150px;
                border-radius: 3px;
                padding: 25px 30px;

                @media ${device.tablet} {
                  width: 342px;
                  box-shadow: 0 14px 30px 0 rgba(0, 0, 0, 0.14);
                }

                h4 {
                  font-size: 0.9em;
                  font-weight: 600;
                  line-height: 1.4em;
                  margin: 0;

                  p {
                    margin: 0;
                    line-height: 30px;
                    display: none;
                    font-size: 0.9em;
                    font-weight: 600;
                    line-height: 1.4em;
                    margin: 0;

                    span {
                      font-size: 0.9em;
                      font-weight: 600;
                      line-height: 1.4em;
                      margin: 0;
                    }

                    &:first-child {
                      display: block;
                    }

                    a {
                      border-bottom: none;
                    }
                  }
                }

                p {
                  font-weight: 600;
                  margin-bottom: 0;
                  font-size: 0.9rem;
                  margin-top: 10px;

                  span {
                    font-weight: 600;
                    font-size: 0.9rem;
                    margin-top: 10px;
                  }

                  &.xs {
                    font-weight: normal;
                    font-size: 0.9rem;
                    margin-top: 10px;
                    opacity: 0.8;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .get-involved {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 50px;
    margin-top: 0;

    @media ${device.tablet} {
      padding-top: 0;
    }

    @media only screen and (min-width: 1580px) {
      padding-top: 0;
    }

    @media ${device.laptopL} {
      margin-left: -${bs()};
      margin-right: -${bs()};
      margin-bottom: 110px;
      margin-top: 0;
    }

    .wrapper {
      color: white;
      padding: ${bs()};
      background-color: ${(props) => props.theme.primary.spaceCadet};
      display: flex;
      flex-direction: column;
      padding: 40px;

      @media ${device.laptopL} {
        margin-left: 300px;
        max-width: 1400px;
        position: relative;
      }

      @media ${device.desktop} {
        margin-left: 400px;
        max-width: 1400px;
        position: relative;
      }

      .content {
        margin-bottom: ${bs()};
        order: 2;
        @media ${device.tablet} {
          max-width: 350px;
          margin-bottom: 0;
        }

        @media ${device.laptop} {
          max-width: 500px;
        }

        @media ${device.laptopL} {
          padding: 100px 0 80px 100px;
        }
      }

      p {
        margin-bottom: 30px;
      }

      img {
        width: 100%;
        order: 1;
        margin-bottom: 40px;

        @media ${device.mobileL} {
          margin-bottom: 0;
        }

        @media ${device.tablet} {
          width: 300px;
          order: 2;
          position: absolute;
          right: 50px;
          bottom: 80px;
          height: auto;
        }

        @media only screen and (min-width: 820px) {
          width: 350px;
        }

        @media ${device.laptop} {
          width: 400px;
        }

        @media ${device.laptopL} {
          width: 550px;
          right: 80px;
          bottom: 120px;
        }

        @media only screen and (min-width: 1580px) {
          width: 640px;
          bottom: 80px;
        }
      }
    }
  }
`

export default SubTopicContainer
