import styled from "styled-components";
import { rgba } from "polished";

import { defaultShevy } from "styles/global.style";
import theme from "styles/theme";
import { device } from "styles/breakpoints";

import { CardShadow } from "components/card/index.style";

const { baseSpacing: bs } = defaultShevy;

const MultiPanelContainer = styled(CardShadow)`
  [role="tablist"] {
    display: flex;
    justify-content: space-around;

    @media ${device.laptop} {
      padding: 0 1em;
    }
    @media ${device.laptopL} {
      padding: 0 105px;
    }
    & + div {
      padding: 0 calc(1em - 1rem);
      @media ${device.laptopL} {
        padding: 0 calc(105px - 1rem);
      }
    }

    button {
      font-size: 0.8em;

      @media ${device.tablet} {
        flex: 1;
        line-height: 2em;
        font-size: 1em;
      }
      background: transparent;
      cursor: pointer;

      color: ${theme.primary.spaceCadet};
      border: none;
      border-bottom: 5px solid ${rgba(theme.secondary.ghcfBlue, 0.25)};
      transition: 200ms ease-in-out border-color;

      font-family: inherit;

      padding-bottom: 0.25em;
      padding-top: 1em;

      @media ${device.laptopL} {
        font-size: 1.25em;
        padding-bottom: 0.7em;
        padding-top: 56px;
      }

      &[aria-selected="true"] {
        font-weight: 600;
      }

      &[aria-selected="true"],
      &:hover {
        border-color: ${theme.secondary.ghcfBlue};
      }
    }
  }
  [role="tabpanel"] {
    overflow: hidden;

    > div {
      padding-top: 1.5em;
      padding-bottom: ${bs(2)};

      @media ${device.laptopL} {
        padding-top: 2.5em;
      }
    }

    .content {
      display: flex;
      align-items: center;
    }
  }
`;

export default MultiPanelContainer;
