import styled from "styled-components";
import { Link } from "components/elements";

const WPContentContainer = styled.div`
  a {
    ${Link.componentStyle.rules}
  }
`;

export default WPContentContainer;
