import styled from "styled-components";
import { device } from "styles/breakpoints";

const ContactContainer = styled.div`
  .image-wrapper {
    order: -1;
    @media ${device.tablet} {
      order: 0;
    }
  }
`;

export default ContactContainer;
