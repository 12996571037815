import React from "react";
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import Truncate from "react-truncate-html";
import { Link } from "react-router-dom";

import ImgWeAre from "assets/images/bigpicture/img-we-are.png";
import { Link as StyledLink } from "components/elements";

import ExploreTopicsContainer from "./index.style";

const ExploreTopics = () => {
  return (
    <ExploreTopicsContainer>
      <div className="wrapper">
        <div className="cta">
          <div className="cta-wrapper">
            <div className="container">
              <Grid>
                <Row>
                  <Col small={12} md={6} className="column">
                    <img src={ImgWeAre} alt="we-are" />
                  </Col>
                  <Col small={12} md={6} className="column">
                    <div className="content">
                      <h3>
                        We are <b>always listening!</b>
                      </h3>
                      <p>
                        Understanding Houston will evolve over time based on
                        user feedback and your input. Let us know what matters
                        to you!
                      </p>
                      <StyledLink as={Link} to="/contact">
                        Contact us
                      </StyledLink>
                    </div>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </ExploreTopicsContainer>
  );
};

export default ExploreTopics;
