import React, { useState, useEffect, Component } from "react";
import { Redirect } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";

// import { rgba } from "polished";

import { Link } from "components/elements";
import { Content, SimpleCrumbs, WPContent } from "components";

import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import numbersToWords from "number-to-words";

import TopicContainer from "./index.style";
import SubtopicDiplay from "./components/subtopic-display";
import {
  CommunityVoicesHighlight,
  CommunityVoicePostAcf,
} from "./components/blog-highlight-display";

import axios from "axios";
import ImgLoader from "assets/images/spinner-opacity.gif";
import Button from "components/elements/button";
import { toast } from "react-toastify";
import VoteContainer from "./vote.style";
import { getPostWithIdAPI } from "services/helpers/apis";
import { Helmet } from "react-helmet";

const SubTopics = (props) => {
  const [activeItem, setActiveItem] = useState("");
  const subtopics = props.content[props.subtopics] || [];
  return (
    <React.Fragment>
      <Row className="content container">
        <Col xs={12} lg={10} lgOffset={1}>
          <h2>
            There are {numbersToWords.toWords(subtopics.length)} subtopics for
            <br />
            {props.topic.title.rendered.toLowerCase()} in Houston
          </h2>
        </Col>
      </Row>
      <Row className="container">
        <Col className="inner-wrapper" xs={12} lg={10} lgOffset={1}>
          <Row className="container">
            {sortBy(subtopics || [], "menu_order").map((subtopic) => (
              <SubtopicDiplay
                subtopic={subtopic}
                key={subtopic.id}
                topic={props.topic}
                isActive={activeItem === subtopic.id}
                setActiveItem={setActiveItem}
              />
            ))}
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const TopicDataDownloadLink = (props) => {
  if (!props.topic.data_download_url || props.topic.hide_data_download_link) {
    return null;
  }

  return (
    <Link
      title={props.topic.data_download_title}
      href={props.topic.data_download_url}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Download the Full Report (opens in a new tab)"
    >
      {props.topic.data_download_text || "Download the Full Report"}
    </Link>
  );
};

class TopicVote extends Component {
  state = {
    votes: 0,
  };

  async componentDidMount() {
    try {
      const name = this.props.name || "Arts";
      const getVotes = await axios.post("/.netlify/functions/votes", {
        name: name,
        subtopic: true,
      });
      this.setState({
        votes: getVotes.data.votes,
      });
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {}

  handleVote = async (event) => {
    if (event.classList.contains("loading")) return false;
    event.classList.add("loading");
    event.style = "background-color: #324d6a";
    event.innerHTML = `<img src=${ImgLoader} alt="" width="30px" />`;
    // axios.post('/.netlify/functions/subscribe', data)
    const tmpSubtopic = document.createElement("textarea");
    tmpSubtopic.innerHTML = this.props.name;
    const subtopic = tmpSubtopic.value;
    const email = localStorage.getItem("email") || false;
    const tracking = localStorage.getItem("user") || false;
    const data = {
      isVoting: true,
      upVote: true,
      downVote: false,
      subtopic: subtopic,
      email: email,
      user: tracking,
    };
    window.track.buttonClicked({
      clickText: "Vote",
      clickPath: window.utils.url.getLocation().pathname,
    });
    const subscriber = await axios
      .post("/.netlify/functions/subscribe", data)
      .then((res) => {
        localStorage.setItem("email", res.data.email);
        localStorage.setItem("user", res.data.user);
        return res.data;
      });
    const user = await axios
      .post("/.netlify/functions/user", subscriber)
      .then((re) => {
        return {
          subtopic: subtopic,
          topics: re.data.topics,
          tracking: re.data.user,
        };
      });
    const db = await axios.post("/.netlify/functions/vote", user).then((r) => {
      event.style = "background-color: #112E51";
      event.innerHTML = `Vote`;
      event.classList.remove("loading");
      return r.data.success;
    });
    const msg = db
      ? "Thanks for voting!"
      : "Oops, you've already voted for this subtopic.";
    const type = db ? "success" : "error";
    toast(msg, {
      type: type,
      className: "notify-default",
      position: "bottom-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      newestOnTop: true,
    });
    const getVotes = await axios.post("/.netlify/functions/votes", {
      name: this.props.name,
      subtopic: true,
    });
    this.setState({
      votes: getVotes.data.votes,
    });
  };

  render() {
    const { votes } = this.state;
    const votesText = Number(votes) === 1 ? "vote" : "votes";

    return (
      <VoteContainer>
        <div className="vote-container">
          <div className="vote-intro">
            <h4>Vote for what matters to you</h4>
            <p className="sm">
              What issues do you think need attention, resources, and
              collaborative action?
            </p>
          </div>
          <div className="vote-box">
            <div className="vote-actions">
              <Button
                className="vote"
                onClick={(e) => this.handleVote(e.target)}
              >
                Vote
              </Button>
              <p className="sm">
                <strong>{`${votes} ${votesText}`}</strong>
              </p>
            </div>
          </div>
        </div>
      </VoteContainer>
    );
  }
}

const TopicDisplay = (props) => {
  const topicContent = props.content[props.topic][0];
  const [communityVoicePost, setCommunityVoicePost] = useState(false);
  useEffect(() => {
    if (!props.content || !props.topic) return;
    async function fetchData() {
      try {
        const getCommunityVoices = await getPostWithIdAPI(
          topicContent.acf.community_voice.ID
        );
        setCommunityVoicePost(getCommunityVoices.data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [props]);

  if (!topicContent) {
    return <Redirect to="/404" />;
  }

  const subtopicsName = `${topicContent.slug}--subtopics`;
  const subtopicGetters =
    (isEmpty(topicContent.children) && [
      {
        path: `pages/?parent=${topicContent.id}`,
        name: subtopicsName,
      },
    ]) ||
    [];

  const style = (isEmpty(topicContent.better_featured_image) && {
    backgroundColor: topicContent.acf.topic_border_color,
  }) || {
    backgroundImage: `url(${topicContent.better_featured_image.source_url})`,
  };

  const subtopicsStyle = {
    // backgroundColor: rgba(topicContent.acf.topic_border_color, 0.1)
  };

  return (
    <>
      <Helmet>
        <title>{topicContent.title.rendered} | Understanding Houston</title>
        <meta name="description" content={topicContent.acf.topic_summary} />
      </Helmet>
      <div className="banner" style={style} />
      <section className="topic--summary">
        <Row className="container">
          <Col xs={12} md={11} mdOffset={1} className="nav-wrapper">
            <SimpleCrumbs
              style={{
                backgroundColor: "#112E51",
              }}
              items={[
                {
                  label: "Explore the Data",
                },
                {
                  label: topicContent.title.rendered,
                  to: `/topic/${topicContent.slug}`,
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="content container">
          <Col xs={12} sm={12} smOffset={0} lg={10} lgOffset={1}>
            <WPContent>{topicContent.content.rendered}</WPContent>
            <TopicDataDownloadLink topic={topicContent.acf} />
            <TopicVote name={topicContent.title.rendered} />
          </Col>
        </Row>
      </section>
      <section className="topic--subtopics" style={subtopicsStyle}>
        <Content getters={subtopicGetters} useMocks={props.useMocks}>
          <SubTopics
            subtopics={subtopicsName}
            topic={topicContent}
            content={{
              [`${subtopicsName}`]: topicContent.children,
            }}
          />
        </Content>
      </section>

      {communityVoicePost ? (
        <section className="community-voices">
          <CommunityVoicePostAcf content={communityVoicePost} />
        </section>
      ) : (
        <section className="community-voices">
          {topicContent.slug && (
            <CommunityVoicesHighlight topicSlug={topicContent.slug} />
          )}
        </section>
      )}
    </>
  );
};

const Topic = (props) => {
  const { topic } = props.match.params;
  const queryParams = queryString.parse(props.location.search);
  const useMocks = queryParams.useMocks === "true";

  return (
    <TopicContainer className={`topic topic-${topic}`}>
      <Content
        useMocks={useMocks}
        getters={[
          {
            path: `pages/?slug=${topic}`,
            name: topic,
          },
        ]}
        error={<Redirect to="/404" />}
      >
        <TopicDisplay topic={topic} useMocks={useMocks} />
      </Content>
    </TopicContainer>
  );
};

const TopicWithProps = (props) => {
  const content = {
    [`${props.data.slug}`]: [props.data],
  };

  return (
    <TopicContainer className={`topic topic-${props.data.slug}`}>
      <TopicDisplay topic={props.data.slug} content={content} />
    </TopicContainer>
  );
};

export { Topic };
export default TopicWithProps;
