import React from "react";
import { Content } from "components";

import { getPageAPI, getAdvisoryCommittesAPI } from "services/helpers/apis";
import InitiativeContainer from "./index.style";
import InitiativeContents from "./contents";
import { Helmet } from "react-helmet";

const defaultPageData = {
  title: {
    rendered: "The Initiative",
  },
  content: {
    rendered: "",
  },
  excerpt: {
    rendered: "",
  },
  acf: {
    banner_content:
      '<p class="sm">The Initiative</p>\n<h1>Our purpose</h1>\n<p>Building a more vibrant Houston region<br />\nwith opportunity for all.</p>\n',
    approach_one: {
      image: {},
    },
    approach_two: {
      image: {},
    },
    tabs: [],
    image: false,
    content: "",
    share_link: "",
    who_we_are_content: {
      image: {},
    },
    share_story_content: {
      image: {},
    },
  },
};

const InitiativeDisplay = (props) => {
  return (
    <InitiativeContainer id="main-content">
      <InitiativeContents
        data={props.content.initiative}
        committees={props.content.committees}
      />
    </InitiativeContainer>
  );
};

const Initiative = () => {
  return (
    <Content
      getters={[
        {
          name: "initiative",
          makeGetter: function() {
            return getPageAPI(635);
          },
        },
        {
          name: "committees",
          makeGetter: function() {
            return getAdvisoryCommittesAPI();
          },
        },
      ]}
    >
      <Helmet>
        <title>Who We Are | Understanding Houston</title>
        {/* <meta name="description" content={} /> */}
      </Helmet>
      <InitiativeDisplay
        content={{
          initiative: defaultPageData,
          committees: [],
        }}
      />
    </Content>
  );
};

export default Initiative;
