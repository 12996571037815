import styled from "styled-components";

import theme from "styles/theme";
import { device } from "styles/breakpoints";

import IconArrowDown from "assets/images/icon-down-arrow.svg";

const NavigationContainer = styled.section`
  .slick-track {
    height: 170px;
  }

  .slick-slide {
    opacity: 0.4;

    .navigation-card--about {
      &::after {
        position: absolute;
        font-size: 0.81em;
        font-weight: 800;
        content: "Explore " url(${IconArrowDown});
        transform: translate(0, -20px);
        opacity: 0;
        transition: 200ms ease-in-out transform, 400ms cubic-bezier(0.19, 1, 0.22, 1) opacity;
      }
    }
    &.slick-current {
      opacity: 1;

      .navigation-card {
        margin-top: -8px;
        @media ${device.tablet} {
          margin-top: -15px;
        }
      }

      .navigation-card--content {
        margin-bottom: 30px;
      }

      .navigation-card--about {
        &::after {
          transform: translate(0, 12px);
          opacity: 1;
        }
      }

      .navigation-card:hover {
        color: ${theme.secondary.ghcfBlue};
        border-color: ${theme.primary.spaceCadet};
        background: transparent;
        svg g {
          stroke: ${theme.primary.spaceCadet};
          stroke-width: 2;
        }
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-dots {
    position: static;
    margin-top: 30px;
    margin-bottom: -30px;

    li {
      button,
      &.slick-active button {
        &::before {
          color: ${theme.secondary.ghcfBlue};
          font-size: 10px;
        }
      }
    }
  }
`;

export default NavigationContainer;
