import styled from "styled-components";

// import { defaultShevy } from "styles/global.style";
import theme from "styles/theme";

import { CardShadow } from "components/card/index.style";

const HALF_MINUS_SPACING = 1260 / 2 - 16 + 72;

const BlogHighlightContainer = styled.div`
  background: ${theme.secondary.ghcfBlue};
  background: linear-gradient(
    90deg,
    transparent calc(50% - ${HALF_MINUS_SPACING}px),
    ${theme.secondary.ghcfBlue} calc(50% - ${HALF_MINUS_SPACING}px)
  );

  &.container {
    margin: 6em auto 8em;
  }
`;

const BlogHighlightContent = styled(CardShadow)`
  background: #ffffff;
  margin-top: 3.5em;
  margin-bottom: -3.5em;

  .actions {
    display: flex;

    > * {
      margin-right: 1em;
    }
  }

  .blog-highlight--featured-image {
    background-size: cover;
    background-color: ${theme.secondary.ghcfBlue};
    background-position: center;
    min-height: 400px;
  }

  .blog-highlight--excerpt {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .role {
    font-weight: bold;
    color: ${theme.secondary.ghcfBlue};
    text-transform: uppercase;
  }
`;

export { BlogHighlightContent };

export default BlogHighlightContainer;
