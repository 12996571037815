import React, { Component } from "react";
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import { Element } from "react-scroll";
import { withRouter } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import Slider from "react-slick";

import ImgGathering from "assets/images/bigpicture/img-gathering.png";
import { SectionPointer, SectionTitle, Link } from "components/elements";
import theme from "styles/theme";

import { GlobalChartStyles } from "components/data-viz";

import GatheringInputContainer from "./index.style";

class GatheringInput extends Component {
  componentDidMount() {
    // const { hash } = this.props.location;
    // if (hash !== "") {
    //   scroller.scrollTo(hash.substr(1), {});
    // }
  }

  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
    };

    return (
      <GatheringInputContainer className="gathering-input">
        <GlobalChartStyles />
        <div className="img-red" />
        <div className="container">
          <div className="title">
            <SectionPointer
              firstColor="#EF233C"
              secondColor="rgba(50,46,108,0.8)"
            />
            <SectionTitle color={theme.primary.spaceCadet}>
              Gathering input
            </SectionTitle>
          </div>
          <div className="content">
            <Grid>
              <Row className="description">
                <Col xs={12} md={7} lg={6}>
                  <img
                    src={ImgGathering}
                    alt="gathering"
                    className="img-gathering"
                  />
                </Col>
                <Col xs={12} md={5} lg={6}>
                  <p>
                    These topics and measures were selected with input from our{" "}
                    <Link as={RouterLink} to="/the-initiative#advisory">
                      Advisory Committee
                    </Link>
                    , the Kinder Institute team, and over 330 nonprofit,
                    philanthropic, and civic stakeholders that attended our
                    county convenings. <br />
                    <br /> At our convenings people voted to indicate which
                    subtopics they thought were most important, most interesting
                    personally, represented knowledge gaps, and importantly,
                    which subtopics were worth highlighting for the community.
                  </p>
                </Col>
              </Row>
              <Row className="cta-title">
                <Col md={10} mdOffset={1}>
                  <h3>
                    Overall, the following 15 subtopics had the most votes out
                    of 45 total subtopics:
                  </h3>
                  <Slider {...settings}>
                    <div
                      className="data-viz"
                      data-chart="Top Subtopics for 3-County Area"
                    />
                    <div
                      className="data-viz"
                      data-chart="Top Subtopics for Fort Bend"
                    />
                    <div
                      className="data-viz"
                      data-chart="Top Subtopics for Harris"
                    />
                    <div
                      className="data-viz"
                      data-chart="Top Subtopics for Montgomery"
                    />
                  </Slider>
                </Col>
                <Col md={10} mdOffset={1}>
                  <Element name="convenings-wrap">
                    <div className="convenings">
                      <Element name="read-more">
                        <h3>
                          Read more about what we heard at our three major
                          convenings:
                        </h3>
                      </Element>
                      <div className="convening">
                        <h4>Fort Bend County Convening</h4>
                        <p className="xs">June 18, 2019</p>
                        <Link
                          href="https://api.understandinghouston.org/wp-content/uploads/2019/11/Fort-Bend-Convening-Summary-Brief-FINAL.pdf"
                          target="_blank"
                          aria-label="Download (opens in a new tab)"
                        >
                          Download
                        </Link>
                      </div>
                      <div className="convening">
                        <h4>Harris County Convening</h4>
                        <p className="xs">August 7, 2019</p>
                        <Link
                          href="https://api.understandinghouston.org/wp-content/uploads/2019/11/Harris-County-Convening-Summary-Brief-FINAL.pdf"
                          target="_blank"
                          aria-label="Download (opens in a new tab)"
                        >
                          Download
                        </Link>
                      </div>
                      <div className="convening">
                        <h4>Montgomery County Convening</h4>
                        <p className="xs">May 3, 2019</p>
                        <Link
                          href="https://api.understandinghouston.org/wp-content/uploads/2019/11/Understanding-Houston-Montgomery-Summary-Brief-7.17.2019_FINAL.pdf"
                          target="_blank"
                          aria-label="Download (opens in a new tab)"
                        >
                          Download
                        </Link>
                      </div>
                    </div>
                  </Element>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </GatheringInputContainer>
    );
  }
}

export default withRouter(GatheringInput);
