/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/no-unescaped-entities */
import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _filter from "lodash/filter";

import { getEventsAPI, getPageWithIdAPI } from "services/helpers/apis";
import { Link } from "components/elements";
import IconHot from "assets/images/blog/icon-hot.svg";
import IconFeed from "assets/images/blog/icon-feed.svg";

import EventsContainer from "./events.style";

import { Helmet } from "react-helmet";

const EventsPageId = "2410";

class Events extends Component {
  state = {
    // events: [],
    upComingEvents: [],
    pastEvents: [],
    pinnedEvents: [],
    bannerImage: null,
  };

  async componentDidMount() {
    try {
      const res = await getEventsAPI();
      const orderedEvents = _reverse(
        _sortBy(res.data, [
          function(o) {
            const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
            return new Date(o.acf.start_date.replace(pattern, "$3-$1-$2"));
          },
        ])
      );
      const currentDate = new Date();

      const getEventsPage = await getPageWithIdAPI(EventsPageId);

      this.setState({
        // events: orderedEvents,
        bannerImage: getEventsPage.data.better_featured_image
          ? getEventsPage.data.better_featured_image.source_url
          : null,
        pinnedEvents: _filter(orderedEvents, function(o) {
          return o.acf.pinned === true;
        }),
        upComingEvents: _reverse(
          _filter(orderedEvents, function(o) {
            const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
            const date = new Date(
              o.acf.start_date.replace(pattern, "$3-$1-$2")
            );
            return date > currentDate;
          })
        ),
        pastEvents: _filter(orderedEvents, function(o) {
          const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
          const date = new Date(o.acf.start_date.replace(pattern, "$3-$1-$2"));
          return date < currentDate;
        }),
      });
      // const MySwal = withReactContent(Swal);
      // MySwal.fire({
      //   title: '<span style="font-family: Roboto;font-size: 24px;font-weight: 900;color: #112E51;padding: 30px 30px 0;margin: 0">All currently scheduled events have been postponed.</span>',
      //   icon: '',
      //   html:
      //     '<p>Please stay tuned and <a class="swal-popup" href="/get-involved#subscribe">sign up to our newsletter</a> for rescheduling information.</p>',
      //   showCloseButton: true,
      //   showCancelButton: false,
      //   confirmButtonColor: '#112E51',
      //   focusConfirm: true,
      //   confirmButtonText: 'Sign Up',
      // }).then((result) => {
      //   if (result.value) {
      //     window.open("/get-involved#subscribe", "_self");
      //   }
      // });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {
      upComingEvents,
      pastEvents,
      pinnedEvents,
      bannerImage,
    } = this.state;

    return (
      <EventsContainer>
        <Helmet>
          <title>Events | Understanding Houston</title>
          {/* <meta name="description" content={} /> */}
        </Helmet>
        <section
          className="banner"
          style={{ backgroundImage: `url(${bannerImage})` }}
        >
          <div className="container">
            <div className="text">
              <h1>Events</h1>
            </div>
          </div>
        </section>

        {/* <section className="events-header">
          <div className="container">
            {pinnedEvents.length > 0 && (
              <div className="reserve-seat">
                <div className="column first">
                  <p className="sm">SAVE THE DATE</p>
                  <h3>{pinnedEvents[0].title.rendered}</h3>
                  <p className="xs">
                    <b>
                      <Moment format="MMM D, YYYY  " tz="America/Chicago">{pinnedEvents[0].acf.start_date}</Moment>
                      <strong
                        style={{
                          fontWeight: '600',
                          textIndent: '0.3rem'
                        }}>
                        {pinnedEvents[0].acf.end_date && <Moment format="- MMM D, YYYY" tz="America/Chicago">{pinnedEvents[0].acf.end_date}</Moment>}
                      </span>

                      {pinnedEvents[0].acf.start_time &&
                        `, ${pinnedEvents[0].acf.start_time}`}
                      {pinnedEvents[0].acf.end_time && " - "}
                      {pinnedEvents[0].acf.end_time}
                    </b>
                  </p>
                </div>
                <div className="column">
                  <p
                    className="xs"
                    dangerouslySetInnerHTML={{
                      __html: pinnedEvents[0].acf.hosted_by
                    }}
                  />
                </div>
                <div className="column">
                  <Button onClick={() => window.open('https://web.cvent.com/event/06c1611b-a16d-439d-afc6-e050bb513472/summary?rp=00000000-0000-0000-0000-000000000000', '_blank')}>Reserve My Seat</Button>
                </div>
              </div>
            )}
          </div>
        </section> */}
        <section className="blog-categories events-post">
          <div className="container">
            <div className="stories">
              <Grid>
                <Row>
                  <Col xs={12} sm={8}>
                    <div className="recent-blogs sub-category-blogs">
                      <div>
                        <p className="col-title primary">
                          <img src={IconHot} alt="Upcoming Events" /> Upcoming
                          Events
                        </p>
                      </div>
                      {upComingEvents.map((event, i) => (
                        <div className="blog" key={i}>
                          {event.better_featured_image && (
                            <div
                              className="blog-image"
                              style={{
                                backgroundImage: `url(${event.better_featured_image.source_url})`,
                              }}
                            />
                          )}
                          <div className="blog-content">
                            <h3
                              dangerouslySetInnerHTML={{
                                __html: event.title.rendered,
                              }}
                            />
                            <p className="xs">
                              <Moment
                                format="MMM D, YYYY  "
                                tz="America/Chicago"
                              >
                                {event.acf.start_date}
                              </Moment>
                              <strong
                                style={{
                                  fontWeight: "400",
                                  textIndent: "0.3rem",
                                }}
                              >
                                {event.acf.end_date && (
                                  <Moment
                                    format="- MMM D, YYYY"
                                    tz="America/Chicago"
                                  >
                                    {event.acf.end_date}
                                  </Moment>
                                )}
                              </strong>

                              {event.acf.start_time &&
                                `, ${event.acf.start_time}`}
                              {event.acf.end_time && " - "}
                              {event.acf.end_time}
                            </p>
                            <p className="xs">{event.acf.address}</p>
                            {event.acf.registration_link && (
                              <Link
                                href={event.acf.registration_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="event-link"
                                aria-label="Register now (opens in a new tab)"
                              >
                                Register now
                              </Link>
                            )}
                            {event.acf.download_presentation_link && (
                              <Link
                                href={event.acf.download_presentation_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="event-link"
                                aria-label="Download presentation (opens in a new tab)"
                              >
                                Download presentation
                              </Link>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col xs={12} sm={4}>
                    <div className="medias">
                      <div>
                        <p className="col-title">
                          <img src={IconFeed} alt="Past Events" /> Past Events
                        </p>
                      </div>
                      <div className="recent-medias">
                        {pastEvents.map((event, i) => (
                          <div className="blog" key={i}>
                            {event.better_featured_image && (
                              <div
                                className="blog-image"
                                style={{
                                  backgroundSize: "cover",
                                  backgroundImage: `url(${event.better_featured_image.media_details.sizes.thumbnail.source_url})`,
                                }}
                              />
                            )}
                            <div className="blog-content">
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: event.title.rendered,
                                }}
                              />
                              <p className="sub-title">
                                <Moment
                                  format="MMM D, YYYY  "
                                  tz="America/Chicago"
                                >
                                  {event.acf.start_date}
                                </Moment>
                                <strong
                                  style={{
                                    fontWeight: "400",
                                    textIndent: "0.3rem",
                                  }}
                                >
                                  {event.acf.end_date && (
                                    <Moment
                                      format="- MMM D, YYYY"
                                      tz="America/Chicago"
                                    >
                                      {event.acf.end_date}
                                    </Moment>
                                  )}
                                </strong>

                                {event.acf.start_time &&
                                  `, ${event.acf.start_time}`}
                                {event.acf.end_time && " - "}
                                {event.acf.end_time}
                              </p>
                              {event.acf.download_presentation_link && (
                                <p>
                                  <Link
                                    href={event.acf.download_presentation_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="event-link"
                                    aria-label="Download presentation (opens in a new tab)"
                                  >
                                    Download presentation
                                  </Link>
                                </p>
                              )}
                              {event.acf.media_link && (
                                <p>
                                  <Link
                                    href={event.acf.media_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Events (opens in a new tab)"
                                    className="media-link"
                                  >
                                    View Media
                                  </Link>
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </section>
      </EventsContainer>
    );
  }
}

export default Events;
