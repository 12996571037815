import React from "react";

import { Col, Row } from "react-styled-flexboxgrid";
import { Quote } from "components";
import { Link } from "components/elements";

import ImageQuoteAvatar from "assets/images/quote-author-default@2x.jpg";

const GiveFeedback = () => {
  return (
    <Row className="container">
      <Col xs={12} sm={5} className="content">
        <div className="about">
          <h3>
            <strong>Give</strong> us feedback.
          </h3>
          <p>
            Love something? Dislike something? Help us get better by letting us know what you really think.
          </p>
          <Link href="/contact?mode=feedback">
            Give feedback
          </Link>
        </div>
      </Col>
      <Col xs={12} sm={7} className="visual">
        <Quote who="Focus Group quote" where="Understanding Houston Harris County Convening" avatar={ImageQuoteAvatar}>
          We want an all-inclusive, user-friendly one-stop-shop data portal that drives impact.&quot;
        </Quote>
      </Col>
    </Row>
  );
};

export default GiveFeedback;
