import React from "react";
import styled from "styled-components";

import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";
import { SectionContainer, SectionCTAContainer } from "../index.style";

const { baseSpacing: bs } = defaultShevy;

const ExploreTopicsContainer = styled(({ forwardRef, ...props }) => (
  <SectionContainer {...props} ref={forwardRef} />
))`
  position: relative;
  margin-bottom: ${bs()};
  padding-bottom: ${bs(3.45)};

  @media ${device.mobileL} {
    padding-bottom: ${bs(5)};
  }

  .left-bg {
    background: ${(props) => props.theme.secondary.deepKoamaru};
    position: absolute;
    width: 100%;
    height: ${bs(25)};

    @media ${device.laptop} {
      left: -${bs()};
      min-width: ${bs(45.5)};
      width: 55%;
      height: ${bs(35.4)};
    }
  }

  .inner-content {
    padding: 60px 30px 0;

    @media ${device.laptop} {
      padding: ${bs(10)} 0 0 ${bs(1.5)};
    }

    .title {
      margin-bottom: ${bs(1.8)};

      @media ${device.laptop} {
        margin-bottom: ${bs(2.7)};
      }

      .title-left {
        .section-pointer {
          margin-bottom: ${bs(0.5)};

          @media ${device.laptop} {
            margin-bottom: 0;
          }
        }

        p {
          color: #ffffff;
          text-transform: uppercase;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.2px;
          margin-bottom: ${bs()};
        }
      }
    }

    .slick-slider {
      margin: 0 -50px;

      @media ${device.mobileL} {
        margin: 0 -70px;
      }

      @media ${device.tablet} {
        margin: 0 -50px;
      }

      .slick-arrow {
        &.slick-prev {
          background: white;
          right: calc(50% + 5px);
          left: auto;
          top: auto;
          bottom: -${bs(1.5)};
          transform: translate(0, 100%);
        }

        &.slick-next {
          background: white;
          left: calc(50% + 5px);
          right: auto;
          top: auto;
          bottom: -${bs(1.5)};
          transform: translate(0, 100%);
        }

        @media ${device.laptop} {
          &.slick-prev {
            left: auto;
            right: ${bs(2.5)};
            top: -${bs(3.9)};
            bottom: auto;
            transform: initial;
          }

          &.slick-next {
            right: 0;
            left: auto;
            top: -${bs(3.9)};
            bottom: auto;
            transform: initial;
          }
        }
      }

      .slick-list {
        @media ${device.laptop} {
          width: 150%;
        }
      }

      .slick-slide {
        > div {
          padding: 0 ${bs(0.5)};
        }
        @media ${device.laptop} {
          margin-right: ${bs()};

          > div {
            padding: 0;
          }
        }

        .slide-content {
          background-color: #f8f8f8;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: relative;

          .slide-content-mobile-image {
            background-size: cover;
            background-position: center;
            height: ${bs(7.3)};
          }

          @media ${device.laptop} {
            width: ${bs(26)} !important;
            height: ${bs(19)};
            display: flex !important;
            align-items: flex-end;

            .slide-content-mobile-image {
              display: none;
            }
          }

          .description {
            background: white;
            padding: ${bs(1.3)} ${bs(0.9)};
            min-height: 6.86em;

            h3 {
              color: ${(props) => props.theme.primary.spaceCadet};
              font-size: 24px;
              line-height: 1;
              margin: 0 0 10px;
            }

            p {
              color: ${(props) => props.theme.primary.spaceCadet};
              margin: 0;
            }

            @media ${device.laptop} {
              border-radius: 3px;
              margin: 0 ${bs(1.5)} ${bs(1.5)};
              height: 5em;
              padding: ${bs()} ${bs()} ${bs(0.7)};
              min-height: initial;
              width: 100%;

              h3 {
                font-size: 31px;
                line-height: 39px;
                margin: 0 0 3px;
              }

              p {
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }

  ${SectionCTAContainer} {
    &.laptop-hidden {
      margin-top: 7.54em;
      text-align: center;
      &:before {
        width: 1px;
        height: 38px;
        left: 50%;
        top: -10px;
        transform: translate(-50%, -100%);
        background: #b19292;
      }

      p {
        margin: 0;
      }
    }
  }
`;

export default ExploreTopicsContainer;
