import React, { PureComponent } from "react";

import LandingContainer from "./index.style";

import Contents from "./contents";

class Landing extends PureComponent {
  componentDidMount() { }

  render() {
    return (
      <LandingContainer id="main-content">
        <Contents />
      </LandingContainer>
    );
  }
}

export default Landing;
