import React, { useState } from "react";
import { Row, Col } from "react-styled-flexboxgrid";
import { withRouter } from "react-router-dom";
import { WPContent } from "components";

import { StickyContainer, Sticky } from "react-sticky";
import { Link } from "react-scroll";

import queryString from "query-string";

import kebabCase from "lodash/kebabCase";

import ContactUs from "./components/contact-us";

import FAQsContainer, { FAQsNavContainer } from "./index.style";
import FAQsProvider from "./components/provider";

const FAQsNav = ({ categories, ...props }) => {
  return (
    <nav className="faqs-categories-nav" {...props}>
      <ul className="faqs-categories-nav--list">
        {categories.map(category => (
          <li key={`faqs-categories-nav-${category.slug}`}>
            <FAQsNavContainer
              as={Link}
              activeClass="active"
              spy
              hashSpy
              to={category.slug}
              smooth
              duration={500}
            >
              <div className="faqs-categories-nav--item">
                <div className="faqs-categories-nav--title">
                  <span>{category.name}</span>
                </div>
                <div className="faqs-categories-nav--description">
                  {category.description}
                </div>
              </div>
            </FAQsNavContainer>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const FAQsSidebar = ({ categories, ...props }) => {
  return (
    <Sticky>
      {({ style, isSticky }) => (
        <aside
          className={`sidebar-wrapper ${isSticky && "sticky"}`}
          style={style}
        >
          <div className="sidebar">
            <FAQsNav categories={categories} {...props} />
          </div>
        </aside>
      )}
    </Sticky>
  );
};

const FAQsQuestion = ({ question, setActiveItem, isActive, ...props }) => {
  const style = {
    maxHeight: `${(isActive && question.answer.length / 2 + 300) || 0}px`
  };

  return (
    <li
      className={`faqs-questions--list-item ${(isActive && "is-active") || ""}`}
      data-question-id={question.name}
      {...props}
    >
      <h6
        className="faqs-questions--list-item-question"
        onClick={() =>
          (isActive && (setActiveItem("") || true)) ||
          setActiveItem(question.name)
        }
      >
        <span>{question.question}</span>
      </h6>
      <WPContent className="faqs-questions--list-item-answer" style={style}>
        {question.answer}
      </WPContent>
    </li>
  );
};

const FAQsCategory = ({ category, setActiveItem, activeItem, ...props }) => {
  return (
    <section
      className={`faqs-category faqs-category--${category.slug}`}
      id={category.slug}
      {...props}
    >
      <h2>{category.name}</h2>
      <p>{category.description}</p>
      <ul className="faqs-questions--list">
        {category.questions.map((question, index) => {
          const name = kebabCase(`${category.name}-${index}`);
          return (
            <FAQsQuestion
              key={`faqs-question-${name}`}
              question={{ ...question, name }}
              setActiveItem={setActiveItem}
              isActive={activeItem === name}
            />
          );
        })}
      </ul>
    </section>
  );
};

const FAQsDisplay = ({ content, ...props }) => {
  const [activeItem, setActiveItem] = useState("");

  return (
    <FAQsContainer {...props}>
      <div className="faqs-header text-center">
        <Row className="container">
          <Col xs={12}>
            <h6>Frequently Asked Questions</h6>
            <h1>We&rsquo;re here to help</h1>
          </Col>
        </Row>
      </div>
      <div className={`faqs-content faqs-content--active-${activeItem}`}>
        <StickyContainer>
          <Row className="container">
            <Col
              className="sidebar-column"
              xs={12}
              xsOffset={0}
              md={4}
              lg={3.75}
            >
              <FAQsSidebar categories={content.faqs} />
            </Col>
            <Col xs={12} xsOffset={0} md={8} lgOffset={0.25}>
              {content.faqs.map(category => (
                <FAQsCategory
                  category={category}
                  key={`faqs-category-${category.slug}`}
                  setActiveItem={setActiveItem}
                  activeItem={activeItem}
                />
              ))}
            </Col>
          </Row>
        </StickyContainer>
      </div>
      <div className="faqs-contact-us contact-us--wrapper">
        <ContactUs />
      </div>
    </FAQsContainer>
  );
};

const FAQs = props => {
  const queryParams = queryString.parse(props.location.search);
  const useMocks = queryParams.useMocks === "true";

  return (
    <FAQsProvider useMocks={useMocks} loading={<FAQsDisplay {...props} />}>
      <FAQsDisplay {...props} />
    </FAQsProvider>
  );
};

export { FAQsDisplay, FAQsProvider, FAQs };
export default withRouter(FAQs);
