import styled from "styled-components";
import IconQuote from "assets/images/icon-quote.svg";

const QuoteContainer = styled.div`
  border-radius: 6px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1),
    0 13px 24px -11px rgba(0, 0, 0, 0.1);
  padding: 40px 30px;

  &::before {
    content: url(${IconQuote});
    text-align: center;
    display: block;
    transform: scale(0.8);
  }
  blockquote {
    margin: 0.5em auto 1em auto;
    font-size: 1.25em;
    text-align: center;
  }

  .quote--who {
    display: flex;
    align-items: center;
    justify-content: center;

    .quote--who-avatar {
      width: 70px;
      height: 70px;

      img {
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .quote--who-about {
      padding-left: 1em;
      font-size: 0.82em;

      .quote--who-name {
        font-weight: 900;
      }
    }
  }
`;

export default QuoteContainer;
