import React, { useContext } from "react";
import { Element } from "react-scroll";
import { Col, Row } from "react-styled-flexboxgrid";
import { TabPanel } from "components";
import { mapItemsWithIdentifiers } from "components/tab-panel";
import { SectionPointer, SectionTitle } from "components/elements";
import theme from "styles/theme";
import BannerPeople from "assets/images/get-involved--ways-montage.png";
import NavContext from "../navigation/context";
import WaysContainer from "./index.style";
import StartVoting from "./start-voting";
import GiveFeedback from "./give-feedback";
import ShareYourStory from "./share-your-story";
import HostADataParty from "./host-a-data-party";

const waysToBeInvolved = [
  {
    id: "tab-0",
    label: "Start voting",
    body: <StartVoting />,
  },
  {
    id: "tab-1",
    label: "Give feedback",
    body: <GiveFeedback />,
  },
  {
    id: "tab-2",
    label: "Share your story",
    body: <ShareYourStory />,
  },
  {
    id: "tab-3",
    label: "Host a data party",
    body: <HostADataParty />,
  },
];

const ways = mapItemsWithIdentifiers(waysToBeInvolved);

const Ways = ({ innerRef }) => {
  const { state: navState, dispatch: dispatchToNav } = useContext(NavContext);

  const handleTarget = () => {
    dispatchToNav({ type: "reset-nav" });
  };

  return (
    <WaysContainer className="get-involved--ways" ref={innerRef}>
      <div className="get-involved--ways-banner">
        <div className="container">
          <img src={BannerPeople} alt="People power Understanding Houston" />
        </div>
      </div>
      <div className="get-involved--ways-about section">
        <Row className="container">
          <Col xs={12} lg={4} sm={5}>
            <div className="title">
              {ways.map((way) => (
                <Element name={way.name} key={`panel-${way.name}`} />
              ))}
              <SectionPointer firstColor={theme.accent.seaGreen} secondColor={theme.accent.pear} />
              <SectionTitle color={theme.primary.spaceCadet}>Ways to get involved</SectionTitle>
            </div>
          </Col>
          <Col xs={12} sm={7} lg={6} lgOffset={1}>
            <p>
              <strong>People like you make Houston one of the best places to live</strong>, and people like you have the ability to shape our community&apos;s future.
            </p>
          </Col>
        </Row>
        <Row className="container">
          <Col xs={12}>
            <Element name="get-involved-tabs">
              <TabPanel items={waysToBeInvolved} {...navState} handleTarget={handleTarget} />
            </Element>
          </Col>
        </Row>
      </div>
    </WaysContainer>
  );
};

export default Ways;
