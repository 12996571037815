import styled from "styled-components";

const CardShadow = styled.div`
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1),
    0 13px 24px -11px rgba(0, 0, 0, 0.15);
`;

const Card = styled(CardShadow)`
  padding: 2em 1.5em;
  margin-bottom: 2em;

  .visual {
    margin-top: 2em;

    img {
      margin-bottom: 0;
    }
  }
`;

export { CardShadow, Card };
