import styled from "styled-components";
import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";
import ImgVideo from "assets/images/youtube-cover.jpg";

const { baseSpacing: bs } = defaultShevy;

const UHouContainer = styled.section`
  padding: 2.13em ${bs()} 0;
  background-color: white;

  @media ${device.tablet} {
    padding-top: 5.09em;
    background-color: rgb(231, 240, 243);
    margin: 0;
  }

  .modal-video {
    .modal-video-body {
      max-width: 1400px;

      .modal-video-close-btn {
        top: -50px;
        right: 0;
      }
    }
  }

  .container {
    @media ${device.laptop} {
      max-width: 1180px;
    }
  }

  .inner-uhou {
    color: ${(props) => props.theme.primary.spaceCadet};
    margin: 0 auto;

    @media ${device.tablet} {
      padding-bottom: 4em;
    }

    img {
      width: 100%;
    }

    > img {
      display: none;
    }

    .title {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .section-pointer {
        margin-bottom: ${defaultShevy.baseSpacing(0.5)};
      }

      img {
        margin-bottom: ${defaultShevy.baseSpacing(1.5)};
      }

      @media ${device.mobileL} {
        flex-direction: column;

        p {
          padding-left: 0;
        }

        img {
          display: none;
        }
      }

      @media ${device.laptop} {
        flex-direction: row;
        align-items: center;

        .section-pointer {
          margin: 0;
        }

        p {
          padding-left: 3.45em;
        }
      }
    }

    p.description {
      margin: ${defaultShevy.baseSpacing(1.5)} 0 0;
      line-height: 32px;
      position: relative;

      &.collapsed {
        height: 8.63em;
        overflow: hidden;

        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          content: "";
          background: linear-gradient(
            to top,
            rgba(255, 255, 255, 1) 20%,
            rgba(255, 255, 255, 0) 80%
          );
          pointer-events: none; /* so the text is still selectable */
        }
      }

      @media ${device.tablet} {
        &.collapsed {
          height: auto;
          overflow: initial;

          &:after {
            content: none;
          }
        }
      }
    }

    .read-more {
      position: relative;
      text-align: center;
      margin-top: 1em;

      span {
        width: 6em;
        height: 3.13em;
        border: 1px solid #d6d7d8;
        background: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        cursor: pointer;

        p {
          text-align: center;
          margin-bottom: 0;
        }
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        border: 1px solid #d6d7d8;
        top: 50%;
      }

      @media ${device.tablet} {
        display: none;
      }
    }

    .video-wrapper {
      position: relative;
      margin: ${defaultShevy.baseSpacing(2)} auto 0;
      background: #000;
      z-index: 3;
      width: 280px;

      @media ${device.mobileM} {
        width: 300px;
        height: 169px;
      }

      @media ${device.mobileL} {
        width: 400px;
        height: 225px;
      }

      @media ${device.tablet} {
        width: 600px;
        height: 338px;
        ${"" /* margin: 0; */}
      }

      @media ${device.laptop} {
        width: 900px;
        height: 507px;
      }

      @media ${device.laptopL} {
        width: 1070px;
        height: 602px;
      }

      .video-content {
        background-color: ${(props) => props.theme.primary.spaceCadet};
        background-image: url(${ImgVideo});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top left;
        border-radius: 3px;
        width: 280px;
        height: 158px;
        padding: 0;
        position: relative;
        margin: auto;
        cursor: pointer;
        transition: opacity 0.3s;

        &:hover {
          opacity: 0.8;
          transition: opacity 0.6s;
        }

        h1 {
          color: white;
          font-size: 2.18em;
          line-height: 1;
        }

        .icon-play {
          width: 2.72em;
          height: 2.72em;
          margin-top: ${defaultShevy.baseSpacing(1.65)};
          cursor: pointer;

          &:hover,
          &:focus {
            opacity: 0.8;
          }
        }

        p {
          position: absolute;
          transform: rotate(270deg);
          color: white;
          bottom: 40px;
          left: 0;
          text-transform: uppercase;
          font-weight: bold;
        }

        @media ${device.mobileM} {
          width: 300px;
          height: 169px;
        }

        @media ${device.mobileL} {
          width: 400px;
          height: 225px;
        }

        @media ${device.tablet} {
          width: 600px;
          height: 338px;
        }

        @media ${device.laptop} {
          width: 900px;
          height: 507px;
          ${"" /* width: 100%; */}
          z-index: 90;
          ${"" /* height: 28.77em; */}
          background-image: url(${ImgVideo});
          padding: 0;

          h1 {
            position: absolute;
            left: 1.02em;
            top: 0.9em;
            font-size: 2.5em;
            line-height: 1.06;
          }

          .icon-play {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 90px;
            height: 90px;
            transform: translate(-50%, -50%);
            color: white;
            margin-top: 0;
          }

          p {
            display: none;
          }
        }

        @media ${device.laptopL} {
          width: 1070px;
          height: 602px;

          h1 {
            font-size: 3.54em;
          }
        }
      }
    }

    @media ${device.tablet} {
      padding-left: 4em;
      padding-right: 4em;

      > img {
        display: block;
      }

      p.description {
        margin: 60px 0;
      }
    }

    @media ${device.laptop} {
      max-width: 1180px;
      padding-left: 2.5em;
      padding-right: 2.5em;
    }
  }
`;

export default UHouContainer;
