import styled from "styled-components";
import { defaultShevy } from "styles/global.style";
import theme from "styles/theme";
import { device } from "styles/breakpoints";
const { baseSpacing: bs } = defaultShevy;

// const VERTICAL_OFFSET = bs(7.27);
// const HALF_OFFSET = bs(7.27 / 2);

const DonateContainer = styled.section`
  margin-top: 30px;

  .get-involved--donate-content {
    background: ${theme.primary.spaceCadet};

    img {
      margin-top: -60px;
      @media ${device.tablet} {
        margin-bottom: 80px;
      }
    }
  }

  @media ${device.laptop} {
    .get-involved--donate-content {
      background: linear-gradient(90deg, transparent calc(50% - 506px), ${(props) => props.theme.primary.spaceCadet} calc(50% - 506px));
    }
  }

  .get-involved--donate-about {
    padding: ${bs(2)} 0;
    h3,
    p {
      color: #ffffff;
    }
    a {
      color: #ffffff;
      &:hover,
      &:focus {
        color: ${(props) => props.theme.accent.pear};
      }
    }
  }
`;

export default DonateContainer;
