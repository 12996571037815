import Highcharts from "highcharts";

export const Charts = [];

const stat = {
  max: function(array) {
    return Math.max.apply(null, array);
  },

  min: function(array) {
    return Math.min.apply(null, array);
  },

  range: function(array) {
    return stat.max(array) - stat.min(array);
  },

  midrange: function(array) {
    return stat.range(array) / 2;
  },

  sum: function(array) {
    let num = 0;
    for (let i = 0, l = array.length; i < l; i++) num += array[i];
    return num;
  },

  mean: function(array) {
    return stat.sum(array) / array.length;
  },

  median: function(array) {
    array.sort(function(a, b) {
      return a - b;
    });
    let mid = array.length / 2;
    return mid % 1 ? array[mid - 0.5] : (array[mid - 1] + array[mid]) / 2;
  },

  modes: function(array) {
    if (!array.length) return [];
    let modeMap = {},
      maxCount = 0,
      modes = [];

    array.forEach(function(val) {
      if (!modeMap[val]) modeMap[val] = 1;
      else modeMap[val]++;

      if (modeMap[val] > maxCount) {
        modes = [val];
        maxCount = modeMap[val];
      } else if (modeMap[val] === maxCount) {
        modes.push(val);
        maxCount = modeMap[val];
      }
    });
    return modes;
  },

  variance: function(array) {
    let mean = stat.mean(array);
    return stat.mean(
      array.map(function(num) {
        return Math.pow(num - mean, 2);
      })
    );
  },

  standardDeviation: function(array) {
    return Math.sqrt(stat.variance(array));
  },

  meanAbsoluteDeviation: function(array) {
    let mean = stat.mean(array);
    return stat.mean(
      array.map(function(num) {
        return Math.abs(num - mean);
      })
    );
  },

  zScores: function(array) {
    let mean = stat.mean(array);
    let standardDeviation = stat.standardDeviation(array);
    return array.map(function(num) {
      return (num - mean) / standardDeviation;
    });
  },
};

const round = function(num, precision) {
  num = parseFloat(num);
  if (!precision) return num;
  return Math.round(num / precision) * precision;
};

Highcharts.theme = {
  colors: [
    "#ACDFF0",
    "#30B1DB",
    "#22A451",
    "#A6DAB9",
    "#FCD300",
    "#FDED99",
    "#FA2C6D",
    "#FC80A7",
    "#E74A19",
    "#F1B5A2",
    "#4E4686",
    "#8376E0",
    "#992188",
    "#D6A6CF",
  ],
  chart: {
    backgroundColor: "#ffffff",
    // spacing: [0, 0, 10, 0],
    style: {
      fontFamily: "Roboto, serif",
    },
    marginTop: 15,
  },
  credits: {
    enabled: false,
    text: "Understanding Houston",
    href: "https://www.understandinghouston.org",
    position: {
      align: "right",
      verticalAlign: "bottom",
      x: -10,
    },
    style: {
      cursor: "pointer",
      color: "#2c598a",
      fontSize: "12px",
      fontWeight: "400",
    },
  },
  navigation: {
    buttonOptions: {
      enabled: false,
      verticalAlign: "bottom",
      align: "left",
      y: 3,
      x: -29,
      symbolStrokeWidth: 0,
      symbolFill: "transparent",
      symbolStroke: "transparent",
      text: "Export Data",
      theme: {
        r: 0,
        strokeWidth: 0,
        fill: "transparent",
        states: {
          hover: {
            fill: "transparent",
            stroke: "transparent",
          },
          select: {
            stroke: "transparent",
            fill: "transparent",
          },
        },
      },
    },
    menuItemHoverStyle: {
      background: "#ffffff",
      color: "#112E51",
      fontWeight: "500",
    },
    menuItemStyle: {
      padding: "0.75em 1em",
      color: "#2c598a",
      background: "none",
      margin: "0",
      fontSize: "12px",
      fontWeight: "400",
      transition: "background 250ms, color 250ms",
    },
    menuStyle: {
      border: "1px solid #f9f9f9",
      background: "#ffffff",
      padding: "0.5em 0",
      margin: "0",
    },
  },
  title: {
    align: "left",
    x: 0,
    widthAdjust: -120,
    useHTML: true,
    style: {
      fontSize: "18px",
      fontWeight: "600",
      fontFamily: "Roboto, serif",
      color: "#112E51",
    },
  },
  subtitle: {
    align: "left",
    widthAdjust: -120,
    useHTML: true,
    x: 0,
    style: {
      fontSize: "13px",
      fontFamily: "Roboto, serif",
      fontWeight: "400",
      color: "#112E51",
    },
  },
  caption: {
    margin: 0,
    floating: false,
    style: { color: "#000000", fontWeight: "400", fontFamily: "Roboto, serif" },
    x: 80,
    widthAdjust: -10,
  },
  tooltip: {
    borderWidth: 0,
    borderRadius: 0,
    padding: 0,
    followPointer: true,
    shape: "square",
    shadow: false,
    shared: false,
    outside: true,
    y: 0,
    useHTML: true,
    style: {
      fontFamily: "Roboto",
    },
    headerFormat:
      '<table style="width: 100%;text-align: left"><tr><td><div class="tooltip-custom-heading">{series.name}</div></td></tr>',
    pointFormat:
      '<tr><td><div class="tooltip-custom-wrapper"><div class="tooltip-custom-group"><div class="tooltip-custom-value">{point.y}</div><div class="tooltip-custom-category">{point.options.category}</div></div><div class="tooltip-custom-group"><div class="tooltip-custom-value">{point.increase}</div><div class="tooltip-custom-category">{point.note}</div></div></div></td></tr>',
    footerFormat: "</table>",
  },
  labels: {
    style: {
      color: "#000000",
    },
  },
  legend: {
    reverseLegendClickAction: true,
    align: "center",
    verticalAlign: "bottom",
    layout: "horizontal",
    x: 0,
    margin: 15,
    y: 0,
    borderWidth: 0,
    itemStyle: {
      color: "#112E51",
      cursor: "pointer",
      fontSize: "13px",
      fontWeight: "500",
    },
    squareSymbol: true,
    symbolRadius: 0,
    itemDistance: 15,
    itemMarginBottom: 10,
  },
  xAxis: {
    tickLength: 10,
    labels: {
      enabled: true,
      style: {
        color: "#000000",
        fontSize: "12px",
        fontWeight: "400",
        fontFamily: "Roboto, serif",
      },
    },
    title: {
      margin: 0,
      enabled: false,
      align: "high",
      textAlign: "right",
      offset: 1,
      y: 40,
      style: {
        fontSize: "12px",
        fontWeight: "400",
        fontFamily: "Roboto, serif",
        color: "#112E51",
      },
    },
    lineWidth: 2,
    lineColor: "#889AAF",
    gridLineWidth: 0,
    tickColor: "#fff",
    minorGridLineWidth: 0,
  },
  yAxis: {
    endOnTick: false,
    tickLength: 10,
    lineColor: "#889AAF",
    title: {
      margin: 0,
      enabled: false,
      align: "high",
      textAlign: "left",
      offset: -1,
      rotation: 0,
      y: -20,
      style: {
        color: "#112E51",
        fontSize: "13px",
        fontWeight: "500",
        fontFamily: "Roboto, serif",
      },
    },
    labels: {
      align: "right",
      style: {
        color: "#000000",
        fontSize: "12px",
        fontWeight: "400",
        fontFamily: "Roboto, serif",
      },
    },
    lineWidth: 0,
    gridLineWidth: 1,
    gridLineColor: "#E1E1E1",
    tickColor: "#fff",
    minorGridLineWidth: 0,
  },
  plotOptions: {
    series: {
      shadow: false,
      stickyTracking: false,
      enableMouseTracking: true,
    },
    line: {
      linecap: "square",
      lineWidth: 2,
      dataLabels: {
        enabled: false,
      },
      enableMouseTracking: true,
      marker: {
        enabled: true,
        radius: 3,
        symbol: "circle",
        fillColor: "#ffffff",
        lineColor: null,
      },
    },
    bar: {
      dataLabels: {
        enabled: false,
      },
    },
    errorbar: {
      color: "#EF233C",
      stemWidth: 1,
      whiskerLength: "5%",
      visible: true,
      tooltip: {
        headerFormat: '<table style="width: 100%;text-align: center">',
        pointFormat:
          '<tr><td><span style="color: #112E51;font-size: 16px;font-weight: 500">{point.low}-{point.high}</span></td></tr><tr><td><span style="color: #718297;font-size: 14px;font-weight: 400">Margin of error</span></td></tr>',
        footerFormat: "</table>",
      },
    },
    area: {
      clip: true,
    },
    spline: {
      linecap: "square",
      lineWidth: 2,
      zIndex: 2,
      dataLabels: {
        enabled: false,
      },
      marker: {
        enabled: true,
        radius: 3,
        symbol: "circle",
        fillColor: "#ffffff",
        lineWidth: 2,
        lineColor: null,
      },
    },
  },
  exporting: {
    allowHTML: true,
    buttons: {
      contextButton: {
        menuItems: [
          "downloadPNG",
          "downloadJPEG",
          "downloadPDF",
          "downloadSVG",
          "separator",
          "printChart",
          "downloadCSV",
        ],
        symbolStrokeWidth: 0,
        symbolFill: "#000000",
        symbolStroke: "#000000",
      },
    },
    chartOptions: {
      chart: {
        width: 800,
        height: (3 / 4) * 100 + "%",
        margin: undefined,
        spacing: [15, 10, 0, 10],
      },
      caption: {
        useHTML: true,
        margin: 75,
        x: 10,
        y: 0,
        style: {
          display: "block",
        },
      },
      legend: {
        margin: 10,
        y: 0,
        align: "left",
        floating: false,
      },
      title: {
        margin: 30,
        x: 10,
        style: {
          display: "block",
        },
      },
      subtitle: {
        x: 10,
        style: {
          display: "block",
          margin: 0,
        },
      },
    },
  },
};

Highcharts.setOptions(Highcharts.theme);
Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
    numericSymbols: ["k", "M", "G", "T", "P", "E"],
  },
});

Highcharts.addEvent(Highcharts.Chart, "afterGetContainer", function(e) {
  this.container.style.background = "transparent";
});
Highcharts.addEvent(Highcharts.Chart, "load", function(e) {});

Highcharts.createElement(
  "link",
  {
    href: "https://fonts.googleapis.com/css?family=Roboto:400,500,700,900",
    rel: "stylesheet",
    type: "text/css",
  },
  null,
  document.getElementsByTagName("head")[0]
);

Highcharts.SVGRenderer.prototype.symbols.person = function(x, y, w, h, r) {
  return [
    "M",
    x + w * (2 / 5),
    y + h / 2,
    "A",
    w / 3,
    w / 3,
    0,
    1,
    1,
    x + (w * 3) / 5,
    y + h / 2,
    "L",
    x + (w * 4) / 5,
    y + h,
    x + (w * 1) / 5,
    y + h,
  ];
};

Highcharts.Renderer.prototype.symbols.vline = function(x, y, width, height) {
  return ["M", x, y + width / 2, "L", x + height, y + width / 2];
};

Highcharts.Renderer.prototype.symbols.hline = function(x, y, width, height) {
  return ["M", x, y + height / 2, "L", x + width, y + width / 2];
};

function toTitleCase(str) {
  let string = str
    .toString()
    .toLowerCase()
    .split(" ");
  for (var i = 0; i < string.length; i++) {
    string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
  }
  return string.join(" ");
}

function hexToR(h) {
  return parseInt(cutHex(h).substring(0, 2), 16);
}
function hexToG(h) {
  return parseInt(cutHex(h).substring(2, 4), 16);
}
function hexToB(h) {
  return parseInt(cutHex(h).substring(4, 6), 16);
}
function cutHex(h) {
  if (h.color.hasOwnProperty("pattern")) {
    if (Highcharts.defined(h.color.pattern.color)) {
      return h.color.pattern.color.charAt(0) === "#"
        ? h.color.pattern.color.substring(1, 7)
        : h.color.pattern.color;
    } else {
      let clr = hasColor(String(h.category));
      return clr.charAt(0) === "#" ? clr.substring(1, 7) : clr;
    }
  } else {
    return h.color.charAt(0) === "#" ? h.color.substring(1, 7) : h.color;
  }
}

const dictionary = [
  {
    name: "county",
    type: "filter",
    terms: ["harris", "fort bend", "montgomery"],
  },
  {
    name: "region",
    type: "filter",
    terms: [
      "3 county",
      "3-county",
      "3-county area",
      "3 county area",
      "houston",
      "texs",
      "us",
      "national",
      "u.s.",
    ],
  },
  {
    name: "races",
    type: "filter",
    terms: ["asian", "black", "hispanic", "white", "unknown", "other"],
  },
  { name: "sex", type: "filter", terms: ["male", "female", "men", "women"] },
  {
    name: "xaxis",
    type: "config",
    terms: ["xaxis", "xaxis title", "xaxistitle", "xaxis color", "xaxiscolor"],
  },
  {
    name: "yaxis",
    type: "config",
    terms: ["yaxis", "yaxis title", "yaxistitle", "yaxis color", "yaxiscolor"],
  },
  { name: "series", type: "config", terms: ["undefined", "series"] },
  { name: "value", type: "config", terms: ["value"] },
  {
    name: "settings",
    type: "config",
    terms: [
      "type",
      "subtitle",
      "source",
      "note",
      "switch",
      "stack",
      "stacking",
      "percent",
      "tracker",
      "label",
      "pattern",
      "logarithmic",
      "currency",
      "hidden",
      "defaultgroup",
      "toggle",
      "default group",
      "group",
      "type",
      "defaulttoggle",
      "default toggle",
      "regression",
      "name",
      "category",
      "axistitle",
      "axiscolor",
      "axis title",
      "axis color",
      "decimals",
      "categorycolors",
      "category colors",
      "ignore",
      "categorystats",
      "category stats",
      "datalabels",
      "data labels",
      "ordinal",
      "max",
    ],
  },
];

const searchDictionaryTerms = function(term, arr = []) {
  term &&
    dictionary
      .filter((d) => d.type === term || d.name === term)
      .forEach((i) => arr.push(...i.terms));
  return arr;
};

const matchDictionaryTerms = function(term) {
  return new RegExp(searchDictionaryTerms(term).join("|"), "i");
};

function getChartByTitle(title, data) {
  if (!Highcharts.defined(data)) return false;
  return data.find((c) => c.title_ === String(title));
}

function hasColor(series) {
  let color;
  switch (
    String(series)
      .replace(/\s+/g, "")
      .replace(/[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/g, "")
      .toLowerCase()
  ) {
    case "fortbend":
      color = "#DDD12A";
      break;
    case "harris":
      color = "#159DBF";
      break;
    case "montgomery":
      color = "#7AC896";
      break;
    case "3county":
      color = "#4E4686";
      break;
    case "houstonmsa":
      color = "#4E4686";
      break;
    case "3countyarea":
      color = "#4E4686";
      break;
    case "texas":
      color = "#708196";
      break;
    case "tx":
      color = "#708196";
      break;
    case "unitedstates":
      color = "#9FABB9";
      break;
    case "us":
      color = "#9FABB9";
      break;
    case "male":
      color = "#37D8DA";
      break;
    case "female":
      color = "#FFA9A6";
      break;
    case "total":
      color = "#EABA5D";
      break;
    case "asian":
      color = "#77c994";
      break;
    case "black":
      color = "#24b0dd";
      break;
    case "hispanic":
      color = "#ded300";
      break;
    case "white":
      color = "#8373e3";
      break;
    case "unknown":
      color = "#fee55b";
      break;
    case "other":
      color = "#fee55b";
      break;
    default:
      color = false;
  }
  return color;
}

const terms = [
  "fort",
  "fortbend",
  "fortbenderror",
  "harris",
  "harriserror",
  "montgomery",
  "montgomeryerror",
  "3county",
  "3countyerror",
  "3countyarea",
  "3countyareaerror",
  "austin",
  "dallas",
  "houston",
  "houstonmsa",
  "sanantonio",
  "texas",
  "tx",
  "unitedstates",
  "us",
  "nhwhite",
  "asian",
  "black",
  "hispanic",
  "white",
  "unknown",
  "other",
  "total",
];

function sortSeries(a, b) {
  var x = a.undefined
    .toString()
    .replace(/\s+/g, "")
    .replace(/[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/g, "")
    .toLowerCase();
  var y = b.undefined
    .toString()
    .replace(/\s+/g, "")
    .replace(/[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/g, "")
    .toLowerCase();
  return (terms.includes(x) && terms.indexOf(x)) < terms.indexOf(y)
    ? -1
    : (terms.includes(x) && terms.indexOf(x)) > terms.indexOf(y)
    ? 1
    : 0;
}

function countDecimals(value) {
  if (Highcharts.defined(value)) {
    value = parseFloat(value);
    if (Math.floor(value) === value) return 0;
    if (value.toString().split(".")[1])
      return value.toString().split(".")[1].length;
    return 0;
  }
}

function getValue(row, param) {
  const data = Object.entries(row)
    .filter((c) => matchDictionaryTerms("config").test(c) && c !== "undefined")
    .map((c, i) => [c[0].toLowerCase().replace(/\s/g, ""), c[1]])
    .find((c) => c[0] === param);
  return Highcharts.defined(data) && Highcharts.defined(data[1])
    ? data[1]
    : undefined;
}

function matchDateFormat(category) {
  return (
    category.match(/(([12]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/) ||
    category.match(/^(18|19|20)\d{2}$/g) ||
    false
  );
}

function getPercentageChange(oldNum, newNum) {
  let decreaseValue = oldNum - newNum;
  return Highcharts.correctFloat((decreaseValue / oldNum) * 100);
}

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export {
  stat,
  round,
  toTitleCase,
  hexToR,
  hexToG,
  hexToB,
  cutHex,
  dictionary,
  searchDictionaryTerms,
  matchDictionaryTerms,
  getChartByTitle,
  hasColor,
  terms,
  sortSeries,
  countDecimals,
  getValue,
  matchDateFormat,
  getPercentageChange,
  sliceIntoChunks,
};
