import styled from "styled-components";

const SearchContainer = styled.div`
  .header.section {
    padding: 40px 0;
    ul {
      list-style: none;
      display: flex;
      margin: 40px 0 0;
      padding: 0;
      justify-content: center;

      li {
        color: #7a8899;
        font-weight: 600;
        font-size: 18px;
        border-bottom: 3px solid transparent;
        padding-bottom: 16px;
        margin: 0 15px;
        cursor: pointer;

        &.active {
          color: #112e51;
          border-bottom: 3px solid #159dbf;
        }
      }
    }
    form {
      display: flex;

      input {
        height: 60px;
        width: 1140px;
        background-color: #f8f8f8;
        font-size: 20px;
        border: none;
        outline: none;
        padding: 15px;
        font-family: "Roboto", sans-serif;
      }

      button {
        width: 60px;
        height: 60px;
        border: none;
        background-color: #159dbf;
        outline: none;
        margin-left: 10px;
        font-size: 16px;
        color: #112e51;
        flex-shrink: 0;
        cursor: pointer;
        border-radius: 0 6px 6px 0;
        color: #fff;

        svg {
          width: 18px;
          height: 18px;
          fill: #fff;
        }
      }
    }

    button {
      width: 60px;
      height: 60px;
      border: none;
      background-color: #f8f8f8;
      outline: none;
      margin-left: 10px;
      font-size: 16px;
      color: #112e51;
      cursor: pointer;
      border-radius: 3px;
      flex-shrink: 0;
    }
  }

  .results {
    .title {
      position: relative;
    }

    .section-results {
      margin-top: 60px;
    }

    .stats-pagination {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;

      h2 {
        flex-grow: 1;
        font-size: 40px;
      }
    }

    .ais-Stats {
      font-size: 15px;
      line-height: 30px;
      height: 30px;
      margin: 0;
      color: #112e51;
      font-weight: normal;
    }

    .ais-Pagination {
      .ais-Pagination-list {
        display: flex;
        margin: 0;
        padding: 0 0 0 10px;

        .ais-Pagination-item--previousPage {
          order: 0;
          border-radius: 0;
          border: 1px solid #c5c9e0;
          width: 30px;
          height: 30px;
          margin: 0;

          &.ais-Pagination-item--disabled {
            opacity: 0.4;
            border-right: none;
          }
        }
        .ais-Pagination-item--nextPage {
          order: 1;
          border-radius: 0;
          border: 1px solid #c5c9e0;
          width: 30px;
          height: 30px;
          margin: 0 10px 0 0;

          &.ais-Pagination-item--disabled {
            opacity: 0.4;
            border-left: none;
          }
        }
        .ais-Pagination-item--page {
          order: 2;
          background: transparent;
          font-size: 15px;
          line-height: 30px;
          height: 30px;
          margin: 0;
          color: #112e51;
          font-weight: normal;
          width: auto;
          a {
            color: #112e51;
          }
        }
        .ais-Pagination-item--lastPage {
          order: 3;
          font-size: 15px;
          background: transparent;
          line-height: 30px;
          height: 30px;
          color: #112e51;
          width: auto;
          margin: 0;

          &::before {
            content: "-";
            padding: 0 5px;
          }
        }
      }
    }

    .ais-Hits {
      margin: 0;
      padding: 0;
      max-width: 100%;

      ul.ais-Hits-list {
        margin: 0;
        padding: 0;

        li.ais-Hits-item {
          .item {
            h5 {
              font-size: 20px;
              font-weight: 800;
              padding: 10px 0 0;
            }

            p.date {
              padding: 10px 0 0;
              font-size: 14px;
              font-weight: 600;
              margin: 0;
            }

            .ais-Snippet {
              font-size: 15px;
              padding: 10px 0 0;
              display: block;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
`;

export default SearchContainer;
