import styled from "styled-components";
import { Link, PostTag } from "components/elements";
import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";

const { baseSpacing: bs } = defaultShevy;

const MainBlogContainer = styled.div`
  background: #e8f0f3;

  .col-title {
    font-weight: 700;
  }

  .container {
    @media ${device.laptopL} {
      max-width: 1406px;
    }
  }

  .categories-menu {
    display: flex;
    flex-direction: column;
    padding-top: 18px;

    p {
      margin-right: 16px;

      @media ${device.laptopL} {
        margin-right: 0;
      }
    }
  }

  section {
    padding: ${bs()};

    @media ${device.laptop} {
      padding: 0;
    }

    &.banner {
      background-color: #06172b;
      background-size: cover;
      background-position: top center;
      position: relative;
      display: flex;
      align-items: center;
      height: 32.6em;
      min-height: 32.6em;

      @media ${device.laptop} {
        padding: ${bs(2)} ${bs()};
      }

      &.main {
        @media ${device.laptop} {
          height: 32.6em;
          min-height: 32.6em;
          padding: 0;
        }
      }

      .content {
        width: 100%;
        max-width: 43.5em;
        color: white;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        padding: ${bs(1.5)} ${bs()};
        color: ${(props) => props.theme.primary.spaceCadet};

        @media ${device.laptop} {
          position: initial;
          max-width: 606px;
          padding: ${bs(1.5)};
        }

        ${PostTag} {
          margin-bottom: 17px;

          span {
            font-size: 14px;
          }
        }

        ${Link} {
          font-size: 18px;
          color: ${(props) => props.theme.primary.spaceCadet};

          &:hover {
            color: ${(props) => props.theme.accent.pear};
          }
        }

        a.back-navigator {
          font-size: 0.63em;
          text-transform: uppercase;
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }
        }

        h1 {
          font-size: 30px;
          color: ${(props) => props.theme.primary.spaceCadet};

          @media ${device.laptop} {
            font-size: 45px;
          }
        }

        h2 {
          margin: 0;
        }
      }
    }

    &.blog-categories {
      position: relative;

      .category-selector {
        margin-bottom: ${bs()};
        display: flex;
        flex-flow: wrap;
        align-items: center;

        @media ${device.laptop} {
          margin-top: 50px;
          margin-bottom: 50px;
          justify-content: center;
        }

        p {
          margin-bottom: 0;
          margin-right: 10px;
          font-size: 20px;
          font-weight: bold;
          padding-bottom: ${bs(0.25)};

          @media ${device.mobileL} {
            padding-bottom: 0;
          }
        }

        .selector {
          min-width: 250px;

          .react-select__menu-list {
            max-height: 275px;
          }
        }
      }

      .categories {
        display: none;
        height: 109px;
        align-items: center;
        justify-content: center;
        background-color: #f9f9f9;
        margin-bottom: 97px;

        @media ${device.laptopL} {
          display: flex;
        }

        p {
          margin: 0;
          font-weight: bold;
          margin-right: 15px;
        }

        ul {
          padding: 0;
          display: flex;
          align-items: center;

          li {
            list-style: none;
            margin: 0 15px;
            font-size: 14px;

            &:last-child {
              margin-right: 0;
            }

            ${Link} {
              border-bottom: none;
              text-transform: uppercase;

              &.active {
                border-bottom: 2px solid ${(props) => props.theme.accent.pear};
              }

              &.is-disabled {
                opacity: 0.65;
                cursor: default;

                &:hover {
                  background: transparent;
                  color: ${(props) => props.theme.primary.spaceCadet};
                }
              }
            }
          }
        }
      }

      .intro {
        margin-top: 50px;
        margin-bottom: 50px;

        @media ${device.laptop} {
          max-width: 80%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 0;
          margin-bottom: 97px;
          text-align: center;
        }
      }
    }

    &.most-read {
      padding: 0 ${bs(1)} 100px ${bs(1)};
      position: relative;

      @media ${device.laptopL} {
        padding: 0 ${bs(1)} 80px ${bs(1)};
      }

      &::after {
        position: absolute;
        display: block;
        content: "";
        width: calc(100vw - (${bs(1)} * 2));
        max-width: 1406px;
        height: 1px;
        background-color: #cecece;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .slick-slider {
        position: relative;

        .slick-arrow {
          position: absolute;
          bottom: -80px;
          top: unset;

          @media ${device.laptopL} {
            top: 50%;
            bottom: unset;
          }

          &:not(.slick-disabled) {
            background-color: white;
            z-index: 1000;
          }

          &.slick-disabled {
            cursor: not-allowed;
          }

          &.slick-prev {
            left: calc(50% - 47px);

            @media ${device.laptopL} {
              left: -60px;
            }
          }

          &.slick-next {
            right: calc(50% - 47px);

            @media ${device.laptopL} {
              right: -60px;
            }
          }
        }

        .slick-list {
          margin: 0 -12px;
          height: 100% !important;

          .slick-track {
            margin: 15px auto;

            .slick-slide {
              > div {
                margin: 0 12px;

                > a {
                  .blog {
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    height: 100%;

                    @media ${device.laptop} {
                      flex-direction: row;
                    }

                    &:hover {
                      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
                    }

                    &:hover,
                    &:hover .blog-content,
                    &:hover .blog-content * {
                      background: rgb(17, 46, 81);
                      color: white;
                      opacity: 1;
                      transition: all 0.25s ease-in-out;
                    }

                    .blog-content {
                      width: 100%;
                      background: #fff;
                      padding: 16px;
                      white-space: normal;

                      * {
                        background: inherit;
                      }

                      p.sub-title {
                        font-size: 14px;
                        font-weight: bold;
                        margin-bottom: 5px;
                      }

                      a.event-link {
                        font-size: 0.65em;
                        text-transform: capitalize;
                      }

                      p.category {
                        opacity: 0.6;
                        margin: 0;
                        font-weight: bold;
                      }

                      @media ${device.laptop} {
                        padding: 20px;
                        min-height: 250px;
                      }

                      h3 {
                        font-size: 24px;
                        margin-bottom: 20px;
                      }

                      p.xs {
                        font-size: 0.68em;
                        font-weight: 600;
                        opacity: 0.6;
                      }

                      p.description {
                        opacity: 0.6;
                        font-size: 0.72em;
                        line-height: 1.875;
                        min-width: 260px;
                      }
                    }

                    .blog-image {
                      width: 33.33333%;
                      height: auto;
                      background-size: cover;
                      background-position: center;
                      flex-shrink: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.latest-blogs {
      padding: 50px ${bs(1)};

      @media ${device.laptopL} {
        padding: 100px ${bs(1)};
      }

      .container {
        .buttons {
          margin-top: 50px;
          width: 100%;
          display: flex;
          justify-content: center;

          @media ${device.laptopL} {
            margin-top: 100px;
          }
        }
      }

      .blog-list {
        padding: 15px 0 0;
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;

        @media ${device.laptopL} {
          grid-template-columns: 1fr 1fr;
        }

        .blog {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          height: 100%;

          &:hover {
            box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
          }

          &:hover,
          &:hover .blog-content,
          &:hover .blog-content * {
            background: rgb(17, 46, 81);
            color: white;
            opacity: 1;
            transition: all 0.25s ease-in-out;
          }

          @media ${device.laptop} {
            flex-direction: row;
            margin: 0;
          }

          .blog-content {
            width: 100%;
            background: #fff;
            padding: 15px;

            * {
              background: inherit;
            }

            @media ${device.laptop} {
              padding: 20px 20px;
            }

            p.sub-title {
              font-size: 14px;
              font-weight: bold;
              margin-bottom: 5px;
            }

            a.event-link {
              font-size: 0.65em;
              text-transform: capitalize;
            }

            p.category {
              opacity: 0.6;
              margin: 0;
              font-weight: bold;
            }

            h3 {
              font-size: 24px;
              margin-bottom: 20px;
            }

            p.xs {
              font-size: 0.68em;
              font-weight: 600;
              opacity: 0.6;
            }

            p.description {
              opacity: 0.6;
              font-size: 0.72em;
              line-height: 1.875;
            }
          }

          .blog-image {
            width: 33.33333%;
            height: auto;
            background-size: cover;
            background-position: center;
            flex-shrink: 0;
          }
        }
      }
    }
  }
`;

export default MainBlogContainer;
