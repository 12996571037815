import styled from "styled-components";

import { defaultShevy } from "styles/global.style";

const { baseSpacing: bs } = defaultShevy;

const SocialLinksWrapper = styled.div`
  p {
    color: #b0b6bd;
    margin: 0 0 ${bs(0.7)};
    font-weight: bold;
  }

  ul {
    display: flex;
    margin: 0;
    list-style: none;
    padding-left: 0;

    li {
      margin-right: 15px;

      &:last-child {
        margin: 0;
      }

      a {
        width: 36px;
        height: 36px;
        background: #0d2440;
        border-radius: 50%;
        display: block;
        color: #b0b6bd;
        text-align: center;
        line-height: 36px;
        font-size: 0.72em;
      }
    }
  }
`;

export default SocialLinksWrapper;
