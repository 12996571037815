import React, { Component } from "react";
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _filter from "lodash/filter";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Truncate from "react-truncate-html";

import { client } from "services/helpers/apiConfig";
import { getEventsAPI } from "services/helpers/apis";
import { SectionTitle, SectionPointer } from "components/elements";

import HighlightsEventsContainer, { ArticleTag } from "./index.style";
import Events from "./events";
import { SectionCTAContainer } from "../index.style";

class HighlightsEvents extends Component {
  state = {
    upComingEvents: [],
    pastEvents: [],
    recentPosts: [],
  };

  async componentDidMount() {
    try {
      const res = await getEventsAPI();
      const getPosts = await client.get("/wp/v2/posts?_embed&per_page=4");

      const orderedEvents = _reverse(
        _sortBy(res.data, [
          function(o) {
            const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
            return new Date(o.acf.start_date.replace(pattern, "$3-$1-$2"));
          },
        ])
      );
      const currentDate = new Date();

      this.setState({
        recentPosts: getPosts.data,
        upComingEvents: _reverse(
          _filter(orderedEvents, function(o) {
            const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
            const date = new Date(o.acf.start_date.replace(pattern, "$3-$1-$2"));
            return date > currentDate;
          })
        ),
        pastEvents: _filter(orderedEvents, function(o) {
          const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
          const date = new Date(o.acf.start_date.replace(pattern, "$3-$1-$2"));
          return date < currentDate;
        }),
      });
    } catch (e) {
      console.log(e);
    }
  }

  renderCategories = (post) => {
    return post._embedded["wp:term"][0].map((category, i) => (
      <span key={i}>
        {category.name} {i < post._embedded["wp:term"][0].length - 1 && " | "}
      </span>
    ));
  };

  renderRightArticle = (post) => {
    return (
      <article className="article narrow">
        <div
          className="article-image"
          style={{
            backgroundImage: `url(${post.better_featured_image ? post.better_featured_image.source_url : ""})`,
          }}
        />
        <div className="summary">
          <ArticleTag bgcolor="#112E51">{this.renderCategories(post)}</ArticleTag>
          <Link to={`/blog/${post.slug}`}>
            <h3>{post.title.rendered}</h3>
          </Link>
          <p className="sub-title">
            {post._embedded.author[0].name} |{" "}
            <Moment format="MMM. DD, YYYY">{post.date}</Moment>
          </p>
          <p className="description sm">
            <Truncate
              lines={2}
              dangerouslySetInnerHTML={{
                __html: post.excerpt.rendered,
              }}
            />
          </p>
        </div>
      </article>
    );
  };

  render() {
    const { pastEvents, upComingEvents, recentPosts } = this.state;

    return (
      <HighlightsEventsContainer className="section" forwardRef={this.props.innerRef}>
        <div className="container">
          <div className="inner-content">
            <div className="title">
              <div className="title-left">
                <SectionPointer firstColor="#DDD12A" secondColor="#7AC896" />
                <SectionTitle>Highlights and events</SectionTitle>
              </div>
              <SectionCTAContainer className="mobile-hidden">
                <p>
                  View{" "}
                  <Link to="/blog">
                    <b>all articles</b>
                  </Link>{" "}
                  or browse <br />{" "}
                  <Link to="/events">
                    <b>all events.</b>
                  </Link>
                </p>
              </SectionCTAContainer>
            </div>
          </div>
          {recentPosts.length > 0 && (
            <div className="events-contents">
              <article className="most-featured-article">
                <div
                  className="article-image"
                  style={{
                    backgroundImage: `url(${recentPosts[0].better_featured_image ? recentPosts[0].better_featured_image.source_url : ""})`,
                  }}
                />
                <div className="smaller-container">
                  <div className="article-content">
                    <ArticleTag bgcolor="#112E51">{this.renderCategories(recentPosts[0])}</ArticleTag>
                    <Link to={`/blog/${recentPosts[0].slug}`}>
                      <h3>{recentPosts[0].title.rendered}</h3>
                    </Link>
                    <p className="sub-title">
                      {recentPosts[0]._embedded.author[0].name} ·{" "}
                      <Moment format="MMM. DD, YYYY">
                        {recentPosts[0].date}
                      </Moment>
                    </p>
                    <p className="description sm">
                      <Truncate
                        lines={2}
                        dangerouslySetInnerHTML={{
                          __html: recentPosts[0].excerpt.rendered,
                        }}
                      />
                    </p>
                  </div>
                </div>
              </article>

              <div className="featured-articles">
                <div className="smaller-container">
                  <Grid className="articles">
                    <Row>
                      <Col xs={12} sm={12} lg={6}>
                        <article
                          className="latest-article"
                          style={{
                            backgroundImage: `url(${recentPosts[1].better_featured_image ? recentPosts[1].better_featured_image.source_url : ""})`,
                          }}
                        >
                          <div
                            className="article-image"
                            style={{
                              backgroundImage: `url(${recentPosts[1].better_featured_image ? recentPosts[1].better_featured_image.source_url : ""})`,
                            }}
                          />
                          <div className="wrapper">
                            <div className="summary">
                              <ArticleTag bgcolor="#112E51">{this.renderCategories(recentPosts[1])}</ArticleTag>
                              <Link to={`/blog/${recentPosts[1].slug}`}>
                                <h3>{recentPosts[1].title.rendered}</h3>
                              </Link>
                              <p className="sub-title">
                                {recentPosts[1]._embedded.author[0].name} |{" "}
                                <Moment format="MMM. DD, YYYY">
                                  {recentPosts[1].date}
                                </Moment>
                              </p>
                              <p className="description sm">
                                <Truncate
                                  lines={2}
                                  dangerouslySetInnerHTML={{
                                    __html: recentPosts[1].excerpt.rendered,
                                  }}
                                />
                              </p>
                            </div>
                          </div>
                        </article>
                      </Col>
                      <Col xs={12} sm={12} lg={6}>
                        <div className="recent-articles">
                          {this.renderRightArticle(recentPosts[2])}
                          {this.renderRightArticle(recentPosts[3])}
                        </div>
                      </Col>
                    </Row>
                  </Grid>

                  <Events upComingEvents={upComingEvents} pastEvents={pastEvents} />
                </div>
              </div>
            </div>
          )}
          <SectionCTAContainer className="laptop-hidden">
            <p>
              View{" "}
              <Link to="/blog">
                <b>all articles</b>
              </Link>{" "}
              or browse <br />{" "}
              <Link to="/events">
                <b>all events.</b>
              </Link>
            </p>
          </SectionCTAContainer>
        </div>
      </HighlightsEventsContainer>
    );
  }
}

export default HighlightsEvents;
