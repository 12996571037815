import React, { Component } from "react";
import { Element, scroller } from "react-scroll";

import { Banner, GatheringInput, ExploreTopics } from "./sections";
import BigPictureContainer from "./index.style";
import { Helmet } from "react-helmet";

class BigPicture extends Component {
  componentDidMount() {
    const { hash } = this.props.location;
    if (hash !== "") {
      setTimeout(() => {
        scroller.scrollTo(hash.substr(1));
      }, 1000);
    }

    const googleSheetId = "14Vxg13fzCrY5pUCul0pOQ6QDtYeml6qD9GBipc-zFM4";
    const chartOptions = [
      {
        title: "Voting",
        options: {
          colors: ["#322E6C", "#159DBF", "#FC9B00", "#EF233C"],
          chart: {
            height: "500px",
          },
          plotOptions: {
            series: {
              pointWidth: 15,
            },
          },
          yAxis: {
            softMax: 500,
            max: 500,
          },
        },
      },
      {
        title: "Top Subtopics for 3-County Area",
        options: {
          colors: ["#322E6C", "#159DBF", "#FC9B00", "#EF233C"],
          chart: {
            height: "500px",
          },
          plotOptions: {
            series: {
              pointWidth: 15,
            },
          },
          yAxis: {
            softMax: 500,
            max: 500,
          },
        },
      },
      {
        title: "Top Subtopics for Fort Bend",
        options: {
          colors: ["#322E6C", "#159DBF", "#FC9B00", "#EF233C"],
          chart: {
            height: "500px",
          },
          plotOptions: {
            series: {
              pointWidth: 15,
            },
          },
        },
      },
      {
        title: "Top Subtopics for Harris",
        options: {
          colors: ["#322E6C", "#159DBF", "#FC9B00", "#EF233C"],
          chart: {
            height: "500px",
          },
          plotOptions: {
            series: {
              pointWidth: 15,
            },
          },
        },
      },
      {
        title: "Top Subtopics for Montgomery",
        options: {
          colors: ["#322E6C", "#159DBF", "#FC9B00", "#EF233C"],
          chart: {
            height: "500px",
          },
          plotOptions: {
            series: {
              pointWidth: 15,
            },
          },
        },
      },
    ];

    import("components/data-viz").then(({ Chart, Data }) => {
      (async function() {
        await Data(googleSheetId).then(function() {
          chartOptions.forEach(function(obj) {
            const chart = Chart.lookup(obj.title);
            if (chart && obj.options) chart.update(obj.options);
          });
        });
      })();
    });
  }

  render() {
    return (
      <BigPictureContainer>
        <Helmet>
          <title>What We Do | Understanding Houston</title>
          {/* <meta name="description" content={} /> */}
        </Helmet>
        <div className="contents">
          <Banner />
          <GatheringInput />
          <Element name="explore-topics">
            <ExploreTopics />
          </Element>
        </div>
      </BigPictureContainer>
    );
  }
}

export default BigPicture;
