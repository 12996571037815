import styled from "styled-components"

import { device } from "styles/breakpoints"
import { defaultShevy } from "styles/global.style"

const { baseSpacing: bs } = defaultShevy

const ArticleContainer = styled.div`
  .social-share {
    .share-button {
      cursor: pointer;
    }
  }

  section {
    &.banner {
      background-size: cover;
      background-position: top center;
      height: 10em;
      background-color: grey;

      @media ${device.laptop} {
        height: 32.6em;
      }
    }

    &.content {
      padding: 0 ${bs()};

      @media ${device.laptop} {
        padding: 0;
      }

      .container {
        @media ${device.laptop} {
          padding-left: ${bs(3.5)};
        }

        .article-header {
          padding-top: ${bs(1.5)};
          background: white;

          @media ${device.laptop} {
            margin-top: -75px;
            padding: ${bs(3)};
          }

          h2 {
            margin-bottom: ${bs(0.5)};

            @media ${device.laptop} {
              font-size: 2.63em;
              line-height: 1.55;
            }
          }

          .status {
            display: flex;
            padding-top: 0.5rem;

            p {
              display: flex;
              align-items: center;
              font-size: 0.63em;
              margin-right: ${bs(0.9)};
              font-weight: bold;

              img {
                margin-right: ${bs(0.5)};
              }
            }
          }

          span {
            @media ${device.laptop} {
              font-size: 14px;
            }
          }
        }

        .article-content {
          .sticky-container {
            position: relative;

            > div:first-child {
              position: absolute;
              display: none;

              @media ${device.laptop} {
                display: block;
              }

              .social-share-side {
                transform: translate3d(-60px, 0, 0) !important;
                padding-top: 65px;
                width: auto !important;

                p {
                  font-size: 0.63em;
                  text-transform: uppercase;
                  margin-bottom: 0.77em;
                  font-weight: bold;
                }

                .share-button {
                  margin-bottom: 10px;
                }
              }
            }
          }

          .wp-content {
            .wp-block-embed-youtube,
            .wp-block-embed,
            .is-type-video,
            .is-provider-youtube,
            .wp-embed-aspect-16-9,
            .wp-has-aspect-ratio {
              margin-left: 0;
              margin-right: 0;
              position: relative;
              padding-bottom: 56.25%; /* 16:9 */
              padding-top: 25px;
              height: 0;
            }
            .wp-block-embed-youtube iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            .wp-block-image {
              .alignleft {
                @media ${device.laptop} {
                  margin-left: 0;

                  img {
                    margin-left: 0;
                  }
                }
              }
              .alignright {
                @media ${device.laptop} {
                  margin-right: 0;

                  img {
                    margin-right: 0;
                  }
                }
              }
            }

            @media ${device.laptop} {
              max-width: 39.68em;
              margin-left: ${bs(3)};
            }

            h5 {
              font-size: 1.36em;
              margin-bottom: ${bs(1.3)};
            }

            p {
              font-size: 0.81em;
              line-height: 1.77;
              margin-bottom: ${bs(2)};
            }
            .has-text-align-center {
              text-align: center;
            }

            ul,
            ol {
              font-size: 0.81em;
              line-height: 1.77;
              margin-bottom: ${bs(2)};

              li {
                line-height: 1.77;
                margin-bottom: ${bs(1.3)};

                ul,
                ol {
                  font-size: 1em;
                  line-height: 1.77;
                  margin: ${bs(1)} 0;

                  li {
                    font-size: 1em;
                  }
                }
              }
            }

            > img {
              width: 100%;
              margin-bottom: ${bs(4)};
            }

            .message {
              margin: ${bs(3)} 0 ${bs(3)} ${bs()};
              border-left: 4px solid #159dbf;
              font-size: 0.9em;
              line-height: 1.7;
              padding-left: ${bs()};
              font-weight: bold;
            }

            .article-tags {
              margin-bottom: ${bs(1.5)};

              span {
                border: 1px solid #cecece;
                padding: 4px 20px;
                border-radius: 14px;
                font-size: 0.63em;
                text-transform: uppercase;
                margin-right: ${bs(0.5)};
              }
            }

            .article-share {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-bottom: ${bs(2)};

              .share-status {
                display: flex;
                align-items: center;
                font-size: 0.63em;
                font-weight: bold;

                span {
                  width: ${bs(2)};
                  height: ${bs(2)};
                  border-radius: 50%;
                  border: 1px solid #322e6c;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: ${bs(0.9)};
                  background-color: rgba(255, 255, 255, 0.9);
                }
              }

              .social-share {
                display: flex;

                .share-button {
                  margin-left: 10px;

                  &:first-child {
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.article-writer {
      background-color: #e6e9ed;
      padding: ${bs()};

      @media ${device.laptop} {
        padding: ${bs(2.5)} 0 ${bs(3)};
      }

      .container {
        .wrapper {
          max-width: 39.68em;
          display: flex;

          @media ${device.laptop} {
            padding-left: ${bs(6.5)};
          }

          img {
            width: ${bs(4)};
            height: ${bs(4)};
            border-radius: 50%;
            flex-shrink: 0;
            object-fit: cover;
          }

          .writer-details {
            padding-left: 1em;

            p {
              margin-bottom: 0;
              &.title-1 {
                opacity: 0.5;
              }

              &.name {
                font-weight: bold;
                color: #112e51;
              }

              &.title-2 {
                opacity: 0.76;
                margin-bottom: ${bs(0.7)};
              }
            }
          }
        }
      }
    }

    &.article-navigator {
      display: flex;

      .post-navigator {
        width: 50%;
        height: ${bs(2)};
        background-color: rgb(13, 36, 64);
        color: white;
        padding: 0 ${bs()};
        cursor: pointer;

        &:hover {
          background-color: rgb(11, 32, 56);
          position: relative;

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 4px;
            background-color: #ddd12a;
          }
        }

        @media ${device.laptop} {
          padding: 0;
          height: ${bs(10)};
        }

        .wrapper {
          max-width: 540px;
          height: 100%;
          display: flex;
          align-items: center;

          h3 {
            font-size: 1em;
            line-height: 1.33;
            max-width: ${bs(15)};
            display: none;
            margin-bottom: 0;

            @media ${device.laptop} {
              display: block;
            }
          }

          p {
            font-size: 0.63em;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 0;
          }
        }

        &.previous-post {
          .wrapper {
            margin-left: auto;
            p {
              display: flex;
              align-items: center;
              margin-right: ${bs(2)};
            }

            img {
              margin-right: 10px;
              @media ${device.laptop} {
                width: 32px;
              }
            }
          }
        }

        &.next-post {
          .wrapper {
            margin-right: auto;
            justify-content: flex-end;

            p {
              display: flex;
              align-items: center;
              margin-left: ${bs(2)};

              img {
                transform: rotate(180deg);
                margin-left: 10px;

                @media ${device.laptop} {
                  width: 32px;
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ArticleContainer
