import styled, { css } from "styled-components";

import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";

const { baseSpacing: bs } = defaultShevy;

const SectionTitle = styled.h2`
  line-height: 1.2;
  margin-bottom: ${defaultShevy.baseSpacing(1.5)};
  width: 100%;

  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};

  @media ${device.tablet} {
    line-height: 1.25;
    margin-bottom: ${bs(4)};
  }

  @media ${device.laptop} {
    margin-bottom: ${bs(6)};
  }
`;

export default SectionTitle;
