import styled from "styled-components";

const PostTag = styled.span`
  padding: 5px 10px;
  color: white;
  text-transform: uppercase;
  font-size: 0.54em;
  font-weight: bold;
  border-radius: 3px;
  background-color: ${props => props.bgcolor};
  display: inline-block;
`;

export default PostTag;
