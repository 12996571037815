import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { isMobileOnly } from "react-device-detect";

import { client } from "services/helpers/apiConfig";

import Truncate from "react-truncate-html";
import ImgDownArrow from "assets/images/down-arrow.png";

import { SectionTitle, Button, SectionPointer } from "components/elements";
import CommunityVoicesContainer from "./index.style";
import { SectionCTAContainer } from "../index.style";

class CommunityVoices extends Component {
  state = {
    data: [],
    activeIndex: 0,
  };

  async componentDidMount() {
    try {
      const resCategory = await client.get(
        "/wp/v2/categories?slug=community-voices"
      );
      const categoryId = resCategory.data[0].id;
      const resPosts = await client.get(`wp/v2/posts?categories=${categoryId}`);
      this.setState({
        data: resPosts.data,
      });
    } catch (e) {
      console.log(e);
    }
  }

  onSelectCandidate = (index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const settings = {
      infinite: false,
      slidesToShow: isMobileOnly ? 5 : 6,
      slidesToScroll: 1,
      focusOnSelect: true,
      // variableWidth: isMobile,
      arrows: false,
    };
    const { data, activeIndex } = this.state;
    const { linkMoreStories } = this.props;

    return (
      <CommunityVoicesContainer
        className="section community-voices"
        forwardRef={this.props.innerRef}
      >
        {!!data.length && (
          <div className="container">
            <div className="inner-content">
              <div className="title">
                <div className="title-left">
                  <SectionPointer firstColor="#159DBF" secondColor="#DDD12A" />
                  <SectionTitle color="white">Articles & Reports</SectionTitle>
                </div>
                {linkMoreStories && (
                  <SectionCTAContainer className="mobile-hidden">
                    <p>
                      <Link to="/blog">
                        <b>Discover more stories</b>
                      </Link>{" "}
                      from <br /> Houstonians just like you.
                    </p>
                  </SectionCTAContainer>
                )}
              </div>
            </div>
            <div className="blog-carousel">
              <div className="blog-viewer">
                <div
                  className="candidate-photo"
                  style={{
                    backgroundImage: data[activeIndex].better_featured_image
                      ? `url(${data[activeIndex].better_featured_image.source_url})`
                      : "none",
                  }}
                />
                <div className="candidate-details">
                  <p className="role">{data[activeIndex].acf.role}</p>
                  {data[activeIndex].acf.name && (
                    <h2 className="sm">{data[activeIndex].acf.name}</h2>
                  )}
                  <h6>
                    <Link to={`/blog/${data[activeIndex].slug}`}>
                      {data[activeIndex].title.rendered}
                    </Link>
                  </h6>
                  <Truncate
                    lines={5}
                    className="comment sm"
                    dangerouslySetInnerHTML={{
                      __html: data[activeIndex].acf.summary,
                    }}
                  />
                  <div className="actions">
                    <Button
                      default
                      className="btn-readmore"
                      as={Link}
                      to={`/blog/${data[activeIndex].slug}`}
                      aria-label={`Read more about ${data[activeIndex].title.rendered}`}
                    >
                      Read more
                    </Button>
                  </div>
                </div>
              </div>
              <div className="slider">
                <Slider {...settings}>
                  {data.map((post, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div
                      key={index}
                      onClick={() => this.onSelectCandidate(index)}
                    >
                      <div
                        className={`slide-content ${index === activeIndex &&
                          "active"}`}
                        style={{
                          backgroundImage: post.better_featured_image
                            ? `url(${post.better_featured_image.media_details.sizes.medium.source_url})`
                            : "none",
                        }}
                      >
                        <div className="slide-border" />
                        <div className="name">
                          <img
                            src={ImgDownArrow}
                            alt="currently selected indicator arrow"
                          />
                          <p className="xs">{post.acf.name}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <SectionCTAContainer className="laptop-hidden">
              <p>
                <Link to="/blog">
                  <b>Discover more stories</b>
                </Link>{" "}
                from <br /> Houstonians just like you.
              </p>
            </SectionCTAContainer>
          </div>
        )}
      </CommunityVoicesContainer>
    );
  }
}

CommunityVoices.defaultProps = {
  linkMoreStories: true,
};

export default CommunityVoices;
