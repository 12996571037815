/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import SignupForm from "components/forms/signup";
import { Col, Row } from "react-styled-flexboxgrid";
import { SocialLinks } from "components";
import ImageMedia from "assets/images/get-involved-stay-connected-1.png";
import MediaContainer from "./index.style";

const Media = ({ innerRef }) => {
  return (
    <MediaContainer className="get-involved--media section" ref={innerRef}>
      <div className="get-involved--media-bg" id="subscribe" />
      <Row className="container get-involved--media-banner">
        <Col xs={12} sm={7}>
          <h3>
            Latest from Instagram
            <br />
            <strong>@understandinghouston</strong>
          </h3>
        </Col>
      </Row>
      <div className="get-involved--media-content">
        <Row className="container">
          <Col xs={12} sm={6}>
            <img src={ImageMedia} alt="Stay connected to Understanding Houston" />
          </Col>
          <Col xs={12} sm={5} smOffset={1}>
            <h3>
              Stay <strong>connected</strong>.
            </h3>
            <p>Follow us on social media or sign up to receive our newsletter.</p>
            <div id="get-involved-signup">
              <SignupForm />
            </div>
            <SocialLinks />
          </Col>
        </Row>
      </div>
    </MediaContainer>
  );
};

export default Media;
