import React, { Component } from "react";
import { Row, Col } from "react-styled-flexboxgrid";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import postscribe from "postscribe";
import { Helmet } from "react-helmet";

import ContactContainer from "./index.style";

class Contact extends Component {
  state = {
    isLoading: true,
    slide: 1,
    stepper: null,
  };

  componentDidMount() {
    const script = `
      <script
        type="text/javascript"
        src="https://ghcf.formstack.com/forms/js.php/uhontact_us_c">
      </script>`;
    postscribe("#contact_form", script, {
      afterAsync: this.setFormLoaded,
    });
    const stepper = window.requestAnimationFrame(this.step);

    this.setState({ stepper });
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.state.stepper);
  }

  getSlideMode = (timestamp) => {
    return (Math.floor(timestamp / 3000) % 6) + 1;
  };

  step = (timestamp) => {
    const slide = this.getSlideMode(timestamp);
    const stepper = window.requestAnimationFrame(this.step);
    this.setState({
      slide,
      stepper,
    });
  };

  setFormLoaded = () => {
    this.setState({
      isLoading: false,
    });
  };

  render() {
    return (
      <ContactContainer className="contact--wrapper">
        <Helmet>
          <title>Contact | Understanding Houston</title>
          {/* <meta name="description" content={} /> */}
        </Helmet>
        <section className="header">
          <Row className="container">
            <Col xs={12} sm={9} lg={7}>
              <h1>We’d love to hear your feedback</h1>
            </Col>
            <Col xs={12} sm={10} lg={9}>
              <p>
                As a community-driven project, Understanding Houston is fueled
                by collaboration and interaction with the people and
                organizations that make our communities better, more vibrant
                places to live, work and play. If you’re interested in getting
                involved, sharing your data story, learning more or giving us
                some feedback, fill out the appropriate form below to get in
                touch!
              </p>
            </Col>
          </Row>
        </section>
        <section className={`contact contact-slide-${this.state.slide}`}>
          <Row className="container">
            <Col xs={12} sm={8} md={7} lg={6}>
              <div
                id="contact_form"
                className={`${this.state.isLoading && "is-loading"}`}
              />
            </Col>
          </Row>
        </section>
      </ContactContainer>
    );
  }
}

export default withRouter(Contact);
