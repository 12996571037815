import styled from "styled-components";

import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";

const { baseSpacing: bs } = defaultShevy;

const SectionContainer = styled.section`
  .inner-content {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
`;

const SectionCTAContainer = styled.div`
  color: ${props => props.theme.primary.spaceCadet};
  width: 14em;
  position: relative;
  display: none;

  &.laptop-hidden {
    display: block;
    width: 100%;
  }

  /* @media screen and (min-width: 1024px) and (max-width: 1440px) {
    display: none;
  } */

  @media ${device.tablet} {
    &.laptop-hidden {
      display: none;
    }
  }

  @media ${device.laptopL} {
    &.mobile-hidden {
      display: block;
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: ${bs(3)};
    height: 1px;
    display: block;
    background-color: #cecece;
    left: -${bs()};
    top: 50%;
    transform: translate(-100%, -50%);
  }

  p {
    margin: 0;

    a {
      border-bottom: 3px solid ${props => props.theme.accent.pear};
      text-decoration: none;
      color: ${props => props.theme.primary.spaceCadet};
    }
  }
`;

export { SectionContainer, SectionCTAContainer };
