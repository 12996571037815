import economicOpportunity from "./economic-opportunity.json";
import subtopics from "./subtopics.json";

const stubs = {
  [`${process.env.REACT_APP_WP_API_URL}wp/v2/pages/?slug=economic-opportunity`]: {
    status: 200,
    response: economicOpportunity
  },
  [`${process.env.REACT_APP_WP_API_URL}wp/v2/pages/?parent=109`]: {
    status: 200,
    response: subtopics
  }
};

export default stubs;
