import React, { Component } from "react";
import { Button } from "components/elements";
import ImgCity from "assets/images/bigpicture/img-city.png";

import BannerContainer from "./index.style";

class Banner extends Component {
  componentDidMount() {}

  render() {
    return (
      <BannerContainer>
        <div className="container">
          <div className="content">
            <h1>Together, we are measuring what matters to do what matters.</h1>
            <p>
              The Understanding Houston website offers over 200 indicators on quality of life issues spanning eight primary topics.
              <br />
              <br /> In our summary report, we highlight 25 key measures that offer a snapshot of quality of life in the Houston three-county area.{" "}
            </p>
            <a
              href="https://api.understandinghouston.org/wp-content/uploads/2024/03/Understanding-Houston-Summary-Report-2023.pdf"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              aria-label="Summary Report (opens in a new tab)"
            >
              <Button>Summary Report</Button>
            </a>
          </div>
        </div>
        <img src={ImgCity} alt="city" />
      </BannerContainer>
    );
  }
}

export default Banner;
