import React from "react";
import styled from "styled-components";

import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";
import BgDesktop from "assets/images/bg-terrien@2x.jpg";
import { SectionContainer } from "../index.style";
import MapContainer from "./svgMap.style";

const SectionWrapper = styled(({ forwardRef, ...props }) => (
  <SectionContainer {...props} ref={forwardRef} />
))`
  background-image: url(${BgDesktop});
  background-color: white;
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 4em ${defaultShevy.baseSpacing()} 1em;
  position: relative;
  z-index: 2;

  @media ${device.laptop} {
    padding: 9.72em 0 9.5em;
    margin: 0;
    margin-top: -170px;
  }

  .inner-content {
    display: flex;
    flex-direction: column;

    @media ${device.mobileL} {
    }

    &.in-scope {
      @media ${device.tablet} {
        padding-left: 4em;
        padding-right: 4em;
      }
    }

    @media ${device.laptop} {
      flex-direction: row;
      padding-right: 0;
    }

    .left-content {
      position: relative;
      margin-bottom: 29px;

      .title {
        flex-direction: column;

        .section-pointer {
          @media ${device.laptop} {
            top: 50px;
          }
        }

        span {
          color: #117e99;
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 9px;
          font-weight: bold;
          text-transform: uppercase;
        }

        h1 {
          margin-bottom: 40px;
        }
      }

      p {
        line-height: 1.45;
        margin-bottom: 30px;

        a {
          font-weight: bold;
          border-bottom: 3px solid ${(props) => props.theme.accent.pear};
        }
      }

      @media ${device.laptop} {
        width: 50%;
        margin-bottom: 0;
      }
    }

    .right-content {
      ${MapContainer} {
        .highcharts-container,
        .map-pagination {
          display: none;
        }
      }

      @media ${device.mobileL} {
        text-align: left;

        .dJCiry {
          text-align: left;
        }
      }

      @media ${device.laptop} {
        ${MapContainer} {
          .highcharts-container,
          .map-pagination {
            display: block;
          }
        }
      }
    }
  }
`;

export default SectionWrapper;
