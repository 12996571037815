import styled from "styled-components";

import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";

import MainBlogContainer from "./main.style";

const { baseSpacing: bs } = defaultShevy;

const EventsContainer = styled(MainBlogContainer)`
  section {
    &.banner {
      height: 280px;

      @media ${device.laptop} {
        height: 380px;
        }

      .container {
        max-width: 85%;

        @media ${device.laptop} {
          max-width: 1406px;
        }
      }

      h1 {
          color: #fff;

          @media ${device.laptop} {
            margin-left: 50px;
          }
        }
    }
    &.events-header {
      margin-top: -${bs(7.5)};
      margin-bottom: 20px;
      @media ${device.laptop} {
        margin-bottom: 60px;
        }

      .container {
        position: relative;
        background: transparent;
        max-width: 85%;
        margin-top: 40px;

        @media ${device.laptop} {
          max-width: 1406px;
          }

        h2 {
          font-size: 2.54em;
          margin-bottom: ${bs(3.15)};
          color: white;
        }

        .reserve-seat {
          padding: 0;
          display: flex;
          align-items: flex-start;
          ${"" /* justify-content: space-between; */}
          flex-direction: column;
          background: transparent;

          @media ${device.laptop} {
            flex-direction: row;
            ${"" /* align-items: center; */}
          }

          &:after {
            content: ' '
          }

          .column {
            margin-top: 20px;
            ${"" /* margin-left: 0; */}

            @media ${device.laptop} {
              margin-top: 140px;
              margin-left: 50px;
              margin-right: 50px
            }

            p {
              font-size: 16px;
              margin-top: 0;
              opacity: 0.9;
              margin-bottom: 0;

              @media ${device.laptop} {
                margin-top: -5px;
                margin-bottom: 20px;
              }
            }

            button {
              padding: 10px 30px;
              height: 46px;
              margin-top: 0;

              @media ${device.laptop} {
                margin-top: -5px;
              }
            }
          }

          .column.first {
            padding: 30px;
            margin-bottom: 20px;
            background: #159DBF;

            @media ${device.laptop} {
              padding: 30px 60px;
              margin-bottom: 0;
              margin-top: 20px;
            }

            h3 {
              color: #fff;
            }

            p {
              color: #fff;
              opacity: 1;
              font-size: 14px;
              margin-top: 0;
            }
          }

          h3 {
            font-size: 1.13em;
            line-height: 1.2;
            color: ${(props) => props.theme.accent.seaGreen};
            max-width: ${bs(17)};
          }

          p.sm {
            font-weight: bold;
          }

          p.xs {
            margin-bottom: 0.5em;
            max-width: ${bs(21)};

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &.blog-categories {
      margin-top: 0;

      .container {
        max-width: 85%;

        @media ${device.laptop} {
          max-width: 1406px;
          ${"" /* max-width: 85%; */}
        }
      }

      .medias {
        ${"" /* padding: 0 0 0 0; */}

        .blog {
          margin-bottom: 24px;
        }

        p.col-title {
          margin: 20px 0 20px 0;

          @media ${device.laptop} {
            margin: 30px 0 30px 0;
          }

          &.primary {
            margin: 20px 0 20px 0;

            @media ${device.laptop} {
              margin: 30px 0 30px 20px;
            }
          }
        }
      }

      .recent-blogs {
        .blog {
          border-radius: 3px;

          .blog-image {
            height: 160px;
            background-size: cover;
            background-position: center;
          }

          .blog-content {
            p.subtitle {
              margin-bottom: 0;
            }

            p.xs {
              margin-bottom: 5px;
              display: flex;
              align-items: center;

              span {
                margin-right: 10px;
                width: 16px;
              }
            }

            a.event-link {
              font-size: 0.65em;
              text-transform: capitalize;
            }
          }
        }
      }
    }
    &.events-post {
      .col-title {
        &.primary {
          margin: 20px 0 20px 0;

          @media ${device.laptop} {
            margin: 30px 0 30px 0;
          }
        }
      }

      .blog {
        margin-bottom: 20px;

        @media ${device.laptop} {
          margin-bottom: 30px;
        }
      }
    }
  }
`;

export default EventsContainer;
