import React from "react";
import { NavLink } from "react-router-dom";

import pick from "lodash/pick";
import kebabCase from "lodash/kebabCase";

import SimpleCrumbsContainer from "./index.style";

const Crumb = props => {
  return (
    <li className="crumb">
      {(props.to && (
        <NavLink
          to={props.to}
          exact
          dangerouslySetInnerHTML={{ __html: props.label }}
        />
      )) || <span dangerouslySetInnerHTML={{ __html: props.label }} />}
    </li>
  );
};

const SimpleCrumbs = props => {
  const elementProps = pick(props, ["style", "className"]);
  return (
    <SimpleCrumbsContainer aria-label="Breadcrumb" {...elementProps}>
      <ul className="crumbs">
        {props.items.map(item => (
          <Crumb {...item} key={kebabCase(item.label)} />
        ))}
      </ul>
    </SimpleCrumbsContainer>
  );
};

export default SimpleCrumbs;
