import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class LinkComponent extends Component {
  componentDidMount() {}

  render() {
    const { propMap, node } = this.props.data;
    const nodeTitle = node[propMap.displayName];

    if (node.link.includes("https://")) {
      return (
        <a
          onClick={(e) => {
            this.props.onNodeClick(e, this.props.data);
          }}
          className={`rpm-node-link rpm-inline-block ${node[
            propMap.linkClasses
          ] || ""}`}
          to={node[propMap.url]}
          href={node[propMap.url] || "#"}
          dangerouslySetInnerHTML={{ __html: nodeTitle }}
          rel="noopener noreferrer"
          target="_blank"
        />
      );
    } else {
      return (
        <NavLink
          onClick={(e) => {
            this.props.onNodeClick(e, this.props.data);
          }}
          className={`rpm-node-link rpm-inline-block ${node[
            propMap.linkClasses
          ] || ""}`}
          to={node[propMap.url]}
          href={node[propMap.url] || "#"}
          dangerouslySetInnerHTML={{ __html: nodeTitle }}
        />
      );
    }
  }
}

export default LinkComponent;
