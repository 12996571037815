// TODO better regex

function replaceAPIContentLinks(html, linkPrefix = "") {
  const apiLinkRegex = /(href="https:\/\/api\.understandinghouston\.org)|(href="\/)/g;
  return html.replace(
    apiLinkRegex,
    `data-target="${linkPrefix}" data-link-mode="router" href="${linkPrefix}`
  );
}

function setTargetBlankForExternalLinks(html) {
  const externalLinkRegex = /(href="http)(?!s:\/\/api\.understandinghouston\.org)/g;
  return html.replace(
    externalLinkRegex,
    'data-link-mode="external" target="_blank" rel="noopener noreferrer" href="http'
  );
}

function cleanHTML(html, linkPrefix = "") {
  const cleanedHTML = replaceAPIContentLinks(html, linkPrefix);
  return setTargetBlankForExternalLinks(cleanedHTML);
}

function cleanContent(wpItem, { linkPrefix = "" }) {
  return {
    ...wpItem,
    content: {
      ...wpItem.content,
      rendered: cleanHTML(wpItem.content.rendered, linkPrefix)
    }
  };
}

export { replaceAPIContentLinks, cleanHTML };

export default cleanContent;
