import styled from "styled-components";

import { defaultShevy } from "styles/global.style";

const { baseSpacing: bs } = defaultShevy;

const PageContainer = styled.div`
  padding: 0 ${bs()};
`;

export default PageContainer;
