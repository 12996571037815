import React, { Component } from "react";
import PushMenu from "react-push-menu-with-logo";
import "react-push-menu-with-logo/styles/component.css";
// import { Link } from "react-router-dom";
import ArrowRight from "assets/images/icon-arrow-right.svg";
import ArrowLeft from "assets/images/icon-arrow-left.svg";
import Logo from "assets/images/logo-u.svg";
import IconSearch from "assets/images/icon-search.svg";
import { defaultTopics } from "components/topic-menu";
import SocialLinks from "../socialLinks";
import MobileMenuContainer from "./mobileMenu.style";
import StyledLink from "./link";

// could be just recursive, do later.
function transformDataToTopicsMenu(topics) {
  return topics.map((topic) => ({
    name: topic.title.rendered,
    id: topic.id,
    link: `/topic/${topic.slug}`,
    children: [],
  }));
}

function buildMenu(topics) {
  return {
    children: [
      {
        name: "Explore the Data",
        id: 5,
        link: "/explore-the-data",
        children: transformDataToTopicsMenu(topics),
      },
      {
        name: "Who We Are",
        id: 1,
        link: "/the-initiative",
        children: [
          {
            name: "Our Approach",
            link: "/the-initiative#ourApproach",
          },
          {
            name: "About Us",
            link: "/the-initiative#aboutUs",
          },
          {
            name: "Advisory Committee",
            link: "/the-initiative#advisory",
          },
          {
            name: "Supporters",
            link: "/the-initiative#supporters",
          },
        ],
      },
      {
        name: "What We Do",
        id: 2,
        link: "/the-big-picture",
      },
      {
        name: "Get Involved",
        id: 4,
        link: "/get-involved",
        children: [
          {
            name: "Contact Us",
            link: "/contact",
          },
          {
            name: "Events",
            link: "/events",
          },
          {
            name: "Donate",
            link: "https://ghcf.kimbia.com/understandinghoustondonation",
          },
        ],
      },
      {
        name: "Articles & Reports",
        id: 3,
        link: "/blog",
        children: [
          {
            name: "Articles & Insights",
            link: "/blog",
          },
          {
            name: "Summary Report",
            link:
              "https://api.understandinghouston.org/wp-content/uploads/2024/03/Understanding-Houston-Summary-Report-2023.pdf",
          },
        ],
      },
      {
        name: "Search",
        id: 7,
        link: "/search",
        children: [],
      },
    ],
  };
}

class MobileMenu extends Component {
  state = {
    menuOpen: false,
    slidingStart: false,
    showSearch: false,
  };

  componentDidMount() {}

  onToggleMenu = () => {
    this.triggerElement.click();
    if (this.state.slidingStart === true) {
      setTimeout(() => {
        this.setState({
          slidingStart: false,
        });
      }, 500);
    } else {
      this.setState({
        slidingStart: true,
      });
    }
  };

  onMenuClosed = () => {
    this.setState({
      menuOpen: false,
    });
  };

  onMenuOpen = () => {
    this.setState({
      menuOpen: true,
      showSearch: false,
    });
  };

  renderSearch = () => {
    return (
      <div className="search-component">
        <div className="search-field">
          <input type="text" placeholder="Search" />
          <img src={IconSearch} alt="icon" />
        </div>
        <p className="xs">
          Search to explore the data, stories, news and more…
        </p>
      </div>
    );
  };

  onShowSearch = () => {
    this.setState({
      showSearch: true,
    });
  };

  render() {
    const { menuOpen, slidingStart, showSearch } = this.state;
    const menuNodes = buildMenu(
      this.props.content.topicsAndSubtopicsMenu.topics
    );

    return (
      <MobileMenuContainer
        className={`${slidingStart ? "opened" : "closed"}`}
        id="mobile-menu-container"
      >
        <button
          onClick={this.onToggleMenu}
          className={`btn-trigger ${!menuOpen ? "open" : "close"}`}
          aria-label={`${!menuOpen ? "open" : "close"}-mobile-menu`}
        >
          <div className="bar bar1" />
          <div className="bar bar2" />
          <div className="bar bar3" />
        </button>
        <PushMenu
          backIcon={<img src={ArrowLeft} alt="arrow-left" />}
          expanderComponent={() => <img src={ArrowRight} alt="arrow-right" />}
          onNodeClick={(e, { menu, node }) => {
            this.onToggleMenu();
            menu.tools.reset();
          }}
          linkComponent={StyledLink}
          isOpen={false}
          nodes={menuNodes}
          type="cover"
          propMap={{ url: "link" }}
          logoIcon={
            <a href="/">
              <img src={Logo} alt="understanding houston logo" />
            </a>
          }
          menuButtonComponent={() => (
            <SocialLinks
              hasTitle={false}
              // hasSearchButton
              onShowSearch={this.onShowSearch}
            />
          )}
          onMenuClose={this.onMenuClosed}
          onMenuOpen={this.onMenuOpen}
          searchComponent={this.renderSearch}
          isSearch={showSearch}
        >
          <div
            className="rpm-trigger"
            id="rpm-trigger"
            // eslint-disable-next-line no-return-assign
            ref={(el) => (this.triggerElement = el)}
          />
          {this.props.children}
        </PushMenu>
      </MobileMenuContainer>
    );
  }
}

MobileMenu.defaultProps = {
  content: defaultTopics,
};

export default MobileMenu;
