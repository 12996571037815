import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Formik, Field, ErrorMessage } from "formik";
import Select from "react-select";
import Button from "../elements/button";

const notify = function(type) {
  const msg =
    type === "success"
      ? "🥳 You're on the list!"
      : "😢 We're missing something.";
  toast(msg, {
    type: type,
    className: "notify-default",
    position: "bottom-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    newestOnTop: true,
  });
};

const topics = [
  { value: "Arts", label: "Arts" },
  { value: "Civic Engagement", label: "Civic Engagement" },
  { value: "Community Context", label: "Community Context" },
  { value: "Economic Opportunity", label: "Economic Opportunity" },
  { value: "Education", label: "Education" },
  { value: "Environment", label: "Environment" },
  { value: "Health", label: "Health" },
  { value: "Housing", label: "Housing" },
  { value: "Disasters", label: "Disasters" },
];

const profiles = [
  { value: "Business leader/staff", label: "Business leader/staff" },
  { value: "Civic/policy leader/staff", label: "Civic/policy leader/staff" },
  { value: "Community leader/resident", label: "Community leader/resident" },
  { value: "Foundation leader/staff", label: "Foundation leader/staff" },
  { value: "GHCF donor/volunteer", label: "GHCF donor/volunteer" },
  { value: "Nonprofit leader/staff", label: "Nonprofit leader/staff" },
  { value: "Researcher/analyst", label: "Researcher/analyst" },
  { value: "Reporter/media member", label: "Reporter/media member" },
];

const counties = [
  { value: "Fort Bend", label: "Fort Bend" },
  { value: "Harris", label: "Harris" },
  { value: "Montgomery", label: "Montgomery" },
  { value: "Other", label: "Other" },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "48px",
    paddingLeft: "13px",
    overflow: "hidden",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "48px",
    lineHeight: "48px",
  }),
  singleValue: (provided) => ({
    ...provided,
    lineHeight: "48px",
  }),
  Input: (provided) => ({
    ...provided,
    height: "38px",
    lineHeight: "48px",
  }),
  input: (provided) => ({
    ...provided,
    height: "38px",
  }),
};
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const required = (value) => (value ? undefined : "Required");

class Wizard extends React.Component {
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues,
    };
  }

  next = (values) =>
    this.setState((state) => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }));

  previous = () =>
    this.setState((state) => ({
      page: Math.max(state.page - 1, 0),
    }));

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values, bag) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      bag.setSubmitting(true);
    } else {
      bag.setTouched({});
      bag.setSubmitting(false);
      this.next(values);
    }
    return onSubmit(values, bag, isLastPage);
  };

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    return (
      <Formik
        initialValues={values}
        enableReinitialize={false}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        render={({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            {activePage}
            <div className="buttons">
              {!isLastPage && (
                <Button type="submit" secondary="true">
                  Next
                </Button>
              )}
              {isLastPage && (
                <Button type="submit" disabled={isSubmitting} secondary="true">
                  Submit
                </Button>
              )}
            </div>
          </form>
        )}
      />
    );
  }
}

const SignupForm = () => (
  <div>
    <Wizard
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        organization: "",
        phone: "",
      }}
      onSubmit={(values, actions, isLastPage) => {
        sleep(300).then(() => {
          values.isVoting = false;
          const county = sessionStorage.getItem("newsletterCounty") || false;
          const topics = sessionStorage.getItem("newsletterTopics") || false;
          const profile = sessionStorage.getItem("newsletterProfile") || false;
          const currentEmail = localStorage.getItem("email") || false;
          const currentUser = localStorage.getItem("user") || false;
          if (county) values.county = county;
          if (topics && isLastPage) values.topics = topics;
          if (profile && isLastPage) values.profile = profile;
          if (currentEmail) values.currentEmail = currentEmail;
          if (currentUser) values.user = currentUser;

          const jsonSubmission = JSON.stringify(values);
          const fetchData = async () => {
            try {
              const result = await axios.post(
                "/.netlify/functions/subscribe",
                jsonSubmission,
                { headers: { "Content-Type": "application/json" } }
              );
              localStorage.setItem("email", result.data.email);
              localStorage.setItem("user", result.data.user);

              if (isLastPage) notify("success");
              if (isLastPage)
                document.getElementById("get-involved-signup").innerHTML =
                  "Thank you for subscribing!";
              if (isLastPage && typeof window.track !== "undefined") {
                window.track.newsletterSignup({
                  userId_: values.currentEmail,
                  email: values.currentEmail,
                });
              }
              return await axios.post("/.netlify/functions/user", result.data);
            } catch (error) {
              if (isLastPage) notify("error");
            }
            if (isLastPage) actions.setSubmitting(false);
          };
          fetchData();
        });
      }}
    >
      <Wizard.Page>
        <div className="form-field">
          <label htmlFor="firstNameSignup">First Name</label>
          <Field
            name="firstName"
            type="text"
            validate={required}
            id="firstNameSignup"
          />
          <ErrorMessage
            name="firstName"
            component="div"
            className="field-error"
          />
        </div>
        <div className="form-field">
          <label htmlFor="lastNameSignup">Last Name</label>
          <Field
            name="lastName"
            component="input"
            type="text"
            validate={required}
            id="lastNameSignup"
          />
          <ErrorMessage
            name="lastName"
            component="div"
            className="field-error"
          />
        </div>
        <div className="form-field">
          <label htmlFor="emailSignup">Email</label>
          <Field
            name="email"
            component="input"
            type="email"
            validate={required}
            id="emailSignup"
          />
          <ErrorMessage name="email" component="div" className="field-error" />
        </div>
      </Wizard.Page>
      <Wizard.Page>
        <div className="form-field">
          <label>County you live in</label>
          <Select
            options={counties}
            name="county"
            className="selector"
            styles={customStyles}
            onChange={(e) => {
              sessionStorage.setItem("newsletterCounty", e.value);
            }}
          />
        </div>
        <div className="form-field">
          <label>Organization</label>
          <Field name="organization" component="input" type="text" />
        </div>
        <div className="form-field">
          <label>Phone Number</label>
          <Field name="phone" component="input" type="text" />
        </div>
      </Wizard.Page>
      <Wizard.Page
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          }
          if (!values.firstName) {
            errors.firstName = "Required";
          }
          if (!values.lastName) {
            errors.lastName = "Required";
          }
          return errors;
        }}
      >
        <div className="form-field">
          <label>Select all topics of interest that apply</label>
          <Select
            isMulti
            options={topics}
            name="topics"
            className="selector"
            styles={customStyles}
            onChange={(e) => {
              const values = [];
              if (e) {
                e.forEach(function(entry) {
                  values.push(entry.value);
                });
                sessionStorage.setItem("newsletterTopics", values.join(", "));
              } else {
                sessionStorage.removeItem("newsletterTopics");
              }
            }}
          />
        </div>
        <div className="form-field">
          <label>What describes you best</label>
          <Select
            options={profiles}
            name="profile"
            className="selector"
            styles={customStyles}
            onChange={(e) => {
              sessionStorage.setItem("newsletterProfile", e.value);
            }}
          />
        </div>
      </Wizard.Page>
    </Wizard>
  </div>
);

export default SignupForm;
