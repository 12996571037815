import React, { Component } from "react";
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import ReactResizeDetector from "react-resize-detector";

import { SectionTitle, SectionPointer } from "components/elements";

import IconLinkedin from "assets/images/icon-linkedin.svg";
import IconFacebook from "assets/images/icon-facebook.svg";
import IconTwitter from "assets/images/icon-twitter.svg";
import IconClose from "assets/images/icon-close.svg";
import IndicatorContainer from "./index.style";

class Indicator extends Component {
  state = {
    selectedIndex: -1,
    visibleDetailIndex: -1,
    columnLength: 5,
    middleIndex: 2,
    detailIndex: -1,
    detailActive: false,
    selectedMember: null,
  };

  componentDidMount() {}

  setVisibleDetailIndex = (index, member) => {
    const { columnLength, middleIndex } = this.state;

    this.setState({
      selectedMember: member,
    });

    let visibleDetailIndex = -1;
    const indexOnColumn = index % columnLength;

    if (indexOnColumn === middleIndex) {
      if (middleIndex === 2) {
        visibleDetailIndex = index - 1;
      } else {
        visibleDetailIndex = Math.floor(index / columnLength) * columnLength + middleIndex;
      }
    }

    if (indexOnColumn < middleIndex) {
      visibleDetailIndex = index;
    }

    if (indexOnColumn > middleIndex) {
      if (middleIndex === 2) {
        visibleDetailIndex = index - 2;
      } else {
        visibleDetailIndex = Math.floor(index / columnLength) * columnLength + middleIndex;
      }
    }

    this.setState(
      {
        selectedIndex: index,
        visibleDetailIndex,
        detailIndex: -1,
        detailActive: false,
      },
      () => {
        // eslint-disable-next-line no-shadow
        const { columnLength, visibleDetailIndex } = this.state;

        setTimeout(() => {
          this.setState({
            detailIndex: visibleDetailIndex + columnLength,
          });
        }, 200);

        setTimeout(() => {
          this.setState({
            detailActive: true,
          });
        }, 300);
      }
    );
  };

  onResize = (width) => {
    this.onCloseDetail();

    if (width <= 1440 && width >= 720) {
      this.setState({
        columnLength: 3,
        middleIndex: 1,
      });
    } else if (width < 720) {
      this.setState({
        columnLength: 1,
        middleIndex: 0,
      });
    } else {
      this.setState({
        columnLength: 5,
        middleIndex: 2,
      });
    }
  };

  onCloseDetail = () => {
    this.setState({
      selectedIndex: -1,
      visibleDetailIndex: -1,
      detailActive: false,
      detailIndex: -1,
    });
  };

  render() {
    const { visibleDetailIndex, selectedIndex, columnLength, middleIndex, detailIndex, detailActive, selectedMember } = this.state;
    const members = this.props.committees;

    const [selectedMemberLocation, ...selectedMemberPositions] = (selectedMember && selectedMember.acf.description.split(",")) || [];

    const firstEmptyCellOrderIndex = visibleDetailIndex !== -1 && members.length <= visibleDetailIndex + columnLength - 1 ? members.length : visibleDetailIndex + columnLength + 1;

    return (
      <div ref={this.props.innerRef}>
        <ReactResizeDetector handleWidth onResize={this.onResize}>
          <IndicatorContainer>
            <div className="container">
              <Grid>
                <Row>
                  <Col xs={12} mdOffset={1} lgOffset={0}>
                    <div className="title">
                      <SectionPointer firstColor="#FC9B00" secondColor="#322E6C" />
                      <SectionTitle>
                        Indicator advisory <br /> committee
                      </SectionTitle>
                    </div>
                  </Col>
                </Row>
              </Grid>
              <div className="content">
                {members.map((member, index) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={`member cell ${detailActive && selectedIndex === index && "active"}`}
                    style={{
                      order: visibleDetailIndex !== -1 && index >= visibleDetailIndex + columnLength ? index + 1 : index,
                    }}
                    onClick={() => this.setVisibleDetailIndex(index, member)}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${member.acf.photo.url})`,
                      }}
                      className="img-photo"
                    />
                    <h6>{member.acf.name}</h6>
                    {member.acf.position !== "" && <p className="xs">{member.acf.position}</p>}
                  </div>
                ))}
                <div
                  className="empty cell"
                  style={{
                    display: (visibleDetailIndex === -1 || members.length >= selectedIndex + columnLength) && "none",
                    order: firstEmptyCellOrderIndex,
                  }}
                />
                {columnLength > 3 && (
                  <div
                    className="empty cell"
                    style={{
                      display: (visibleDetailIndex === -1 || members.length >= selectedIndex + columnLength) && "none",
                      order: visibleDetailIndex !== -1 && members.length === visibleDetailIndex + columnLength - middleIndex ? firstEmptyCellOrderIndex + 1 : visibleDetailIndex + columnLength + 1,
                    }}
                  />
                )}
                {selectedMember && (
                  <div className={`detail cell ${detailIndex !== -1 && "active"}`} style={{ order: detailIndex }}>
                    <span className="btn-close" onClick={() => this.onCloseDetail()}>
                      <img src={IconClose} alt="close" />
                    </span>
                    <div
                      className="photo"
                      style={{
                        backgroundImage: `url(${selectedMember.acf.photo.url})`,
                      }}
                    />
                    <div className="information">
                      {selectedMember.acf.position !== "" && <p className="xs">{selectedMember.acf.position}</p>}
                      <h3>{selectedMember.acf.name}</h3>
                      <p className="sm">{selectedMember.acf.quote}</p>
                      {selectedMember.acf.description !== "" && (
                        <div className="work-histories">
                          <p className="xs">
                            <strong>{selectedMemberLocation}</strong>
                            <br />
                            {selectedMemberPositions.join(", ")}
                          </p>
                        </div>
                      )}
                      <div className="social-links">
                        {selectedMember.acf.facebook !== "" && (
                          <a href={selectedMember.acf.facebook} target="_blank" rel="noopener noreferrer" aria-label="Facebook (opens in a new tab)">
                            <img src={IconFacebook} alt="Facebook" />
                          </a>
                        )}
                        {selectedMember.acf.twitter !== "" && (
                          <a href={selectedMember.acf.twitter} target="_blank" rel="noopener noreferrer" aria-label="Twitter (opens in a new tab)">
                            <img src={IconTwitter} alt="Twitter" />
                          </a>
                        )}
                        {selectedMember.acf.linkedin !== "" && (
                          <a href={selectedMember.acf.linkedin} target="_blank" rel="noopener noreferrer" aria-label="LinkedIn (opens in a new tab)">
                            <img src={IconLinkedin} alt="LinkedIn" />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </IndicatorContainer>
        </ReactResizeDetector>
      </div>
    );
  }
}

export default Indicator;
