import styled from "styled-components";

import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";

const { baseSpacing: bs } = defaultShevy;

const EventsContainer = styled.div`
  position: relative;
  padding-top: ${defaultShevy.baseSpacing(2)};

  @media ${device.tablet} {
    ${"" /* position: absolute;
    width: 100%;
    bottom: -86px; */}
  }

  .events-type {
    padding-left: ${defaultShevy.baseSpacing(1.5)};
    margin-bottom: ${defaultShevy.baseSpacing(1.7)};
    display: flex;
    flex-wrap: wrap;

    @media ${device.tablet} {
      padding-left: 0;
      margin-bottom: 0;
    }

    span,
    a {
      color: ${(props) => props.theme.primary.spaceCadet};
      font-size: 16px;
      line-height: 20px;
      text-decoration: none;
      font-weight: bold;
      cursor: pointer;
      margin-bottom: 1em;

      &.active,
      &:hover {
        border-bottom: 3px solid ${(props) => props.theme.accent.pear};
      }

      &:not(last-child) {
        margin-right: 30px;

        @media ${device.tablet} {
          margin-last: 42px;
        }
      }
    }
  }
  .events-slider {
    display: none;

    @media ${device.tablet} {
      display: block;
    }
  }

  .events-slider,
  .events {
    margin-top: 43px;

    .slick-slider {
      box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.08);
    }

    .slick-list {
      width: 150%;
    }

    .slick-arrow {
      &.slick-prev {
        left: auto;
        right: 50px;
        top: -58.5px;
      }

      &.slick-next {
        right: 0;
        top: -58.5px;
      }
    }

    .slick-slide {
      margin-right: 20px;
      outline: none;
      cursor: default;
    }

    .slide-content {
      background: white;
      width: 522px;
      display: flex;
      flex-direction: column-reverse;
      box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      overflow: hidden;

      @media ${device.tablet} {
        flex-direction: row;
        // height: ${bs(9)};
        min-height: ${bs(9)};
        margin-bottom: 0;
      }

      .event-summary {
        flex: 1;
        padding: 25px 30px;

        h3 {
          font-size: 1em;
          line-height: 1.36;
          margin: 0 0 ${bs(0.5)};
          font-weight: 900;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          -webkit-box-orient: vertical;
        }

        p.xs {
          font-size: 0.65em;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        a.media-link {
          font-weight: bold;
          text-decoration: underline;
          font-size: 16px;
        }
      }

      .event-photo {
        // height: ${bs(9)};
        min-height: ${bs(9)};
        background-size: cover;
        background-position: center;

        @media ${device.tablet} {
          width: ${bs(9)};
        }
      }
    }
  }

  .events {
    margin-bottom: ${defaultShevy.baseSpacing(2.5)};

    .slide-content {
      padding: 0;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: ${defaultShevy.baseSpacing(0.5)};
      background: white;
      width: calc(100% - 3rem) !important;
      margin-left: auto;
      margin-right: auto;

      h3 {
        font-size: 1em;
      }
    }

    @media ${device.tablet} {
      display: none;
    }
  }
`;

export default EventsContainer;
