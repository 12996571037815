import styled from "styled-components";

import { device } from "styles/breakpoints";
import banner from "assets/images/heroBg.png";
import mobileBanner from "assets/images/bg-banner-mobile.png";
import { defaultShevy } from "styles/global.style";

import button from "../elements/button";

const { baseSpacing: bs } = defaultShevy;

const BannerContainer = styled.div`
  height: 26.68em;
  display: flex;
  position: relative;
  background-image: url(${mobileBanner});
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 -${defaultShevy.baseSpacing()};

  @media ${device.tablet} {
    margin: 0;
    height: 28.54em;
    background-image: url(${banner});
  }

  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 31px;

    .container {
      text-align: left;
      color: white;

      p {
        line-height: 1.4em;
        font-size: 1.09em;
        margin-bottom: ${defaultShevy.baseSpacing(1.5)};
      }

      h1 {
        font-size: 55px;
        line-height: 1.2;
        color: white;
        max-width: 200px;

        span {
          font-size: 1.85em;
          line-height: 1.2;

          i {
            font-style: normal;
          }
        }
      }

      @media ${device.tablet} {
        h1 {
          font-size: ${defaultShevy.h1.fontSize};
          line-height: 1.02;
          max-width: 100%;
          margin-bottom: ${defaultShevy.baseSpacing(1)};

          span {
            font-size: inherit;

            i {
              font-style: italic;
            }
          }
        }

        p {
          margin-bottom: ${defaultShevy.baseSpacing(1.5)};
          line-height: 1.4em;
          font-size: 1.09em;
        }

        ${button} {
          width: ${bs(9.55)};
          height: ${bs(2.9)};
        }
      }

      @media ${device.laptopL} {
        h1 {
          font-size: ${defaultShevy.h1.fontSize};
        }
      }
    }
  }
`;

export default BannerContainer;
