import styled from "styled-components";

// TODO: check font sizing....doesn't seem like we should need to define exactly here...

const IntroductionContainer = styled.section`
  .content {
    .container {
      text-align: center;
    }

    .get-involved--introduction-about {
      font-size: 30px;
      line-height: 48px;
    }
  }
`;

export default IntroductionContainer;
