import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Arrow = styled.div`
  &.slick-prev,
  &.slick-next {
    width: 41px;
    height: 41px;
    border: 1px solid #cecece;
    border-radius: 50%;
    color: rgba(72, 73, 76, 0.5);
    font-size: 15px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    &:before {
      content: none;
    }

    &:focus,
    &:hover {
      color: rgba(72, 73, 76, 1);
    }
  }
`;

const NextArrow = (props) => {
  const { className } = props;
  return (
    <Arrow className={className} {...props} aria-label="Next" title="Next" role="button">
      <FontAwesomeIcon icon={faChevronRight} />
    </Arrow>
  );
};

const PrevArrow = (props) => {
  const { className } = props;
  return (
    <Arrow className={className} {...props} aria-label="Previous" title="Previous" role="button">
      <FontAwesomeIcon icon={faChevronLeft} />
    </Arrow>
  );
};

export { NextArrow, PrevArrow };
