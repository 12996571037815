import styled, { css } from "styled-components";
import { Button } from "reakit/Button";
import { darken } from "polished";

import { device } from "styles/breakpoints";

const UhouButton = styled(Button)`
  padding: 0 1.09em;
  color: ${props =>
    props.textcolor ? props.textcolor : props.theme.primary.spaceCadet};
  font-size: 0.81em;
  height: 2.66em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
  background: ${props =>
    props.bgcolor ? props.bgcolor : props.theme.accent.pear};
  font-family: "Roboto", sans-serif;

  &:hover {
    background: ${props =>
      props.bgcolor
        ? darken(0.1, props.bgcolor)
        : darken(0.1, props.theme.accent.pear)};
  }

  ${props =>
    props.default &&
    css`
      background: transparent;
      border: 1px solid #cecece;
    `};

  ${props =>
    props.secondary &&
    css`
      background: rgba(0, 0, 0, 0.2);
      color: #fff;
    `};

  @media ${device.tablet} {
    height: 3.22em;
  }
`;

export default UhouButton;
