import React, { createContext, useReducer } from "react";

const NavContext = createContext();

const initialState = {
  navTo: null,
  activeSlide: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return initialState;
    case "reset-nav":
      return { ...state, navTo: initialState.navTo };
    case "set-nav":
      return { ...state, navTo: action.payload };
    case "set-active-slide":
      return { ...state, activeSlide: action.payload };
    default:
      return state;
  }
};

function NavProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <NavContext.Provider value={value}>{props.children}</NavContext.Provider>
  );
}

export { NavProvider };
export const NavConsumer = NavContext.Consumer;
export default NavContext;
