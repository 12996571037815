import styled from "styled-components";
import { rgba, darken } from "polished";

import theme from "styles/theme";
import { device } from "styles/breakpoints";

import { CardShadow } from "components/card/index.style";
import { Button } from "components/elements";

import BackgroundImg from "assets/images/contact/bg-contact-form-p.png";

import ContactSlide1 from "assets/images/contact/contact-slide-1.jpg";
import ContactSlide2 from "assets/images/contact/contact-slide-2.jpg";
import ContactSlide3 from "assets/images/contact/contact-slide-3.jpg";
import ContactSlide4 from "assets/images/contact/contact-slide-4.jpg";
import ContactSlide5 from "assets/images/contact/contact-slide-5.jpg";
import ContactSlide6 from "assets/images/contact/contact-slide-6.jpg";

const ContactContainer = styled.div`

  background: ${rgba(theme.secondary.ghcfBlue, 0.1)};

  &.contact--wrapper {
    .header,
    .contact {
      padding: 4em 0;
    }

    .contact {
      position: relative;
      padding-top: 400px;

      @media ${device.tablet} {
        padding-top: 4em;
      }

      &::before,
      &::after {
        height: calc(100% - 8em);
        width: 100%;
        content: " ";
        transition: 200ms ease-in-out background-image;
        background-repeat: no-repeat;
        background-size:  cover;
        background-position: center;
        background-color: ${theme.primary.spaceCadet};
        position: absolute;
        top: 0;
        z-index: -1;
      }

      &::before {

        @media ${device.tablet} {
          width: calc(62.5% - 15px);
        }

        left: 0;
        background-image: url(${BackgroundImg});
      }

      &::after {
        right: 12.5%;
        width: 75%;
        top: -4em;
        height: 450px;
        background-size: cover;
        background-position: center;

        @media ${device.tablet} {
          width: calc(37.5% - 15px);
          height: calc(100% - 8em);
          right: 0;
          top: 0;
          height: calc(100% - 20em);
        }

        @media ${device.laptop} {
          height: calc(100% - 8em);
        }

      }
      &.contact-slide-1::after {
        background-image: url(${ContactSlide1});
      }
      &.contact-slide-2::after {
        background-image: url(${ContactSlide2});
      }
      &.contact-slide-3::after {
        background-image: url(${ContactSlide3});
      }
      &.contact-slide-4::after {
        background-image: url(${ContactSlide4});
      }
      &.contact-slide-5::after {
        background-image: url(${ContactSlide5});
      }
      &.contact-slide-6::after {
        background-image: url(${ContactSlide6});
      }
    }
  }

  .fsBody .fsForm {
    float: none;
  }

  .fsRowBody input[type="text"].fsRequired,
  .fsRowBody input[type="email"].fsRequired,
  .fsRowBody input[type="number"].fsRequired,
  .fsRowBody input[type="tel"].fsRequired,
  .fsForm select.fsRequired,
  .fsForm textarea.fsRequired,
  .fsRowBody input[type="text"],
  .fsRowBody input[type="email"],
  .fsRowBody input[type="number"],
  .fsRowBody input[type="tel"],
  .fsForm select,
  .fsForm textarea,
  .fsSignature,
  .fsForm input:focus,
  .fsForm select:focus,
  .fsForm textarea:focus,
  .fsForm .ui-slider-handle:focus {
    border: 1px solid ${rgba(theme.primary.spaceCadet, 0.2)};
  }

  .fsBody .fsLabel,
  .fsBody label,
  .fsRowBody input[type="text"],
  .fsRowBody input[type="email"],
  .fsRowBody input[type="number"],
  .fsRowBody input[type="tel"],
  .fsForm select,
  .fsForm textarea {
    color: #112E51;
    font-weight: 600;
  }

  #contact_form {
    background: white;
    min-height: 600px;
    ${CardShadow.componentStyle.rules}

    &.is-loading {
      &::after {
        content: "...";
      }
    }
  }

  .fsPreviousButton,
  .fsSubmitButton,
  .fsNextButton {
    ${Button.componentStyle.rules}
    background-color: ${theme.accent.pear} !important;
    color: ${theme.primary.spaceCadet} !important;
    border-radius: 3px !important;
    cursor: pointer !important;

    &:hover {
      background: ${(props) => (props.bgcolor ? `${darken(0.1, props.bgcolor)} !important` : `${darken(0.1, props.theme.accent.pear)} !important`)};
    }
  }

  .fsFieldFocused:not(.fsValidationError).fsSpan100 {
    margin-bottom: 10px !important;
  }

  .fsSubmitButton {
    margin: 0;
  }

  @media (max-width: 40em) {
    .fsBody {
      padding: 20px !important;
    }
  }
`;

export default ContactContainer;
