import { Charts, Chart, Sheets } from "components/data-viz"

async function fetchAllData(gKey) {
  const references = document.querySelectorAll("[data-chart]")
  const getWorksheets = Array.from(
    new Set(Array.from(references).map((ref) => ref))
  ).map((worksheetsID) =>
    Sheets.getWorksheet(
      gKey,
      encodeURIComponent(worksheetsID.dataset.chart),
      "AIzaSyCASkBVNFPUpUzWMIphbO5TythFUHvEsqU"
    ).then((worksheet) => {
      worksheet.title = worksheetsID.dataset.chart
      worksheet.dom = worksheetsID
      worksheet.id = worksheetsID.dataset.chart
        .replace(/\W/gi, "")
        .toLowerCase()
      return worksheet
    })
  )

  const worksheetArrays = await Promise.all(getWorksheets)
  return worksheetArrays
}

export default async function fetchCharts(gKey) {
  return await fetchAllData(gKey)
    .then((collection) => {
      collection.forEach(function(c, i) {
        Charts[i] = new Chart(c)
      })
    })
    .catch((error) => {
      console.log(error)
    })
}
