import React, { Component } from "react";

import { Link } from "components/elements";
import { WPContent } from "components";

import CityBackground from "assets/images/br-the-initiative-2.jpg";
import IconDiagram from "assets/images/icon-our-approach-2.svg";
import IconCommittee from "assets/images/icon-advisory-committee-2.svg";
import IconHand from "assets/images/icon-who-we-are-2.svg";
import IconHandshake from "assets/images/icon-supporters-2.svg";
import IconArrowDown from "assets/images/icon-down-arrow.svg";

import BannerContainer from "./index.style";

class Banner extends Component {
  componentDidMount() {}

  render() {
    const { content } = this.props;

    return (
      <BannerContainer>
        <div className="container">
          <WPContent className="text">{content}</WPContent>
          <div className="section-links">
            <div
              className="link"
              onClick={() => this.props.scrollTo("ourApproach")}
            >
              <img src={IconDiagram} className="icon" alt="diagram" />
              <h6>Our approach</h6>
              <Link className="sm">
                Explore <img src={IconArrowDown} alt="arrow-down" />
              </Link>
            </div>
            <div
              className="link"
              onClick={() => this.props.scrollTo("aboutUs")}
            >
              <img src={IconHand} className="icon" alt="hand" />
              <h6>About Us</h6>
              <Link className="sm">
                Explore <img src={IconArrowDown} alt="arrow-down" />
              </Link>
            </div>
            <div
              className="link"
              onClick={() => this.props.scrollTo("advisory")}
            >
              <img src={IconCommittee} className="icon" alt="committee" />
              <h6>Advisory committee</h6>
              <Link className="sm">
                Explore <img src={IconArrowDown} alt="arrow-down" />
              </Link>
            </div>
            <div
              className="link"
              onClick={() => this.props.scrollTo("supporters")}
            >
              <img src={IconHandshake} className="icon" alt="shake" />
              <h6>Supporters & partners</h6>
              <Link className="sm">
                Explore <img src={IconArrowDown} alt="arrow-down" />
              </Link>
            </div>
          </div>
        </div>
        <img src={CityBackground} alt="city-background" />
      </BannerContainer>
    );
  }
}

export default Banner;
