export default {
  all: {
    1980: {
      nhWhite: 0.639320239,
      nhBlack: 0.185673016,
      hispanic: 0.149883846,
      nhAapi: 0.018242109,
      other: 0.00688078895
    },
    1990: {
      nhWhite: 0.5607236,
      nhBlack: 0.1802025,
      hispanic: 0.2176389,
      nhAapi: 0.0376487,
      other: 0.0037863
    },
    2000: {
      nhWhite: 0.4532708,
      nhBlack: 0.17273,
      hispanic: 0.3042315,
      nhAapi: 0.053672,
      other: 0.0160956
    },
    2010: {
      nhWhite: 0.3673727,
      nhBlack: 0.1745491,
      hispanic: 0.371126,
      nhAapi: 0.0702506,
      other: 0.0167016
    },
    2017: {
      nhWhite: 0.3346417,
      nhBlack: 0.1743944,
      hispanic: 0.3879493,
      nhAapi: 0.0843486,
      other: 0.018666
    }
  },
  fortBend: {
    1980: {
      nhWhite: 0.607958975,
      nhBlack: 0.153860263,
      hispanic: 0.203720404,
      nhAapi: 0.0278571756,
      other: 0.00660318237
    },
    1990: {
      nhWhite: 0.5378603,
      nhBlack: 0.2026342,
      hispanic: 0.1947112,
      nhAapi: 0.0620084,
      other: 0.0027859
    },
    2000: {
      nhWhite: 0.462088,
      nhBlack: 0.1963002,
      hispanic: 0.2112303,
      nhAapi: 0.1118402,
      other: 0.0185413
    },
    2010: {
      nhWhite: 0.3616143,
      nhBlack: 0.2105778,
      hispanic: 0.2373982,
      nhAapi: 0.169013,
      other: 0.0213965
    },
    2017: {
      nhWhite: 0.3305541,
      nhBlack: 0.2032771,
      hispanic: 0.2449165,
      nhAapi: 0.2053782,
      other: 0.0158742
    }
  },
  harris: {
    1980: {
      nhWhite: 0.626437251,
      nhBlack: 0.19476275,
      hispanic: 0.153172775,
      nhAapi: 0.0185503748,
      other: 0.00707684888
    },
    1990: {
      nhWhite: 0.5422303,
      nhBlack: 0.1873409,
      hispanic: 0.2288465,
      nhAapi: 0.0377287,
      other: 0.0038535
    },
    2000: {
      nhWhite: 0.4211825,
      nhBlack: 0.182232,
      hispanic: 0.3292826,
      nhAapi: 0.0512907,
      other: 0.0160123
    },
    2010: {
      nhWhite: 0.3297885,
      nhBlack: 0.1843044,
      hispanic: 0.4084439,
      nhAapi: 0.0616043,
      other: 0.0158589
    },
    2017: {
      nhWhite: 0.2955512,
      nhBlack: 0.1847846,
      hispanic: 0.4295052,
      nhAapi: 0.0708271,
      other: 0.0193319
    }
  },
  montgomery: {
    1980: {
      nhWhite: 0.912854997,
      nhBlack: 0.0476079292,
      hispanic: 0.033380809,
      nhAapi: 0.00266953077,
      other: 0.00348673407
    },
    1990: {
      nhWhite: 0.8750556,
      nhBlack: 0.042036,
      hispanic: 0.0726505,
      nhAapi: 0.0062733,
      other: 0.0039846
    },
    2000: {
      nhWhite: 0.8140778,
      nhBlack: 0.0342992,
      hispanic: 0.1264603,
      nhAapi: 0.0110529,
      other: 0.0141098
    },
    2010: {
      nhWhite: 0.712263,
      nhBlack: 0.040674,
      hispanic: 0.2077868,
      nhAapi: 0.021038,
      other: 0.0182382
    },
    2017: {
      nhWhite: 0.6586961,
      nhBlack: 0.0510252,
      hispanic: 0.240886,
      nhAapi: 0.0324136,
      other: 0.0169792
    }
  }
};
