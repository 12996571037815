import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import IntroductionContainer from "./index.style";

const Introduction = ({ innerRef }) => {
  return (
    <IntroductionContainer className="get-involved--introduction section" ref={innerRef}>
      <div className="content">
        <div className="container">
          <h1>Get involved</h1>
        </div>
        <Row className="container">
          <Col xs={12} sm={10} smOffset={1} lg={8} lgOffset={2}>
            <p className="get-involved--introduction-about">
              Building a more vibrant Houston region is only possible if
              we leverage our greatest asset — <strong>our people</strong>.
            </p>
          </Col>
        </Row>
      </div>
    </IntroductionContainer>
  );
};

export default Introduction;
