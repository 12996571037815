import styled from "styled-components";

import { defaultShevy } from "styles/global.style";
// import theme from "styles/theme";
import { device } from "styles/breakpoints";

import { Col } from "react-styled-flexboxgrid";

import { CardShadow } from "components/card/index.style";

const { baseSpacing: bs } = defaultShevy;

const SubtopicWrapper = styled(Col)`
  .topic-subtopic-card--feature {
    width: 100%;
    height: 260px;
    background-position: center;
    background-size: cover;
  }

  &:nth-child(n) {
    .topic-subtopic-card {
      @media ${device.laptop} {
        height: 600px;
      }
    }
  }

  &:nth-child(2n) {
    @media ${device.laptop} {
      margin-top: 2em;
      margin-bottom: -2em;
    }
  }
`;

const SubtopicContainer = styled(CardShadow)`
  margin-bottom: 1rem;
  margin-top: 1rem;
  transition: 200ms ease-in-out background-color;
  cursor: pointer;

  .topic-subtopic-card {
    &--info {
      padding: 1.5em 2em;
      height: 100%;
      transition: 200ms ease-in-out transform,
        200ms ease-in-out background-color, 200ms ease-in-out max-height;
      position: relative;
      overflow-y: hidden;
      display: flex;
      flex-direction: column;

      .topic-subtopic-card--content {
        > * {
          display: none;
        }
      }
      .topic-subtopic-preview {
        display: none;
      }
      .topic-subtopic-card--nav {
        display: none;
        flex-direction: column;
        justify-content: space-between;

        list-style: none;
        padding: 0;

        margin-bottom: 0;
        flex-grow: 2;
      }

      .toggle-toc {
        svg {
          margin-right: 0.4em;
        }

        g {
          fill: #ffffff;
        }

        a {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          padding: 1em 0 0 1em;
          font-weight: 600;
          font-size: 0.75em;
          border-bottom: 0;

          &:hover {
            g {
              fill: ${props => props.theme.accent.pear};
            }
          }
        }
      }
    }
  }

  &.topic-subtopic-card {
    &--quick-stat {
      background-color: white;
      .topic-subtopic-card--info {
        background-color: white;

        @media ${device.tablet} {
          max-height: 340px;
        }

        .topic-subtopic-stat {
          display: block;
          border-left: 6px solid ${props => props.theme.accent.seaGreen};
          padding-left: 1rem;

          p {
            margin-bottom: ${bs(0.5)};
          }
          p:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &--intro,
    &--toc {
      background-color: ${props => props.theme.primary.spaceCadet};
      .topic-subtopic-card--info {
        transform: translateY(-260px);
        max-height: 100%;
        background-color: ${props => props.theme.primary.spaceCadet};
        color: white;

        .topic-subtopic-card--nav {
          display: flex;
        }
      }
    }

    &--intro {
      .topic-subtopic-card--info {
        .topic-subtopic-introduction {
          display: block;
        }
      }
    }

    &--toc {
      .topic-subtopic-card--info {
        .topic-subtopic-toc {
          display: block;
        }
        .topic-subtopic-card--content {
          border-left: 0.5px dashed #fff;
          padding-left: 1em;

          > *[data-title] {
            &::after {
              visibility: visible;
              position: absolute;
              background-color: ${props => props.theme.primary.spaceCadet};
              content: "";
              width: 7px;
              height: 7px;
              border-radius: 50%;
              border: 1px solid #159dbf;
              top: calc(50% - 3px);
              left: calc(-1em - 3.5px);
            }
          }
        }
        .topic-subtopic-card--content,
        .topic-subtopic-card--links {
          > *[data-title] {
            visibility: hidden;
            display: block;
            font-size: 1em;
            font-weight: 400;
            line-height: 1.5em;
            height: 1.5em;
            position: relative;
            margin: ${bs(0.5)} 0;

            &:hover {
              &::before {
                color: ${props => props.theme.accent.pear};
              }
            }

            &::before {
              content: attr(data-title);
              visibility: visible;
            }
          }
        }
      }
    }
  }

  h4 {
    font-size: 1.27em;
    margin-bottom: 8.5px;
  }
`;

export { SubtopicWrapper };
export default SubtopicContainer;
