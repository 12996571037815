import React from "react";
import _first from "lodash/first";
import _kebabCase from "lodash/kebabCase";
import _merge from "lodash/merge";
import { useTabState, Tab, TabList, TabPanel } from "reakit";
import SwipeableViews from "react-swipeable-views";
import withSizes from "react-sizes";
import { Element } from "react-scroll";

import { sizes } from "styles/breakpoints";

import Card from "components/card";

import TabPanelContainer from "./index.style";

function mapItemsWithIdentifiers(items) {
  return items.map((item, itemIndex) =>
    _merge(
      {
        index: itemIndex,
        name: _kebabCase(item.label)
      },
      item
    )
  );
}

function findItemByName(items, navToName) {
  return items.find(item => item.name === navToName) || {};
}

const UHouTabPanel = props => {
  const { items } = props;

  const itemsWithIds = mapItemsWithIdentifiers(items);
  const defaultSelect = _first(itemsWithIds);

  const tab = useTabState({ selectedId: defaultSelect.id });
  const navToItem = findItemByName(itemsWithIds, props.navTo);

  if (navToItem.id !== undefined && navToItem.id !== tab.selectedId) {
    tab.select(navToItem.id);
    props.handleTarget();
  }

  const getCurrentItem = function () {
    const found = itemsWithIds.find(item => item.id === tab.currentId);
    return found.name || 'start-voting';
  }

  const onChangeIndex = nextIndex => {
    tab.select('tab-' + nextIndex);
  };

  return (
    <TabPanelContainer
      className={`tab-panel tab-panel--active-${getCurrentItem()}`}
    >
      <TabList {...tab}>
        {itemsWithIds.map(item => (
          <Tab {...tab} id={item.id} key={`tab-${item.name}`}>
            {item.label}
          </Tab>
        ))}
      </TabList>
      <SwipeableViews
        index={Number(tab.selectedId.replace('tab-', ''))}
        onChangeIndex={onChangeIndex}
        enableMouseEvents
        slideStyle={{ overflow: "visible" }}
      >
        {itemsWithIds.map(item => (
          <TabPanel
            {...tab}
            id={`panel-${item.id}`}
            key={`panel-${item.name}`}
            className={`panel--${item.name}`}
          >
            {item.body}
          </TabPanel>
        ))}
      </SwipeableViews>
    </TabPanelContainer>
  );
};

const Cards = props => {
  const { items } = props;

  const itemsWithIds = mapItemsWithIdentifiers(items);

  return (
    <div>
      {itemsWithIds.map(item => (
        <Element name={item.name}>
          <Card item={item} />
        </Element>
      ))}
    </div>
  );
};

const _ResponsivePanels = props => {
  if (props.isTablet) {
    return <UHouTabPanel {...props} />;
  }

  return <Cards {...props} />;
};
const ResponsivePanels = withSizes(({ width }) => ({
  isTablet: width > sizes.tablet
}))(_ResponsivePanels);

export { mapItemsWithIdentifiers, UHouTabPanel, Cards, Card };
export default ResponsivePanels;
