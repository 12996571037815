import styled from "styled-components";
import { Grid, Row } from "react-styled-flexboxgrid";

import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";

const { baseSpacing: bs } = defaultShevy;


const InitiativeContainer = styled.div`
  section {
    &.description {
      padding: ${bs(1.85)} ${bs()} 0;
      background-color: #fff;

      @media ${device.laptop} {
        padding: ${bs(8.3)} 0 ${bs(5.5)};
      }

      p {
        font-size: 1em;
        line-height: 1.45;
        margin-bottom: 0;

        @media ${device.laptop} {
          font-size: 1.36em;
          line-height: 1.6;
        }

        a {
          border-bottom: 3px solid ${props => props.theme.accent.pear};
          text-decoration: none;
          cursor: pointer;
          font-weight: bold;

          &:hover {
            background: ${props => props.theme.primary.spaceCadet};
            border-color: ${props => props.theme.primary.spaceCadet};
            color: ${props => props.theme.accent.pear};
          }
        }
      }
    }

    .container {
      .title {
        position: relative;
      }
    }

    .title {
      @media ${device.tablet} {
        margin-left: 3em;
      }

      @media ${device.laptop} {
        margin-left: 0;
      }
    }
  }

  .share-story {
    position: relative;
    height: 520px;
    padding: 0 ${bs()};

    @media ${device.laptop} {
      padding: 0;
    }

    .background {
      background-color: ${props => props.theme.primary.spaceCadet};
      height: 100%;
      position: absolute;
      left: 0;
      width: 100%;

      @media ${device.laptop} {
        width: calc(100% - 210px);
      }

      img {
        position: absolute;
        display: none;

        @media ${device.laptop} {
          width: 400px;
          right: -100px;
          bottom: 100px;
        }

        @media ${device.laptopL} {
          width: 585px;
          right: 60px;
          bottom: 60px;
        }
      }

      @media ${device.laptop} {
        img {
          display: block;
        }
      }
    }

    ${Grid} {
      width: 100%;

      ${Row} {
        height: 520px;
        align-items: center;
      }
    }

    .container {
      position: relative;
      z-index: 9;
      color: #fff;
    }
  }
`;

export default InitiativeContainer;
