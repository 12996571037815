import React, { useState } from "react";
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import { Link } from "react-router-dom";
import { SectionPointer, SectionTitle, Button } from "components/elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { TopicMenuProvider, WPContent } from "components";

import theme from "styles/theme";

import ExploreTopicsContainer from "./index.style";

const SubtopicsMenu = (topic, index) => {
  return (
    <Col xs={12} sm={6} md={4} lg={3} key={index} className="topic-card">
      <details
        style={{
          borderBottom: `6px solid ${topic.acf.topic_border_color}`,
        }}
      >
        <summary className="summary">
          <div className="topic-image-container">
            <Link to={`/topic/${topic.slug}`}>
              <div
                className="topic-image"
                style={{
                  backgroundImage: `url(${
                    topic.better_featured_image
                      ? topic.better_featured_image.source_url
                      : ""
                  })`,
                }}
              ></div>
            </Link>
          </div>
          <div className="summary-content">
            <h3>
              <Link to={`/topic/${topic.slug}`}>{topic.title.rendered}</Link>
            </h3>
            <div className="view-toggle">
              <p className="xs">
                <strong>View all subtopics</strong>
              </p>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </summary>
        <div className="submenu">
          <div className="submenu-items">
            {topic.children.map((child) => {
              const url = new URL(child.link);
              return (
                <div key={child.id} className="submenu-item">
                  <Link to={`/topic${url.pathname}`}>
                    {<WPContent>{child.title.rendered}</WPContent>}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="submenu-arrow"
                    />
                  </Link>
                </div>
              );
            })}
          </div>
          <Link to={`/topic/${topic.slug}`}>
            <Button>Learn More</Button>
          </Link>
        </div>
      </details>
    </Col>
  );
};

const ExploreTopicsDisplay = ({ content }) => {
  return (
    <Row className="topics">
      {content.topicsAndSubtopicsMenu.topics.map((topic, index) => {
        return SubtopicsMenu(topic, index);
      })}
    </Row>
  );
};

const ExploreTopics = () => {
  return (
    <ExploreTopicsContainer>
      <div className="wrapper">
        <div className="container">
          <Grid>
            <Row>
              <Col md={9} mdOffset={2} className="section-title">
                <div className="title">
                  <h1 color={theme.primary.spaceCadet}>Explore the data</h1>
                  <h3 color={theme.primary.spaceCadet}>
                    Together, we are measuring what matters to{" "}
                    <em>do what matters</em>.
                  </h3>
                </div>
                <p className="description">
                  Understanding Houston is divided into nine key topics and 31
                  subtopics affecting quality of life in Houston’s three largest
                  counties. Within these nine topics, you’ll encounter over 200
                  visual indicators — tracked over time for clarity and context
                  — paired with insightful analysis and valuable context to
                  understand the Houston area and the issues that matter most in
                  our communities.
                </p>
                <p>
                  Explore, <Link to="/blog">learn</Link> and{" "}
                  <Link to="/events">engage</Link> with us as we work to build a
                  more vibrant Houston region with opportunity for all.
                </p>
                <br />
              </Col>
            </Row>

            <TopicMenuProvider loading={() => null} error={() => null}>
              <ExploreTopicsDisplay />
            </TopicMenuProvider>
          </Grid>
        </div>
      </div>
    </ExploreTopicsContainer>
  );
};

export default ExploreTopics;
