import React from "react";

import { Col, Row } from "react-styled-flexboxgrid";
import { Link } from "components/elements";
import { Link as RouterLink } from "react-router-dom";

import ImageStory00 from "assets/images/get-involved--donate.jpg";

const awarenessStory = {
  image: ImageStory00,
  about: "Raising awareness as a nonprofit partner",
  slug: "stories-awareness"
};

const ShareYourStory = () => {
  return (
    <Row className="container">
      <Col xs={12} sm={5} className="content">
        <div className="about">
          <h3>
            <strong>Share</strong> your data story.
          </h3>
          <p>
            Have you used Understanding Houston data to inform your work or civic efforts? If so, we want to hear from you!
          </p>
          <Link as={RouterLink} to="/contact?mode=story">
            Share your story
          </Link>
        </div>
      </Col>
      <Col xs={12} sm={7} className="visual">
        <img
          src={awarenessStory.image}
          alt={awarenessStory.about}
          key={awarenessStory.slug}
        />
      </Col>
    </Row>
  );
};

export default ShareYourStory;
