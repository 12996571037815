import styled from "styled-components";
import { defaultShevy } from "styles/global.style";
import PageContainer from "../page.style";

const { baseSpacing: bs } = defaultShevy;

const ChartsContainer = styled(PageContainer)`
  padding: ${bs()};

  .data-viz {
    margin: 0;
  }

  .slick-slider {
    margin: 0 50px 60px;

    .slick-dots {
      bottom: -30px;
    }
  }

  .slick-list {
    .data-viz {
      box-shadow: none;

      .chart {
        box-shadow: none;

        .data-viz-heading {
          background: #fff;
        }
      }
    }
  }

  .slick-arrow {
    &.slick-prev {
      display: block;
      width: 32px;
      height: 32px;
      left: -52px;
      &:before {
        font-size: 32px;
        color: #000;
      }
    }

    &.slick-next {
      display: block;
      width: 32px;
      height: 32px;
      right: -52px;
      &:before {
        font-size: 32px;
        color: #000;
      }
    }
    &:before {
      font-size: 32px;
      color: #000;
    }
  }
`;

export default ChartsContainer;
