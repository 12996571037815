import styled from "styled-components";

const Input = styled.input`
  height: 48px;
  width: 300px;
  border-radius: 3px;
  border: none;
  padding-left: 25px;
  font-size: 14px;
  color: ${props => props.theme.primary.spaceCadet};
  outline: none;
`;

export default Input;
