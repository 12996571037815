import React from "react";

import QuoteContainer from "./index.style";

const Quote = props => {
  return (
    <QuoteContainer className="quote">
      <blockquote className="quote--text">{props.children}</blockquote>
      <div className="quote--who">
        <div className="quote--who-avatar">
          <img src={props.avatar} alt={`${props.who} from ${props.where}`} />
        </div>
        <div className="quote--who-about">
          <div className="quote--who-name">{props.who}</div>
          <div className="quote--who-location">{props.where}</div>
        </div>
      </div>
    </QuoteContainer>
  );
};

export default Quote;
