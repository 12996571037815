import _filter from "lodash/filter";
import _groupBy from "lodash/groupBy";
import _keyBy from "lodash/keyBy";
import _partition from "lodash/partition";
import _sortBy from "lodash/sortBy";
import _map from "lodash/map";
import _snakeCase from "lodash/snakeCase";
import _find from "lodash/find";
import _get from "lodash/get";

import cleanContent from "helpers/content";

function getTitleFromTag(tagHTML) {
  const titleAttributeRegex = /data-title="(.*?)"/g;
  const edgesRegex = /(data-title=")|(")/g;

  const [match] = tagHTML.match(titleAttributeRegex);
  const title = match.replace(edgesRegex, "");
  const id = _snakeCase(title);

  return {
    title,
    id,
  };
}

function getSections(html) {
  const openingTagsWithTitle = /<(\w+)\s+data-title+.*?>/g;

  return _map(html.match(openingTagsWithTitle), getTitleFromTag);
}

function wrapSections(html) {
  const openingTagsWithTitle = /<(\w+)\s+data-title+.*?>/g;
  const resourcesHTMLOpeningTags = /<(\w+)\s+class="resources-slider"+.*?>/g;
  const theDataOpeningTags = /<(\w+)\s+id="the_data"+.*?>/g;
  const [nonResourcesHTML, resourcesTag, resourcesHTML] = html.split(
    resourcesHTMLOpeningTags
  );

  let isFirst = true;

  const wrapper = (openingTag) => {
    const { id } = getTitleFromTag(openingTag);
    if (id === "resources") {
      return openingTag;
    }
    if (isFirst) {
      isFirst = false;
      return `<div id="${id}">${openingTag}`;
    }
    return `</div><div id="${id}">${openingTag}`;
  };
  const handleTheData = (theDataTag) => {
    return `</div>${theDataTag}`;
  };
  const wrappedHTML = nonResourcesHTML
    .replace(openingTagsWithTitle, wrapper)
    .replace(theDataOpeningTags, handleTheData);

  const contentHTML = `<div id="overview">${wrappedHTML}</div>`;

  if (!resourcesTag || !resourcesHTML) {
    return `${contentHTML}`;
  }

  return `${contentHTML}
    <div class="resources-slider" id="resources">${resourcesHTML}
    </div>
  `;
}

function handleSections(html) {
  const titleWithIdTags = /(data-title="(.*?)id="(.*?)")|(id="(.*?)data-title="(.*?)")/g;
  const idAttributeRegex = /id="(.*?)"/g;
  const cleanIdAttributes = (match) => {
    return match.replace(idAttributeRegex, "");
  };

  const cleanedHTML = wrapSections(
    html.replace(titleWithIdTags, cleanIdAttributes)
  );

  return {
    html: cleanedHTML,
    navigateSections: getSections(cleanedHTML),
  };
}

function organizeTopicsAndChildren(cleanedPages, topicCategoryId) {
  const [parents, children] = _partition(
    _sortBy(cleanedPages, "menu_order"),
    (page) => {
      return page.categories.includes(topicCategoryId);
    }
  );

  const topicsIndexById = _keyBy(parents, "id");
  const childrenGroupedByParent = _groupBy(children, "parent");

  const parentTopics = parents.map((topic) => {
    return {
      ...topic,
      children: childrenGroupedByParent[topic.id],
    };
  });

  const childTopics = _filter(
    children.map((childTopic) => {
      return {
        data: {
          ...childTopic,
        },
        parent: topicsIndexById[childTopic.parent],
      };
    }),
    "parent"
  );

  return {
    parentTopics,
    childTopics,
  };
}

function transformPagesToTopicsAndChildren(pages, topicCategoryId) {
  const cleanedPages = pages.map((page) =>
    cleanContent(page, { linkPrefix: "/topic" })
  );
  const { parentTopics, childTopics } = organizeTopicsAndChildren(
    cleanedPages,
    topicCategoryId
  );

  const sectionedChildTopics = childTopics.map((childTopic) => {
    const { html, navigateSections } = handleSections(
      childTopic.data.content.rendered
    );

    return {
      ...childTopic,
      data: {
        ...childTopic.data,
        content: {
          ...childTopic.data.content,
          rendered: html,
        },
        navigateSections,
      },
    };
  });

  return {
    parentTopics,
    childTopics: sectionedChildTopics,
  };
}

const parseResponse = (response) => {
  return _get(response, "data");
};

function makeTopicsAndSubtopicsGetter(axios, requiredFields) {
  const baseURL = `${process.env.REACT_APP_WP_API_URL}wp/v2/`;
  return axios
    .get(
      `${baseURL}categories?per_page=100&slug=topic,sub-topic&_fields=id,slug,link`
    )
    .then(parseResponse)
    .then((categories) =>
      Promise.all([
        _find(categories, { slug: "topic" }).id,
        axios
          .get(
            `${baseURL}pages?per_page=100&categories=${_map(
              categories,
              "id"
            ).join(",")}&_fields=${requiredFields.join(",")}`
          )
          .then(parseResponse),
      ])
    )
    .then((res) => {
      const [topicCategoryId, pages] = res;
      return {
        topicCategoryId,
        pages,
      };
    });
}

export {
  transformPagesToTopicsAndChildren,
  organizeTopicsAndChildren,
  makeTopicsAndSubtopicsGetter,
};
