import React, { Component } from "react";
import Select from "react-select";
import _find from "lodash/find";
import PopRaceData from "assets/json/popRace";
import { selectStyles } from "styles/global.style";
import Bar from "./bar";
import BarChartContainer from "./index.style";

const options = [
  { value: "fortBend", label: "Fort Bend" },
  { value: "harris", label: "Harris" },
  { value: "montgomery", label: "Montgomery" },
  { value: "all", label: "3-County" },
];

const counties = [
  { name: "Fort Bend", value: "fortBend" },
  { name: "Harris", value: "harris" },
  { name: "Montgomery", value: "montgomery" },
  { name: "3-County", value: "all" },
];

class BarChart extends Component {
  state = {
    chartData: null,
    years: null,
  };

  componentDidMount() {
    this.selectPopRaceData("all");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeCounty !== this.props.activeCounty) {
      this.selectPopRaceData(
        _find(counties, ["name", nextProps.activeCounty]).value
      );
      // this.onSelectCounty(nextProps.county);
    }
  }

  onSelectCounty = (county) => {
    this.selectPopRaceData(county.value);
    this.props.onChangeCounty(county.name);
  };

  selectPopRaceData = (county) => {
    this.setState(
      {
        chartData: PopRaceData[county],
      },
      () => {
        this.setState((prevState) => ({
          years: Object.getOwnPropertyNames(prevState.chartData),
        }));
      }
    );
  };

  handleChangeFilter = (selectedOption) => {
    const county = {
      value: selectedOption.value,
      name: selectedOption.label,
    };

    this.onSelectCounty(county);
  };

  render() {
    const { chartData, years } = this.state;
    const { activeCounty } = this.props;

    return (
      <BarChartContainer className="bar-chart">
        <div className="counties">
          <ul>
            {counties.map((county, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li
                key={index}
                onClick={() => this.onSelectCounty(county)}
                title={`View ${county.name} data`}
              >
                <span className={`${activeCounty === county.name && "active"}`}>
                  {county.name}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <label
          id="filterBy"
          className="visually-hidden"
          htmlFor="filter-by-county"
        >
          Filter by County
        </label>
        <Select
          styles={selectStyles}
          options={options}
          className="selector selector-county"
          placeholder="Filter By"
          classNamePrefix="react-select"
          onChange={this.handleChangeFilter}
          inputId="filter-by-county"
          aria-labelledby="filterBy"
        />
        <div className="chart">
          {years &&
            chartData &&
            years.map((year, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Bar year={year} data={chartData[year]} key={index} />
            ))}
          <div className="percentage-marker">
            <span>0</span>
            <span>25</span>
            <span>50</span>
            <span>75</span>
            <span>100 %</span>
          </div>
        </div>
      </BarChartContainer>
    );
  }
}

export default BarChart;
