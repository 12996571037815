import React, { Component } from "react";
import SwipeableViews from "react-swipeable-views";

import MeasuringContainer from "./index.style";

class Measuring extends Component {
  state = {
    selectedTabIndex: 1
  };

  componentDidMount() {}

  onSelectTab = index => {
    this.setState({
      selectedTabIndex: index
    });
  };

  handleChangeIndex = index => {
    this.setState({
      selectedTabIndex: index + 1
    });
  };

  render() {
    const { selectedTabIndex } = this.state;
    const { tabs } = this.props;

    return (
      <MeasuringContainer ref={this.props.innerRef}>
        <div className="container">
          <h3>Our First Three Years</h3>
          <div className="tab-contents">
            <div className="tabs">
              {tabs.map((tab, i) => (
                <div
                  className={`tab ${selectedTabIndex === i + 1 && "active"}`}
                  onClick={() => this.onSelectTab(i + 1)}
                  key={i}
                  dangerouslySetInnerHTML={{
                    __html: tab.tab_nav
                  }}
                />
              ))}
            </div>
            <SwipeableViews
              index={selectedTabIndex - 1}
              enableMouseEvents
              onChangeIndex={this.handleChangeIndex}
            >
              {tabs.map((tab, i) => (
                <div className="slide" key={i}>
                  <h6>{tab.tab_content_title}</h6>
                  <div className="summaries">
                    {tab.tab_content_column.map((column, j) => (
                      <div className="summary" key={j}>
                        <img src={column.image.url} alt="office" />
                        <p className="sm">{column.text}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </SwipeableViews>
          </div>
        </div>
      </MeasuringContainer>
    );
  }
}

export default Measuring;
