import styled from "styled-components";

import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";

const { baseSpacing: bs } = defaultShevy;

const GatheringInputContainer = styled.section`
  margin-bottom: ${bs(7)};
  position: relative;

  @media ${device.tablet} {
    .title {
      margin-left: 3em;
    }
  }  

  .slick-slider {
    margin-bottom: 60px;

    .slick-dots {
      bottom: -30px;
    }
  }

  .slick-list {
    .data-viz {
      box-shadow: none;
      margin-top: 30px;
      margin-bottom: 30px;

      .chart {
        box-shadow: none;

        .data-viz-heading {
          background: #fff;
        }
      }
    }
  }

  .slick-arrow {
    &.slick-prev {
      display: block;
      width: 32px;
      height: 32px;
      left: -52px;
    }

    &.slick-next {
      display: block;
      width: 32px;
      height: 32px;
      right: -52px;
    }
    &:before {
      font-size: 32px;
    }
  }

  .content {
    position: relative;

    .img-gathering {
      margin-left: -${bs(4)};
      width: 27em;
    }

    .description {
      align-items: center;
      margin-bottom: ${bs(3)};  

      .cHRxDW {
        padding-top: ${bs(2)};
      }

      @media ${device.tablet} {
        margin-bottom: ${bs(7)}; 

        .cHRxDW {
          padding-top: ${bs(7)};
        }
      }     

      .kUzqm {
        padding-top: 1em;
      }
      .bXuxYG {
        padding-top: 1em;
      }

      @media ${device.tablet} {
        .bXuxYG {
          padding-top: 5em;
        }
      }     
    }

    h3 {
      font-size: 1.36em;
    }
    
    .cta-title {
      display: block;
      margin-top: 60px;

      h3 {
        padding-top: 30px;
      }
    }

    .convenings {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      margin: ${bs(0)} auto;
      width: 100%
      max-width: 940px;

      .convening {
        margin: 0 ${bs(0.5)};
        background: white;
        border-radius: 5px;
        padding: ${bs(1.5)};
        height: ${bs(8.2)};
        flex: 0 0 100%;
        margin: 2% 0;

        @media ${device.tablet} {
          flex: 0 0 31%;
          max-width: ${bs(15)};
        }

        h4 {
          font-size: 1em;
          margin-bottom: ${bs(0.5)};
        }

        a {
          font-size: 0.81em;
          display: none;
        }

        p {
          color: #0f2d52;
        }

        &:hover {
          box-shadow: 0 14px 30px 0 rgba(0, 0, 0, 0.14);

          a {
            display: inline-block;
          }

          p {
            display: none;
          }
        }
      }
    }
  }
  .img-red {
    position: absolute;
    top: ${bs(10.5)};
    background-color: #ef233c;
    width: 33em;
    height: 20em;
    display: none;

    @media ${device.tablet} {
      display: block;
    }
    @media ${device.laptop} {
      right: calc(100vw - 26em)
    }
  }
`;

export default GatheringInputContainer;
