import React, { Component } from "react";
import { Row, Col } from "react-styled-flexboxgrid";
import { withRouter } from "react-router-dom";
import { WPContent } from "components";
import { getPageWithIdAPI } from "services/helpers/apis";

import PrivacyContainer from "./index.style";
const PrivacyPageId = "3769";

class Privacy extends Component {
  state = {
    title: "",
    content: "",
    date: ""
  };

  async componentDidMount() {
    try {
      const getPrivacyPage = await getPageWithIdAPI(PrivacyPageId);
      this.setState({
        content: getPrivacyPage.data.content
          ? getPrivacyPage.data.content
          : null,
        title: getPrivacyPage.data.title ? getPrivacyPage.data.title : null,
        date: getPrivacyPage.data.date ? getPrivacyPage.data.date : null
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { title, date, content } = this.state;

    return (
      <PrivacyContainer className="container">
        <section>
          <Row>
            <Col xs={12} sm={12} lg={9} lgOffset={1}>
              <h1 className="title">
                <WPContent>{title.rendered}</WPContent>
              </h1>
              {date && (
                <div className="last-updated-date">
                  <p>
                    <WPContent>
                      {"Last Updated: " +
                        date.substr(0, 4) +
                        "/" +
                        date.substring(5, 7) +
                        "/" +
                        date.substring(8, 10)}
                    </WPContent>
                  </p>
                </div>
              )}
              <WPContent>{content.rendered}</WPContent>
            </Col>
          </Row>
        </section>
      </PrivacyContainer>
    );
  }
}

export default withRouter(Privacy);