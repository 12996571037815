import styled from "styled-components";

import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";

import IconArrowRight from "assets/images/icon-arrow-right.svg";

const { baseSpacing: bs } = defaultShevy;

const SimpleCrumbsContainer = styled.nav`
  padding-top: 0;
  padding-bottom: 0;
  font-size: 22px;

  ul {
    list-style: none;
    margin: 0;
    padding: ${bs(0.5)};
    display: flex;
    flex-wrap: wrap;
  }

  a,
  span {
    font-size: 0.72em;
    display: inline-block;
    padding: ${bs(0.25)} 0;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    margin-bottom: -4px;
    color: #fff;
  }

  span {
    cursor: default;
  }

  a {
    &:hover {
      border-bottom: 2px solid ${props => props.theme.secondary.spaceCadet};
    }

    &.active {
      opacity: 0.5;
      cursor: default;

      &:hover {
        border-bottom: 2px solid transparent;
      }
    }
  }

  li {
    padding: ${bs(0.25)};
    padding-bottom: 0;
    padding-top: 0;
    display: flex;
    align-items: center;

    @media ${device.tablet} {
      padding: ${bs(0.25)};
    }

    &:not(:last-child) {
      &::after {
        content: url(${IconArrowRight});
        margin-left: ${bs(0.5)};
        filter: brightness(0) invert(1);
      }
    }
    &:first-child {
      text-transform: uppercase;
      a,
      span {
        font-weight: 800;
      }
    }
  }
`;

export default SimpleCrumbsContainer;
