import React from "react";
import { Route, withRouter } from "react-router-dom";
import TopicsAndSubtopicsProvider from "./provider";
import TopicContent from "./content";
import SubTopic from "../subTopic";

const defaultTopic = {
  slug: "",
  id: "",
  title: {
    rendered: "",
  },
  content: {
    rendered: "",
  },
  acf: {},
  children: [],
};

const TopicsAndSubtopicsDisplay = (props) => {
  const { topics, subtopics } = props.content.topicsAndSubtopics;
  const routeURL = props.match.url;
  const renderedName = (name) => {
    const tmpName = document.createElement("textarea");
    tmpName.innerHTML = name;
    return tmpName.value;
  };

  if (!topics.length || !subtopics.length) {
    return <TopicContent data={defaultTopic} />;
  }

  return (
    <div>
      {topics.length > 0 &&
        topics.map((topic) => (
          <Route
            exact
            path={`${routeURL}/${topic.slug}`}
            render={() => <TopicContent data={topic} />}
            key={`topic-${topic.slug}`}
          />
        ))}
      {subtopics.length > 0 &&
        subtopics.map((topic) => (
          <Route
            path={`${routeURL}${topic.parent ? `/${topic.parent.slug}` : ""}/${
              topic.data.slug
            }`}
            render={() => (
              <SubTopic
                data={topic}
                history={props.history}
                name={renderedName(topic.data.title.rendered)}
              />
            )}
            key={`subtopic-${topic.data.slug}`}
          />
        ))}
    </div>
  );
};

const TopicsAndSubtopics = (props) => {
  return (
    <TopicsAndSubtopicsProvider
      loading={<TopicsAndSubtopicsDisplay {...props} />}
    >
      <TopicsAndSubtopicsDisplay {...props} />
    </TopicsAndSubtopicsProvider>
  );
};

export {
  TopicsAndSubtopicsDisplay,
  TopicsAndSubtopicsProvider,
  TopicsAndSubtopics,
};
export default withRouter(TopicsAndSubtopics);
