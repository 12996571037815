import styled from 'styled-components';
import { rgba } from 'polished';

import { defaultShevy } from 'styles/global.style';
import { device } from 'styles/breakpoints';
import theme from 'styles/theme';

const { baseSpacing: bs } = defaultShevy;

const SupportersContainer = styled.section`
  background-color: #e8f0f3;
  padding: 0 ${bs()} 3em;

  @media ${device.laptop} {
    padding: 0 0 5em;
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media ${device.laptop} {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .toggler {
      border: 2px solid #112e51;
      border-radius: 42.5px;
      display: flex;
      background-color: #112e51;
      margin: 1em 0;
      font-size: 0.8em;
      width: 100%;

      @media ${device.tablet} {
        margin: 0;
        font-size: 1em;
        width: auto;
      }

      span {
        font-size: 0.8em;
        height: 60px;
        line-height: 60px;
        white-space: nowrap;
        padding: 0 10px;
        background-color: white;
        cursor: pointer;
        width: 50%;
        text-align: center;

        @media ${device.tablet} {
          font-size: 1em;
          width: auto;
          padding: 0 20px;
        }

        @media ${device.laptopL} {
          padding: 0 40px;
        }

        &:first-child {
          border-top-left-radius: 42px;
          border-bottom-left-radius: 42px;
        }

        &:last-child {
          border-top-right-radius: 42px;
          border-bottom-right-radius: 42px;
        }

        &.active {
          color: white;
          background-color: ${(props) => props.theme.primary.spaceCadet};
        }

        &:hover {
          color: white;
          background-color: ${rgba(theme.primary.spaceCadet, 0.5)};
        }
      }
    }
  }

  .tab-content {
    padding-top: 79px;
    
    .section {
      margin-bottom: 69px;

      h6 {
        margin-bottom: 18px;
      }

      .logos-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -10px;

        .logo {
          margin-bottom: 26px;
          padding: 0 10px;
          flex: 0 100%;
          height: 200px;

          @media ${device.mobileL} {
            flex: 0 50%;
            height: 200px;
          }

          @media ${device.tablet} {
            flex: 0 33%
            height: 200px;
          }

          @media ${device.laptop} {
            flex: 0 25%;
            height: 254px;
          }

          .card {
            background: white;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            text-align: center;
            display: flex;
            background-color: white;
            border-radius: 6px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.03);

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }         
        }
      }

      &.section-2 {
        .logos-content {
          .logo {
            @media ${device.mobileL} {
              flex: 0 50%;
              height: 120px;
            }
  
            @media ${device.tablet} {
              flex: 0 25%;
              height: 120px;
            }
  
            @media ${device.laptop} {
              flex: 0 20%;
              height: 150px;
            }
          }
        }
      }

      .names-content {
        background-color: white;
        border-radius: 6px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.03);
        padding: 35px 40px 20px;
        display: flex;
        flex-wrap: wrap;

        p {
          line-height: 40px;
          margin-bottom: 10px;
          font-size: 22px;

          @media ${device.mobileL} {
            width: 100%;
          }

          @media ${device.tablet} {
            width: 33%;
            padding-left: 20px;
          }
        }
      }

      &.section-4 {
        .names-content {
          padding: 45px 40px 34px;
          flex-direction: column;

          @media ${device.tablet} {
            max-height: 950px;
          }

          @media ${device.laptop} {
            max-height: 720px;
          }

          @media ${device.laptopL} {
            max-height: 570px;
          }

          p {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 11px;

            @media ${device.tablet} {
              width: 33%;
              padding-left: 20px;
            }

            @media ${device.laptop} {
              width: 25%;
              padding-left: 20px;
            }

            @media ${device.laptopL} {
              width: 20%;
              padding-left: 20px;
            }
          }
        }
      }
    }
  }

  .text-bottom {
    font-size: 1.81em;
    line-height: 1;
    text-align: center;
    margin: 0;
  }
`;

export default SupportersContainer;
