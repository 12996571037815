import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { Element } from "react-scroll";

import { Link } from "components/elements";

import ImagePartnership from "assets/images/get-involved--donate-landscape.jpg";
import ContactContainer from "./index.style";

const Contact = ({ innerRef }) => {
  return (
    <ContactContainer
      className="get-involved--partnership section"
      ref={innerRef}
    >
      <Element name="donate-to-understanding-houston">
        <div className="get-involved--partnership-content">
          <Row className="container" id="donate">
            <Col
              className="get-involved--partnership-about-wrapper wrapper"
              xs={12}
              sm={5}
            >
              <div className="get-involved--partnership-about">
                <h3>
                  Donate to <strong>Understanding Houston</strong>
                </h3>
                <p>
                  Help us drive action and keep this community resource
                  evolving.
                </p>
                <Link
                  href="https://ghcf.kimbia.com/understandinghoustondonation"
                  target="_blank"
                  aria-label="Donate Today (opens in a new tab)"
                >
                  Donate Today
                </Link>
              </div>
            </Col>
            <Col xs={12} sm={6} smOffset={1} className="image-wrapper">
              <img
                src={ImagePartnership}
                alt="Amplify your impact through partnership"
              />
            </Col>
          </Row>
        </div>
      </Element>
    </ContactContainer>
  );
};

export default Contact;
