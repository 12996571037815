import React, { useContext, useEffect } from "react";

import some from "lodash/some";
import values from "lodash/values";
import first from "lodash/first";
import pick from "lodash/pick";
import map from "lodash/map";
import groupBy from "lodash/groupBy";
import forEach from "lodash/forEach";
import merge from "lodash/merge";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";

import axios, { contentMoxios as moxios } from "./axios";
import ContentContext from "./context";
import ContentWrapperContainer from "./index.style";

const baseURLS = {
  content: `${process.env.REACT_APP_WP_API_URL}wp/v2/`,
  data: process.env.REACT_APP_DATA_URL || "/"
};

function makeGetter(useMocks, getter) {
  const client = (useMocks && moxios) || axios;

  if (getter.makeGetter) {
    return getter.makeGetter(client);
  }

  const path = `${baseURLS[getter.type] || baseURLS.content}${getter.path}`;
  return client.get(path);
}

function makePromiseToGet(useMocks, getter) {
  return makeGetter(useMocks, getter)
    .then(function (response) {
      if (getter.transformData) {
        return getter.transformData(response.data);
      }
      return response.data;
    })
    .then(function (data) {
      return {
        type: "set-data",
        payload: {
          [getter.name]: data
        }
      };
    })
    .catch(function (error) {
      if (error.response) {
        return {
          type: "set-errors",
          payload: {
            [getter.name]: error.response
          }
        };
      }
      return {
        type: "set-errors",
        payload: {
          [getter.name]: error
        }
      };
    });
}

const ContentLoader = ({ payload }) => {
  const { dispatch } = useContext(ContentContext);

  useEffect(() => {
    dispatch({
      type: "set-data",
      payload
    });
  }, []);

  return null;
};

const Content = props => {
  const { state, dispatch } = useContext(ContentContext);
  const contentNames = map(props.getters, "name");
  const useMocks = props.useMocks || false;

  useEffect(() => {
    const getters = filter(
      props.getters,
      getter =>
        // if data not already in state, and not explicitly being triggered for reload
        (!state.data[getter.name] || getter.shouldReload) &&
        (getter.makeGetter || getter.path) &&
        state.status[getter.name] !== "loading"
    );

    dispatch({
      type: "set-loading",
      payload: map(getters, "name")
    });

    const promiseGetters = getters.map(makePromiseToGet.bind(null, useMocks));

    Promise.all(promiseGetters).then(results => {
      const items = groupBy(results, "type");

      forEach(items, (actions, type) => {
        const payload = merge(...map(actions, "payload"));

        dispatch({
          type,
          payload
        });
      });
    });
  }, []);

  // useEffect(() => {
  //   console.info("NEW GETTERS", contentNames);
  // }, [props.getters]);
  const content = pick(state.data, contentNames);

  const isLoading = some(
    pick(state.status, contentNames),
    status => status === "loading"
  );
  const errorResult = first(values(pick(state.errors, contentNames)));

  if (errorResult) {
    return (
      <ContentWrapperContainer
        className={`content-wrapper content-wrapper--error content-wrapper--error-${errorResult.status}`}
      >
        {props.error}
      </ContentWrapperContainer>
    );
  }

  const isContentLoading =
    (!isEmpty(contentNames) && isEmpty(content)) || isLoading;

  return (
    <ContentWrapperContainer
      className={`content-wrapper content-wrapper--${(isContentLoading &&
        "loading") ||
        "loaded"}`}
    >
      {React.Children.toArray(props.children).map(child =>
        React.cloneElement(child, {
          // ...state,
          content: merge({}, child.props.content || {}, content)
        })
      )}
    </ContentWrapperContainer>
  );
};

export { ContentContext, ContentLoader };

export default Content;
