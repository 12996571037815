import React from "react";
import Search from "components/search";
import withURLSync from "URLSync";
import SearchContainer from "./index.style";
import { Helmet } from "react-helmet";

const SearchPage = () => {
  return (
    <SearchContainer>
      <Helmet>
        <title>Search | Understanding Houston</title>
        {/* <meta name="description" content={} /> */}
      </Helmet>
      <Search />
    </SearchContainer>
  );
};

export default withURLSync(SearchPage);
