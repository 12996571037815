import React from "react";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import _reverse from "lodash/reverse";
import _sortBy from "lodash/sortBy";
import _filter from "lodash/filter";
import { Col, Row } from "react-styled-flexboxgrid";
import { Content } from "components";
import { SectionTitle, SectionPointer } from "components/elements";
import theme from "styles/theme";
import { EventsList } from "../../../landing/sections/highlightsEvents/events";
import GetInvolvedEventsContainer, {
  GetInvolvedEventsList,
} from "./index.style";

// TODO pull out and make common.
function orderAndGroupEvents(events) {
  const orderedEvents = _reverse(
    _sortBy(events, [
      function(o) {
        const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        return new Date(o.acf.start_date.replace(pattern, "$3-$1-$2"));
      },
    ])
  );
  const currentDate = new Date();

  return {
    events: orderedEvents,
    upComingEvents: _reverse(
      _filter(orderedEvents, function(o) {
        const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        const date = new Date(o.acf.start_date.replace(pattern, "$3-$1-$2"));
        return date > currentDate;
      })
    ),
    pastEvents: _filter(orderedEvents, function(o) {
      const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
      const date = new Date(o.acf.start_date.replace(pattern, "$3-$1-$2"));
      return date < currentDate;
    }),
  };
}

const EventsListDisplay = (props) => {
  const events = orderAndGroupEvents(props.content.events);

  return (
    <GetInvolvedEventsList>
      {props.content.events.length && <EventsList {...events} />}
    </GetInvolvedEventsList>
  );
};

const GetInvolvedEvents = ({ innerRef }) => {
  return (
    <GetInvolvedEventsContainer
      className="get-involved--events section"
      ref={innerRef}
    >
      <Element name="events-listing">
        <div className="get-involved--events-about">
          <Row className="container" id="attend-event">
            <Col xs={12} md={5}>
              <div className="title">
                <SectionPointer
                  firstColor={theme.primary.pantoneRed}
                  secondColor={theme.primary.spaceCadet}
                />
                <SectionTitle color={theme.primary.spaceCadet}>
                  Attend an upcoming event
                </SectionTitle>
                <p>
                  Check-out our <Link to="/events">event schedule</Link> and
                  join us for what piques your interest. You can also explore
                  videos from past events.
                </p>
              </div>
            </Col>
            <Col xs={12} md={7}>
              <Content
                getters={[
                  {
                    name: "events",
                    path: "events?per_page=100",
                  },
                ]}
              >
                <EventsListDisplay content={{ events: [] }} />
              </Content>
            </Col>
          </Row>
        </div>
      </Element>
    </GetInvolvedEventsContainer>
  );
};

export default GetInvolvedEvents;
