import React from "react";
import { Card as CardContainer } from "./index.style";

const Card = ({ item }) => {
  return (
    <CardContainer key={`panel-${item.name}`} className={`panel--${item.name}`}>
      {item.body}
    </CardContainer>
  );
};

export default Card;
