import styled from "styled-components";

import { device } from "styles/breakpoints";

const VoteContainer = styled.div`
  background-color: ${props => props.theme.primary.spaceCadet};
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 25px 25px 40px;

  @media ${device.tablet} {
    padding: 40px;
  }

  @media ${device.laptopL} {
    padding: 25px 25px 40px;
  }

  h4 {
    font-size: 1.27em;
    margin-bottom: 8.5px;
  }

  p.sm {
    border-bottom: 1px solid #d6d7d8;
    padding-bottom: 18px;
  }

  p.text-voted {
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;

    img {
      margin-right: 10px;
    }

    span {
      margin-left: 10px;
      text-transform: uppercase;
      font-size: 0.72em;
      color: ${props => props.theme.accent.seaGreen};
      font-weight: bold;
    }
  }
`;

export default VoteContainer;
