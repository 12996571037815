import { client } from "./apiConfig"

const getAcfFieldsWithPageIdAPI = (pageId) => {
  return client.get(`/acf/v3/pages/${pageId}`)
}

const getPageWithIdAPI = (pageId) => {
  return client.get(`/wp/v2/pages/${pageId}`)
}

const getPostWithIdAPI = (postId) => {
  return client.get(`/wp/v2/posts/${postId}`)
}

const getAdvisoryCommittesAPI = () => {
  return client.get("/wp/v2/advisory_committees")
}

const getEventsAPI = () => {
  return client.get("/wp/v2/events?_embed&per_page=100")
}

const getCommunityVoicesAPI = () => {
  return client
    .get("/wp/v2/categories?slug=community-voices")
    .then((categoryResponse) => {
      return categoryResponse.data[0] || {}
    })
    .then((category) => {
      return client.get(`/wp/v2/posts?categories=${category.id}`)
    })
    .catch((error) => {
      console.warn(error)
      return { data: [] }
    })
}

const getPostsAPI = () => {
  return client.get("/wp/v2/posts?_embed&per_page=100")
}

const getTopicAPI = (id) => {
  return client.get(`/wp/v2/topics/${id}`)
}

const getCategoriesAPI = () => {
  return client.get("/wp/v2/categories?per_page=100")
}

const getTopicCategoryAPI = () => {
  return client.get(`/wp/v2/categories?slug=topic`)
}

const getSubTopicCategoryAPI = () => {
  return client.get(`/wp/v2/categories?slug=sub-topic`)
}

const getPagesAPI = () => {
  return client.get("wp/v2/pages?per_page=100")
}

const getPageAPI = (pageId) => {
  return client.get(`wp/v2/pages/${pageId}`)
}

export {
  getAcfFieldsWithPageIdAPI,
  getPageWithIdAPI,
  getAdvisoryCommittesAPI,
  getEventsAPI,
  getCommunityVoicesAPI,
  getTopicAPI,
  getTopicCategoryAPI,
  getSubTopicCategoryAPI,
  getCategoriesAPI,
  getPagesAPI,
  getPageAPI,
  getPostsAPI,
  getPostWithIdAPI,
}
