import React from "react";
import styled from "styled-components";

import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";

const { baseSpacing: bs } = defaultShevy;

const Pointer = styled.div`
  display: flex;
  position: relative;
  z-index: 2;

  span {
    width: 33.75px;
    height: 33.75px;
    border-radius: 50%;
    display: inline-block;
    mix-blend-mode: multiply;

    &.second {
      margin-left: -10.5px;
    }
  }

  @media ${device.tablet} {
    position: absolute;
    left: -${bs()};
    transform: translateX(-100%);
    top: 16px;
  }
`;

const SectionPointer = ({ firstColor, secondColor }) => (
  <Pointer className="section-pointer">
    <span className="first" style={{ backgroundColor: firstColor }} />
    <span className="second" style={{ backgroundColor: secondColor }} />
  </Pointer>
);

export default SectionPointer;
