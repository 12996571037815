import styled from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";

const { baseSpacing: bs } = defaultShevy;

const WhoWeAreContainer = styled.section`
  padding: ${bs(3)} ${bs()} ${bs()};
  background-color: #fff;

  @media ${device.laptop} {
    /* margin: 0 -${bs()}; */
    padding: ${bs(5.5)} 0 ${bs(8)};
  }

  .title {
    h2 {
      @media ${device.tablet} {
        margin-bottom: ${bs(4)};
      }
      @media ${device.laptop} {
        margin-bottom: ${bs(5.5)};
      }
    }
  }

  ${Grid} {
    width: 100%;

    ${Row} {
      ${Col} {
        img {
          width: 100%;
        }

        &.description {
          @media ${device.laptop} {
            padding: 0 100px 0 0;
          }

          @media ${device.laptopL} {
            padding: 0 213px 0 0;
          }

          p {
            font-size: 1em;
            line-height: 1.45;
            margin-bottom: ${bs(2)};

            @media ${device.laptop} {
              line-height: 1.81;
            }

            a {
              border-bottom: 2px solid ${props => props.theme.accent.pear};
              text-decoration: none;
              cursor: pointer;
              font-weight: bold;

              &:hover {
                background: ${props => props.theme.primary.spaceCadet};
                border-color: ${props => props.theme.primary.spaceCadet};
                color: ${props => props.theme.accent.pear};
              }
            }
          }

          h2 {
            margin-bottom: ${bs()};

            @media ${device.laptop} {
              font-size: 1.81em;
              line-height: 1.5;
            }
          }

          h3 {
            margin-bottom: ${bs()};

            @media ${device.laptop} {
              font-size: 1.36em;
              line-height: 1.33;
            }
          }
        }
      }
    }
  }
`;

export default WhoWeAreContainer;
