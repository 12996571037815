import styled from "styled-components";

const SearchContainer = styled.div`
  .header {
    align-items: center;
    background-color: rgba(21, 157, 191, 0.1);
    ${"" /* background: #F9F9F9;
  background-image: linear-gradient(161deg, #FFF2E1 0%, #FEFCE1 98%); */}
    background-position: center;
    background-size: cover;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 368px;
    padding: 0.5rem 1rem;
    text-align: center;
  }

  .header h1 {
    color: #112e51;
  }

  .container {
    display: flex;
    margin: 0 auto;
    max-width: 1300px;
    padding: 2rem 1rem;
  }

  .container-filters {
    flex: 1;
    margin-right: 60px;
    max-width: 260px;
  }

  .container-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 80px;
  }

  .container-results {
    flex: 3;
  }

  .container-options {
    border-bottom: 1px solid #ebecf3;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    padding: 30px 0;
  }

  .container-options .container-option:not(:first-child) {
    margin-left: 48px;
  }

  .container-options select {
    min-width: 100px;
  }

  .container-footer {
    margin: 4rem 0;
  }

  .clear-filters {
    align-items: center;
    display: flex;
  }

  .clear-filters svg {
    margin-right: 8px;
  }

  /* Panel */

  .container-body .ais-Panel {
    border-top: 1px solid #ebecf3;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  .ais-Panel-header {
    font-family: Hind, sans-serif;
  }

  /* Search box */

  .header .ais-SearchBox {
    height: 64px;
    ${"" /* width: 740px; */}
  }

  .header .ais-SearchBox .ais-SearchBox-input {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.2);
    padding: 4px 48px 0 64px;
    height: 60px;
    background-color: #fff;
    font-size: 20px;
    border: none;
    outline: none;
    padding: 15px;
  }

  .header .ais-SearchBox-submit {
    padding: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    border: none;
    background-color: #f8f8f8;
    outline: none;
    margin-left: 10px;
    font-size: 16px;
    color: #112e51;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 8px;
    right: 0;
    left: auto;
  }

  .header .ais-SearchBox-reset {
    padding: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    border: none;
    background-color: #f8f8f8;
    outline: none;
    margin-left: 10px;
    font-size: 16px;
    color: #112e51;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 8px;
    right: 0;
    left: auto;
  }

  .header .ais-SearchBox .ais-SearchBox-input::placeholder {
    color: rgba(33, 36, 61, 0.24);
    opacity: 1;
    /* Firefox */
  }

  .ais-SearchBox-input:-ms-input-placeholder {
    color: rgba(33, 36, 61, 0.24);
  }

  .ais-SearchBox-input::-ms-input-placeholder {
    color: rgba(33, 36, 61, 0.24);
  }

  .ais-SearchBox-submit {
    color: #e2a400;
  }

  .ais-RefinementList .ais-SearchBox-input {
    font-family: Hind, sans-serif;
    /*
    The "Hind" font family is vertically off-balance.
    Adding some padding top makes it more vertically aligned.
  */
    padding-top: 2px;
  }

  /* Hits */

  .hit {
    color: #21243d;
    font-size: 14px;
    line-height: 18px;
  }

  .hit h1 {
    font-size: 14px;
  }

  .hit-category {
    color: #21243d;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 8px;
    opacity: 0.7;
    text-transform: uppercase;
  }

  .hit-description {
    margin-top: 2px;
  }

  .hit-info-container {
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .hit-image-container {
    align-items: center;
    display: flex;
    height: 174px;
    justify-content: center;
    margin: auto;
    width: 174px;
  }

  .hit-image {
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }

  .hit-em {
    color: #e2a400;
    font-size: 11px;
    font-weight: 600;
  }

  .hit-rating {
    border: 1px solid rgba(226, 164, 0, 0.5);
    border-radius: 4px;
    margin-left: 4px;
    padding: 0 4px;
  }

  .hits-empty-state {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 300px;
  }

  .hits-empty-state-title {
    font-family: Hind;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
  }

  .hits-empty-state-description {
    color: rgba(35, 37, 51, 0.6);
    font-size: 0.875rem;
    text-align: center;
  }

  .hits-empty-state .ais-ClearRefinements {
    margin-top: 1rem;
  }

  .hits-empty-state .ais-ClearRefinements-button--disabled {
    display: none;
  }

  .hits-empty-state .ais-ClearRefinements-button {
    background: rgba(10, 8, 41, 0.04);
    border-radius: 3px;
    color: #21243d;
    min-height: 48px;
    padding: 16px 24px;
  }

  /* ToggleRefinement */

  .ais-ToggleRefinement-label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .ais-ToggleRefinement-checkbox {
    font: inherit;
    margin-left: 1rem;
    margin-right: 0;
    position: relative;
  }

  .ais-ToggleRefinement-checkbox:checked::before {
    color: #e2a400;
  }

  .ais-ToggleRefinement-checkbox::before {
    align-items: center;
    color: rgba(33, 36, 61, 0.32);
    content: "No";
    display: flex;
    font-size: 0.8rem;
    height: 16px;
    position: absolute;
    right: 38px;
  }

  .ais-ToggleRefinement-checkbox:checked::before {
    content: "Yes";
  }

  .ais-ToggleRefinement-count {
    display: none;
  }

  /* RatingMenu */

  .ais-RatingMenu-item:not(.ais-RatingMenu-item--selected) {
    opacity: 0.5;
  }

  .ais-RatingMenu-starIcon {
    margin-right: 0.5rem;
  }

  /* Hide all mobile-specific design on desktop */

  /* @media (min-width: 900px) {
  [data-layout='mobile'] {
    display: none;
  }
} */
`;

export default SearchContainer;
