import styled from "styled-components";

import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";
import ImgCity from "assets/images/bg-measure-what-matters.jpg";

const { baseSpacing: bs } = defaultShevy;

const MeasuringContainer = styled.section`
  text-align: center;
  background-color: #fff;
  background-image: url(${ImgCity});
  background-size: auto 60%;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding: 0 ${bs()} 140px;

  @media ${device.tablet} {
    background-size: 100% 60%;
  }

  h3 {
    margin-bottom: ${bs(2)};
    font-weight: 900;

    @media ${device.tablet} {
      margin-bottom: ${bs(3.65)};
    }
  }

  .tab-contents {
    max-width: 903px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1),
      0 13px 24px -11px rgba(122, 200, 150, 0.5);

    @media ${device.laptop} {
      padding: 42px 67px 0;
    }

    .tabs {
      display: flex;

      .tab {
        flex: 1;
        border-bottom: 4px solid #e8f0f3;
        cursor: pointer;

        &.active {
          border-color: #159dbf;
        }

        h5 {
          font-size: 0.8em;
          margin-bottom: 0;
        }

        span {
          font-size: 0.6em;
          line-height: 1.75;
          margin-bottom: ${bs(0.5)};
          display: block;
        }

        @media ${device.tablet} {
          h5 {
            font-size: 1.36em;
            line-height: 1.2;
          }

          span {
            font-size: 0.72em;
          }
        }
      }
    }

    .slide {
      padding: ${bs()};

      @media ${device.tablet} {
        padding: ${bs(3)};
      }

      h6 {
        margin-bottom: 35px;
        line-height: 1.3;

        @media ${device.tablet} {
          font-size: 1.36em;
        }
      }

      .summaries {
        display: flex;
        flex-direction: column;

        @media ${device.tablet} {
          flex-direction: row;
        }

        .summary {
          flex: 1;
          padding: 0 15px;

          img {
            margin-bottom: ${bs()};
          }

          p {
            line-height: 1.55;
          }
        }
      }
    }
  }
`;

export default MeasuringContainer;
