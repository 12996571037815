import React from "react";
import { scroller } from "react-scroll";
import { Button } from "components/elements";
import VideoBackground from "components/video-background";
import BannerContainer from "./index.style";

const Banner = ({ value, target = value.button_target === "page" ? value.button_link : value.button_section }) => (
  <BannerContainer>
    <VideoBackground />
    <div className="content">
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: value.banner_title }} />
        <p className="sm">
          <span dangerouslySetInnerHTML={{ __html: value.banner_subtitle }} />
        </p>
        <Button title={value.banner_button_text} onClick={() => scroller.scrollTo(target, { duration: 1500, delay: 100, smooth: true, offset: 180 })}>
          {" "}
          {value.banner_button_text}
        </Button>
      </div>
    </div>
  </BannerContainer>
);

export default Banner;
