import styled from "styled-components";

const Link = styled.a`
  border-bottom: ${props => (props.borderHeight ? props.borderHeight : "2px")}
  solid ${props => props.theme.accent.pear};
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background: ${props => props.theme.primary.spaceCadet};
    border-color: ${props => props.theme.primary.spaceCadet};
    color: ${props => props.theme.accent.pear};
  }
`;

export default Link;
