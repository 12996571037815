import React, { useState, useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import { Col, Row } from "react-styled-flexboxgrid";
import {
  InstantSearch,
  connectSearchBox,
  Hits,
  Index,
  Configure,
  Pagination,
  Stats,
  Snippet,
} from "react-instantsearch-dom";
import SearchContainer from "./index.style";

const algoliaClient = algoliasearch(
  "WXJM7O29P6",
  "6a0636ce692ec9fcbfa31d78cb673ec8"
);

const WebsiteHit = ({ hit }) => {
  const url = hit.permalink
    ? hit.permalink.replace("https://api", "https://www")
    : null;
  return (
    <div className="item">
      <h5>
        <a href={url}>{hit.post_title}</a>
      </h5>
      <Snippet hit={hit} attribute="content" />
    </div>
  );
};

const BlogHit = ({ hit }) => {
  // const url = hit.permalink.replace("https://api", "https://www")
  return (
    <div className="item">
      <div style={{ width: "100%" }}>
        {hit.imgSrc && (
          <a href={`/blog/${hit.slug}/`}>
            <img
              alt={hit.title}
              className="center"
              src={hit.imgSrc}
              style={{ width: 150, height: 150, margin: "0 auto" }}
            />
          </a>
        )}
      </div>
      <h5>
        <a href={`/blog/${hit.slug}/`}>{hit.title}</a>
      </h5>
      <p className="date">{hit.post_date_formatted}</p>
      <Snippet hit={hit} attribute="excerpt" />
    </div>
  );
};

const Search = ({}) => {
  const [option, setOption] = useState("all");

  const SearchBox = ({ currentRefinement, refine }) => (
    <div className="ais-SearchBox" role="search">
      <form
        noValidate
        action=""
        role="search"
        className="ais-SearchBox-form"
        onSubmit={(e) => {
          e.preventDefault();
          if (typeof window.track !== "undefined") {
            window.track.siteSearched({
              query: currentRefinement,
            });
          }
        }}
      >
        <input
          type="search"
          value={currentRefinement}
          onChange={(event) => refine(event.currentTarget.value)}
          placeholder="Search to explore the data, stories, news and more..."
          className="ais-SearchBox-input"
          name="search"
          id="search"
          aria-labelledby="search-label"
        />
        {!currentRefinement ? (
          <button
            type="submit"
            title="Submit your search query."
            className="ais-Searchbox-submit"
          >
            <svg
              className="ais-SearchBox-submitIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 40 40"
            >
              <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path>
            </svg>
          </button>
        ) : (
          <button
            type="reset"
            title="Clear your search query."
            className="ais-Searchbox-reset"
            onClick={() => refine("")}
          >
            <svg
              className="ais-SearchBox-resetIcon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              width="10"
              height="10"
            >
              <path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"></path>
            </svg>
          </button>
        )}
      </form>
    </div>
  );

  const CustomSearchBox = connectSearchBox(SearchBox);

  return (
    <SearchContainer>
      <InstantSearch searchClient={algoliaClient} indexName="website">
        <section className="header section">
          <Row className="container">
            <Col xs={12} sm={10} smOffset={1} lg={8} lgOffset={2}>
              <h1 id="search-label">Search</h1>
              <ul>
                <li
                  className={`${option === "all" && "active"}`}
                  onClick={() => setOption("all")}
                >
                  All
                </li>
                <li
                  className={`${option === "website" && "active"}`}
                  onClick={() => setOption("website")}
                >
                  Website
                </li>
                <li
                  className={`${option === "deno-posts" && "active"}`}
                  onClick={() => setOption("deno-posts")}
                >
                  Blog
                </li>
              </ul>
              {/* <SearchBox
                translations={{
                  placeholder: "Search to explore the data, stories, news and more...",
                }}
              /> */}
              <CustomSearchBox />
            </Col>
          </Row>
        </section>

        <section className="search">
          <Row className="container">
            <Col xs={12}>
              <div className="results">
                {option === "all" ? (
                  <div>
                    <Index indexName="website">
                      <Configure
                        hitsPerPage={4}
                        attributesToSnippet={["content"]}
                      />

                      <div className={`section-results ${option}`}>
                        <div className="stats-pagination">
                          <h2>Website</h2>
                          <Stats
                            translations={{
                              stats(nbHits) {
                                return `${nbHits} results`;
                              },
                            }}
                          />
                          <Pagination
                            padding={0}
                            showFirst={false}
                            showLast={true}
                            translations={{
                              previous: (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                >
                                  <g
                                    fill="none"
                                    fillRule="evenodd"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.143"
                                  >
                                    <path d="M9 5H1M5 9L1 5l4-4" />
                                  </g>
                                </svg>
                              ),
                              next: (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                >
                                  <g
                                    fill="none"
                                    fillRule="evenodd"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.143"
                                  >
                                    <path d="M1 5h8M5 9l4-4-4-4" />
                                  </g>
                                </svg>
                              ),
                              last(nbPages) {
                                return nbPages;
                              },
                              page(currentRefinement) {
                                return `${currentRefinement}`;
                              },
                            }}
                          />
                        </div>
                        <Hits hitComponent={WebsiteHit} />
                      </div>
                    </Index>

                    <Index indexName="deno-posts">
                      <Configure hitsPerPage={4} />
                      <div className={`section-results ${option}`}>
                        <div className="stats-pagination">
                          <h2>Articles & Reports</h2>
                          <Stats
                            translations={{
                              stats(nbHits) {
                                return `${nbHits} results`;
                              },
                            }}
                          />
                          <Pagination
                            padding={0}
                            showFirst={false}
                            showLast={true}
                            translations={{
                              previous: (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                >
                                  <g
                                    fill="none"
                                    fillRule="evenodd"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.143"
                                  >
                                    <path d="M9 5H1M5 9L1 5l4-4" />
                                  </g>
                                </svg>
                              ),
                              next: (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                >
                                  <g
                                    fill="none"
                                    fillRule="evenodd"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.143"
                                  >
                                    <path d="M1 5h8M5 9l4-4-4-4" />
                                  </g>
                                </svg>
                              ),
                              last(nbPages) {
                                return nbPages;
                              },
                              page(currentRefinement) {
                                return `${currentRefinement}`;
                              },
                            }}
                          />
                        </div>
                        <Hits hitComponent={BlogHit} />
                      </div>
                    </Index>
                  </div>
                ) : (
                  <div>
                    <Index indexName={option}>
                      <Configure hitsPerPage={8} />
                      <div className={`section-results ${option}`}>
                        <div className="stats-pagination">
                          {option === "deno-posts" ? (
                            <h2>Articles & Reports</h2>
                          ) : (
                            <h2>Website</h2>
                          )}

                          <Stats
                            translations={{
                              stats(nbHits) {
                                return `${nbHits} results`;
                              },
                            }}
                          />
                          <Pagination
                            padding={0}
                            showFirst={false}
                            showLast={true}
                            translations={{
                              previous: (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                >
                                  <g
                                    fill="none"
                                    fillRule="evenodd"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.143"
                                  >
                                    <path d="M9 5H1M5 9L1 5l4-4" />
                                  </g>
                                </svg>
                              ),
                              next: (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                >
                                  <g
                                    fill="none"
                                    fillRule="evenodd"
                                    stroke="#000"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.143"
                                  >
                                    <path d="M1 5h8M5 9l4-4-4-4" />
                                  </g>
                                </svg>
                              ),
                              last(nbPages) {
                                return nbPages;
                              },
                              page(currentRefinement) {
                                return `${currentRefinement}`;
                              },
                            }}
                          />
                        </div>
                        {option === "deno-posts" ? (
                          <Hits hitComponent={BlogHit} />
                        ) : (
                          <Hits hitComponent={WebsiteHit} />
                        )}
                      </div>
                    </Index>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </section>
      </InstantSearch>
    </SearchContainer>
  );
};

export default Search;
