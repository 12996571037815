import { createGlobalStyle } from "styled-components";
import { device } from "styles/breakpoints";
import IconDropDown from "assets/images/data-viz/dropdown.svg";

const GlobalChartStyles = createGlobalStyle`
  .highcharts-menu hr {
    margin: 0;
    background-color: #E0E0E0;
    border: none;
    height: 1px;
  }
  .highcharts-contextbutton text {
    color: #112E51 !important;
    fill: #112E51 !important;
    font-weight: 500 !important;
    font-size: 12px;
    font-family: Roboto, serif;
  }

  .highcharts-contextbutton path {
    display: none;
  }

  .highcharts-tooltip>span {
    border: none;
    border-radius: 3px;
    padding: 12px 14px;
    background: #fff;
    border: 0px solid #708AA9;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

  }

  .tooltip-custom-heading {
    color: #4C627C;
    font-size: 12px;
    font-weight: 400;
    padding: 0 0 3px;
    display:block;
    margin-bottom: 3px;
    border-bottom: 1px solid #E8F0F3;
    text-align: left;
  }

  .tooltip-custom-category {
    color: #4C627C;
    font-size: 14px;
    font-weight: 400
  }

  .tooltip-custom-value {
    color: #112E51;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .tooltip-custom-wrapper {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row no-wrap;
    align-items: baseline;
    vertical-align: baseline;
  }

  .tooltip-custom-group {
    flex: 1;
    margin-right: 14px;
  }

  .highcharts-tooltip span.text {
    display: block
  }

  .highcharts-tooltip {
    box-shadow: none;
  }
  .small-chart .highcharts-subtitle {
      display: none;
  }
  .small-chart .highcharts-legend {
      display: none;
  }
  .data-viz {
    margin: 60px 0 80px;
    width: 100%;
    position: relative;

    > .chart {
      box-shadow: 0 15px 30px -5px rgba(50,50,93,.1), 0 10px 20px -10px rgba(0,0,0,.1), 0 -12px 20px -10px rgba(0,0,0,.02);
      border-radius: 6px;
      background: #fff;
      padding: 0;
      margin: 0;

      .chart {
        border-radius: 3px;
        background: #fff;
        min-height: 340px;
        padding: 0 20px 10px;

        @media ${device.tablet} {
          padding: 0 30px 10px;
          min-height: 500px;
        }
      }
    }

    .data-viz-heading {
      margin: 0;
      display: flex;
      align-items: baseline;
      vertical-align: baseline;
      flex-flow: column wrap;
      border-bottom: 2px solid #e6ebf1;
      border-radius: 6px 6px 0 0;
      padding: 20px 20px 15px;
      background: #e6eaee;

      @media ${device.tablet} {
        flex-flow: row wrap;
        padding: 20px 30px 15px;
      }

      div.data-viz-titles {
        flex: 1;

        h4.heading {
          line-height: 1.4rem;
          color: #112E51;
          font-size: 18px;
          font-weight: 600;
          font-family: Roboto;
          margin: 0;

          @media ${device.tablet} {
            line-height: 1.4rem;
          }
        }

        p {
          margin: 5px 0 0;
          font-size: 14px !important;
          line-height: 1.4rem;
          color: #000000;

          @media ${device.tablet} {
            margin: 5px 0 0;
            font-size: 16px;
          }
        }
      }

      div.data-viz-filters {
        margin-bottom: 10px;
        margin-top: 10px;

        @media ${device.tablet} {
          margin-bottom: 0;
          margin-top: 0;
        }

        p {
          font-family: Roboto;
          margin: 0 10px 0 0;
          text-transform: uppercase;
          vertical-align: baseline;
          font-size: 14px;
          display: none;
        }

        div.data-viz-buttons {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font: inherit;
          vertical-align: baseline;

          select {
            padding-right: 24px;
            margin: 0 0.25rem;
            ${"" /* top: 15px; */}
            text-rendering: auto;
            letter-spacing: normal;
            word-spacing: normal;
            text-indent: 0px;
            text-shadow: none;
            text-align: start;
            appearance: none;
            cursor: default;
            align-items: center;
            white-space: pre;
            pointer-events: all;
            font-feature-settings: "pnum";
            box-sizing: border-box;
            border: none;
            outline: none;
            font: 600 13px Roboto;
            color: #4C627C;
            text-transform: uppercase;
            ${"" /* position: absolute; */}
            padding-left: 10px;
            height: 25px;
            border-radius: 13px;
            background: url(${IconDropDown}) no-repeat center right 10px,linear-gradient(#fefeff,#f6f9fc);
            box-shadow: 0 1px 2px rgba(0,0,0,.08),0 2px 5px rgba(50,50,93,.11);
            z-index: 9;
            right: 0;

            &::-ms-expand {
              display: none;
            }
          }

          option {
            font-weight: normal;
            display: block;
            white-space: pre;
            min-height: 1.2em;
            padding: 0px 2px 1px;
          }

          button.button {
            font: 600 13px Roboto;
            color: #4C627C;
            text-transform: uppercase;
            border: none;
            padding-left: 10px;
            height: 25px;
            border-radius: 13px;
            background: linear-gradient(#fefeff,#f6f9fc);
            box-shadow: 0 1px 2px rgba(0,0,0,.08), 0 2px 5px rgba(50,50,93,.11);
            z-index: 2;
            margin: 0 0.25rem;
            padding: 0 10px;
            cursor: pointer;
            outline: 0;
            box-direction: normal;
            font-smoothing: antialiased;
            font-feature-settings: "pnum";

            &.active {
              background: #4C627C !important;
              color: #fff;
              box-direction: normal;
              font-smoothing: antialiased;
              font-feature-settings: "pnum";
            }

            &:active {
              background: #4C627C !important;
              color: #fff;
              box-direction: normal;
              font-smoothing: antialiased;
              font-feature-settings: "pnum";
            }
          }
        }
      }
    }

    div.data-viz-footer {
      position: relative;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px !important;
      flex-flow: column wrap;
      ${"" /* align-items: baseline;
      vertical-align: baseline; */}
      background: #fff;
      padding: 0 20px 15px;
      border-radius: 0 0 6px 6px;
      border-top: 1px solid #e6ebf1;
      padding-top: 15px;

      @media ${device.tablet} {
        padding: 0 30px 0;
        flex-flow: row wrap;
      }

      div.data-viz-export-options {
        position: relative;
        top: auto;
        right: auto;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        line-height: 18px;
        font-size: 12px;
        font-weight: 400;
        display:block;
        font-family: Roboto;
        font-size: 13px !important;
        color: #000000;
        box-direction: normal;
        font-smoothing: antialiased;
        font-feature-settings: "pnum";

        @media ${device.tablet} {
          margin-right: 10px;
          padding-top: 15px;
          padding-bottom: 15px;
        }

        a.data-viz-export {
          font-size: 13px !important;
          line-height: 18px;
          font-weight: 500;
          font-family: Roboto;
          color: #112E51;
          margin: 0 15px 0 0;
          box-direction: normal;
          font-smoothing: antialiased;
          font-feature-settings: "pnum";
          text-decoration: none;
        }

        a.data-viz-download {
          font-size: 13px !important;
          font-weight: 500;
          line-height: 18px;
          font-family: Roboto;
          color: #112E51;
          margin: 0 0 0 0;
          box-direction: normal;
          font-smoothing: antialiased;
          font-feature-settings: "pnum";
          text-decoration: none;
        }
      }

      div.data-viz-caption {
        margin-left: 0;
        flex: 1;
        font-size: 13px !important;
        padding-top: 10px;

        @media ${device.tablet} {
          border-left: 1px solid #e6ebf1;
          padding-left: 10px;
          margin-left: 10px;
          padding-top: 15px;
          padding-bottom: 15px;
        }

        p {
          color: #000000;
          line-height: 18px;
          font-size: 13px !important;
          font-weight: 400;
          font-family: Roboto;
          margin: 0;
          box-direction: normal;
          font-smoothing: antialiased;
          font-feature-settings: "pnum";
          strong  {
            font-weight: 500;
          }
        }
      }
    }
  }
`;
export default GlobalChartStyles;
