const theme = {
  primary: {
    spaceCadet: "#112E51",
    pantoneRed: "#EF233C"
  },
  accent: {
    seaGreen: "#7AC896",
    pear: "#DDD12A"
  },
  secondary: {
    ghcfBlue: "#159DBF",
    deepKoamaru: "#322E6C",
    vividGamboge: "#FC9B00"
  },
  other: {
    ug: "#22A451",
    ug100: "#D2ECDC"
  },
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 2, // rem
    outerMargin: 0, // rem
    mediaQuery: "only screen",
    container: {
      sm: 45, // rem
      md: 60, // rem
      lg: 78.75 // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 90 // em
    }
  }
};

export default theme;
