/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "components/arrows";
import EventsContainer from "./events.style";

const SliderContentInner = ({ event }) => (
  <div className="slide-content">
    <article className="event-summary">
      <h3>{event.title.rendered}</h3>
      <p className="xs">
        <Moment format="MMM D, YYYY" tz="America/Chicago">
          {event.acf.start_date}
        </Moment>
        {event.acf.start_time
          ? `, ${event.acf.start_time}${event.acf.end_time &&
              ` - ${event.acf.end_time}`}`
          : ""}
        <br />
        {event.acf.address}
      </p>
      {event.acf.media_link && (
        <a
          href={event.acf.media_link}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Events (opens in a new tab)"
          className="media-link"
        >
          View Media
        </a>
      )}
    </article>
    <div
      className="event-photo"
      style={{
        backgroundImage: `url(${
          event.better_featured_image
            ? event.better_featured_image.source_url
            : ""
        })`,
      }}
    />
  </div>
);

const SliderContent = ({ activeEventType, event }) =>
  (event.acf.registration_link && activeEventType === "upComingEvents" && (
    <a
      href={event.acf.registration_link}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Events (opens in a new tab)"
    >
      <SliderContentInner event={event} />
    </a>
  )) || <SliderContentInner event={event} />;

class EventsList extends Component {
  state = {
    activeEvents: this.props.upComingEvents,
    activeEventType: "upComingEvents",
  };

  componentDidMount() {}

  changeEventsType = (eventType) => {
    this.setState({
      activeEvents: this.props[eventType],
      activeEventType: eventType,
    });
  };

  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
    };

    const { activeEvents, activeEventType } = this.state;

    return (
      <EventsContainer>
        <div>
          <div className="events-type">
            <span
              onClick={() => this.changeEventsType("upComingEvents")}
              className={`${activeEventType === "upComingEvents" && "active"}`}
            >
              Upcoming Events
            </span>
            <span
              onClick={() => this.changeEventsType("pastEvents")}
              className={`${activeEventType === "pastEvents" && "active"}`}
            >
              Past Events
            </span>
            <Link to="/events">View All Events</Link>
          </div>
          <div className="events-slider">
            {activeEvents.length > 0 && (
              <Slider {...settings}>
                {activeEvents.map((event, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <SliderContent
                    key={index}
                    event={event}
                    activeEventType={activeEventType}
                  />
                ))}
              </Slider>
            )}
          </div>
        </div>
        <div className="events">
          {activeEvents.slice(0, 4).map((event, index) => (
            <SliderContent
              key={index}
              event={event}
              activeEventType={activeEventType}
            />
          ))}
        </div>
      </EventsContainer>
    );
  }
}

const Events = (props) => (
  <EventsContainer>
    <EventsList {...props} />
  </EventsContainer>
);

export { EventsList };

export default Events;
