import React, { Component } from "react";
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import { WPContent } from "components";

import { SectionTitle, SectionPointer } from "components/elements";

import WhoWeAreContainer from "./index.style";

class WhoWeAre extends Component {
  componentDidMount() {}

  render() {
    const { content } = this.props;

    return (
      <WhoWeAreContainer ref={this.props.innerRef}>
        <div className="container">
          <Grid>
            <Row>
              <Col xs={12} mdOffset={1}>
                <div className="title">
                  <SectionPointer firstColor="#159DBF" secondColor="#EF233C" />
                  <SectionTitle>About us</SectionTitle>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
        <Grid className="content">
          <Row>
            <Col xs={12} md={6}>
              <img src={content.image.url} alt="who" />
            </Col>
            <Col xs={12} md={6} className="description">
              <WPContent cleanContent={true}>{content.content}</WPContent>
            </Col>
          </Row>
        </Grid>
      </WhoWeAreContainer>
    );
  }
}

export default WhoWeAre;
