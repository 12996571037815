import React from "react";
import { Content } from "components";

import {
  transformPagesToTopicsAndChildren,
  makeTopicsAndSubtopicsGetter
} from "helpers/topics-and-subtopics";

const defaultTopics = [
  {
    slug: "",
    id: "",
    title: {
      rendered: ""
    },
    content: {
      rendered: ""
    },
    acf: {},
    children: []
  }
];

const defaultTopicsAndSubtopics = {
  topicsAndSubtopics: {
    topics: defaultTopics,
    subtopics: []
  }
};

const REQUIRED_FIELDS = [
  "id",
  "parent",
  "slug",
  "title",
  "categories",
  "menu_order",
  "acf",
  "featured_media",
  "better_featured_image",
  "content",
  "excerpt"
];

function makeGetter(axios) {
  return makeTopicsAndSubtopicsGetter(axios, REQUIRED_FIELDS).then(
    ({ pages, topicCategoryId }) => {
      const { parentTopics, childTopics } = transformPagesToTopicsAndChildren(
        pages,
        topicCategoryId
      );
      return {
        data: {
          topics: parentTopics,
          subtopics: childTopics
        }
      };
    }
  );
}

const TopicsAndSubtopicsProvider = ({ children, ...props }) => {
  return (
    <Content
      getters={[
        {
          name: "topicsAndSubtopics",
          makeGetter
        }
      ]}
      {...props}
    >
      {React.Children.toArray(children).map(child =>
        React.cloneElement(child, {
          content: defaultTopicsAndSubtopics
        })
      )}
    </Content>
  );
};

export default TopicsAndSubtopicsProvider;
