import styled from "styled-components";
import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";

const { baseSpacing: bs } = defaultShevy;

const PrivacyContainer = styled.div`

  &.container {
    .title {      
      font-size: 1.6em;
      margin-top: ${bs(1.85)};
      margin-bottom: ${bs(0.4)};
      padding: 0 10px 0 10px;

      @media ${device.tablet} {
        font-size: 2.45em;
        margin-top: ${bs(2.85)};
        margin-bottom: ${bs(1)};
        padding: 0 20px 0 20px;
      }

      @media ${device.laptop} {
        font-size: 3.45em;
        margin-top: ${bs(7.85)};
        margin-bottom: ${bs(1.3)};
      }
    }
    
    .last-updated-date {
      font-size: 0.82em;
      line-height: 1.23em;

      @media ${device.laptop} {
        margin-bottom: ${bs(4.75)};
      }
    }

    h2 {

      font-size: 1.2em;
      padding: 0 10px 0 10px;

      @media ${device.tablet} {
        font-size: 1.6em;
        padding: 0 20px 0 20px;
      }

      @media ${device.laptop} {
        font-size: 2.23em;
      }
    }

    h3 {

      font-size: 1.1em;
      padding: 0 10px 0 10px;

      @media ${device.tablet} {
        font-size: 1.4em;
        padding: 0 20px 0 20px;
      }

      @media ${device.laptop} {
        font-size: 1.93em;
      }
    }

    p {
      
      font-size: 0.7em;
      margin-bottom: ${bs(0.7)};
      padding: 0 10px 0 10px;

      @media ${device.tablet} {
        font-size: 0.8em;
        line-height: 1.2em;
        padding: 0 20px 0 20px;
        margin-bottom: ${bs(1.2)};
      }

      @media ${device.laptop} {
        font-size: 1em;
        ling-height: 1.82em;
        margin-bottom: ${bs(2)};
      }
    }
  }
`;

export default PrivacyContainer;