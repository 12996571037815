import React, { PureComponent } from "react";
import { scroller, Element } from "react-scroll";
import { withRouter } from "react-router";
import { Waypoint } from "react-waypoint";

import {
  Introduction,
  Navigation,
  Ways,
  Donate,
  Events,
  Media,
  Contact
} from "./sections";

import { NavProvider } from "./sections/navigation/context";

const IntroductionWithRef = React.forwardRef((props, ref) => {
  return <Introduction innerRef={ref} {...props} />;
});

const WaysWithRef = React.forwardRef((props, ref) => {
  return <Ways innerRef={ref} {...props} />;
});

const DonateWithRef = React.forwardRef((props, ref) => {
  return <Donate innerRef={ref} {...props} />;
});

const EventsWithRef = React.forwardRef((props, ref) => {
  return <Events innerRef={ref} {...props} />;
});

const MediaWithRef = React.forwardRef((props, ref) => {
  return <Media innerRef={ref} {...props} />;
});

const ContactWithRef = React.forwardRef((props, ref) => {
  return <Contact innerRef={ref} {...props} />;
});

class Contents extends PureComponent {
  componentDidMount() {
    const { hash } = this.props.location;

    if (hash.substr(1) === "give-feedback") {
      setTimeout(() => {
        scroller.scrollTo("get-involved-tabs");
      }, 1000);
    } else {
      setTimeout(() => {
        scroller.scrollTo(hash.substr(1));
      }, 1000);
    }
  }

  render() {
    return (
      <NavProvider>
        <div className="contents">
          <IntroductionWithRef />
          <Navigation />
          <Waypoint scrollableAncestor={window} topOffset={100}>
            <WaysWithRef />
          </Waypoint>

          <Waypoint scrollableAncestor={window} topOffset={100}>
            <DonateWithRef />
          </Waypoint>

          <Waypoint
            scrollableAncestor={window}
            topOffset={100}
            bottomOffset="100px"
          >
            <EventsWithRef />
          </Waypoint>

          <Waypoint scrollableAncestor={window} topOffset={100}>
            <MediaWithRef />
          </Waypoint>

          <Element name="stay-connected">
            <Waypoint scrollableAncestor={window} topOffset={100}>
              <ContactWithRef />
            </Waypoint>
          </Element>
        </div>
      </NavProvider>
    );
  }
}

export default withRouter(Contents);
