import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faLinkedinIn, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import SocialLinksWrapper from "./index.style";

const SocialLinks = ({ hasTitle = true, hasSearchButton = false }) => (
  <SocialLinksWrapper className="social-links">
    {hasTitle && <p className="xs">Follow Us</p>}
    <ul>
      <li>
        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/UnderstandingHouston" title="Facebook" aria-label="Facebook (opens in a new tab)">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
      </li>
      <li>
        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/understanding-houston/" title="LinkedIn" aria-label="LinkedIn (opens in a new tab)">
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
      </li>
      <li>
        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/understandinghouston/" title="Instagram" aria-label="Instagram (opens in a new tab)">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </li>
      <li>
        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/UnderstandHOU" title="Twitter" aria-label="Twitter (opens in a new tab)">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </li>
      {hasSearchButton}
    </ul>
  </SocialLinksWrapper>
);

export default SocialLinks;
