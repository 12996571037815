import styled from "styled-components";

// import { defaultShevy } from "styles/global.style";
// import theme from "styles/theme";
// import { device } from "styles/breakpoints";
import { Button } from "components/elements";

const ContactUsButton = styled(Button)`
  display: inline-flex;
`;

const ContactUsContainer = styled.div`
  address {
    font-style: normal;
    color: white;
    display: flex;

    &::before {
      content: " ";
      width: 2em;
    }
  }

  .contact-us--location-phone,
  .contact-us--location-place {
    font-weight: 800;
  }

  &.contact-us > div {
    padding-top: 3em;
    padding-bottom: 3em;
  }
`;

export { ContactUsButton };

export default ContactUsContainer;
