/* eslint-disable react/no-danger */
import React, { useState, useEffect, createRef } from "react";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";

import snakeCase from "lodash/snakeCase";

import { Link } from "components/elements";
import { WPContent } from "components";
import { ReactComponent as IconClose } from "assets/images/icon-close.svg";

import SubtopicContainer, { SubtopicWrapper } from "./index.style";

const SubtopicDisplay = ({ subtopic, topic, setActiveItem, isActive }) => {
  const tocEl = createRef();
  const history = useHistory();

  const goToContent = (clickEvent) => {
    if (!clickEvent.target.dataset.title) {
      return;
    }
    const targetHash = snakeCase(clickEvent.target.dataset.title);
    history.push(`/topic/${topic.slug}/${subtopic.slug}/#${targetHash}`);
  };

  useEffect(() => {
    tocEl.current.addEventListener("click", goToContent);
  }, []);

  const [isTOC, setTOC] = useState(false);

  const toggleModes = () => {
    setTOC(false);
    if (isActive) {
      setActiveItem("");
      return;
    }
    setActiveItem(subtopic.id);
  };

  const toggleTOC = (clickEvent) => {
    clickEvent.stopPropagation();
    setTOC((prevState) => !prevState);
  };

  const style = (isEmpty(subtopic.better_featured_image) && {
    backgroundColor: topic.acf.topic_border_color,
  }) || {
    backgroundImage: `url(${subtopic.better_featured_image.source_url})`,
  };

  const showTOC = false;

  const goToSubTopic = () => {
    window.scrollTo(0, 0);
    history.push(`/topic/${topic.slug}/${subtopic.slug}`);
  };

  return (
    <SubtopicWrapper xs={12} sm={12} md={6}>
      <SubtopicContainer
        onMouseEnter={toggleModes}
        onMouseLeave={toggleModes}
        className={`topic-subtopic-card topic-subtopic-card--${(isActive &&
          isTOC &&
          "toc") ||
          (isActive && "intro") ||
          "quick-stat"}`}
      >
        <div className="topic-subtopic-card--feature" style={style} />
        <div className="topic-subtopic-card--info">
          <h3 dangerouslySetInnerHTML={{ __html: subtopic.title.rendered }} />
          <WPContent
            className="topic-subtopic-preview topic-subtopic-stat"
            style={{
              borderColor: topic.acf.topic_border_color,
            }}
          >
            {subtopic.acf.data_preview}
          </WPContent>
          <WPContent className="topic-subtopic-preview topic-subtopic-introduction">
            {subtopic.acf.summary_preview}
          </WPContent>
          <p className="topic-subtopic-preview topic-subtopic-toc">
            In this page we talk about the following:
          </p>
          <div ref={tocEl}>
            <div className="topic-subtopic-card--links">
              <p data-title="Overview" id="" />
              <p data-title="The Data" id="the_data" />
            </div>
            <WPContent className="topic-subtopic-card--content">
              {subtopic.content.rendered}
            </WPContent>
          </div>
          <ul className="topic-subtopic-card--nav">
            <li className="go-to-subtopic">
              <Link onClick={goToSubTopic}>Explore the Data</Link>
            </li>
            {(showTOC && (
              <li className="toggle-toc">
                <Link onClick={toggleTOC}>
                  {isTOC && <IconClose />}Table of contents
                </Link>
              </li>
            )) ||
              null}
          </ul>
        </div>
      </SubtopicContainer>
    </SubtopicWrapper>
  );
};

export default SubtopicDisplay;
