import React, { Component } from "react";

import { BarChart } from "components";
import { SectionTitle, SectionPointer } from "components/elements";

import SVGMap from "./svgMap";
import SectionWrapper from "./index.style";

class OurScope extends Component {
  state = {
    activeCounty: "3-County",
  };

  onChangeCounty = (county) => {
    this.setState({
      activeCounty: county,
    });
  };

  render() {
    const { activeCounty } = this.state;
    const { content } = this.props;

    const descriptionPropertyName =
      activeCounty === "3-County"
        ? "all_county_description"
        : `${activeCounty
            .toLowerCase()
            .split(" ")
            .join("_")}_county_description`;

    return (
      <SectionWrapper className="section" forwardRef={this.props.innerRef}>
        <div className="container">
          <div className="inner-content in-scope">
            <div className="left-content">
              <div className="title">
                <SectionPointer firstColor="#0EB3D4" secondColor="#EF233C" />
                <span>{content.tag}</span>
                <SectionTitle>{content.title}</SectionTitle>
              </div>
              <p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: content[descriptionPropertyName],
                  }}
                />
              </p>
              <div>
                <BarChart
                  activeCounty={activeCounty}
                  onChangeCounty={this.onChangeCounty}
                />
              </div>
            </div>
            <div className="right-content">
              <SVGMap
                activeCounty={activeCounty}
                onChangeCounty={this.onChangeCounty}
              />
            </div>
          </div>
        </div>
      </SectionWrapper>
    );
  }
}

export default OurScope;
