import React, { PureComponent } from "react";
import { Waypoint } from "react-waypoint";
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import { Element, scroller } from "react-scroll";
import { withRouter } from "react-router";
import { WPContent } from "components";

import { Button } from "components/elements";
import {
  Banner,
  OurApproach,
  Measuring,
  WhoWeAre,
  Indicator,
  Supporters,
} from "./sections";

const OurApproachWithRef = React.forwardRef((props, ref) => {
  return <OurApproach innerRef={ref} {...props} />;
});

const MeasuringWithRef = React.forwardRef((props, ref) => {
  return <Measuring innerRef={ref} {...props} />;
});

const WhoWeAreWithRef = React.forwardRef((props, ref) => {
  return <WhoWeAre innerRef={ref} {...props} />;
});

const IndicatorWithRef = React.forwardRef((props, ref) => {
  return <Indicator innerRef={ref} {...props} />;
});

const SupportersWithRef = React.forwardRef((props, ref) => {
  return <Supporters innerRef={ref} {...props} />;
});

class Contents extends PureComponent {
  componentDidMount() {
    const { hash } = this.props.location;
    if (hash !== "") {
      setTimeout(() => {
        scroller.scrollTo(hash.substr(1));
      }, 2000);
    }
  }

  scrollToElement = (element) => {
    scroller.scrollTo(element, {
      duration: 1500,
      delay: 100,
      smooth: true,
    });
  };

  render() {
    const { data, committees } = this.props;

    return (
      <div className="contents">
        <Banner
          scrollTo={this.scrollToElement}
          content={data.acf.banner_content}
        />
        <Waypoint scrollableAncestor={window} topOffset={100}>
          <section className="description">
            <WPContent className="container" cleanContent={true}>
              {data.content.rendered}
            </WPContent>
          </section>
        </Waypoint>

        <Element name="ourApproach" id="ourApproach">
          <Waypoint scrollableAncestor={window} topOffset={100}>
            <OurApproachWithRef
              approachOne={data.acf.approach_one}
              approachTwo={data.acf.approach_two}
            />
          </Waypoint>
        </Element>

        <Waypoint scrollableAncestor={window} topOffset={100}>
          <MeasuringWithRef tabs={data.acf.tabs} />
        </Waypoint>

        <Element name="aboutUs" id="aboutUs">
          <Waypoint scrollableAncestor={window} topOffset={100}>
            <WhoWeAreWithRef content={data.acf.who_we_are_content} />
          </Waypoint>
        </Element>

        <section className="share-story">
          <div className="background">
            <img src={data.acf.share_story_content.image.url} alt="woman" />
          </div>
          <div className="container">
            <Grid>
              <Row>
                <Col xs={12} sm={12} md={6} className="content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.acf.share_story_content.content,
                    }}
                  />
                  {/* TODO should this just be  link? */}
                  <Button onClick={() => window.open("/contact?mode=story")}>
                    Share Your Story
                  </Button>
                </Col>
              </Row>
            </Grid>
          </div>
        </section>

        <Element name="advisory" id="advisory">
          <Waypoint scrollableAncestor={window} topOffset={100}>
            <IndicatorWithRef committees={committees} />
          </Waypoint>
        </Element>

        <Element name="supporters" id="supporters">
          <Waypoint scrollableAncestor={window} topOffset={100}>
            {data.acf.major_supporters && data.acf.partners && (
              <SupportersWithRef
                majorSupporters={data.acf.major_supporters}
                partners={data.acf.partners}
              />
            )}
          </Waypoint>
        </Element>
      </div>
    );
  }
}

export default withRouter(Contents);
