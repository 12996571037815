import styled from "styled-components";

import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";

const { baseSpacing: bs } = defaultShevy;

const ExploreTheDataContainer = styled.div`
  transition: background-color 0.5s ease-in-out;
  background-color: #e5e8f3;

  section {
    padding: ${bs()};

    ${device.tablet} {
      padding: 0;
    }

    .title {
      position: relative;
    }
  }
`;

export default ExploreTheDataContainer;
