import styled from "styled-components";

import { device } from "styles/breakpoints";

const BarChartContainer = styled.div`
  padding: 40px 50px;
  box-shadow: 0 14px 30px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  border-radius: 4px;

  .counties {
    display: none;

    ul {
      list-style: none;
      display: flex;
      justify-content: left;
      margin: 0 0 35px;
      padding: 0 0 0 30px

      li {
        span {
          font-size: 16px;
          line-height: 30px;
          font-weight: bold;
          cursor: pointer;
          margin: 0 10px;

          &.active {
            border-bottom: 3px solid ${props => props.theme.accent.pear};
          }
        }
      }
    }
  }

  .selector-county {
    margin-bottom: 20px;
    padding-left: 40px;

    @media ${device.laptopL} {
      display: none;
    }
  }

  .chart {
    .percentage-marker {
      display: flex;
      justify-content: space-between;
      padding-left: 40px;

      span {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  @media ${device.laptopL} {
    position: absolute;
    width: calc(100% + 50px);
    margin-left: -50px;

    .counties {
      display: block;
    }
  }
`;

export default BarChartContainer;
