import styled from "styled-components";

import theme from "styles/theme";

const VerticalSliderContainer = styled.div`
  &.slick-slider {
    display: flex;
    align-items: center;
  }

  .slick-slide {
    opacity: 0.4;

    &.slick-current {
      opacity: 1;
    }
  }

  .slick-dots {
    position: static;
    width: auto;
    margin-left: 1em;

    li {
      display: block;
      height: auto;

      button,
      &.slick-active button {
        &::before {
          opacity: 1;
          color: ${theme.primary.spaceCadet};
        }
      }

      button {
        height: 2rem;

        &::before {
          font-size: 14px;
          content: " ";
          height: 0.75rem;
          width: 0.75rem;
          border-radius: 50%;
          border: 2.5px solid ${theme.primary.spaceCadet};
          line-height: 0.75rem;
        }

        &:hover {
          &::before {
            background: ${theme.secondary.ghcfBlue};
          }
        }
      }

      &.slick-active button {
        &::before {
          background: ${theme.primary.spaceCadet};
        }
      }
    }
  }
`;

export default VerticalSliderContainer;
