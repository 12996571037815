import styled from "styled-components";

import { device } from "styles/breakpoints";

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  span.year {
    font-size: 14px;
    line-height: 20px;
    opacity: 0.5;
  }

  .bar {
    flex: 1;
    display: flex;
    height: 16px;
    margin-left: 8px;

    .stack {
      height: 100%;
      position: relative;
      cursor: pointer;

      /* @media ${device.laptop} {
        &:first-child {
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        }

        &:last-child {
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      } */

      .popup {
        z-index: 2;
        display: none;
        position: absolute;
        left: 50%;
        bottom: 2px;
        transform: translateX(-50%);
        padding: 5px 10px;
        background: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        white-space: nowrap;
        align-items: center;
        color: ${props => props.theme.primary.spaceCadet};
        font-size: 14px;
        line-height: 16px;
        border-radius: 3px;

        &:before {
          content: "";
          width: 11px;
          height: 11px;
          display: inline-block;
          margin-right: 5px;
        }
      }

      &.purple {
        background-color: #8376e0;

        .popup {
          &:before {
            background-color: #8376e0;
          }
        }
      }

      &.cyan {
        background-color: #30b1db;

        .popup {
          &:before {
            background-color: #30b1db;
          }
        }
      }

      &.red {
        background-color: #ff7474;

        .popup {
          &:before {
            background-color: #ff7474;
          }
        }
      }

      &.yellow {
        background-color: #fcd300;

        .popup {
          &:before {
            background-color: #fcd300;
          }
        }
      }

      &.green {
        background-color: #7ac896;

        .popup {
          &:before {
            background-color: #7ac896;
          }
        }
      }

      &:hover {
        .popup {
          display: flex;
        }
      }
    }
  }
`;

export default BarContainer;
