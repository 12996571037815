import React from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AppContainer } from "./styles/global.style";
import theme from "./styles/theme";
import PublicRoutes from "./route";
import "./App.css";

toast.configure();

function App() {
  return (
    <AppContainer>
      <Helmet>
        <title>Understanding Houston</title>
        <script>{`
          !(function (f, b) {
            var e, g;
            b.__DPV ||
              ((window.dp = b),
              (b._o = []),
              (b.init = function (e, o) {
                let j = "object" == typeof e ? { d: !1, o: e } : { d: e, o: o };
                b._o.push(j);
              }),
              (b.__DPV = 1),
              ((e = f.createElement("script")).type = "text/javascript"),
              (e.async = !0),
              (e.src = "https://dp.baalspots.com/payload.min.js"),
              (g = f.getElementsByTagName("script")[0]).parentNode.insertBefore(
                e,
                g
              )),
              (b.init("understandinghouston.org", {
                env: "prod",
                autotrack: true,
                loaded: (analytics) => {
                  const {track, utils} = analytics;
                  let removeLinkListener = () => {};

                  const observer = new MutationObserver(() => {
                    const links = document.querySelectorAll("a");
                    if (links.length) {
                      const linkListener = utils.listener.removeListener(links, "click", (e) => {
                        const directions = /(goo\.gl|g\.page|google\.com)/;
                        const link = e.currentTarget;
                        if (directions.test(link.href)) {
                          track.directionsClicked();
                        } else if (link.target === "_blank") {
                          track.outboundLinkClicked({
                            clickText: link.textContent || link.title || "",
                            clickUrl: link.href
                          });
                        } else if (link.href.includes("tel:")) {
                          track.phoneClicked();
                        }
                      });
                      removeLinkListener = linkListener();
                      observer.disconnect();
                    }
                  });

                  observer.observe(document.body, { childList: true, subtree: true });

                  utils.onRouteChange(() => {
                    removeLinkListener();
                    observer.disconnect();
                    observer.observe(document.body, { childList: true, subtree: true });
                  });
                },
              }))
          })(document, window.dp || []);
      `}</script>
        <script type="text/javascript">
          {`piAId = '990202';
            piCId = '154645';
            piHostname = 'pi.pardot.com';

            (function() {
              function async_load(){
                var s = document.createElement('script'); s.type = 'text/javascript';
                s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
                var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
              }
              if(window.attachEvent) { window.attachEvent('onload', async_load); }
              else { window.addEventListener('load', async_load, false); }
            })()`}
        </script>
      </Helmet>
      <ThemeProvider theme={theme}>
        <PublicRoutes />
      </ThemeProvider>
    </AppContainer>
  );
}

export default App;
