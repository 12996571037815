import React from "react";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import NavigationCardContainer from "./index.style";

const NavigationCardContent = function ({ icon, heading, detail }) {
  return (
    <div className="navigation-card--content">
      <div className="navigation-card--icon">{icon}</div>
      <div className="navigation-card--about">
        <h6>{heading}</h6>
        <p className="sm">{detail}</p>
      </div>
    </div>
  );
};

const NavigationCard = function (props) {
  const { heading, detail, icon, to, isActive, isPageLink } = props;

  if (isPageLink) {
    return (
      <NavigationCardContainer
        className="navigation-card"
        as={(isActive && Link) || "div"}
        to={(isActive && to) || null}
      >
        <NavigationCardContent icon={icon} heading={heading} detail={detail} />
      </NavigationCardContainer>
    );
  }

  const scrollToElement = () => {
    if (!isActive) {
      return;
    }
    scroller.scrollTo(to, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -50
    });
  };
  return (
    <NavigationCardContainer
      className="navigation-card"
      onClick={scrollToElement}
    >
      <NavigationCardContent icon={icon} heading={heading} detail={detail} />
    </NavigationCardContainer>
  );
};

export default NavigationCard;
