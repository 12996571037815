import React, { Component } from "react";

import VideoBackgroundContainer from "./index.style";

import hero_mp4 from "assets/videos/banner.mp4";
import hero_webm from "assets/videos/banner.webm";

const playlist = [{ webm: hero_webm, mp4: hero_mp4 }];

class VideoBackground extends Component {
  constructor(props) {
    super(props);
    this.state = {
      int: 0,
      mp4: hero_mp4,
      webm: hero_webm
    };
    this.element = React.createRef();
  }

  componentDidMount() {
    this.element.current.addEventListener("ended", this);
  }

  componentWillUnmount() {
    this.element.current.removeEventListener("ended", this);
  }

  handleEvent(e) {
    this.updateVideo(this.state.int);
  }

  updateVideo(i) {
    let interval = interval > playlist.length - 1 ? 0 : i;
    this.setState({
      int: interval,
      mp4: playlist[interval].mp4,
      webm: playlist[interval].webm
    });
    this.element.current.play();
  }

  render() {
    const { mp4, webm } = this.state;
    return (
      <VideoBackgroundContainer>
        <video
          ref={this.element}
          className="background-video"
          playsInline
          autoPlay
          muted
          preload="auto"
        >
          <source src={mp4} type="video/mp4" />
          <source src={webm} type="video/webm" />
        </video>
      </VideoBackgroundContainer>
    );
  }
}

export default VideoBackground;
