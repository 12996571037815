import styled from "styled-components";
import theme from "styles/theme";
import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";
import IconFilter from "assets/images/icon-filter.svg";
import IconTime from "assets/images/icon-event-time.svg";
import IconLocation from "assets/images/icon-event-location.svg";
// import EventsContainer from "../../../landing/sections/highlightsEvents/events.style";

const { baseSpacing: bs } = defaultShevy;

const GetInvolvedEventsList = styled.div`
  @media ${device.tablet} {
    ${"" /* position: static;
    bottom: auto; */}
  }
  @media ${device.tablet} {
    .sc-brqgnP {
      ${"" /* bottom: -200px; */}
    }
  }
  margin-bottom: 76px;

  .events-slider {
    .slick-arrow {
      &.slick-prev {
        left: 0;
        right: auto;
        bottom: -96px;
        top: auto;
      }
      &.slick-next {
        left: 4em;
        right: auto;
        bottom: -96px;
        top: auto;
      }

      &.slick-prev,
      &.slick-next {
        transition: 100ms ease-in-out background, 100ms ease-in-out border-color, 100ms ease-in-out color;
        &:hover,
        &:focus {
          background: ${theme.secondary.ghcfBlue};
          border-color: ${theme.secondary.ghcfBlue};
          color: #ffffff;
        }
      }
    }
    .slide-content .event-summary p {
      font-size: 14px;
    }
  }
  .event--time,
  .event--location {
    &::before {
      width: 16px;
      display: inline-block;
      text-align: center;
    }
  }
  .event--time {
    &::before {
      content: url(${IconTime});
    }
  }
  .event--location {
    &::before {
      content: url(${IconLocation});
    }
  }
`;

const GetInvolvedEventsContainer = styled.section`
  .title {
    position: relative;
    @media ${device.tablet} {
      margin-top: 30px;
      margin-right: 80px;
      padding-bottom: 80px;
    }
  }

  .container {
    max-width: auto !important;
  }

  .events-type {
    &::before {
      content: url(${IconFilter}) "  Events";
      font-weight: 800;
      margin-right: 1em;
      @media ${device.tablet} {
        margin-right: 2em;
      }
    }
    > span {
      &:hover {
        background: ${(props) => props.theme.primary.spaceCadet};
        border-color: ${(props) => props.theme.primary.spaceCadet};
        color: ${(props) => props.theme.accent.pear};
      }
    }
    padding-left: 0;
    @media ${device.tablet} {
      padding-left: ${bs(1.5)};
    }
  }
`;

export default GetInvolvedEventsContainer;
export { GetInvolvedEventsList };
