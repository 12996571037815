import communityVoices from "./community-voices.json";
import categories from "./categories.json";

const stubs = {
  [`${process.env.REACT_APP_WP_API_URL}wp/v2/categories/?slug=community-voices`]: {
    status: 200,
    response: categories
  },
  [`${process.env.REACT_APP_WP_API_URL}wp/v2/posts/?categories=19`]: {
    status: 200,
    response: communityVoices
  }
};

export default stubs;
