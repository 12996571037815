import React from "react";
import styled from "styled-components";

import { Button } from "components/elements";
import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";
import { SectionContainer, SectionCTAContainer } from "../index.style";

const { baseSpacing: bs } = defaultShevy;

const CommunityVoicesContainer = styled(({ forwardRef, ...props }) => (
  <SectionContainer {...props} ref={forwardRef} />
))`
  padding-top: ${bs(3)};
  margin-right: -${bs()};
  background: ${props => props.theme.primary.spaceCadet};

  @media ${device.tablet} {
    padding-top: ${bs(5)};
    padding-bottom: ${bs(2)};
    margin-right: 0;
  }

  @media ${device.laptop} {
    padding-bottom: ${bs(3)};
  }

  .container {
    .inner-content {
      .title {
        position: relative;
        padding-left: ${bs(1.5)};

        @media ${device.tablet} {
          padding-left: 0;
          margin-bottom: ${bs(4.1)};
        }

        .title-left {
          h1 {
            color: #fff;
          }
        }

        ${SectionCTAContainer} {
          p {
            color: white;

            a {
              color: white;
            }
          }
        }
      }
    }
    .blog-carousel {
      .blog-viewer {
        background: #fff;

        @media ${device.tablet} {
          height: ${bs(31.75)};
          display: flex;
          margin-bottom: ${bs()};
        }

        .candidate-photo {
          height: ${bs(13.05)};
          background-color: grey;
          background-size: cover;
          background-position: center;

          @media ${device.tablet} {
            width: 50%;
            height: 100%;
          }

          @media ${device.laptopL} {
            width: ${bs(26.65)};
          }
        }

        .candidate-details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: ${bs(1.5)} ${bs(1.5)} ${bs(2)};

          @media ${device.mobileL} {
            padding: 20px;
          }

          @media ${device.tablet} {
            width: 50%;
          }

          @media ${device.laptopL} {
            flex: 1;
            width: auto;
            padding: 0 ${bs(5.4)} 0 ${bs(5.8)};
          }

          .role {
            color: ${props => props.theme.secondary.ghcfBlue};
            font-size: 0.75em;
            line-height: 1;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 0;

            @media ${device.tablet} {
              margin-bottom: ${bs()};
            }
          }

          h2 {
            color: ${props => props.theme.primary.spaceCadet};
            line-height: 1.34em;
            font-family: "Playfair Display", serif;
            font-weight: 900;
          }

          h6 {
            color: ${props => props.theme.primary.spaceCadet};
            font-weight: 900;

            @media ${device.tablet} {
              font-size: 1.4em;
            }
          }

          .comment {
            color: ${props => props.theme.primary.spaceCadet};
            line-height: 30px;
            margin-bottom: ${bs(2)};
          }

          .actions {
            display: flex;
            flex-wrap: wrap;

            ${Button} {
              margin-right: 0.68em;
              height: ${bs(2.4)};
              font-size: 0.72em;

              &.btn-readmore {
                @media ${device.tablet} {
                  display: flex;
                }
              }
            }
          }
        }
      }

      .slider {
        padding: ${bs(0.9)} 0 ${bs(1.1)} ${bs(1)};
        background-color: rgba(255, 255, 255, 0.9);

        @media ${device.mobileL} {
          padding: 0;
          background-color: initial;
        }

        .slick-list {
          @media ${device.tablet} {
            overflow-x: hidden;
            height: 280px;
          }

          .slick-track {
            display: flex;
            margin-left: 0;

            .slick-slide {
              line-height: 1;
              padding-right: ${bs(0.5)};

              @media ${device.mobileL} {
                padding-right: 0;
              }

              .slide-content {
                height: ${bs(3)};
                width: ${bs(3)};
                background-size: cover;
                background-position: center;
                position: relative;
                opacity: 0.6;

                @media ${device.mobileL} {
                  height: ${bs(10)};
                  width: auto;
                }

                .slide-border {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  height: 6px;
                  background: ${props => props.theme.accent.pear};
                  display: none;
                }

                .name {
                  position: absolute;
                  bottom: 0px;
                  text-align: center;
                  display: none;
                  width: 100%;
                  color: white;
                  transform: translateY(100%);
                  padding-top: ${bs(0.6)};

                  img {
                    margin: 0 auto 8px;
                  }

                  p {
                    border-bottom: 2px solid ${props => props.theme.accent.pear};
                    display: inline-block;
                    line-height: 21px;
                    font-weight: 900;
                  }
                }

                &.active {
                  opacity: 1;

                  .slide-border {
                    display: block;
                  }

                  .name {
                    @media ${device.mobileL} {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    ${SectionCTAContainer} {
      &.laptop-hidden {
        height: 8em;
        padding-left: ${bs(1.5)};
        display: flex;
        align-items: center;

        @media ${device.tablet} {
          display: none;
        }

        &:before {
          left: 0;
          width: ${bs(1)};
          transform: translate(0, -50%);
        }

        p {
          margin: 0;
          color: #fff;

          a {
            color: #fff;
          }
        }
      }
    }
  }
`;

export default CommunityVoicesContainer;
