import React, { createContext, useReducer } from "react";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
import keys from "lodash/keys";
import zipObject from "lodash/zipObject";
import constant from "lodash/constant";

const ContentContext = createContext();

const initialState = {
  data: {},
  status: {},
  errors: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return initialState;
    case "set-data":
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        },
        status: {
          ...state.status,
          ...mapValues(action.payload, constant("complete"))
        },
        errors: {
          ...omit(state.errors, keys(action.payload))
        }
      };
    case "set-status":
      return {
        ...state,
        status: {
          ...state.status,
          ...action.payload
        },
        data: {
          ...omit(state.data, keys(action.payload))
        },
        errors: {
          ...omit(state.errors, keys(action.payload))
        }
      };
    case "set-loading":
      return {
        ...state,
        status: {
          ...state.status,
          ...zipObject(action.payload, ["loading"])
        }
      };
    case "set-errors":
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload
        },
        status: {
          ...state.status,
          ...mapValues(action.payload, constant("error"))
        },
        data: {
          ...omit(state.data, keys(action.payload))
        }
      };
    default:
      return state;
  }
};

function ContentProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  // window.STATE = state
  return (
    <ContentContext.Provider value={value}>
      {props.children}
    </ContentContext.Provider>
  );
}

export { ContentProvider };
export const ContentConsumer = ContentContext.Consumer;
export default ContentContext;
