import styled from "styled-components";
import { rgba } from "polished";
import { defaultShevy } from "styles/global.style";
import theme from "styles/theme";
import { device } from "styles/breakpoints";
const { baseSpacing: bs } = defaultShevy;
const lightGHCFBlueBG = rgba(theme.secondary.ghcfBlue, 0.1);

const WaysContainer = styled.section`
  .title {
    position: relative;
    @media ${device.tablet} {
      margin-left: 25%;
    }
  }
  .get-involved--ways-banner {
    background: ${lightGHCFBlueBG};
    background: linear-gradient(${lightGHCFBlueBG} 50%, transparent 50%);
  }
  .container,
  .container-fluid {
    img {
      width: 100%;
    }
  }
  .get-involved--ways-about {
    padding: 72px 0;

    p {
      font-size: 24px;
      line-height: 38px;
      margin-top: 0.5em;
    }
    .about {
      @media ${device.laptop} {
        margin-top: -20px;
      }

      p {
        font-size: 18px;
        line-height: 30px;
        margin-top: 0.5em;
      }

      a {
        font-size: 18px;
      }
    }
  }

  .panel--host-a-data-party {
    img {
      margin-left: -2rem;
      margin-bottom: -1.5rem;
      width: calc(100% + 2rem);

      @media ${device.tablet} {
        margin: 0;
      }
    }
  }

  .tab-panel {
    margin-top: ${bs(2)};

    @media ${device.laptopL} {
      margin-top: ${bs(3.45)};
    }

    button {
      font-size: 18px;
    }

    p {
      font-size: 18px;
      line-height: 38px;
      margin-top: 0.5em;
    }

    a {
      font-size: 18px;
    }
  }

  [role="tabpanel"] {
    outline: none;

    @media ${device.tablet} {
      height: 440px;
    }
    @media ${device.laptopL} {
      height: 520px;
    }
    &.panel--share-your-story {
      img {
        max-width: 75%;
      }
    }
    &.panel--host-a-data-party {
      .image-wrapper {
        @media ${device.tablet} {
          padding: 0;
          margin-left: -1rem;
        }

        img {
          margin-left: -2rem;
          margin-bottom: -2rem;

          @media ${device.tablet} {
            margin: 0;
          }
        }
      }
      .about {
        @media ${device.laptop} {
          margin-top: -60px;
        }

        p {
          font-size: 18px;
          line-height: 38px;
          margin-top: 0.5em;
        }

        a {
          font-size: 18px;
        }
      }
    }
    &.panel--start-voting {
      > div {
        @media ${device.laptopL} {
          padding-top: 1em;
        }
      }
      .slick-list {
        padding: 0 !important;
      }
      .get-involved--start-voting-img-wrapper {
        height: 114.78px;
        @media ${device.laptopL} {
          height: 134.75px;
        }
      }
      img {
        margin-top: 1.3%;
        margin-bottom: -1.3%;
      }
    }
  }
`;

export default WaysContainer;
