import React from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Slider from "react-slick";
import { Element } from "react-scroll";
import _map from "lodash/map";
import { SectionTitle, SectionPointer } from "components/elements";
import { NextArrow, PrevArrow } from "components/arrows";
import { selectStyles } from "styles/global.style";
import { TopicMenuProvider } from "components";
import ExploreTopicsContianer from "./index.style";
import { SectionCTAContainer } from "../index.style";

// TODO Refactor landing page and big picture page explore topic cards as one common component
const ExploreTopicsDisplay = (props) => {
  const history = useHistory();
  const { topics } = props.content.topicsAndSubtopicsMenu;
  const topicSelectorOptions = _map(topics, (topic) => ({
    value: topic.slug,
    label: topic.title.rendered,
  }));
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    centerMode: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          variableWidth: false,
        },
      },
    ],
  };

  function onTopicChange(topic) {
    history.push(`/topic/${topic}`);
  }

  return (
    <Element name="explore-topics">
      <ExploreTopicsContianer
        className="section explore-topics"
        forwardRef={props.innerRef}
      >
        <div className="left-bg" />
        <div className="container">
          <div className="inner-content">
            <div className="title">
              <div className="title-left">
                <SectionPointer firstColor="#7AC896" secondColor="#159DBF" />
                <SectionTitle color="white">Explore the data</SectionTitle>
                <label
                  id="explore-topics-label"
                  className="visually-hidden"
                  htmlFor="select-topic"
                >
                  Select data to explore
                </label>
                <Select
                  styles={selectStyles}
                  options={topicSelectorOptions}
                  className="selector selector-topic"
                  placeholder="Select data"
                  classNamePrefix="react-select"
                  onChange={(e) => onTopicChange(e.value)}
                  aria-labelledby="explore-topics-label"
                  inputId="select-topic"
                />
              </div>
              <SectionCTAContainer className="mobile-hidden explore-faq">
                <p>
                  <b>Getting started</b>: Read our most <br />{" "}
                  <Link to="/faqs">
                    <b>frequently asked questions.</b>
                  </Link>
                </p>
              </SectionCTAContainer>
            </div>
            <Slider {...settings}>
              {!!topics.length &&
                topics.map((topic, index) => (
                  <article key={index + topic.slug}>
                    <Link to={`/topic/${topic.slug}`}>
                      <div
                        className="slide-content"
                        style={{
                          backgroundImage: `url(${
                            topic.better_featured_image
                              ? topic.better_featured_image.source_url
                              : ""
                          })`,
                        }}
                      >
                        <div
                          className="slide-content-mobile-image"
                          style={{
                            backgroundImage: `url(${
                              topic.better_featured_image
                                ? topic.better_featured_image.source_url
                                : ""
                            })`,
                          }}
                        />
                        <div
                          className="description"
                          style={{
                            borderBottom: `6px solid ${topic.acf.topic_border_color}`,
                          }}
                        >
                          <h3>{topic.title.rendered}</h3>
                          <p className="xs">{topic.acf.topic_summary}</p>
                        </div>
                      </div>
                    </Link>
                  </article>
                ))}
            </Slider>
          </div>
          <SectionCTAContainer className="laptop-hidden">
            <p>
              <b>Getting started</b>:<br /> Read our most <br />{" "}
              <Link to="/faqs">
                <b>frequently asked questions.</b>
              </Link>
            </p>
          </SectionCTAContainer>
        </div>
      </ExploreTopicsContianer>
    </Element>
  );
};

const ExploreTopics = (props) => {
  return (
    <TopicMenuProvider>
      <ExploreTopicsDisplay {...props} />
    </TopicMenuProvider>
  );
};

export default ExploreTopics;
