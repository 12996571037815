import styled from "styled-components";

const MobileMenuContainer = styled.div`
  /* .menu-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2.18em;
    background-color: ${props => props.theme.primary.spaceCadet};
    z-index: 99;
  } */

  .open {
    cursor: pointer;
  }

  &.opened {
    .rpm-container {
      z-index: 98;
    }
  }

  &.closed {
    .rpm-container {
      z-index: -1;
    }
  }

  .rpm-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 100px;
    height: 100%;
    min-height: auto;

    .rpm-trigger {
      position: fixed;
      bottom: 0;
      left: 0;
    }

    .rpm-mp-level {
      background: #fff;
      padding: 20px 20px 0 30px;

      .rpm-mp-header {
        padding: 0;
        margin-bottom: 62px;
        line-height: 1;
      }
    }

    .rpm-mp-menu a,
    .rpm-node-exp {
      color: ${props => props.theme.primary.spaceCadet};
      padding: 0;
      background: transparent;
      box-shadow: none;
      font-size: 0.72em;
      font-weight: 500;
    }

    .rpm-mp-menu ul li > .rpm-node-cntr {
      box-shadow: none;
      text-shadow: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1em;

      > div {
        width: 100%;
      }

      &.parent-menu-item {
        a {
          text-transform: uppercase;
          font-weight: bold;
        }
      }

      a {
        padding: 0;
        font-size: 0.72em;
        width: initial;
        font-weight: 500;
      }
    }

    .rpm-scroller {
      overflow-y: auto;
      /* position: absolute;
      top: auto;
      left: 0;
      z-index: 1;
      width: 300px;
      height: 3em;
      min-height: 3em;
      bottom: 0;
      transform: translate3d(-100%, 0, 0); */
    }

    .rpm-mp-bottom-menu {
      height: 2.18em;
      padding: 46px 30px;
      display: flex;
      align-items: center;


      ul {
        li {
          a {
            color: #b0b6bd;
            background-color: #0d2440;
            border-radius: 50%;
          }

          &.btn-search {
            a {
              background-color: transparent;
              border: 1px solid #042442;
              color: ${props => props.theme.primary.spaceCadet};
            }
          }
        }
      }
    }

    .rpm-mp-back {
      margin-bottom: 10px;
      background: transparent;
      box-shadow: none;

      .rpm-back-item {
        padding: 0;
        margin-right: 6px;
      }
    }
  }

  .btn-trigger {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: none;
    background-color: ${props => props.theme.primary.pantoneRed};
    right: 20px;
    bottom: 24px;
    position: fixed;
    z-index: 99;

    .bar {
      width: 21px;
      height: 3px;
      background-color: white;
      margin: 4px auto;
      transition: 0.4s;
    }

    &.close .bar1 {
      -webkit-transform: rotate(-45deg) translate(-5px, 5px);
      transform: rotate(-45deg) translate(-5px, 5px);
    }

    &.close .bar2 {
      opacity: 0;
    }

    &.close .bar3 {
      -webkit-transform: rotate(45deg) translate(-5px, -5px);
      transform: rotate(45deg) translate(-5px, -5px);
    }
  }

  .search-component {
    .search-field {
      position: relative;
      margin-bottom: 40px;

      input {
        height: 60px;
        width: 100%;
        background-color: #F8F8F8;
        padding-left: 22px;
        border: none;
        font-size: 18px;
        outline: none;
      }

      img {
        position: absolute;
        right: 17px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    p {
        padding: 0 45px;
      }
  }

  @media screen and (min-width: 1270px) {
    display: none;
  }
`;

export default MobileMenuContainer;
