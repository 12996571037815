import styled from "styled-components";

const VideoBackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;

  .background-video {
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
  }
`;

export default VideoBackgroundContainer;
