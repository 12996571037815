import React from "react"
import { Col, Row } from "react-styled-flexboxgrid"
import { Link as RouterLink } from "react-router-dom"

import sortBy from "lodash/sortBy"
import isEmpty from "lodash/isEmpty"
import map from "lodash/map"
import difference from "lodash/difference"
import filter from "lodash/filter"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVolumeUp, faPlay } from "@fortawesome/free-solid-svg-icons"
import { Button } from "components/elements"
import { Content, WPContent } from "components"
import defaultCommunityVoices from "helpers/content-stubs/community-voices.json"

import BlogHighlightContainer, { BlogHighlightContent } from "./index.style"

function rankByStickyOrMostRecent(post) {
  if (post.sticky) {
    return 1
  }

  const publishedTimestamp = new Date(post.date_gmt).getTime()
  const now = new Date().getTime()

  return publishedTimestamp / now
}

const BlogHighlightItem = (props) => {
  const backgroundStyle =
    (props.blogHighlight.better_featured_image && {
      backgroundImage: `url(${props.blogHighlight.better_featured_image.source_url})`,
    }) ||
    {}

  return (
    <BlogHighlightContainer as={Row} className="container blog-highlight">
      <Col xsOffset={0} xs={12} sm={11.5} smOffset={0.25}>
        <BlogHighlightContent as={Row}>
          <Col
            xs={12}
            xsOffset={0}
            sm={5}
            smOffset={0}
            className="blog-highlight--featured-image"
            style={backgroundStyle}
          />
          <Col
            xs={12}
            xsOffset={0}
            sm={6}
            smOffset={0.5}
            className="blog-highlight--excerpt"
          >
            <p className="role">{props.blogHighlight.acf.role}</p>
            <h2 className="sm">{props.blogHighlight.title.rendered}</h2>
            <WPContent>{props.blogHighlight.excerpt.rendered}</WPContent>
            <div className="actions">
              {props.audio && (
                <Button>
                  <FontAwesomeIcon icon={faVolumeUp} />
                  &nbsp;Audio
                </Button>
              )}
              {props.video && (
                <Button>
                  <FontAwesomeIcon icon={faPlay} />
                  &nbsp;Video
                </Button>
              )}
              <Button
                default
                className="btn-readmore"
                as={RouterLink}
                to={`/blog/${props.blogHighlight.slug}`}
              >
                Read more
              </Button>
            </div>
          </Col>
        </BlogHighlightContent>
      </Col>
    </BlogHighlightContainer>
  )
}

const BlogHighlightDisplay = (props) => {
  const blogs =
    (isEmpty(props.content["blog-highlights"]) &&
      props.defaultContent["blog-highlights"]) ||
    props.content["blog-highlights"]

  const mostRelevantBlogs = filter(blogs, (blog) =>
    isEmpty(difference(blog.categories, props.blogCategoryIds))
  )

  if (isEmpty(mostRelevantBlogs)) {
    return null
  }

  const sortedBlogs = sortBy(mostRelevantBlogs, rankByStickyOrMostRecent)
  const blogHighlight = sortedBlogs[0]

  return <BlogHighlightItem blogHighlight={blogHighlight} />
}

const CommunityVoicesDisplay = (props) => {
  const blogCategories = props.content[`blog-categories--${props.topicSlug}`]
  if (isEmpty(blogCategories)) {
    return null
  }
  const blogCategoryIds = map(blogCategories, "id")
  const blogHighlightGetters = [
    {
      path: `posts/?per_page=100&categories=${blogCategoryIds.join(",")}`,
      name: "blog-highlights",
    },
  ]

  return (
    <div className="community-voices--wrapper">
      <Content getters={blogHighlightGetters} useMocks={props.useMocks}>
        <BlogHighlightDisplay
          blogCategoryIds={blogCategoryIds}
          defaultContent={{ "blog-highlights": defaultCommunityVoices }}
          error={
            <BlogHighlightDisplay
              content={{ "blog-highlights": defaultCommunityVoices }}
            />
          }
        />
      </Content>
    </div>
  )
}

const CommunityVoicesHighlight = (props) => {
  return (
    <Content
      getters={[
        {
          path: `categories/?per_page=100&slug=community-voices,${props.topicSlug}`,
          name: `blog-categories--${props.topicSlug}`,
        },
      ]}
    >
      <CommunityVoicesDisplay
        {...props}
        content={{
          [`blog-categories--${props.topicSlug}`]: [],
        }}
      />
    </Content>
  )
}

const CommunityVoicePostAcf = (props) => {
  const backgroundStyle =
    (props.content.better_featured_image && {
      backgroundImage: `url(${props.content.better_featured_image.source_url})`,
    }) ||
    {}

  return (
    <div className="community-voices--wrapper">
      <BlogHighlightContainer as={Row} className="container blog-highlight">
        <Col xsOffset={0} xs={12} sm={11.5} smOffset={0.25}>
          <BlogHighlightContent as={Row}>
            <Col
              xs={12}
              xsOffset={0}
              sm={5}
              smOffset={0}
              className="blog-highlight--featured-image"
              style={backgroundStyle}
            />
            <Col
              xs={12}
              xsOffset={0}
              sm={6}
              smOffset={0.5}
              className="blog-highlight--excerpt"
            >
              <p className="role">{props.content.acf.role}</p>
              <h2 className="sm">{props.content.title.rendered}</h2>
              <WPContent>{props.content.excerpt.rendered}</WPContent>
              <div className="actions">
                {props.audio && (
                  <Button>
                    <FontAwesomeIcon icon={faVolumeUp} />
                    &nbsp;Audio
                  </Button>
                )}
                {props.video && (
                  <Button>
                    <FontAwesomeIcon icon={faPlay} />
                    &nbsp;Video
                  </Button>
                )}
                <Button
                  default
                  className="btn-readmore"
                  as={RouterLink}
                  to={`/blog/${props.content.slug}`}
                >
                  Read more
                </Button>
              </div>
            </Col>
          </BlogHighlightContent>
        </Col>
      </BlogHighlightContainer>
    </div>
  )
}

export {
  CommunityVoicesHighlight,
  CommunityVoicesDisplay,
  BlogHighlightItem,
  CommunityVoicePostAcf,
}

export default BlogHighlightDisplay
