import React, { Component } from "react";
import { Row, Col } from "react-styled-flexboxgrid";
import { withRouter } from "react-router-dom";
import { WPContent } from "components";
import { getPageWithIdAPI } from "services/helpers/apis";
import TermsofServiceContainer from "./index.style";

const TermsofServicesPageId = "3705";

class TermsOfService extends Component {

  state = {
    title: "",
    content: "",
    date: ""
  };

  async componentDidMount() {
    try {
      const getTermsofServicesPage = await getPageWithIdAPI(TermsofServicesPageId);
      this.setState({
        content: getTermsofServicesPage.data.content
          ? getTermsofServicesPage.data.content
          : null,
        title: getTermsofServicesPage.data.title
          ? getTermsofServicesPage.data.title
          : null,
        date: getTermsofServicesPage.data.date
          ? getTermsofServicesPage.data.date
          : null
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { title, date, content } = this.state;
    return (
      <TermsofServiceContainer className="container">
        <section>
          <Row>
            <Col xs={12} sm={12} lg={9} lgOffset={1}>
              <h1 className="title">
                <WPContent>
                  {title.rendered}
                </WPContent>
              </h1>
              <div className="last-updated-date">
                <p>
                  <WPContent>
                    {"Last Updated: " + date.substr(0, 4) + "/" + date.substring(5, 7) + "/" + date.substring(8, 10)}
                  </WPContent>
                </p>
              </div>
              <WPContent>
                {content.rendered}
              </WPContent>
            </Col>
          </Row>
        </section>
      </TermsofServiceContainer>
    );
  }
}

export default withRouter(TermsOfService);