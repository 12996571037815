import styled from "styled-components";
import { rgba } from "polished";

import theme from "styles/theme";
import { device } from "styles/breakpoints";

import BackgroundImage from "assets/images/background-faqs.jpg";

import { CardShadow } from "components/card/index.style";

const MINUS_SPACING = 1260 / 2 - 16;
const calculateFixedContactWidth = width => (1 / 6) * width + 16;

const FAQsNavBefore = styled.span`
  color: ${theme.secondary.vividGamboge};
  font-weight: 800;
  border: 2px solid ${theme.secondary.vividGamboge};
  border-radius: 50%;
  text-align: center;
  content: "?";
`;

const FAQsNavContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin-bottom: 1em;
  align-items: center;
  padding: 1em;

  @media ${device.laptopL} {
    align-items: flex-start;
  }

  &:hover,
  &.active {
    ${CardShadow.componentStyle.rules}
    border-radius: 4px;
  }

  &::before {
    ${FAQsNavBefore.componentStyle.rules}
    width: 2em;
    height: 2em;
    line-height: 2em;
    margin-top: 0.5em;
    margin-right: 1em;
    display: none;

    @media ${device.laptopL} {
      display: block;
    }
  }

  .faqs-categories-nav--item {
    flex: 1;
  }
  .faqs-categories-nav--title {
    font-weight: 800;
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;

    @media ${device.laptopL} {
      margin-bottom: 0;
    }

    span {
      flex: 1;
    }

    &::before {
      ${FAQsNavBefore.componentStyle.rules}
      font-size: 0.75em;
      width: 1.5em;
      height: 1.5em;
      line-height: 1.5em;
      margin-right: 0.5em;

      @media ${device.laptopL} {
        display: none;
      }
    }
  }
`;

const FAQsContainer = styled.div`
  min-height: 800px;

  .sidebar-column {
    display: none;
    @media ${device.laptop} {
      display: block;
    }
  }

  .sticky .sidebar {
    margin-top: 100px;
  }

  .faqs-category {
    margin: 3em 0;

    &:first-child {
      margin-top: 1.5em;
    }
  }

  .faqs-header {
    min-height: 200px;
    background: ${theme.primary.spaceCadet};
    background-image: url(${BackgroundImage});
    background-size: cover;
    color: #ffffff;

    h6 {
      text-transform: uppercase;
    }
  }

  .faqs-content,
  .faqs-header {
    padding: 2em 0;
  }

  .faqs-header {
    @media ${device.tablet} {
      padding: 4em 0;
    }
  }

  .faqs-questions--list,
  .faqs-categories-nav--list {
    list-style: none;
    padding-left: 0;
  }

  .text-center {
    text-align: center;
  }

  .faqs-questions--list-item {
    border-bottom: 1px solid #ccc;
    display: block;
  }

  .faqs-questions--list-item-question {
    padding: 1em 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;

    span {
      flex: 1;
    }

    &::after {
      font-weight: 200;
      font-size: 0.75em;
      content: "+";
      border: 1px solid ${theme.primary.spaceCadet};
      border-radius: 50%;
      width: 1.25em;
      height: 1.25em;
      text-align: center;
      line-height: 1.25em;
    }
  }

  .faqs-questions--list-item-answer {
    overflow: hidden;
    transition: 200ms linear max-height;
    height: auto;
    max-height: 0;
  }

  .is-active {
    .faqs-questions--list-item-question {
      &::after {
        content: "—";
      }
    }
  }

  ul + p {
    margin-top: 1em;
  }

  .contact-us--wrapper {
    @media ${device.tablet} {
      background: ${theme.secondary.deepKoamaru};
      background: linear-gradient(
        90deg,
        transparent calc(50% - ${MINUS_SPACING}px),
        ${rgba(theme.secondary.deepKoamaru, 0.1)} calc(50% - ${MINUS_SPACING}px),
        ${rgba(theme.secondary.deepKoamaru, 0.1)}
          calc(${calculateFixedContactWidth(960)}px + 50%),
        ${theme.secondary.deepKoamaru}
          calc(${calculateFixedContactWidth(960)}px + 50%)
      );
    }

    @media ${device.laptopL} {
      background: linear-gradient(
        90deg,
        transparent calc(50% - ${MINUS_SPACING}px),
        ${rgba(theme.secondary.deepKoamaru, 0.1)} calc(50% - ${MINUS_SPACING}px),
        ${rgba(theme.secondary.deepKoamaru, 0.1)}
          calc(${calculateFixedContactWidth(1260)}px + 50%),
        ${theme.secondary.deepKoamaru}
          calc(${calculateFixedContactWidth(1260)}px + 50%)
      );
    }

    .contact-us--about {
      background: ${rgba(theme.secondary.deepKoamaru, 0.1)};

      @media ${device.tablet} {
        background: transparent;
      }
    }

    .contact-us--location-wrapper {
      background: ${theme.secondary.deepKoamaru};

      @media ${device.tablet} {
        background: transparent;
      }
    }

    margin-bottom: 4em;
    margin-top: 4em;
  }
`;

export { FAQsNavContainer };

export default FAQsContainer;
