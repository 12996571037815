import styled from "styled-components";
import Shevy from "shevyjs";

import HeaderContainer from "components/header/index.style";
import { device } from "styles/breakpoints";

const defaultShevyOptions = {
  baseFontSize: "22px",
  baseLineHeight: 1.25,
  baseFontScale: [3.45, 2.27, 1.77, 1.5, 1.25, 1.09],
  addMarginBottom: true,
  proximity: true,
  proximityFactor: 0.72,
};

export const defaultShevy = new Shevy(defaultShevyOptions);

export const mobileShevy = new Shevy({
  ...defaultShevyOptions,
  baseFontScale: [2.36, 1.9, 1.36, 1.5, 1.25, 1.09],
});

const { baseSpacing: bs } = defaultShevy;

export const AppContainer = styled.div`
  line-height: ${defaultShevy.baseLineHeight};
  font-size: ${defaultShevy.baseFontSize};

  .hidden {
    display: none;
  }

  h1 {
    font-size: ${mobileShevy.h1.fontSize};
    margin: 0 0 ${defaultShevy.baseSpacing()};

    @media ${device.tablet} {
      font-size: ${defaultShevy.h1.fontSize};
    }
  }

  h2 {
    font-size: ${mobileShevy.h2.fontSize};
    margin: 0 0 ${defaultShevy.baseSpacing()};

    &.sm {
      font-size: 1.45em;
    }

    @media ${device.tablet} {
      font-size: ${defaultShevy.h2.fontSize};

      &.sm {
        font-size: 1.9em;
      }
    }
  }

  h3 {
    font-size: ${mobileShevy.h3.fontSize};
    font-weight: 900;
    margin: 0 0 ${defaultShevy.baseSpacing()};

    @media ${device.tablet} {
      font-size: ${defaultShevy.h3.fontSize};
    }
  }

  h4 {
    font-size: ${mobileShevy.h4.fontSize};
    margin: 0 0 ${defaultShevy.baseSpacing()};

    @media ${device.tablet} {
      font-size: ${defaultShevy.h4.fontSize};
    }
  }

  h5 {
    font-size: ${mobileShevy.h5.fontSize};
    margin: 0 0 ${defaultShevy.baseSpacing()};

    @media ${device.tablet} {
      font-size: ${defaultShevy.h5.fontSize};
    }
  }

  h6 {
    font-size: ${mobileShevy.h6.fontSize};
    margin: 0 0 ${defaultShevy.baseSpacing()};

    @media ${device.tablet} {
      font-size: ${defaultShevy.h6.fontSize};
    }
  }

  p {
    margin: 0 0 ${defaultShevy.baseSpacing()};

    &.xs {
      font-size: 0.72em;
      line-height: 1.5;
    }

    &.sm {
      font-size: 0.81em;
    }

    &.md {
      font-size: 1.09em;
    }
  }

  button {
    outline: none;
  }
`;

export const Wrapper = styled.div`
  color: ${(props) => props.theme.primary.spaceCadet};

  #header.subtopic {
    .headroom-wrapper {
      .headroom {
        @media screen and (min-width: 1270px) {
          position: absolute;
        }
      }
    }
  }

  .headroom-wrapper {
    background: white;
    margin: 0 -${bs()};

    .headroom {
      top: 0;
      left: 0;
      right: 0;
      box-shadow: 0 1px 2.2px rgba(0, 0, 0, 0.008), 0 2.3px 5.3px rgba(0, 0, 0, 0.012),
        0 4.4px 10px rgba(0, 0, 0, 0.015), 0 7.8px 17.9px rgba(0, 0, 0, 0.018),
        0 14.6px 33.4px rgba(0, 0, 0, 0.022), 0 35px 80px rgba(0, 0, 0, 0.03);
      position: absolute;

      @media screen and (min-width: 1270px) {
        z-index: 99;
        position: fixed;
        transition: transform 500ms ease-in-out;
      }
    }

    .headroom--unfixed {
      transform: translateY(0);
    }

    .headroom--scrolled {
      ${HeaderContainer} {
        @media screen and (min-width: 1270px) {
          height: 80px;
        }
      }
    }

    @media screen and (min-width: 1270px) {
      .headroom--unpinned {
        transform: translateY(-100%);
      }
      .headroom--pinned {
        transform: translateY(0%);
      }
    }
  }

  .container {
    width: 100%;
    margin: 0 auto;

    @media ${device.tablet} {
      padding: 0;

      .inner-content {
        padding-left: 3em;
      }
    }

    @media ${device.laptop} {
      max-width: 960px;

      .inner-content {
        padding-left: 4em;
      }
    }

    @media ${device.laptopL} {
      max-width: 1260px;
    }
  }

  .selector {
    width: 100%;
    font-size: 0.72em;

    .react-select__control {
      height: 49px;
      border: 1px solid #ececec;

      .react-select__value-container {
        padding-left: 25.5px;
      }

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__dropdown-indicator {
        color: ${(props) => props.theme.primary.spaceCadet};
        padding-right: 29px;
      }
    }

    @media ${device.tablet} {
      width: 361px;
      max-width: 100%;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  #main-content {
    position: relative;
  }
  .wp-block-image {
    width: 75%;
    margin: 30px auto;

    @media ${device.tablet} {
      width: 100%;
    }

    img {
      max-width: 100%;
      height: auto !important;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    figcaption {
      text-align: center;
      font-size: 13px !important;
      line-height: 28px !important;

      small {
        font-size: 13px !important;
        line-height: 28px !important;

        a {
          font-size: 13px !important;
          line-height: 28px !important;
        }
      }

      a {
        font-size: 13px !important;
        line-height: 28px !important;
      }
    }
  }
`;

export const selectStyles = {
  placeholder: () => ({
    color: "#112E51",
    fontSize: "16px",
    fontWeight: "bold",
  }),
};
