import React, { Component } from "react";
import { Grid, Col, Row } from "react-styled-flexboxgrid";

import { SectionTitle, SectionPointer } from "components/elements";

import SupportersContaienr from "./index.style";

const Logos = ({ logos }) => {
  return (
    <div className="logos-content">
      {logos.map((logo, index) => (
        <div key={index} className="logo">
          <div className="card">
            <img src={logo.logo.url} alt="logo" />
          </div>
        </div>
      ))}
    </div>
  );
};

const Names = ({ names }) => {
  return (
    <div className="names-content">
      {names.map((name, index) => (
        <p key={index}>{name.name}</p>
      ))}
    </div>
  );
};

class Supporters extends Component {
  state = {
    option: "supporters",
  };

  componentDidMount() {}

  onChangeOption = (type) => {
    this.setState({
      option: type,
    });
  };

  render() {
    const { option } = this.state;
    const { majorSupporters, partners } = this.props;

    return (
      <SupportersContaienr ref={this.props.innerRef}>
        <div className="container">
          <Grid>
            <Row>
              <Col xs={12} mdOffset={1} lgOffset={0} md={11} lg={12}>
                <div className="title">
                  <SectionPointer firstColor="#7AC896" secondColor="#DDD12A" />
                  <SectionTitle>
                    Major supporters <br /> and partners
                  </SectionTitle>
                  <div className="toggler">
                    <span
                      className={`${option === "supporters" && "active"}`}
                      onClick={() => this.onChangeOption("supporters")}
                    >
                      Major supporters
                    </span>
                    <span
                      className={`${option === "partners" && "active"}`}
                      onClick={() => this.onChangeOption("partners")}
                    >
                      Our partners
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Grid>

          {option === "supporters" ? (
            <div className="tab-content">
              <div className="section section-1">
                <h6>{majorSupporters.section_1.title}</h6>
                <Logos logos={majorSupporters.section_1.logos} />
              </div>
              <div className="section section-2">
                <h6>{majorSupporters.section_2.title}</h6>
                <Logos logos={majorSupporters.section_2.logos} />
              </div>
              <div className="section section-3">
                <h6>{majorSupporters.section_3.title}</h6>
                <Names names={majorSupporters.section_3.names} />
              </div>
              <div className="section section-4">
                <h6>{majorSupporters.section_4.title}</h6>
                <Names names={majorSupporters.section_4.names} />
              </div>
            </div>
          ) : (
            <div className="tab-content">
              <div className="section">
                <Logos logos={partners.logos} />
              </div>
            </div>
          )}

          {/* <p className='text-bottom'>
            <Link
              href='https://api.understandinghouston.org/wp-content/uploads/2019/11/1119_80uhou_Thank-you.pdf'
              target='_blank'
            >
              + Many generous donors
            </Link>
          </p> */}
        </div>
      </SupportersContaienr>
    );
  }
}

export default Supporters;
