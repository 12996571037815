import styled from "styled-components";
import { rgba } from "polished";

import { defaultShevy } from "styles/global.style";
import { device, sizes } from "styles/breakpoints";

import TopicContainerBase from "components/topic-page-base/index.style";

const { baseSpacing: bs } = defaultShevy;

const TopicContainer = styled(TopicContainerBase)`
  .mobile-hidden {
    display: none;

    @media ${device.laptopL} {
      display: block;
    }
  }
  .topic--subtopics {
    background-color: ${(props) => rgba(props.theme.primary.spaceCadet, 0.1)};
    padding-top: 6em;
    padding-bottom: 6em;
  }

  .topic-subtopic-stat {
    border-left: 6px solid transparent;
    padding-left: 1rem;
  }

  .wp-content {
    h2 {
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      font-size: 1.8em;
    }
  }

  .nav-wrapper {
    @media ${device.laptop} {
      padding-right: 0;
    }
  }

  nav {
    ul {
      padding-left: ${bs(2)};
    }
  }

  .inner-wrapper {
    padding: 0;
  }

  .community-voices {
    .content-wrapper--loaded {
    }
  }

  .banner,
  nav {
    transition: 200ms ease-in-out background;
  }

  .community-voices--wrapper {
  }

  .topic--subtopics > div,
  .blog-highlight {
    @media (max-width: ${sizes.laptop}px) {
      max-width: 90%;
      margin: auto;
    }
  }
`;

export default TopicContainer;
