import React, { useContext, useEffect } from "react";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
import { Events } from "react-scroll";

import _kebabCase from "lodash/kebabCase";

import { ReactComponent as IconVote } from "assets/images/get-involved--vote.svg";
import { ReactComponent as IconCalendar } from "assets/images/get-involved--calendar.svg";
import { ReactComponent as IconFeedback } from "assets/images/get-involved--feedback.svg";
import { ReactComponent as IconNotes } from "assets/images/get-involved--notes.svg";
import { ReactComponent as IconDonate } from "assets/images/get-involved--donate.svg";
import { ReactComponent as IconData } from "assets/images/icon-host-data-party.svg";

import NavContext from "./context";
import NavigationContainer from "./index.style";
import NavigationCard from "../navigation-card";

const topActions = [
  {
    heading: "Start voting",
    detail: "Vote for what matters to you.",
    icon: <IconVote viewBox="-1 -1 69 69" />,
    to: "start-voting"
  },
  {
    heading: "Give feedback",
    detail: "Help us improve.",
    icon: <IconFeedback viewBox="-1 -1 69 69" />,
    to: "give-feedback"
  },
  {
    heading: "Share your story",
    detail: "Tell us your data story.",
    icon: <IconNotes viewBox="-1 -1 70 62" />,
    to: "share-your-story"
  },
  {
    heading: "Attend an event",
    detail: "Find what peaks your interest.",
    icon: <IconCalendar viewBox="-1 -1 69 69" />,
    to: "events-listing"
  },
  {
    heading: "Host a data party",
    detail: "Make the most of the site.",
    icon: <IconData viewBox="-1 -1 69 69" />,
    to: "host-a-data-party"
  },
  {
    heading: "Donate",
    detail: "Help us drive action.",
    icon: <IconDonate viewBox="-1 -1 40 44" />,
    to: "donate-to-understanding-houston"
  }
];

const Navigation = function () {
  const { state, dispatch } = useContext(NavContext);

  useEffect(() => {
    const handleNavigation = to => {
      dispatch({ type: "set-nav", payload: to });
    };
    Events.scrollEvent.register("end", handleNavigation);

    function cleanup() {
      Events.scrollEvent.remove("end");
    }

    return cleanup;
  });

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: !isMobile,
    swipe: isMobile,
    autoplay: true,
    centerMode: true,
    focusOnSelect: true,
    afterChange: current => {
      dispatch({ type: "set-active-slide", payload: current });
    }
  };

  return (
    <NavigationContainer
      as={Slider}
      {...settings}
      className="get-involved--navigation section"
    >
      {topActions.map((action, actionIndex) => (
        <NavigationCard
          {...action}
          isActive={state.activeSlide === actionIndex}
          key={`get-involved--nav-${_kebabCase(action.heading)}`}
        />
      ))}
    </NavigationContainer>
  );
};

export default Navigation;
