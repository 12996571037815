import React, { Component } from "react";
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import { WPContent } from "components";

import { SectionTitle, SectionPointer } from "components/elements";
import OurApproachContainer from "./index.style";

class OurApproach extends Component {
  componentDidMount() {}

  render() {
    const { approachOne, approachTwo } = this.props;

    return (
      <OurApproachContainer ref={this.props.innerRef}>
        <div className="container">
          <Grid>
            <Row>
              <Col mdOffset={1} lgOffset={0}>
                <div className="title">
                  <SectionPointer firstColor="#DDD12A" secondColor="#7AC896" />
                  <SectionTitle>Our approach</SectionTitle>
                </div>
              </Col>
            </Row>
            <Row className="approach-one">
              <Col xs={12} md={6}>
                <img src={approachOne.image.url} alt="approach 1" />
              </Col>
              <Col xs={12} md={6}>
                <WPContent>{approachOne.description}</WPContent>
              </Col>
            </Row>
            <Row className="approach-two">
              <Col xs={12} md={6} className="description">
                <WPContent>{approachTwo.description}</WPContent>
              </Col>
              <Col xs={12} md={6} className="photo">
                <img src={approachTwo.image.url} alt="approach 2" />
              </Col>
            </Row>
          </Grid>
        </div>
      </OurApproachContainer>
    );
  }
}

export default OurApproach;
