import styled from "styled-components";

import { defaultShevy } from "styles/global.style";
import theme from "styles/theme";
import { device } from "styles/breakpoints";

const { baseSpacing: bs } = defaultShevy;

const NavigationCardContainer = styled.div`
  border-radius: 5px;
  border: 2px solid ${theme.secondary.ghcfBlue};
  color: ${theme.primary.spaceCadet};
  background-color: #ffffff;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.11),
    0 15px 24px -11px rgba(21, 157, 191, 0.22);
  margin: 0 ${bs(0.5)};
  padding: ${bs(1)};
  display: block;

  @media ${device.tablet} {
    padding: ${bs(1.5)};
    margin: 0 ${bs(0.75)};
  }

  .navigation-card--content {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    transition: 200ms ease-in-out margin-bottom;
  }

  box-sizing: border-box;
  transition: 200ms ease-in-out margin-top;

  @media ${device.tablet} {
    width: 405px;
  }

  .navigation-card--about {
    margin-left: ${bs(0.5)};

    @media ${device.tablet} {
      margin-left: ${bs(1.5)};
    }

    h6 {
      font-weight: 900;
      line-height: 1.45;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
      line-height: 1.56;
    }
  }

  .navigation-card--icon {
    height: 2em;
    width: 2em;
    @media ${device.tablet} {
      height: 67px;
      width: 67px;
    }
    text-align: center;
    svg {
      width: auto;
      height: inherit;
    }
  }
`;

export default NavigationCardContainer;
