import React from "react";
import DropdownMenu, { NestedDropdownMenu } from "react-dd-menu-customized";
import { NavLink } from "react-router-dom";
import { Content } from "components";
import {
  organizeTopicsAndChildren,
  makeTopicsAndSubtopicsGetter,
} from "helpers/topics-and-subtopics";
import cachedTopicsJSON from "helpers/content-stubs/topics-and-subtopics.json";

const REQUIRED_FIELDS = [
  "id",
  "parent",
  "slug",
  "title",
  "categories",
  "menu_order",
  "acf",
  "featured_media",
  "better_featured_image",
  "link",
];

const defaultTopics = { topicsAndSubtopicsMenu: { topics: cachedTopicsJSON } };
const defaultNestedProps = {
  animate: false,
  delay: 0,
};

function makeMenuGetter(axios) {
  return makeTopicsAndSubtopicsGetter(axios, REQUIRED_FIELDS).then(
    ({ pages, topicCategoryId }) => {
      const { parentTopics, childTopics } = organizeTopicsAndChildren(
        pages,
        topicCategoryId
      );
      return {
        data: {
          topics: parentTopics,
          subtopics: childTopics,
        },
      };
    }
  );
}

// Customize NestedDropDownMenu to allow click-through of link elements/components
class ClickableNestedDropdownMenu extends NestedDropdownMenu {
  handleToggleComponentClick = (e) => {
    const { isClickOpen } = this.state;
    this.setState({
      isClickOpen: !isClickOpen,
    });
    if (e.target.href) {
      setTimeout(() => {
        this.props.close();
      }, 0);
      return;
    }
    e.stopPropagation();
  };
}

function makeNestedTopicProps(topic) {
  return {
    ...defaultNestedProps,
    toggle: (
      <NavLink
        className="parent-menu-item"
        to={`/topic/${topic.slug}`}
        exact
        title={topic.title.rendered}
        dangerouslySetInnerHTML={{ __html: topic.title.rendered }}
      />
    ),
  };
}

const SubtopicItem = ({ topic, subtopic }) => (
  <li>
    <NavLink
      to={`/topic/${topic.slug}/${subtopic.slug}`}
      exact
      title={subtopic.title.rendered}
      dangerouslySetInnerHTML={{ __html: subtopic.title.rendered }}
    />
  </li>
);

const NestedTopicItem = ({ topic, close }) => {
  return (
    <ClickableNestedDropdownMenu {...makeNestedTopicProps(topic)} close={close}>
      {topic.children.map((subtopic) => (
        <SubtopicItem subtopic={subtopic} topic={topic} key={subtopic.id} />
      ))}
    </ClickableNestedDropdownMenu>
  );
};

const TopicMenuDisplay = ({ content, ...menuOptions }) => {
  return (
    <DropdownMenu {...menuOptions} className="explore">
      {content.topicsAndSubtopicsMenu.topics.map((topic) => (
        <NestedTopicItem
          topic={topic}
          key={topic.id}
          close={menuOptions.close}
        />
      ))}
    </DropdownMenu>
  );
};

const FooterTopicMenuDisplay = ({ content, ...props }) => {
  return (
    <ul {...props}>
      {content.topicsAndSubtopicsMenu.topics.map((topic) => (
        <li key={`footer-nav-${topic.id}`}>
          <NavLink
            to={`/topic/${topic.slug}`}
            exact
            dangerouslySetInnerHTML={{ __html: topic.title.rendered }}
          />
        </li>
      ))}
    </ul>
  );
};

const TopicMenuProvider = ({ children, ...props }) => {
  return (
    <Content
      getters={[
        {
          name: "topicsAndSubtopicsMenu",
          makeGetter: makeMenuGetter,
        },
      ]}
      {...props}
    >
      {React.Children.toArray(children).map((child) =>
        React.cloneElement(child, {
          content: defaultTopics,
        })
      )}
    </Content>
  );
};

const TopicMenu = (props) => {
  return (
    <TopicMenuProvider error={<TopicMenuDisplay {...props} />}>
      <TopicMenuDisplay {...props} />
    </TopicMenuProvider>
  );
};

const FooterTopicMenu = (props) => {
  return (
    <TopicMenuProvider loading={<FooterTopicMenuDisplay {...props} />}>
      <FooterTopicMenuDisplay {...props} />
    </TopicMenuProvider>
  );
};

export {
  TopicMenuProvider,
  TopicMenuDisplay,
  FooterTopicMenuDisplay,
  FooterTopicMenu,
  defaultTopics,
};

export default TopicMenu;
