import styled from "styled-components";
import { rgba } from "polished";

import { Link } from "components/elements";

import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";
import theme from "styles/theme";

const { baseSpacing: bs } = defaultShevy;

const GetInvolvedContainer = styled.div`
  position: relative;
  div:not(.events-type) > a:not(.navigation-card),
  p > a {
    border-bottom: 3px solid ${theme.accent.pear};

    &:hover {
      color: ${theme.accent.pear};
      background-color: ${theme.primary.spaceCadet};
      border-bottom-color: transparent;
    }
  }
  div.events-type > a {
    &:hover {
      color: ${theme.accent.pear};
      background-color: ${theme.primary.spaceCadet};
      border-bottom-color: transparent;
    }
  }

  .section {
    padding: 72px 0;
    &.get-involved--introduction,
    &.get-involved--navigation {
      background-color: ${rgba(theme.secondary.ghcfBlue, 0.1)};
    }
    &.get-involved--introduction {
      padding-bottom: 0;
    }
    &.get-involved--events {
      padding: 0;
    }
    &.get-involved--navigation {
      padding-bottom: 144px;
    }
    &.get-involved--partnership {
      ${Link} {
        display: inline-block;
        margin-right: 1.5em;
      }
    }
    &.get-involved--media,
    &.get-involved--partnership {
      img {
        margin-bottom: ${bs(2)};

        @media ${device.tablet} {
          margin-bottom: 0;
        }
      }
    }
    @media ${device.tablet} {
      &.get-involved--media {
        margin-top: 60px;
      }
    }
    @media ${device.laptop} {
      &.get-involved--media {
        margin-top: 0;
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
  }

  .container,
  .container-fluid {
    img {
      width: 100%;
      display: block;
      margin: 0 auto;
    }
  }

  @media ${device.tablet} {
    [class^="get-involved--"][class$="content"] {
      .container {
        > div {
          &:first-child {
            padding-right: 0;
          }
          &:last-child {
            padding-left: 0;
          }
        }
      }
    }
  }

  h3 {
    font-weight: 400;
  }
`;

export default GetInvolvedContainer;
