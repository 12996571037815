import React, { useEffect } from "react";
import Slider from "react-slick";

import _omit from "lodash/omit";

import VerticalSliderContainer from "./vertical-slider.style";

const VerticalSlider = props => {
  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    ..._omit(props, ["children"])
  };

  // TODO this needs another way for the vertical sizing to work
  // when slidesToShow and slidesToScroll are different
  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      window.dispatchEvent(new Event("resize"));
    }, 250);
  });

  return (
    <VerticalSliderContainer as={Slider} {...settings}>
      {props.children}
    </VerticalSliderContainer>
  );
};

export default VerticalSlider;
