import styled from "styled-components";

import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";

import Button from "../elements/button";
import SocialLinksWrapper from "../socialLinks/index.style";

const { baseSpacing: bs } = defaultShevy;

const FooterContainer = styled.footer`
  position: relative;
  z-index: 99;
  background-color: ${(props) => props.theme.primary.spaceCadet};
  margin: 0 -${bs()};
  color: #b0b6bd;

  @media ${device.tablet} {
    margin: 0;
    padding: ${bs()};
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .footer-top {
    padding: ${bs(3)} ${bs(1.7)} ${bs(3)} ${bs(2)};

    @media ${device.tablet} {
      padding: ${bs(4)} 0 ${bs(2.2)};
    }

    .container {
      display: flex;
      flex-direction: column;

      .menu-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        @media ${device.tablet} {
          padding-left: 3.9em;
          flex-direction: row;
          flex-wrap: wrap;

          .column {
            width: 50%;
            margin-bottom: 2em;
          }
        }

        @media ${device.laptopL} {
          .column {
            width: auto;
            margin-bottom: 0;
          }
        }
      }

      @media ${device.tablet} {
        flex-direction: row;
        justify-content: space-between;
      }

      ${SocialLinksWrapper} {
        display: none;

        @media ${device.tablet} {
          display: block;
        }
      }

      h4 {
        font-size: 0.72em;
        margin-top: 0;
      }

      .logo {
        width: 180px;
        margin-bottom: ${bs(3)};

        @media ${device.tablet} {
          margin-bottom: ${bs(1.7)};
        }
      }

      .column {
        margin-bottom: ${bs(1.5)};

        @media ${device.tablet} {
          margin-bottom: 0;
        }
      }

      ul.page-links {
        margin: 0;

        li {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            color: #b0b6bd;
            text-decoration: none;
            font-size: 0.72em;
            line-height: 1.5;
          }
        }
      }

      .subscribe-form {
        display: flex;
        flex-direction: column;

        @media ${device.laptop} {
          width: ${bs(15)};
        }

        ${"" /* select {
          margin-bottom: 10px;
          width: 100%;
          height: 48px;
          border-radius: 3px;
          border: none;
          padding-left: 25px;
          font-size: 14px;
          color: #112E51;
          outline: none;

          option {
            appearance: none;
            padding-left: 25px;
          }
        } */}
        .selector {
          margin-bottom: 10px;
          width: 100%;
          height: 48px;
          border-radius: 3px;
          border: none;
          padding-left: 0;
          font-size: 14px;
          color: ${(props) => props.theme.primary.spaceCadet};
          outline: none;
          ${"" /* max-height: 48px; */}

          &:first-child {
            height: 48px;
            ${"" /* max-height: 48px; */}
          }
        }

        input {
          margin-bottom: 10px;
          width: 100%;
          height: 48px;
          border-radius: 3px;
          border: none;
          padding-left: 25px;
          font-size: 14px;
          color: ${(props) => props.theme.primary.spaceCadet};
          outline: none;
        }

        ${Button} {
          width: 166px;
          height: 48px;
          padding: 0;
          margin-top: 8px;
          font-size: 16px;
        }

        div.field-error {
          color: white;
          font-size: 0.7em;
          display: block;
          margin-bottom: 1em;
        }
      }

      .logos {
        margin-top: ${bs(1.5)};
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img {
          max-width: 228px;
        }

        @media ${device.tablet} {
          display: none;
        }

        img:first-child {
          margin-bottom: ${bs()};
        }
      }
    }
  }

  .footer-bottom {
    padding: ${bs(2)} ${bs(2)} ${bs(2)};
    border-top: 1px solid #0d2440;

    @media ${device.tablet} {
      padding: ${bs(2.1)} 0 ${bs(1.05)};
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        ${SocialLinksWrapper} {
          display: block;

          @media ${device.tablet} {
            display: none;
          }
        }

        > ul {
          display: flex;
          flex-flow: wrap;
          margin: ${bs(1.5)} 0 ${bs(0.5)};

          @media ${device.tablet} {
            margin: 0;
          }

          li {
            margin-right: ${bs()};

            &:last-child {
              margin: 0;
            }

            a {
              color: #b0b6bd;
              text-decoration: none;
              font-size: 0.72em;
              line-height: 1.5em;
            }
          }
        }

        > p {
          margin-top: ${bs(0.5)};
        }
      }

      .right {
        display: none;

        @media ${device.laptop} {
          display: flex;
          align-items: center;
        }

        img {
          margin-right: 28px;

          &:last-child {
            margin: 0;
          }

          &.logo-ghcf {
            max-width: 235px;
          }

          &.logo-kinder {
            max-width: 189px;
          }
        }
      }
    }
  }

  p.thank-you {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
`;

export default FooterContainer;
