import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import GetInvolvedContainer from "./index.style";

import Contents from "./contents";

class GetInvolved extends PureComponent {
  componentDidMount() {}

  render() {
    return (
      <GetInvolvedContainer id="main-content">
        <Helmet>
          <title>Get Involved | Understanding Houston</title>
          {/* <meta name="description" content={} /> */}
        </Helmet>
        <Contents />
      </GetInvolvedContainer>
    );
  }
}

export default GetInvolved;
