import React, { useState } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import Select from "react-select";
import _find from "lodash/find";
import _map from "lodash/map";
import _filter from "lodash/filter";
import _keyBy from "lodash/keyBy";
import _groupBy from "lodash/groupBy";
import _includes from "lodash/includes";
import _mapValues from "lodash/mapValues";
import Moment from "react-moment";
import { TopicMenuProvider, Content } from "components";
import { selectStyles } from "styles/global.style";
import { Link } from "components/elements";
import IconFeed from "assets/images/blog/icon-feed.svg";
import IconHot from "assets/images/blog/icon-hot.svg";
import IconArrowLeft from "assets/images/blog/icon-long-arrow-left-blue.svg";
import MainBlogContainer from "./main.style";
import { NextArrow, PrevArrow } from "components/arrows";
import Slider from "react-slick";
import { Button } from "components/elements";
import topicsAndSubtopicsJSON from "../../helpers/content-stubs/topics-and-subtopics";
import { Helmet } from "react-helmet";

const abridgedDefaultArticle = {
  title: {
    rendered: "",
  },
  excerpt: {
    rendered: "",
  },
  date: "",
  better_featured_image: {},
};

const defaultArticle = {
  ...abridgedDefaultArticle,
  content: {
    rendered: "",
  },
  acf: {},
  _embedded: {
    author: [
      {
        avatar_urls: [],
      },
    ],
    "wp:featuredmedia": [],
  },
};

const sliderSettings = {
  adaptiveHeight: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: false,
  centerMode: false,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const BlogContent = ({ blog, className }) => {
  return (
    <RouterLink to={`/blog/${blog.slug}`}>
      <article className={className ? `blog ${className}` : "blog"}>
        <div
          className="blog-image"
          style={{
            backgroundImage: `url(${
              blog.better_featured_image
                ? blog.better_featured_image.source_url
                : ""
            })`,
          }}
        />
        <div className="blog-content">
          <h3>{blog.title.rendered}</h3>
          <p className="description sm">
            {blog.yoast_head_json?.description || ""}
          </p>
          <p className="sub-title">
            {blog._embedded.author[0].name},{" "}
            <Moment format="MMM. DD, YYYY">{blog.date}</Moment>
          </p>
          <p className="category xs">
            {blog._embedded["wp:term"] &&
              blog._embedded["wp:term"][0].map((category, i) => (
                <span key={i}>
                  {category.name}{" "}
                  {i < blog._embedded["wp:term"][0].length - 1 && " | "}
                </span>
              ))}
          </p>
        </div>
      </article>
    </RouterLink>
  );
};

const renderBlogList = (blogs = [], maxCount) => {
  if (!blogs || !blogs.length) return null;
  return (
    <div className="blog-list">
      {blogs.slice(0, maxCount).map((blog, i) => (
        <BlogContent blog={blog} key={i} />
      ))}
    </div>
  );
};

const CategoryHeader = ({ activeCategory }) => {
  const { slug } = activeCategory;
  const {
    better_featured_image: { source_url },
  } = _find(topicsAndSubtopicsJSON, { slug });

  return (
    <section
      className="banner"
      style={{
        backgroundImage: `url(${source_url})`,
      }}
    >
      <div className="container">
        <div className="content">
          <RouterLink className="back-navigator" to="/blog">
            <img src={IconArrowLeft} alt="back arrow" /> Articles & Insights
          </RouterLink>
          <h2>{activeCategory.name}</h2>
        </div>
      </div>
    </section>
  );
};

const StickyPost = ({ stickyPost }) => {
  return (
    <section
      className="banner main"
      style={{
        backgroundImage: `url(${
          stickyPost.better_featured_image
            ? stickyPost.better_featured_image.source_url
            : ""
        })`,
      }}
    >
      {stickyPost.title.rendered && (
        <div className="container">
          <div className="content">
            <p className="col-title">
              <img src={IconFeed} alt="feed" /> Featured Story
            </p>
            <h1>{stickyPost.title.rendered}</h1>
            <p className="description sm">
              {stickyPost.yoast_head_json?.description || ""}
            </p>
            <Link borderHeight="3px" href={`/blog/${stickyPost.slug}`}>
              Read story
            </Link>
          </div>
        </div>
      )}
    </section>
  );
};

const TopicSelectDisplay = ({
  onChangeCategory,
  value,
  categoriesWithPosts,
  ...props
}) => {
  const selectorOptions = _map(
    props.content.topicsAndSubtopicsMenu.topics,
    (topic) => ({
      value: topic.slug,
      label: topic.title.rendered,
      isDisabled: !_includes(categoriesWithPosts, topic.slug),
    })
  );
  const topicSelectorOptions = [
    {
      value: "all",
      label: "All",
    },
    ...selectorOptions,
  ];

  const activeTopic = _find(topicSelectorOptions, { value });

  return (
    <Select
      options={topicSelectorOptions}
      value={activeTopic}
      {...props}
      onChange={onChangeCategory}
      isSearchable={false}
    />
  );
};

const sortPosts = (posts) => {
  const stickyPost = _find(posts, { sticky: true });
  const postsGroupedByStatus = _groupBy(posts, "acf.featured_or_trending");
  const mostReadPosts = _filter(posts, "acf.most_read");

  return {
    stickyPost,
    featuredStories: postsGroupedByStatus.Featured || [],
    trendingMedias: postsGroupedByStatus.Trending || [],
    mostReadPosts,
    posts,
    count: posts.length,
  };
};

const groupPostsByCategories = (posts, categoriesBySlug) => {
  const all = [...posts];
  const postsByCategories = _mapValues(categoriesBySlug, (category) => {
    const postsForCategory = _filter(posts, (post) =>
      _includes(_map(post.categoriesExpanded, "slug"), category.slug)
    );
    return sortPosts(postsForCategory);
  });

  return {
    ...postsByCategories,
    all: sortPosts(all),
  };
};

const MainBlogDisplay = (props) => {
  const { category, onCategorySelect } = props;
  const { posts, categories, categoriesBySlug } = props.content.blog;

  const activeCategory = categoriesBySlug[category] || { slug: "all" };
  const processedPosts = groupPostsByCategories(posts, categoriesBySlug);

  const filteredPosts = processedPosts[category] || {
    stickyPost: undefined,
    featuredStories: [],
    trendingMedias: [],
    mostReadPosts: [],
    posts: [],
  };

  const [maxCount, setMaxCount] = useState(10);

  const { featuredStories, trendingMedias, mostReadPosts } = filteredPosts;

  const categoriesWithPosts = _map(
    _filter(categories, (category) => processedPosts[category.slug].count > 0),
    "slug"
  );

  const isFilteredResults = activeCategory.slug !== "all";

  const currentPosts = isFilteredResults ? filteredPosts.posts : posts;

  let mostReadList = isFilteredResults ? mostReadPosts : trendingMedias;
  mostReadList = mostReadList.slice(0, 5);

  return (
    <MainBlogContainer>
      {(!isFilteredResults && (
        <StickyPost
          activeCategory={activeCategory}
          stickyPost={processedPosts.all.stickyPost || defaultArticle}
        />
      )) || <CategoryHeader activeCategory={activeCategory} />}
      <section className="blog-categories">
        <div className="container">
          <div className="category-selector">
            <p id="filterBy">Filter by</p>
            <TopicMenuProvider>
              <TopicSelectDisplay
                styles={selectStyles}
                categoriesWithPosts={categoriesWithPosts}
                className="selector selector-topic"
                placeholder="All"
                classNamePrefix="react-select"
                value={category}
                onChangeCategory={onCategorySelect}
                aria-labelledby="filterBy"
              />
            </TopicMenuProvider>
          </div>
          <div className="intro">
            <p>
              Understanding Houston articles offer analysis, perspective, and
              lived experience from a variety of authors including nonprofit
              leaders and researchers, among others. They do not necessarily
              reflect the opinion of Understanding Houston and/or the Greater
              Houston Community Foundation, but reflect our commitment to
              lifting up unique voices within the Houston community.
            </p>
          </div>
        </div>
      </section>
      {mostReadList.length ? (
        <section
          className={`most-read ${isFilteredResults ? "latest-blogs" : ""}`}
        >
          <div className="container">
            <p className="col-title">
              <img src={IconHot} alt="hot" /> Most Read
            </p>

            {!isFilteredResults ? (
              <div
                className={`stories ${!isFilteredResults && "main"} most-read`}
              >
                <Slider {...sliderSettings}>
                  {mostReadList.map((blog, i) => (
                    <BlogContent blog={blog} key={i} />
                  ))}
                </Slider>
              </div>
            ) : (
              <div className={`stories ${!isFilteredResults && "main"}`}>
                {renderBlogList(mostReadList, 2)}
              </div>
            )}
          </div>
        </section>
      ) : null}
      <section className="latest-blogs">
        <div className="container">
          <p className="col-title">
            <img src={IconFeed} alt="feed" /> Most Recent
          </p>

          <div className={`stories ${!isFilteredResults && "main"}`}>
            {renderBlogList(currentPosts, maxCount)}
          </div>
          {maxCount < currentPosts.length && (
            <div className="buttons">
              <Button
                title="Load More Blogs"
                onClick={() => setMaxCount(maxCount + 10)}
              >
                Load More Blogs
              </Button>
            </div>
          )}
        </div>
      </section>
    </MainBlogContainer>
  );
};

const MainBlog = (props) => {
  return (
    <Content
      getters={[
        {
          name: "blog",
          makeGetter: (client) => {
            const baseURL = `${process.env.REACT_APP_WP_API_URL}wp/v2/`;
            return client
              .get(
                `${baseURL}categories?per_page=100&slug=blog,community-voices&_fields=id,slug`
              )
              .then((response) =>
                Promise.all([
                  _map(response.data, "id"),
                  client.get(
                    `${baseURL}categories?per_page=100&parent=${response.data[0].id}&_fields=id,slug,name`
                  ),
                  client.get(
                    `${baseURL}posts?per_page=100&_embed&categories=${_map(
                      response.data,
                      "id"
                    ).join(",")}`
                  ),
                ])
              )
              .then(([blogCategoryIds, categoriesResponse, postsResponse]) => {
                const categoriesById = _keyBy(categoriesResponse.data, "id");
                const categoriesBySlug = _keyBy(
                  categoriesResponse.data,
                  "slug"
                );
                const postsWithCategories = _map(postsResponse.data, (post) => {
                  const categories = _map(
                    _filter(post.categories, (categoryId) =>
                      _includes(_map(categoriesResponse.data, "id"), categoryId)
                    ),
                    (categoryId) => categoriesById[categoryId]
                  );
                  return {
                    ...post,
                    categoriesExpanded: categories,
                  };
                });

                return {
                  data: {
                    categories: categoriesResponse.data,
                    posts: postsWithCategories,
                    categoriesById,
                    categoriesBySlug,
                  },
                };
              });
          },
        },
      ]}
    >
      <Helmet>
        <title>Articles & Reports | Understanding Houston</title>
        {/* <meta name="description" content={} /> */}
      </Helmet>
      <MainBlogDisplay
        content={{
          blog: {
            categories: [],
            posts: [],
            categoriesById: {},
            categoriesBySlug: {},
          },
        }}
        category={props.match.params.category || "all"}
        onCategorySelect={(category) => {
          props.history.push(`/blog/categories/${category.value}`);
        }}
      />
    </Content>
  );
};

export default withRouter(MainBlog);
