import React from "react";
import { Content } from "components";

import { cleanHTML } from "helpers/content";

import map from "lodash/map";
import groupBy from "lodash/groupBy";

const defaultFAQs = { faqs: [] };

function getDataFromResponse(response) {
  return response.data;
}

function makeFAQsGetter(axios) {
  const baseURL = `${process.env.REACT_APP_WP_API_URL}wp/v2/`;

  return axios
    .get(`${baseURL}categories?per_page=100&slug=faqs&_fields=name,id,slug`)
    .then(getDataFromResponse)
    .then(([faqsCategory]) => {
      return axios
        .get(
          `${baseURL}categories?per_page=100&parent=${faqsCategory.id}&orderby=id&order=asc&_fields=id,parent,description,name,slug`
        )
        .then(getDataFromResponse);
    })
    .then(faqsCategories => {
      return Promise.all([
        faqsCategories,
        axios
          .get(`${baseURL}pages?per_page=100&slug=faqs`)
          .then(getDataFromResponse)
      ]);
    })
    .then(([categories, faqPages]) => {
      const { questions } = faqPages[0].acf;
      const questionsByCategory = groupBy(questions, "category");
      const faqs = map(categories, category => ({
        ...category,
        questions:
          map(questionsByCategory[category.id], question => ({
            ...question,
            answer: cleanHTML(question.answer, "/")
          })) || []
      }));

      return {
        data: faqs
      };
    });
}

const FAQsProvider = ({ children, ...props }) => {
  return (
    <Content
      getters={[
        {
          name: "faqs",
          makeGetter: makeFAQsGetter
        }
      ]}
      {...props}
    >
      {React.Children.toArray(children).map(child =>
        React.cloneElement(child, {
          content: defaultFAQs
        })
      )}
    </Content>
  );
};

export default FAQsProvider;
