import styled from "styled-components";

const VoteContainer = styled.div`
  background-color: ${props => props.theme.primary.spaceCadet};
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 25px 25px 40px;

  h6 {
    margin-bottom: 8.5px;
  }

  p.sm {
    border-bottom: 1px solid #d6d7d8;
    padding-bottom: 18px;
  }

  p.text-voted {
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;

    img {
      margin-right: 10px;
    }

    span {
      margin-left: 10px;
      text-transform: uppercase;
      font-size: 0.72em;
      color: ${props => props.theme.accent.seaGreen};
      font-weight: bold;
    }
  }
  
  button {
    background: none;
    border: none;
    font-size: 16px;
    border-radius: 4px;
  }

  #upvote.active {
    background: orangered;
    color: white;
  }

  #downvote.active {
    background: rgb(119, 119, 236);
    color: white;
  }
`;

export default VoteContainer;