import axios from "axios";
import moxios from "moxios";
import forEach from "lodash/forEach";

import contentStubs from "helpers/content-stubs";

const contentAxios = axios.create();
const contentMoxios = axios.create();

moxios.install(contentMoxios);

forEach(contentStubs, function(stub, path) {
  moxios.stubRequest(path, stub);
});

export { contentMoxios };
export default contentAxios;
