import React, { Component } from "react";
import Highcharts from "highcharts/highstock";

import mapData from "assets/mapJSON/tx_counties";
import MapChart from "components/map";

import MapContainer from "./svgMap.style";

require("highcharts/modules/map")(Highcharts);

class SVGMap extends Component {
  state = {
    mapOptions: {
      chart: {
        height: 500,
        spacing: [0, 0, 0, 0]
      },
      title: {
        text: ""
      },
      styledMode: true,
      legend: {
        enabled: false
      },
      tooltip: {
        headerFormat: null,
        borderRadius: 5,
        pointFormat:
          "Population: <b>{point.population}</b><br/>Square miles: <b>{point.sqm}</b>"
      },
      series: [
        {
          mapData,
          dataLabels: {
            enabled: true,
            color: "#FFFFFF",
            format: "{point.name}",
            style: {
              fontSize: "16px",
              textOutline: "none"
            }
          },
          data: [
            {
              "hc-key": "us-tx-339",
              color: "#7AC896",
              population: "570,934",
              sqm: "1,077 mi²"
            },
            {
              "hc-key": "us-tx-201",
              color: "#159DBF",
              population: "4,652,980",
              sqm: "1,777 mi²"
            },
            {
              "hc-key": "us-tx-157",
              color: "#DDD12A",
              population: "764,828",
              sqm: "885 mi²"
            }
          ],
          states: {
            hover: {
              brightness: 0.1
            }
          },
          joinBy: "hc-key",
          allAreas: false
        }
      ]
    }
  };

  componentDidMount() { }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeCounty !== this.props.activeCounty) {
      this.setState(prevState => ({
        mapOptions: {
          ...prevState.mapOptions,
          series: [
            {
              ...prevState.mapOptions.series[0],
              data: [
                {
                  "hc-key": "us-tx-157",
                  color: `${
                    nextProps.activeCounty === "3-County" ||
                      nextProps.activeCounty === "Fort Bend"
                      ? "#DDD12A"
                      : "#8D9DAF"
                    }`
                },
                {
                  "hc-key": "us-tx-201",
                  color: `${
                    nextProps.activeCounty === "3-County" ||
                      nextProps.activeCounty === "Harris"
                      ? "#159DBF"
                      : "#8D9DAF"
                    }`
                },
                {
                  "hc-key": "us-tx-339",
                  color: `${
                    nextProps.activeCounty === "3-County" ||
                      nextProps.activeCounty === "Montgomery"
                      ? "#7AC896"
                      : "#8D9DAF"
                    }`
                }
              ]
            }
          ]
        }
      }));
    }
  }

  render() {
    const counties = ["3-County", "Harris", "Fort Bend", "Montgomery"];
    const { activeCounty } = this.props;
    const { mapOptions } = this.state;

    return (
      <MapContainer>
        <MapChart
          options={mapOptions}
          highcharts={Highcharts}
          activeCounty={activeCounty}
        />
        <div className="map-pagination">
          {counties.map((county, index) => (
            <span
              className={`${county === activeCounty && "active"}`}
              onClick={() => this.props.onChangeCounty(county)}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />
          ))}
        </div>
        <div className="chart-colors">
          <span className="color-nhwhite">White</span>
          <span className="color-hispanic">Hispanic</span>
          <span className="color-nhblack">Black</span>
          <span className="color-nhasian">Asian or Pacific Islander</span>
          <span className="color-nhother">Other</span>
        </div>
      </MapContainer>
    );
  }
}

export default SVGMap;
