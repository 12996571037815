import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

import { SectionTitle, SectionPointer } from "components/elements";
import ImgCityScapeMobile from "assets/images/cityscape_mobile.png";
import ImgCityScape from "assets/images/cityscape.png";
import UHouContainer from "./index.style";

class UHou extends Component {
  state = {
    collapsedDescription: true,
    modalIsOpen: false,
  };

  componentDidMount() {}

  onReadmore = () => {
    this.setState({
      collapsedDescription: false,
    });
  };

  openVideoModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeVideoModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  render() {
    const { collapsedDescription } = this.state;
    const { content } = this.props;

    return (
      <UHouContainer className="section u-hou" ref={this.props.innerRef}>
        <div className="container">
          <div className="inner-uhou">
            <div className="title">
              <SectionPointer firstColor="#DDD12A" secondColor="#7AC896" />
              <SectionTitle>{content.title}</SectionTitle>
              <img src={ImgCityScapeMobile} alt="city scape" />
              <p>
                <span dangerouslySetInnerHTML={{ __html: content.sub_title }} />
              </p>
            </div>
            <img src={ImgCityScape} alt="city scape" />
            <p className={`description ${collapsedDescription && "collapsed"}`}>
              <span dangerouslySetInnerHTML={{ __html: content.description }} />
            </p>
            <div className="read-more">
              <span onClick={this.onReadmore}>
                <p className="xs">
                  Read more <br /> <FontAwesomeIcon icon={faChevronDown} />
                </p>
              </span>
            </div>
            <div className="video-wrapper">
              <div className="video-content" onClick={this.openVideoModal}>
                {/* <h1>
                  Explore. <br /> Learn. <br /> Engage.
                </h1>
                <img
                  src={IconPlay}
                  alt="play icon"
                  className="icon-play"
                  onClick={this.openVideoModal}
                />
                <p>Watch</p> */}
              </div>
            </div>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.modalIsOpen}
          videoId={content.youtube_video_id}
          onClose={this.closeVideoModal}
        />
      </UHouContainer>
    );
  }
}

export default UHou;
