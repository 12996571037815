import React, { Component } from "react";

import BarContainer from "./bar.style";

class Bar extends Component {
  componentDidMount() { }

  render() {
    const { index, data, year } = this.props;

    return (
      <BarContainer key={index}>
        <span className="year">{year}</span>
        <div className="bar">
          <span
            className="stack purple"
            style={{ width: `${data.nhWhite * 100}%` }}
          >
            <span className="popup">
              <b>{Math.floor(data.nhWhite * 100)}</b><b>%</b>
            </span>
          </span>
          <span
            className="stack red"
            style={{ width: `${data.hispanic * 100}%` }}
          >
            <span className="popup">
              <b>
                {Math.floor((data.hispanic) * 100)}
              </b><b>%</b>
            </span>
          </span>
          <span
            className="stack cyan"
            style={{ width: `${data.nhBlack * 100}%` }}
          >
            <span className="popup">
              <b>{Math.floor((data.nhBlack) * 100)}</b><b>%</b>
            </span>
          </span>
          <span
            className="stack yellow"
            style={{ width: `${data.nhAapi * 100}%` }}
          >
            <span className="popup">
              <b>
                {Math.floor(
                  (data.nhAapi) * 100)}
              </b><b>%</b>
            </span>
          </span>
          <span
            className="stack green"
            style={{ width: `${data.other * 100}%` }}
          >
            <span className="popup">
              <b>
                {Math.floor((data.other) * 100)}
              </b><b>%</b>
            </span>
          </span>
        </div>
      </BarContainer>
    );
  }
}

export default Bar;
