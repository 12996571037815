import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

import { ContentProvider } from "components";

import Pages from "./pages";

function ScrollToTop() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);

  return null;
}

class PublicRoutes extends Component {
  componentDidMount() {
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = "manual";
    }
  }

  render() {
    return (
      <ContentProvider>
        <Router>
          <ScrollToTop />
          <Pages />
        </Router>
      </ContentProvider>
    );
  }
}

export default PublicRoutes;
