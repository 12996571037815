import React, { useEffect} from "react";
import { Helmet } from "react-helmet";

import { StickyContainer, Sticky } from "react-sticky";
import { withRouter } from "react-router-dom";

import Moment from "react-moment";
import _findIndex from "lodash/findIndex";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";

import { Content, WPContent } from "components";
import { PostTag } from "components/elements";
import IconTime from "assets/images/blog/icon-time.svg";
import IconArrowLeft from "assets/images/blog/icon-long-arrow-left.svg";
import { client } from "services/helpers/apiConfig";

import ArticleContainer from "./article.style";

import Zooming from "zooming";
const zooming = new Zooming();

const abridgedDefaultArticle = {
  title: {
    rendered: "",
  },
  excerpt: {
    rendered: "",
  },
  better_featured_image: {
    media_details: {
      width: "",
      height: "",
    },
  },
  date: new Date(),
};

const defaultArticle = {
  ...abridgedDefaultArticle,
  content: {
    rendered: "",
  },
  acf: {},
  _embedded: {
    author: [
      {
        avatar_urls: [],
      },
    ],
    "wp:featuredmedia": [],
  },
};

const defaultArticlesNav = [];

const ArticleDisplay = ({ content, slug, shareUrl }) => {
  const { articlesNav } = content;
  const article = content[`article--${slug}`];

  const currentArticleIndex = _findIndex(articlesNav, {
    id: article.id,
  });
  const prevArticleIndex =
    currentArticleIndex > 0 ? currentArticleIndex - 1 : articlesNav.length - 1;
  const nextArticleIndex =
    currentArticleIndex + 1 < articlesNav.length ? currentArticleIndex + 1 : 0;

  const prevArticle = articlesNav[prevArticleIndex] || abridgedDefaultArticle;
  const nextArticle = articlesNav[nextArticleIndex] || abridgedDefaultArticle;

  async function fetchAllImages() {
    return document.getElementsByClassName("wp-block-image");
  }

  async function attachListener() {
    return await fetchAllImages().then(() => {
      return zooming.listen(".wp-block-image img");
    });
  }
  attachListener();

  useEffect(() => {
    const checkArticle = document.querySelector('.article-content');
    
    if ( checkArticle ) {
      let allTabs 		= document.querySelectorAll('.tab-contents > .tabs > .tab');
			let tabSummaries 	= document.querySelector('.tab-contents > .tab-summaries');
			let allSummaries 	= document.querySelectorAll('.tab-contents > .tab-summaries >.tab-summary');

			if ( allTabs.length > 0 && allSummaries.length > 0) {
				allTabs.forEach(function(tab, i){
					tab.addEventListener('click', function(evt){
						let tabTarget 		= evt.currentTarget;
						let tabClass 		= tabTarget.getAttribute('data-tab-target');
						let targetSummary = document.querySelector('.' + tabClass);

						if ( targetSummary ) {
							allTabs.forEach(function(item, t){
								item.classList.remove('active');
							});

							allSummaries.forEach(function(summary, t){
								summary.classList.remove('active');
							});

							tabTarget.classList.add('active');
							targetSummary.classList.add('active');
						}// if
					});
				});

				function addHeightTabContent(summaries) {
					let setTabSummariesHeight = 0;

					summaries.forEach(function(summary, i){
						if ( summary.offsetHeight > setTabSummariesHeight ) {
							setTabSummariesHeight = summary.offsetHeight;
						}
					});

					tabSummaries.style.minHeight = setTabSummariesHeight + 'px';
				}

				addHeightTabContent(allSummaries);

				let smallScreen = false;

				window.addEventListener('resize', function(evt){
					
					if ( !smallScreen && window.innerWidth < 768 ) {
						console.log('window.innerWidth', window.innerWidth);
						smallScreen = true;
						addHeightTabContent(allSummaries);
					}

					if (window.innerWidth > 768 && smallScreen) {
						smallScreen = false;
						addHeightTabContent(allSummaries);
					}
				});
			}// if
    }
    
  })

  return (
    <ArticleContainer>
      <section
        className="banner"
        style={{
          backgroundImage: `url(${
            article.acf && article.acf.photo
              ? article.acf.photo.url
              : article.better_featured_image &&
                article.better_featured_image.source_url
          })`,
        }}
      />
      <section className="content">
        <div className="container">
          {article.title.rendered && (
            <div className="article-header">
              <PostTag bgcolor="#FC9B00">
                {article._embedded["wp:term"] &&
                  article._embedded["wp:term"][0].map((category, i) => (
                    <span key={i}>
                      {category.name}{" "}
                      {i < article._embedded["wp:term"][0].length - 1 && " | "}
                    </span>
                  ))}
              </PostTag>
              <h2>{article.title.rendered}</h2>
              <div className="status">
                <p>
                  <img src={IconTime} alt="icon" />{" "}
                  <Moment format="MMM. DD, YYYY">{article.date}</Moment>
                </p>
              </div>
            </div>
          )}

          <div className="article-content">
            <StickyContainer className="sticky-container">
              <Sticky topOffset={80}>
                {({
                  style,

                  // the following are also available but unused in this example
                  // isSticky,
                  // wasSticky,
                  // distanceFromTop,
                  // distanceFromBottom,
                  // calculatedHeight
                }) => (
                  <div className="social-share-side" style={style}>
                    <p>Share</p>
                    <div className="social-share">
                      <FacebookShareButton
                        url={shareUrl}
                        quote={article.title.rendered}
                        className="share-button"
                      >
                        <FacebookIcon size={40} round />
                      </FacebookShareButton>
                      <LinkedinShareButton
                        url={shareUrl}
                        windowWidth={750}
                        windowHeight={600}
                        className="share-button"
                      >
                        <LinkedinIcon size={40} round />
                      </LinkedinShareButton>
                      <TwitterShareButton
                        url={shareUrl}
                        title={article.title.rendered}
                        className="share-button"
                      >
                        <TwitterIcon size={40} round />
                      </TwitterShareButton>
                    </div>
                  </div>
                )}
              </Sticky>
              <div className="wp-content">
                <WPContent className="texts" cleanContent={true}>
                  {article.content.rendered}
                </WPContent>
                <div className="article-tags">
                  {article._embedded["wp:term"] &&
                    article._embedded["wp:term"][1].map((tag, i) => (
                      <span key={i}>{tag.name}</span>
                    ))}
                </div>
                <div className="article-share">
                  <div className="social-share">
                    <FacebookShareButton
                      url={shareUrl}
                      quote={article.title.rendered}
                      className="share-button"
                    >
                      <FacebookIcon size={40} round />
                    </FacebookShareButton>
                    <LinkedinShareButton
                      url={shareUrl}
                      windowWidth={750}
                      windowHeight={600}
                      className="share-button"
                    >
                      <LinkedinIcon size={40} round />
                    </LinkedinShareButton>
                    <TwitterShareButton
                      url={shareUrl}
                      title={article.title.rendered}
                      className="share-button"
                    >
                      <TwitterIcon size={40} round />
                    </TwitterShareButton>
                  </div>
                </div>
              </div>
            </StickyContainer>
          </div>
        </div>
      </section>
      <section className="article-writer">
        <div className="container">
          <div className="wrapper">
            {article._embedded.author[0].acf ? (
              <img
                src={article._embedded.author[0].acf.author_image.url}
                alt="writer"
              />
            ) : null}
            <div className="writer-details">
              <p className="xs title-1">Written by</p>
              <p className="name">{article._embedded.author[0].name}</p>
              <p className="sm">{article._embedded.author[0].description}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="article-navigator">
        <div className="post-navigator previous-post">
          <div className="wrapper">
            <a href={`/blog/${prevArticle.slug}`}>
              <p>
                <img src={IconArrowLeft} alt="arrow" /> Previous
              </p>
            </a>
            <a href={`/blog/${prevArticle.slug}`}>
              <h3>{prevArticle.title.rendered}</h3>
            </a>
          </div>
        </div>
        <div className="post-navigator next-post">
          <div className="wrapper">
            <a href={`/blog/${nextArticle.slug}`}>
              <h3>{nextArticle.title.rendered}</h3>
            </a>
            <a href={`/blog/${nextArticle.slug}`}>
              <p>
                Next <img src={IconArrowLeft} alt="arrow" />
              </p>
            </a>
          </div>
        </div>
      </section>
      <Helmet>
        <title>{article.title.rendered} | Understanding Houston</title>
        <meta property="og:title" content={article.title.rendered} />
        <meta property="og:site_name" content="Understanding Houston" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content={
            article.acf && article.acf.photo
              ? article.acf.photo.url
              : article.better_featured_image &&
                article.better_featured_image.source_url
          }
        ></meta>
        <meta
          property="og:image:width"
          content={article.better_featured_image.media_details.width}
        />
        <meta
          property="og:image:height"
          content={article.better_featured_image.media_details.height}
        />
      </Helmet>
    </ArticleContainer>
  );
};

const Article = (props) => {
  const { slug } = props.match.params;

  const getters = [
    {
      name: `article--${slug}`,
      makeGetter: function() {
        return client
          .get(`/wp/v2/posts?slug=${slug}&_embed`)
          .then((response) => ({ data: response.data[0] }));
      },
    },
    {
      name: "articlesNav",
      makeGetter: function() {
        return client.get(
          `/wp/v2/posts?per_page=100&categories=8&_fields=id,slug,date,date_gmt,title,excerpt`
        );
      },
    },
  ];

  const shareUrl = window.location.href;

  return (
    <Content getters={getters}>
      <ArticleDisplay
        content={{
          [`article--${slug}`]: defaultArticle,
          articlesNav: defaultArticlesNav,
        }}
        shareUrl={shareUrl}
        slug={slug}
      />
    </Content>
  );
};

export default withRouter(Article);
