import styled from "styled-components";

import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";

const { baseSpacing: bs } = defaultShevy;

const IndicatorContainer = styled.section`
  padding: ${bs(3)} ${bs()} ${bs()};
  background-color: #e8f0f3;

  @media ${device.laptop} {
    margin: -128px 0 0;
    padding: 275px 0 0;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media ${device.laptop} {
      padding: 80px 0 200px;
    }

    .cell {
      &.member {
        text-align: center;
        color: ${props => props.theme.primary.spaceCadet};
        padding-bottom: 20px;
        margin-bottom: 20px;
        width: 100%;
        cursor: pointer;
        transition: all 0.5s ease;

        &.active {
          position: relative;

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 12px 10px;
            border-color: transparent transparent #ffffff transparent;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        h6 {
          font-size: 1.09em;
          line-height: 1.29;
          margin: 0;
        }

        p {
          margin: 0;
        }

        .img-photo {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          background-position: center;
          background-size: cover;
          margin: 0 auto 14px;
          box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
        }

        @media ${device.tablet} {
          width: 33.333%;
        }

        @media ${device.laptopL} {
          width: 20%;
        }
      }

      &.detail {
        display: none;
        background-color: white;
        width: 100%;
        box-shadow: 0 30px 50px 0 rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        flex-direction: column;
        position: relative;
        margin-top: -20px;
        margin-bottom: 20px;
        min-height: 250px;

        .btn-close {
          cursor: pointer;
          position: absolute;
          right: 10px;
          top: 10px;
        }

        @media ${device.tablet} {
          width: 66.66%;
          flex-direction: row;
        }

        @media ${device.laptopL} {
          width: 60%;
        }

        &.active {
          display: inline-flex;
        }

        .photo {
          width: 100%;
          height: 300px;
          background-size: cover;
          background-position: center;
          flex-shrink: 0;
          display: none;

          @media ${device.laptop} {
            display: block;
            height: auto;
            width: 50%;
          }
        }

        .information {
          padding: 20px;
          text-align: left;

          @media ${device.tablet} {
          }

          @media ${device.laptopL} {
            padding: 40px 40px 30px;
          }

          > p {
            &.xs {
              text-transform: uppercase;
              margin-bottom: 10px;
            }
          }

          h3 {
            font-size: 1.36;
            line-height: 1.2;
            margin-bottom: 13px;
          }

          .work-histories {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .history {
              width: calc(50% - 10px);
              p.xs {
                line-height: 1.62;
              }
            }
          }

          .social-links {
            display: flex;

            a {
              margin-right: 33px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      &.empty {
        width: 100%;

        @media ${device.tablet} {
          width: 33.333%;
        }

        @media ${device.laptopL} {
          width: 20%;
        }
      }
    }
  }
`;

export default IndicatorContainer;
