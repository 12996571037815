import styled from "styled-components";

import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";

const { baseSpacing: bs } = defaultShevy;

const ExploreTopicsContainer = styled.section`
  background-color: #ffffff;
  padding: 0 0 ${bs()};

  @media ${device.laptop} {
    background-color: #138eb675;
  }

  .wrapper {
    background-color: white;
    padding: ${bs(2)} 0 ${bs(0)};

    @media ${device.tablet} {
      padding: ${bs(5.5)} 0 0;
    }
  }

  .section-title {
    flex-basis: 100%;

    @media ${device.tablet} {
      .title {
        margin-left: ${bs(4)};
      }
    }

    @media ${device.laptop} {
      .title {
        margin-left: 0;
      }
    }
  }

  .topics {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &::after {
      content: "";
      flex: auto;
    }

    @media ${device.tablet} {
      margin-bottom: ${bs(4)};
    }

    a.fRWOYY {
      flex: 0 100%;
      height: ${bs(20.8)};
      padding: ${bs(0.5)} ${bs(0.5)};

      .topic {
        background-color: #e5e8f3;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
        padding: 0;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        height: 100%;
      }

      .summary {
        position: absolute;
        bottom: 10px;
        right: 10px;
        left: 10px;
        background-color: white;
        padding: 1em 1em 0.5em;
        border-radius: 3px;

        h3 {
          font-size: 1em;
          margin-bottom: 5px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .cta {
    color: white;
    padding: ${bs(2)} 0;

    @media ${device.laptop} {
      padding: ${bs(5.1)} 0;
    }

    .cta-wrapper {
      background: ${(props) => props.theme.primary.spaceCadet};
    }

    @media ${device.laptop} {
      .cta-wrapper {
        background: linear-gradient(
          90deg,
          transparent calc(50% - 686px),
          ${(props) => props.theme.primary.spaceCadet} calc(50% - 686px)
        );
      }
    }

    .container {
      position: relative;

      .column {
        flex-basis: 100%;
      }

      .sc-eHgmQL {
        width: 100%;
        height: 300px;
        overflow: hidden;

        @media ${device.laptop} {
          height: auto;
          overflow: visible;
        }
      }

      .content {
        max-width: ${bs(26)};
        padding: ${bs(2)} ${bs(2)} ${bs(3)} ${bs(2)};

        @media ${device.laptop} {
          margin-top: ${bs(2)};
        }

        h3 {
          font-weight: normal;
          font-size: 1.81em;
          line-height: 1.5;
        }

        a {
          margin-right: ${bs(1.5)};
          font-size: 0.81em;
        }
      }

      img {
        width: 100%;
        position: relative;

        @media ${device.tablet} {
          top: -${bs(4)};
        }
      }
    }
  }
`;

export default ExploreTopicsContainer;
