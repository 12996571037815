import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

import MobileMenu from "components/header/mobileMenu";
import { TopicMenuProvider } from "components/topic-menu";
import { Footer, Header } from "components";
import { Wrapper } from "styles/global.style";

import Landing from "./landing";
import Initiative from "./initiative";
import GetInvolved from "./get-involved";
import Topic from "./topic";
import MainBlog from "./blog/main";
import Article from "./blog/article";
import Events from "./blog/events";
import FAQsPage from "./faqs";
import Contact from "./contact";
import BigPicture from "./bigPicture";
import Thankyou from "./thankyou";
import Search from "./search";
import Charts from "./charts";
import TermsOfService from "./termsofservice";
import Privacy from "./privacy";
import ExploreTheData from "./exploreTheData";

class Pages extends Component {
  componentDidMount() {}

  render() {
    return (
      <Wrapper>
        <TopicMenuProvider>
          <Header />
          <MobileMenu />
        </TopicMenuProvider>
        <main role="main">
          <Route exact path="/" component={Landing} />
          <Route path="/explore-the-data" component={ExploreTheData} />
          <Route path="/the-initiative" component={Initiative} />
          <Route path="/get-involved" component={GetInvolved} />
          <Route path="/topic" component={Topic} />
          <Route path="/blog" component={MainBlog} exact />
          <Route path="/blog/categories/:category" component={MainBlog} exact />
          <Route path="/blog/:slug" component={Article} exact />
          <Route path="/events" component={Events} />
          <Route path="/faqs" component={FAQsPage} />
          <Route path="/contact" component={Contact} />
          <Route path="/the-big-picture" component={BigPicture} />
          <Route path="/thank-you" component={Thankyou} />
          <Route path="/search" component={Search} />
          <Route path="/charts" component={Charts} />
          <Route path="/terms-conditions" component={TermsOfService} />
          <Route path="/privacy-policy" component={Privacy} />
        </main>
        <Footer />
      </Wrapper>
    );
  }
}

export default withRouter(Pages);
