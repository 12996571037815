import styled from "styled-components";
import { Grid } from "react-styled-flexboxgrid";

import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";

const { baseSpacing: bs } = defaultShevy;

const OurApproachContainer = styled.section`
  padding: 2.13em ${bs()} ${bs()};
  background-color: #fff;

  @media ${device.laptop} {
    padding: 0 0 ${bs(5.65)};
  }

  p {
    line-height: 1.45;

    @media ${device.laptop} {
      line-height: 1.25;
    }
  }

  ${Grid} {
    width: 100%;

    @media ${device.laptop} {
    }
  }
  .title {
    h2 {
      @media ${device.tablet} {
        margin-bottom: ${bs(4)};
      }
      @media ${device.laptop} {
        margin-bottom: ${bs(6)};
      }
    }
  }

  .approach-one,
  .approach-two {
    display: flex;
    align-items: center;

    img {
      width: 100%;
      margin-bottom: ${bs()};

      @media ${device.laptop} {
        margin-bottom: 0;
        max-width: 24.27em;
      }
    }
  }

  .approach-one {
    @media ${device.laptop} {
      margin-bottom: ${bs(6)};
    }
  }

  .approach-two {
    .description {
      order: 2;
    }

    .photo {
      order: 1;
    }

    @media ${device.laptop} {
      .description {
        order: 1;
      }

      .photo {
        order: 2;
      }
    }
  }
`;

export default OurApproachContainer;
