import styled from "styled-components";

import { device } from "styles/breakpoints";
import iconRight from "assets/images/icon-arrow-right.svg";

import StyledButton from "../elements/button";

const HeaderContainer = styled.div`
  height: 80px;
  background: #fff;
  padding: 0 20px;
  transition: height 0.5s ease;

  @media screen and (min-width: 1270px) {
    height: 80px;
    padding: 0 20px;
  }

  .search-container {
    width: 100%;
    height: 100%;

    .content {
      padding-right: 160px;
      padding-left: 140px;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .search-widget {
        form {
          display: flex;

          input {
            height: 60px;
            width: 1140px;
            background-color: #f8f8f8;
            font-size: 20px;
            border: none;
            outline: none;
            padding: 15px;
            font-family: "Roboto", sans-serif;
          }

          button {
            width: 60px;
            height: 60px;
            border: none;
            background-color: #f8f8f8;
            outline: none;
            margin-left: 10px;
            font-size: 16px;
            color: #112e51;
            flex-shrink: 0;
            cursor: pointer;
            border-radius: 3px;
          }
        }

        button {
          width: 60px;
          height: 60px;
          border: none;
          background-color: #f8f8f8;
          outline: none;
          margin-left: 10px;
          font-size: 16px;
          color: #112e51;
          cursor: pointer;
          border-radius: 3px;
          flex-shrink: 0;
        }
      }
    }
  }

  .nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0;

    @media ${device.laptop} {
      padding: 0 10px;
    }

    @media ${device.laptopL} {
      padding: 0 20px;
    }

    .logo {
      display: flex;
      align-items: center;

      img {
        height: 38px;
        width: auto;
      }

      .logo-u {
        margin-right: 9px;
      }

      img {
        height: 42px;
        margin-top: 4px;
      }
    }

    .nav-menu {
      display: none;

      @media screen and (min-width: 1270px) {
        display: block;
      }

      @media ${device.laptop} {
        padding-right: 0px;
      }

      > ul {
        list-style: none;
        display: flex;
        align-items: center;

        @media ${device.tablet} {
          padding-left: 10px;
        }

        > li {
          margin-right: 50px;
          cursor: pointer;

          &.li-search {
            margin-right: 20px;
            > a {
              &.active {
                border-bottom: none;
              }
            }
          }

          &:last-child {
            margin-right: 0;
          }

          a {
            display: inline-block;

            p {
              line-height: 1.5em;
              font-weight: 500;
              color: #112e51;
              margin: 0;
            }
            text-decoration: none;
          }

          > a {
            &.active {
              border-bottom: 2px solid
                ${(props) => props.theme.primary.spaceCadet};
            }
          }

          .btn-search {
            border-radius: 3px;
            width: 40px;
            height: 40px;
            font-size: 0.72em;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .btn-search-container {
            display: flex;
            align-items: center;
            border: 2px solid rgba(17, 46, 81, 0.1);
          }

          .btn-search-label {
            padding-right: 13px;
          }

          ${StyledButton} {
            font-size: 0.72em;
            height: 40px;
          }

          @media ${device.tablet} {
          }

          @media ${device.laptopL} {
            margin-right: 50px;
          }

          &.btn-nested-menu {
            button {
              cursor: default;
            }
          }
        }
      }
    }
  }

  .dd-menu {
    .dd-menu-items {
      display: none;
      font-size: 0.72em;
      padding: 0.5em 0 0;
      margin: 0;

      > ul {
        position: relative;

        &:after {
          content: " ";
          position: absolute;
          right: 32px;
          top: -6px;
          border-top: none;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
          border-bottom: 6px solid white;
        }

        &:before {
          content: " ";
          position: absolute;
          right: 30px;
          top: -8px;
          border-top: none;
          border-right: 8px solid transparent;
          border-left: 8px solid transparent;
          border-bottom: 8px solid #d6d7d8;
        }
      }

      ul {
        color: ${(props) => props.theme.primary.spaceCadet};
        box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid #d6d7d8;
        border-radius: 3px;
        padding: 21px 0;

        li:hover:not([role="separator"]):not(.separator),
        li > *:focus,
        li:hover:not([role="separator"]):not(.separator),
        ol li > *:focus,
        .active {
          color: ${(props) => props.theme.primary.spaceCadet};
          background-color: #eaeaeb;
        }

        li {
          a {
            padding: 9px 30px;
            font-size: 16px;
            line-height: 1.5;
          }

          &:last-child {
            a {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    li.nested-dd-menu {
      a.parent-menu-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: url(${iconRight});
          margin-left: 2em;
        }
      }
    }

    &:hover {
      .dd-menu-items {
        display: block;
      }
    }
  }
`;

export default HeaderContainer;
