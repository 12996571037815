import styled from "styled-components";

import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";

const { baseSpacing: bs } = defaultShevy;

const BannerContainer = styled.section`
  background-color: #e5e8f3;
  position: relative;
  padding: ${bs(2.5)} ${bs()};

  @media ${device.laptop} {
    padding: ${bs(7.9)} 0 0;
    height: ${bs(36.5)};
  }

  > img {
    width: 100%;
    margin-left: 20px;
    margin-top: -40px;

    @media ${device.tablet} {
      width: auto;
      position: absolute;
      margin-left: 0;
      margin-top: 0;
      height: 100%;
      left: 70%;
      top: 0;
    }

    @media ${device.laptop} {
      width: 971px;
      bottom: auto;
      top: ${bs(2.85)};
      left: 60%;
    }

    @media ${device.laptopL} {
      left: 50%;
    }
  }

  .container {
    position: relative;
    z-index: 1;

    .text {
      p {
        font-size: 0.81em;
        margin-bottom: ${bs(4.1)};

        &.sm {
          font-weight: 900;
          text-transform: uppercase;
          line-height: 0.88;
          letter-spacing: 2px;
          margin-bottom: 0;
        }

        @media ${device.laptop} {
          font-size: 1.36em;
          line-height: 1.6;
        }
      }
    }

    .section-links {
      display: none;
      justify-content: space-between;

      @media ${device.laptop} {
        display: flex;
      }

      .link {
        width: calc(25% - 10px);
        max-width: 300px;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 30px 50px 0 rgba(0, 0, 0, 0.2);
        padding: ${bs()} ${bs()} ${bs(1.7)};
        cursor: pointer;
        font-weight: 600;

        > img {
          margin-bottom: 1em;
        }

        h6 {
          margin-bottom: ${bs(0.6)};
        }

        a.sm {
          font-size: 0.81em;
        }

        &:hover {
          background: ${props => props.theme.primary.spaceCadet};
          color: white;
          transition: all 200ms ease-in-out;

          img:not(.icon) {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
`;

export default BannerContainer;
