import styled from "styled-components";

import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";
import theme from "styles/theme";

import { Button } from "components/elements";

const { baseSpacing: bs } = defaultShevy;

const ExploreTopicsContainer = styled.section`
  background-color: rgba(143, 197, 216);
  padding: 0 0 ${bs()};

  .wrapper {
    background-color: rgba(229, 237, 241);
    padding: ${bs(2)} 24px;

    @media ${device.tablet} {
      padding: ${bs(5.5)} 0;
    }
  }

  .section-title {
    flex-basis: 100%;
    margin: auto;

    .title {
      text-align: center;
      margin-bottom: 30px;
    }

    a {
      border-bottom: 2px solid ${theme.accent.pear};

      &:hover {
        background: #112e51;
        border-color: #112e51;
        color: #ddd12a;
      }
    }
  }

  .topics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 100%;
    margin: auto;
    margin-top: 120px;

    @media ${device.tablet} {
      margin-bottom: ${bs(4)};
    }

    .topic-card {
      padding: 0;
      height: 100%;
      max-width: 100%;
      margin-bottom: 1em;

      @media ${device.tablet} {
        flex-basis: 49%;
      }

      @media ${device.laptop} {
        flex-basis: 32%;
      }

      details {
        background-color: white;
        padding-bottom: 30px;

        summary {
          display: block;
          overflow: hidden;

          .topic-image-container {
            overflow: hidden;
            width: 100%;
            height: ${bs(14)};
            margin: auto;
            position: relative;

            .topic-image {
              max-width: 100%;
              display: block;
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              transition: all 0.3s ease-in-out;
            }
          }

          .summary-content {
            padding: 20px 30px 0px 30px;

            h3 {
              font-size: 31px;
              line-height: 39px;
            }

            .view-toggle {
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
            }
          }

          p {
            margin-bottom: 0;
            line-height: 32px;
          }

          svg {
            height: 14px;
            transition: all 0.3s ease-in-out;

            path {
              transition: all 0.3s ease-in-out;
            }
          }

          &::-webkit-details-marker {
            display: none;
          }
        }

        .submenu {
          padding: 0px 30px;

          .submenu-items {
            margin-top: 10px;
            margin-bottom: 30px;

            .submenu-item {
              margin-bottom: 10px;

              a {
                display: flex;
                justify-content: space-between;
                align-items: center;

                div {
                  border-bottom: 3px solid transparent;
                  transition: border-bottom 0.3s ease-in-out;
                }

                &:hover > div {
                  border-bottom: 3px solid currentColor;
                }

                svg {
                  height: 14px;
                  transition: all 0.3s ease-in-out;

                  path {
                    transition: all 0.3s ease-in-out;
                  }
                }

                .wp-content {
                  font-size: 0.72em;
                }

                .submenu-arrow {
                  padding-right: 7px;
                }

                &:hover > .submenu-arrow {
                  transform: translateX(4px);
                }
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          ${Button} {
            box-sizing: border-box;
            width: 100%;
            height: ${bs(2.9)};
            color: white;
            background: rgb(239, 35, 60);
            border-radius: 4px;
            font-size: 0.72em;

            &:hover {
              background: rgb(208, 15, 39);
              transition: all 0.3s ease-in-out;
            }
          }
        }

        &[open] {
          .topic-image {
            transform: scale(1.1);
          }

          summary {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }

      &:hover,
      &:active {
        box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.14);
      }
    }
  }
`;

export default ExploreTopicsContainer;
