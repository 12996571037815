import React from "react";

import { Col, Row } from "react-styled-flexboxgrid";
import { Link } from "components/elements";
import { Link as RouterLink } from "react-router-dom";

import ImageDataParty from "assets/images/get-involved--data-party.png";

const HostADataParty = () => {
  return (
    <Row className="container">
      <Col xs={12} sm={5} className="content">
        <div className="about">
          <h3>
            <strong>Host</strong> a data party.
          </h3>
          <p>
            You bring 30+ people, we’ll bring the data and the party.
          </p>
          <Link as={RouterLink} to="/contact?mode=data-party">
            Make a request
          </Link>
        </div>
      </Col>
      <Col xs={12} sm={7} className="visual image-wrapper">
        <img src={ImageDataParty} alt="Host a data party" />
      </Col>
    </Row>
  );
};

export default HostADataParty;
