import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { Link as RouterLink } from "react-router-dom";

import ContactUsContainer, { ContactUsButton } from "./index.style";

const ContactUs = () => {
  const mapLink = "https://www.google.com/maps/place/Greater+Houston+Community+Foundation/@29.7533436,-95.4527189,15z/data=!4m5!3m4!1s0x0:0x946fb78cd17a87cd!8m2!3d29.7533436!4d-95.4527189";
  return (
    <ContactUsContainer as={Row} className="container contact-us">
      <Col className="contact-us--about" xsOffset={0} xs={12} sm={7} smOffset={1}>
        <h2>Can&rsquo;t find what you&rsquo;re looking for?</h2>
        <p>If your question is not answered above, we&rsquo;re happy to help.</p>
        <ContactUsButton as={RouterLink} to="/contact">
          Contact Us
        </ContactUsButton>
      </Col>
      <Col className="contact-us--location-wrapper" xsOffset={0} xs={12} sm={3.5} smOffset={0.5}>
        <address className="contact-us--location">
          <div>
            <p className="contact-us--location-place">
              <a href={mapLink} target="_blank" rel="noopener noreferrer" aria-label="Greater Houston Community Foundation (opens in a new tab)">
                The Greater Houston Community Foundation
              </a>
            </p>
            <p className="contact-us--location-address">
              <a href={mapLink} target="_blank" rel="noopener noreferrer" aria-label="Address (opens in a new tab)">
                515 Post Oak Blvd.
                <br />
                Suite 1000
                <br />
                Houston, TX 77027
              </a>
            </p>
            <p className="contact-us--location-phone">713.333.2200</p>
          </div>
        </address>
      </Col>
    </ContactUsContainer>
  );
};

export default ContactUs;
