import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { Link as RouterLink } from "react-router-dom";
import ImageDonate from "assets/images/get-involved-phillanthrophy-2.jpg";
import DonateContainer from "./index.style";

const Donate = ({ innerRef }) => {
  return (
    <DonateContainer className="get-involved--donate section" ref={innerRef}>
      <div className="get-involved--donate-content">
        <Row className="container">
          <Col xs={12} sm={6}>
            <img
              src={ImageDonate}
              alt="Donate to Understanding Houston to drive action and data engagement"
            />
          </Col>
          <Col
            className="get-involved--donate-about-wrapper wrapper"
            xs={12}
            sm={5}
            smOffset={1}
          >
            <div className="get-involved--donate-about">
              <h3>
                <strong>Amplify</strong> your philanthropic impact
              </h3>
              <p>
                The Greater Houston Community Foundation offers the tools and
                expertise to amplify the positive impact donors can have across
                the Houston area and beyond. Start a conversation.
              </p>
              <RouterLink to="/contact?mode=donate">
                Start a conversation
              </RouterLink>
            </div>
          </Col>
        </Row>
      </div>
    </DonateContainer>
  );
};

export default Donate;
