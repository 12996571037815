import styled from "styled-components";
import { darken } from "polished";
import theme from "styles/theme";
import { device } from "styles/breakpoints";

const GRAY = "rgba(17,46,81,0.26)";
const BG_GRAY = "rgba(17,46,81,0.04)";

const FB_BLUE = "#3064CE";
const GRAM_RED = "#E1306C";
const LINKED_BLUE = "#0077B5";
const TWITTER_BLUE = "#48A9F2";

const GRADIENT_END_BUFFER = "72px";
const GRADIENT_END = `calc(50% - ${GRADIENT_END_BUFFER})`;

const MediaContainer = styled.section`
  .get-involved--media-bg {
    position: absolute;
    z-index: -1;
    background: linear-gradient(43.74deg, #ff0000 0%, #f3003d 35.46%, #e8005f 54.86%, #cb00ba 100%);
    height: 525px;
    width: 100%;
    @media ${device.tablet} {
      width: 57%;
    }
    @media ${device.laptop} {
      width: 48%;
    }
    @media ${device.laptopL} {
      width: ${GRADIENT_END};
    }
  }

  .get-involved--media-banner {
    color: white;
    padding-top: 6rem;
    @media ${device.tablet} {
      padding-top: 144px;
    }
  }

  input {
    border: 1px solid ${GRAY};
    border-radius: 3px;
    padding: 0.25em 0.75em;
    background: ${BG_GRAY};
    font-size: 0.9em;
  }

  input,
  button {
    width: 100%;
  }

  button {
    background: ${theme.accent.pear};
    color: ${theme.primary.spaceCadet};
    height: 48px;
  }

  #get-involved-signup {
    form {
      margin-bottom: 20px;
    }
    .field-error {
      font-size: 16px;
      color: #ef233c;
    }
    div.form-field {
      margin-bottom: 20px;
    }
    label {
      font-size: 18px;
      font-weight: 800;
      font-size: 14px;
      color: #112e51;
      opacity: 0.5;
    }
    &.button {
      display: flex;
      align-items: flex-end;
    }
  }

  .subscribe-form--field {
    margin-bottom: 1em;

    label {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 14px;
      color: #112e51;
      opacity: 0.5;
    }

    &.button {
      display: flex;
      align-items: flex-end;
    }

    input {
      &::placeholder {
        color: transparent;
      }
    }
  }

  .social-links {
    margin-top: 1em;

    p {
      display: none;
    }

    ul li a {
      width: 50px;
      height: 50px;
      line-height: 50px;
      color: #ffffff;
      font-size: 1em;

      &[href*="facebook"] {
        background: ${FB_BLUE};
        &:hover {
          background: ${darken(0.15, FB_BLUE)};
        }
      }

      &[href*="linkedin"] {
        background: ${LINKED_BLUE};
        &:hover {
          background: ${darken(0.15, LINKED_BLUE)};
        }
      }

      &[href*="instagram"] {
        background: ${GRAM_RED};
        &:hover {
          background: ${darken(0.15, GRAM_RED)};
        }
      }

      &[href*="twitter"] {
        background: ${TWITTER_BLUE};
        &:hover {
          background: ${darken(0.15, TWITTER_BLUE)};
        }
      }
    }
  }
`;

export default MediaContainer;
