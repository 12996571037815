import styled from "styled-components";

import { device } from "styles/breakpoints";

const MapContainer = styled.div`
  position: relative;
  text-align: center;

  @media ${device.laptop} {
    display: block;
    text-align: left;
  }

  .highcharts-container {
    .highcharts-credits {
      display: none;
    }

    .highcharts-background {
      fill: none;
    }

    .highcharts-tooltip-box {
      stroke-width: 0;
      border-radius: 5px;
      fill-opacity: 1;
      fill: white;
    }

    .highcharts-point {
      stroke-width: 0;
    }
  }

  .map-pagination {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    span {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      display: block;
      background: ${props => props.theme.primary.spaceCadet};
      margin-bottom: 15px;
      position: relative;
      cursor: pointer;

      &.active {
        &:after {
          content: "";
          position: absolute;
          width: 25px;
          height: 25px;
          display: block;
          border-radius: 50%;
          border: 2px solid #112e51;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
        }
      }
    }
  }

  .chart-colors {
    display: inline-block;

    @media ${device.laptop} {
      display: block;
      position: absolute;
      padding-top: 50px;
      width: 50%;
      right: 100px;
    }

    @media ${device.laptopL} {
      display: block;
      position: absolute;
      padding-top: 0;
      right: 0;
    }

    span {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 6px;
      font-size: 0.72em;

      @media ${device.laptop} {
        margin-bottom: 12px;
      }

      &:before {
        content: "";
        width: 18px;
        height: 18px;
        border-radius: 5px;
        display: inline-block;
        margin-right: 10px;
      }

      &.color-hispanic {
        &:before {
          background: #ff7474;
        }
      }

      &.color-nhasian {
        &:before {
          background: #fcd300;
        }
      }

      &.color-nhblack {
        &:before {
          background: #30b1db;
        }
      }

      &.color-nhwhite {
        &:before {
          background: #8376e0;
        }
      }

      &.color-nhother {
        &:before {
          background: #7ac896;
        }
      }
    }
  }
`;

export default MapContainer;
