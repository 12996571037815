import styled from "styled-components";
// import { Link } from "components/elements";
// import { defaultShevy } from "styles/global.style";
// import { device } from "styles/breakpoints";
// import theme from "styles/theme";
import { defaultShevy } from "styles/global.style";

const { baseSpacing: bs } = defaultShevy;

const ThankyouContainer = styled.div`;
    padding: ${bs(4)} ${bs(1)};
`;

export default ThankyouContainer;

