import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Col, Row } from "react-styled-flexboxgrid";
import { Link } from "components/elements";

import ImageVote00 from "assets/images/get-involved--vote-00--health.png";
import ImageVote01 from "assets/images/get-involved--vote-01--environment.png";
import ImageVote02 from "assets/images/get-involved--vote-02--arts-culture.png";
import ImageVote03 from "assets/images/get-involved--vote-03--housing.png";
import ImageVote04 from "assets/images/get-involved--vote-04--community.png";
import ImageVote05 from "assets/images/get-involved--vote-05--economic.png";
import ImageVote06 from "assets/images/get-involved--vote-06--education.png";
import ImageVote07 from "assets/images/get-involved--vote-07--civic.png";

import VerticalSlider from "./vertical-slider";

const topics = [
  {
    image: ImageVote00,
    about: "View and show your interest in health",
    slug: "vote-topic-health"
  },
  {
    image: ImageVote01,
    about: "View and show your interest in environment",
    slug: "vote-topic-environment"
  },
  {
    image: ImageVote02,
    about: "View and show your interest in arts and culture",
    slug: "vote-topic-arts-culture"
  },
  {
    image: ImageVote03,
    about: "View and show your interest in housing",
    slug: "vote-topic-housing"
  },
  {
    image: ImageVote04,
    about: "View and show your interest in community",
    slug: "vote-topic-community"
  },
  {
    image: ImageVote05,
    about: "View and show your interest in economic issues",
    slug: "vote-topic-economic"
  },
  {
    image: ImageVote06,
    about: "View and show your interest in education",
    slug: "vote-topic-education"
  },
  {
    image: ImageVote07,
    about: "View and show your interest in civic engagement",
    slug: "vote-topic-civic"
  }
];
const StartVoting = () => {
  return (
    <Row className="container">
      <Col xs={12} sm={5} className="content">
        <div className="about">
          <h3>
            <strong>Vote</strong> for what matters to you.
          </h3>
          <p>
            Help us make community-driven decisions about where we put our time,
            attention and resources.
          </p>
          <Link as={RouterLink} to="/the-big-picture#explore-topics">
            Start voting by exploring the data
          </Link>
        </div>
      </Col>
      <Col xs={12} sm={7} className="visual">
        <VerticalSlider
          slidesToShow={3}
          centerMode
          focusOnSelect
          swipe={false}
          swipeToSlide={false}
          autoplay
        >
          {topics.map(topic => (
            <div
              className="get-involved--start-voting-img-wrapper"
              key={topic.slug}
            >
              <img src={topic.image} alt={topic.about} />
            </div>
          ))}
        </VerticalSlider>
      </Col>
    </Row>
  );
};

export default StartVoting;
