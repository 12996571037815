import styled from "styled-components";

import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";

// import PageContainer from "pages/page.style";
import VoteContainer from "components/vote/index.style";

const { baseSpacing: bs } = defaultShevy;

const TopicPageContainer = styled.div`
  &.topic-disasters .banner {
    background-position: bottom center;
  }
  .banner {
    background-size: cover;
    background-position: top center;
    height: ${bs(20)};
    max-height: ${bs(42)};

    @media ${device.tablet} {
      height: ${bs(40.3)};
    }
  }

  ${VoteContainer} {
    margin-right: 0;
    margin-bottom: 50px;
    padding: 0.5em 1.5em;

    transform: rotate(90deg);
    transform-origin: bottom left;
    transition: 200ms ease-in-out transform;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    > *,
    p.text-voted {
      display: inline-block;
    }
    p.text-voted {
      margin-left: 1em;
    }

    h6 + p {
      display: none;
    }

    h6 {
      margin-bottom: 0;
    }

    @media ${device.desktop} {
      padding: 25px 25px 40px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      > *,
      h6 + p {
        display: block;
      }

      h6 {
        font-size: ${defaultShevy.h4.fontSize};
        margin-bottom: 8.5px;
      }

      p.text-voted {
        display: flex;
        margin-left: 0;
      }

      transform: rotate(0deg);
      margin-right: 4em;
      margin-bottom: 0;
    }
  }

  .sidebar {
    position: sticky;
    top: 0;
    z-index: 90;
    width: 480px;

    @media ${device.desktop} {
      padding-top: 100px;
      width: 400px;
    }
  }

  .topic--summary {
    margin-top: -${bs(3)};

    @media ${device.desktop} {
      margin-top: -${bs(10)};
    }

    .content {
      min-height: ${bs(21)};
    }

    @media ${device.desktop} {
      .content {
        min-height: calc(50vh + 300px);
      }
    }
    .content {
      padding: 3em 1em 7em 1em;
      background: rgba(255, 255, 255, 1);

      @media ${device.laptopL} {
        padding-left: 0;
        padding-right: 0;
      }
    }

    h1 {
      padding-bottom: 0.5em;
      font-size: 2em;

      @media ${device.tablet} {
        font-size: ${defaultShevy.h1.fontSize};
      }
    }
  }
`;

export default TopicPageContainer;
