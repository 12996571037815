import React, { Component } from "react";
import Slider from "react-slick";
import queryString from "query-string";
import { GlobalChartStyles } from "components/data-viz";
import ChartsContainer from "./index.style";

class Charts extends Component {
  async componentDidMount() {
    try {
      const params = queryString.parse(window.location.search);
      const id = params.id || false;
      const title = params.title || "";

      document.getElementById("header").classList.add("hidden");
      document.getElementById("mobile-menu-container").classList.add("hidden");
      document.getElementById("footer").classList.add("hidden");
      document.getElementById("embed").dataset.chart = title;

      if (params.title2) {
        document.getElementById("embed2").dataset.chart = params.title2;
      }

      if (params.title3) {
        document.getElementById("embed3").dataset.chart = params.title3;
      }

      import("components/data-viz").then(({ Chart, Data }) => {
        (async function() {
          await Data(id);
        })();
      });
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    document.getElementById("header").classList.remove("hidden");
    document.getElementById("mobile-menu-container").classList.remove("hidden");
    document.getElementById("footer").classList.remove("hidden");
  }

  render() {
    const isSlider = queryString.parse(window.location.search).title2;
    const slide2 = queryString.parse(window.location.search).title2;
    const slide3 = queryString.parse(window.location.search).title3;
    const settings = {
      dots: true,
      infinite: false,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      pauseOnHover: true,
    };
    return (
      <ChartsContainer>
        <GlobalChartStyles />
        {isSlider ? (
          <Slider {...settings}>
            <div id="embed" className="data-viz" data-chart="fsafds" />
            {slide2 && (
              <div id="embed2" className="data-viz" data-chart="fsafds" />
            )}
            {slide3 && (
              <div id="embed3" className="data-viz" data-chart="fsafds" />
            )}
          </Slider>
        ) : (
          <div id="embed" className="data-viz" data-chart="fsafds" />
        )}
      </ChartsContainer>
    );
  }
}

export default Charts;
