import styled from "styled-components";

import { device } from "styles/breakpoints";

const VoteContainer = styled.div`
  .vote-container {
        padding: 10px 0 5px;
        width: 100%;
        max-width: 480px;
        margin: 60px 0 0;
        ${'' /* display: flex;
        flex-direction: row;
        @media ${device.laptopL} {
          display: block;
        } */}
        background: #E8F0F3;
        padding: 30px;

        @media ${device.laptopL} {
          background: #E8F0F3;
          padding: 30px;
          width: 100%;
        }

        h4 {
          font-size: 1.27em;
          margin-bottom: 8.5px;
          font-weight: 700;
          font-size: 0.9em;
          font-weight: 700;
          line-height: 1.4em;
        }

        p.sm {
          border-bottom: 1px solid #d6d7d8;
          padding-bottom: 18px;
          font-size: 0.65em;
          line-height: 1.5;
        }

        &.sticky {
          transform: translateZ(0px);
          position: fixed;
          bottom: 0px;
          left: 0px;
          width: 340px;
        }

        .vote-intro {
          @media ${device.laptopL} {
          }
        }

        .vote-box {
          flex-direction: column;
          margin: 0;
          display: flex;
          padding: 0;
          justify-content: center;
          vertical-align: middle;
          position: relative;
          align-items: normal;

          .vote-count {
            background: #fff;
            align-items: center;
            display: inline-block;
            width: 46px;
            height: 46px;
            position: absolute;
            left: -10px;
            top: -10px;
            border-radius: 50%;
            color: #112E51;
            overflow: hidden;
            transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
            transition-delay: 0.2s;
            box-shadow: 0 1px 2.2px rgba(0, 0, 0, 0.008),0 2.3px 5.3px rgba(0, 0, 0, 0.012),0 4.4px 10px rgba(0, 0, 0, 0.015), 0 7.8px 17.9px rgba(0, 0, 0, 0.018),0 14.6px 33.4px rgba(0, 0, 0, 0.022),0 35px 80px rgba(0, 0, 0, 0.03);
            text-align: center;

            p {
              margin: 0;
              padding: 0;
              font-weight: 700;
              width: 100%;
              font-size: 0.6em;

              @media ${device.laptopL} {
                width: auto;
                padding: 30px;
                font-size: 0.7em;
                line-height: 46px;
              }
            }
          }
          .vote-actions {
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            width: 100%;
            text-align: center;

            @media ${device.laptopL} {
              padding: 0;
              display: block;
              flex-direction: row;
              align-items: baseline;
            }

            button {
              width: 100%;
              margin: auto;
              background: #112E51;
              color: #fff;
              font-size: 0.65em;

              @media ${device.laptopL} {
                width: 100%;
                font-size: 0.7em;
              }
            }

            p.sm {
              font-size: 0.6em;
              margin: 5px auto 0;
              border-radius: 3px;
              border-bottom: 0;
              padding: 3px;
              background-color: rgba(255,255,255,0.9);
              box-shadow: 0 1px 2.2px rgba(0, 0, 0, 0.008),0 2.3px 5.3px rgba(0, 0, 0, 0.012),0 4.4px 10px rgba(0, 0, 0, 0.015), 0 7.8px 17.9px rgba(0, 0, 0, 0.018),0 14.6px 33.4px rgba(0, 0, 0, 0.022),0 35px 80px rgba(0, 0, 0, 0.03);
              text-align: center;
              width: 100%;

              @media ${device.laptopL} {
                order: 2;
                background: none;
                width: auto;
                box-shadow: none;
                border-radius: 0;
                margin: 10px 0 0;
                padding: 0;
                font-size: 0.6em;
              }
            }
          }
        }
      }
`;

export default VoteContainer;
