import styled from "styled-components";

import { defaultShevy } from "styles/global.style";
import { device } from "styles/breakpoints";

const { baseSpacing: bs } = defaultShevy;

const BannerContainer = styled.section`
  position: relative;

  .container {
    margin-bottom: ${bs()};
    padding: ${bs(3)} 0 0;

    @media ${device.tablet} {
      padding: ${bs(4)} 0 ${bs(4)};
      margin-bottom: 0;
    }
    @media ${device.laptop} {
      padding: ${bs(4)} 0 ${bs(10)};
    }

    @media ${device.tablet} {
      .content {
        max-width: 45%;
      }
    }
  }

  img {
    display: none;

    @media ${device.tablet} {
      display: block;
      position: absolute;
      left: calc(100vw - 20%);
      top: ${bs(2.6)};
      height: 829px;
      width: 757px;
      transform: left 0.2s ease-in-out;
    }

    @media ${device.laptop} {
      left: calc(100vw - 400px);
    }
    @media ${device.laptopL} {
      left: calc(100vw - 757px);
    }
  }

  .data-viz {
    display: none;

    @media ${device.tablet} {
      display: block;
      position: absolute;
      z-index: 2;
      left: calc(100vw - 20%);
      top: ${bs(2.6)};
      height: 829px;
      width: 757px;
      transform: left 0.2s ease-in-out;
    }

    @media ${device.laptop} {
      left: calc(100vw - 400px);
    }
    @media ${device.laptopL} {
      left: calc(100vw - 757px);
    }
  }
`;

export default BannerContainer;
