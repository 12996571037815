import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Row, Col } from "react-styled-flexboxgrid";
import { Link } from "components/elements";
import ThankYouContainer from "./index.style";

const ThankYou = () => {
  return (
    <ThankYouContainer className="section thank-you">
      <section className="thankyou">
        <Row className="container">
          <Col xs={12} className="banner">
            <h1>Thank you</h1>
          </Col>
          <Col xs={12} className="content-wrap">
            <p>
              Thank you for reaching out to Understanding Houston. A member of
              our team will review your message and reach out to discuss next
              steps.
            </p>
            <Link as={RouterLink} to="/">
              Go to the homepage
            </Link>
          </Col>
        </Row>
      </section>
    </ThankYouContainer>
  );
};
export default ThankYou;
