import React, { useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import { Link } from "react-router-dom";
import { Link as StyledLink, Button } from "components/elements";
import { Banner, Content } from "components";
import { getAcfFieldsWithPageIdAPI } from "services/helpers/apis";
import cachedLandingFields from "helpers/content-stubs/landing.json";
import { ExploreTopics, HighlightsEvents, OurScope, UHou } from "./sections";
import Joyride from "react-joyride";
import { isMobile } from "react-device-detect";

const UHouWithRef = React.forwardRef((props, ref) => {
  return <UHou innerRef={ref} {...props} />;
});

const OurScopeWithRef = React.forwardRef((props, ref) => {
  return <OurScope innerRef={ref} {...props} />;
});

const ExploreTopicsWithRef = React.forwardRef((props, ref) => {
  return <ExploreTopics innerRef={ref} {...props} />;
});

const HighlightsEventsWithRef = React.forwardRef((props, ref) => {
  return <HighlightsEvents innerRef={ref} {...props} />;
});

const ContentsDisplay = (props) => {
  const { acfFields } = props.content.landing;
  const [hasCompletedTour, setHasCompletedTour] = useState(false);
  const [doNotShowChecked, setDoNotShowChecked] = useState(false);

  const steps = [
    {
      target: ".explore",
      content: "Find a topic you’re interested in.",
      placement: "bottom",
    },
    {
      target: ".get-involved",
      content: "Share your feedback, donate or join our mission.",
      placement: "bottom",
    },
    {
      target: ".articles-reports",
      content: "Get deeper insights and guest perspectives in our blog.",
      placement: "bottom",
    },
    {
      target: ".li-search",
      content: "Type key words to search the data and articles.",
      placement: "left",
    },
    {
      target: ".bar-chart",
      content:
        "Hover or interact with charts to filter data for customizable views.",
    },
    {
      target: ".explore-faq",
      content:
        "Have questions about who we are or how to use the site? Read FAQs here.",
    },
    {
      target: ".subscribe-form",
      content: "Subscribe to the newsletter here!",
    },
    {
      target: ".logo",
      content: (
        <>
          <div style={{ paddingBottom: "20px" }}>
            Thanks and enjoy browsing!
          </div>
          <div>
            {" "}
            <label>
              <input
                type="checkbox"
                checked={doNotShowChecked}
                onChange={() => setDoNotShowChecked(!doNotShowChecked)}
              />{" "}
              Do not show again.
            </label>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("hasCompletedTour")) setHasCompletedTour(true);
  }, [hasCompletedTour]);

  useEffect(() => {
    if (doNotShowChecked) localStorage.setItem("hasCompletedTour", true);
  }, [doNotShowChecked]);

  return (
    <div className="contents">
      <Banner value={acfFields.banner} />
      {!isMobile && !hasCompletedTour && (
        <Joyride
          steps={steps}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          styles={{
            options: {
              primaryColor: "rgb(239, 35, 60)",
            },
            tooltip: {
              fontSize: "1.2em",
              color: "black",
            },
            tooltipContent: {
              paddingBottom: "10px",
              textAlign: "left",
            },
          }}
          locale={{
            skip: "Skip",
            last: "Done",
          }}
        />
      )}

      <Waypoint scrollableAncestor={window} topOffset={100}>
        <section className="section save-date">
          <div className="container">
            <div className="content">
              <div className="first-column">
                <p
                  className="text-uhou-event"
                  dangerouslySetInnerHTML={{ __html: acfFields.event_name }}
                />
                {/* <p className="text-uhou-event">All currently scheduled events have been postponed.</p>
                <div><p className="xs">Please stay tuned and sign up to our newsletter for rescheduling information.</p></div> */}
              </div>
              <div className="second-column">
                <p className="xs" style={{ marginBottom: "11px" }}>
                  <b>
                    {/* <b>All currently scheduled events have been postponed.</b> */}
                    {acfFields.event_date}, {acfFields.event_start_time} -{" "}
                    {acfFields.event_end_time}
                  </b>
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: acfFields.event_description,
                  }}
                />
                {/* <div><p className="xs">Please stay tuned and sign up to our newsletter for rescheduling information.</p></div> */}
              </div>
              <div className="third-column">
                <div>
                  {/* <Button
                    style={{ display: "inline-block", marginRight: 30 }}
                    onClick={() =>
                      window.open("/get-involved#subscribe", "_self")
                    }
                  >
                    Sign Up
                  </Button> */}
                  <Button
                    onClick={() =>
                      window.open(
                        `${acfFields.event_reserve_my_seat_link}`,
                        "_blank"
                      )
                    }
                  >
                    Reserve My Seat
                  </Button>
                  <span style={{ display: "inline-block" }}>
                    <StyledLink href="/events">View all events</StyledLink>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Waypoint>

      <Waypoint scrollableAncestor={window} topOffset={100}>
        <UHouWithRef content={acfFields.understanding_houston} />
      </Waypoint>

      <Waypoint
        scrollableAncestor={window}
        topOffset={100}
        bottomOffset="100px"
      >
        <OurScopeWithRef content={acfFields.our_scope} />
      </Waypoint>

      <Waypoint scrollableAncestor={window} topOffset={100}>
        <ExploreTopicsWithRef />
      </Waypoint>

      <Waypoint scrollableAncestor={window} topOffset={100}>
        <HighlightsEventsWithRef />
      </Waypoint>

      <section className="section donate">
        <h1>{acfFields.get_invovled.title}</h1>
        <p className="md">{acfFields.get_invovled.sub_title}</p>
        <Button>
          <Link to="/get-involved">{acfFields.get_invovled.button_text}</Link>
        </Button>
      </section>
    </div>
  );
};

const Contents = () => {
  return (
    <Content
      getters={[
        {
          name: "landing",
          makeGetter: () => {
            return getAcfFieldsWithPageIdAPI(107).then((response) => ({
              data: {
                acfFields: response.data.acf,
              },
            }));
          },
        },
      ]}
    >
      <ContentsDisplay
        content={{
          landing: {
            acfFields: cachedLandingFields.acf,
          },
        }}
      />
    </Content>
  );
};

export default Contents;
