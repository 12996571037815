import React from "react";
import styled from "styled-components";
import { Grid } from "react-styled-flexboxgrid";

import { device } from "styles/breakpoints";
import { defaultShevy } from "styles/global.style";
import { SectionContainer, SectionCTAContainer } from "../index.style";

const { baseSpacing: bs } = defaultShevy;

export const ArticleTag = styled.span`
  padding: 5px 10px;
  color: white;
  text-transform: uppercase;
  font-size: 0.54em;
  font-weight: bold;
  border-radius: 3px;
  background-color: ${props => props.bgcolor};
  position: absolute;
  top: -${bs()};
  left: ${bs(1.5)};
  transform: translateY(-100%);
`;

const HighlightsEventsContainer = styled(({ forwardRef, ...props }) => (
  <SectionContainer {...props} ref={forwardRef} />
))`
  margin: ${bs()} -${bs(0.5)} ${bs(4.5)};

  @media ${device.tablet} {
    padding-bottom: 0;
    margin: ${bs()} 0 -40px;
    padding-top: ${bs(5.04)};
    // padding-bottom: ${bs(5.04)};
    background: white;
  }

  .inner-content {
    .title {
      padding: ${bs(2.5)} ${bs(3)} ${bs(1.5)} ${bs(1.5)};

      @media ${device.tablet} {
        margin-bottom: ${bs(4)};
        padding: 0;
      }

      .title-left {
        h1 {
          color: ${props => props.theme.primary.spaceCadet};
          margin: 0;
        }
      }
    }
  }

  .events-contents {
    .smaller-container {
      width: 100%;
      max-width: 1218px;
      position: relative;
      margin: 0 auto;
    }

    .sub-title {
      font-size: 14px;
      font-weight: bold;
    }

    .most-featured-article {
      margin-bottom: 10px;
      @media ${device.tablet} {
        margin-bottom: ${bs(1.85)};
      }

      .article-image {
        height: ${bs(14.1)};
        background-color: ${props => props.theme.primary.pantoneRed};
        background-size: cover;
        background-position: center;

        @media ${device.tablet} {
          height: ${bs(24)};
        }
      }

      .article-content {
        background: white;
        position: relative;
        padding: ${bs(1.5)} ${bs(1.5)} ${bs(2.5)};

        @media ${device.tablet} {
          margin-top: -${bs(2.5)};
          padding: ${bs()} ${bs(1.7)} ${bs(2.45)};
        }

        ${ArticleTag} {
          @media ${device.laptopL} {
            top: -${bs(2.25)};
            left: 3em;
            transform: initial;
          }
        }

        h3 {
          font-size: 1.36em;
          margin: 0;
        }

        p.sub-title {
          margin-bottom: 5px;
        }

        p.description {
          display: none;
          line-height: 1.5;

          > span p {
            margin: 0;
          }

          @media ${device.tablet} {
            display: block;
          }
        }
      }
    }

    .featured-articles {
      ${Grid} {
        max-width: 100%;
      }

      .smaller-container {
        @media ${device.tablet} {
          padding-right: 1em;
          padding-left: 1em;
          padding-bottom: 0;

          .events-slider {
            padding-right: 2em;
          }
        }

        @media ${device.laptop} {
          padding-right: 0;
          padding-left: 0;
        }

        .description {
          display: none;
          line-height: 1.5;

          > span p {
            margin: 0;
          }

          @media ${device.tablet} {
            display: block;
          }
        }

        .articles {
          color: ${props => props.theme.primary.spaceCadet};

          h3 {
            color: ${props => props.theme.primary.spaceCadet};
            font-size: 1.09em;
            margin: 0 0 ${bs(0.5)};
          }

          .sub-title {
            margin-bottom: ${bs(0.5)};
          }

          .latest-article {
            position: relative;
            margin-bottom: ${defaultShevy.baseSpacing(0.5)};

            .article-image {
              width: 100%;
              height: 10em;
              background-color: ${props => props.theme.primary.pantoneRed};
              background-size: cover;
              background-position: center;
            }

            @media ${device.tablet} {
              background-size: cover;
              background-position: center;
              background-color: ${props => props.theme.primary.pantoneRed};
              margin: 0 0 2em;
              height: ${bs(23)};

              .article-image {
                display: none;
              }
            }

            @media ${device.laptopL} {
              margin: 1em 1em 0;
            }

            .wrapper {
              background: white;

              @media ${device.tablet} {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 18.77em;
              }

              .summary {
                padding: ${bs(1.2)} ${bs(1.5)} ${bs(2)};
                position: relative;

                @media ${device.tablet} {
                  padding: ${bs(1.2)};
                }
              }
            }
          }

          .recent-articles {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            
            .article {
              display: flex;
              flex-direction: column;
              background: white;
              margin-bottom: ${defaultShevy.baseSpacing(0.5)};

              &.narrow {
                padding: 1em 0;
                border-bottom: 
                
              }    

              @media ${device.tablet} {
                flex-direction: row;
              }

              @media ${device.laptopL} {
                margin-bottom: 0;
              }

              .article-image {
                width: 100%;
                height: 10em;
                background-color: ${props => props.theme.primary.pantoneRed};
                background-size: cover;
                background-position: center;
                flex-shrink: 0;

                @media ${device.tablet} {
                  width: ${bs(9.2)};
                  height: ${bs(10.2)};
                }
              }

              .summary {
                padding: ${bs(1.2)} ${bs(1.05)} ${bs(2)} ${bs(1.5)};
                position: relative;
                line-height: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;

                @media ${device.tablet} {
                  padding: 0 0 0 ${bs()};
                }

                p {
                  width: 100%;
                }

                h3 {
                  width: 100%;
                  line-height: 1.25;
                }

                ${ArticleTag} {
                  @media ${device.tablet} {
                    margin-bottom: ${bs(0.5)};
                    display: inline-block;
                    transform: translateY(0);
                    position: relative;
                    top: 0;
                    left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ${SectionCTAContainer} {
    &.laptop-hidden {
      padding-left: ${bs(1.8)};

      &:before {
        width: ${bs(1.2)};
        left: 0;
        transform: translate(0, -50%);
      }
    }
  }
`;

export default HighlightsEventsContainer;
